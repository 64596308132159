import { Component, OnInit, Renderer2 } from '@angular/core';
import { Observable } from 'rxjs';
import { CancellationService } from 'src/app/services/cancellation.service';
import { RouterHistoryService } from 'src/app/services/router-history.service';

@Component({
  selector: 'app-mnp-attention',
  templateUrl: './mnp-attention.component.html',
  styleUrls: ['./mnp-attention.component.scss']
})
export class MnpAttentionComponent implements OnInit {
  phone_number$: Observable<string> = this.cancelService.line_number$;
  back_button = true;
  checked = false;
  mat_content = 'radio_button_unchecked';

  constructor(private cancelService: CancellationService,
    private render: Renderer2,
    readonly _routerHistoryService: RouterHistoryService
    ) { }

  ngOnInit() {
  }
  toggleChecked() {
    this.checked = !this.checked;
    if (this.checked === false) {
      this.mat_content = 'radio_button_unchecked';
    } else {
      this.mat_content = 'radio_button_checked';
    }
  }
}
