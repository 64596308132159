import { UrlSegment } from '@angular/router';

export class Utils {
    constructor() {
    }

    public static isValue(value: any): boolean {
        return (value !== undefined && value !== null);
    }

    public static getDisplayMessage(response: any, altString: string): string {
        let msgString = altString;
        if (Utils.isValue(response) && Utils.isValue(response.messages)) {
            if (response.messages.length > 0) {
                msgString = response.messages[0];
            }
        }
        return msgString;
    }

    public static getDisplayErrorMessage(errResponse: any, altString: string): string {
        let errString = altString;
        if (Utils.isValue(errResponse) && Utils.isValue(errResponse.error) && Utils.isValue(errResponse.error.errors)) {
            if (errResponse.error.errors.length > 0) {
                errString = errResponse.error.errors[0];
            }
        }
        return errString;
    }

    public static createUrlString(pathes: UrlSegment[], params: Object, fromRoot: boolean = true): string {
        let urlString = fromRoot ? '/' : '';
        const pathParts: string[] = [];
        pathes.forEach(path => {
            pathParts.push(path.path);
        });
        urlString += pathParts.join('/');
        const paramString = this.createUrlParamString(params);
        if (paramString.length > 0) {
            urlString += paramString;
        }
        return urlString;
    }

    public static createUrlParamString(params: Object) {
        let paramString = '';
        if (Utils.isValue(params)) {
            const paramParts: string[] = [];
            Object.keys(params).forEach(key => {
                paramParts.push(key + '=' + params[key]);
            });
            if (paramParts.length > 0) {
                paramString += '?';
                paramString += paramParts.join('&');
            }
        }
        return paramString;
    }

    public static unselectsFromSelecteds(candidates: any, selecteds: any): any {
        const unselects = [];
        if (!Utils.isValue(candidates)) {
            return null;
        }
        candidates.forEach(candidate => {
            if (Utils.isValue(selecteds)) {
                const founds = selecteds.filter(selected => {
                    return selected.name === candidate.name;
                });
                if (founds.length < 1) {
                    unselects.push(candidate);
                }
            } else {
                unselects.push(candidate);
            }
        });
        return unselects;
    }
    public static ConvertStringToNumber(input: string) {
        if (input.trim().length === 0) {
            return NaN;
        }
        return Number(input); // Number(null) === 0
    }
    public static convertExpireDate (date: string) {
      const monthExpire = date.slice(2, 4); // MM
      const yearExpire = date.slice(0, 2); // YY
      const year = new Date().getFullYear().toString().slice(0, 2);
      return `${monthExpire}/${year}${yearExpire}`;
    }
    // Check webview run on Android or IOS
    public static isAndroid(): boolean {
      return (/Android/i).test(navigator.userAgent);
    }
    public static isIOS(): boolean {
      return (/iPhone|iPad|iPod|Opera Mini/i).test(navigator.userAgent);
    }
}
