
    <mat-expansion-panel class="invoice-item">
      <mat-expansion-panel-header class="invoice-item__header">
        <mat-panel-title>
          <div class="title" *ngIf="invoice">
            <p class="title-name">{{invoice.invoice_code}}</p>
            <p class="title-info">Total: &nbsp;&nbsp;¥{{invoice.sub_total + invoice.sub_total_tax}}&nbsp;({{pay_status}})</p>
            <p class="title-info">Payment: {{invoice.pay_method}}</p>
          </div>
        </mat-panel-title>
        <div class="details">Details</div>
      </mat-expansion-panel-header>
      <div class="invoice-item__content">
        <a href="https://operation-system-be.sakuramobile.jp/invoices/show/{{secure_random}}" target="_blank" class="invoice-link">
        <div class="invoice show-invoice" [ngClass]="{showInvoicePaid: pay_status === 'Unpaid' ? false: true}">

              Show invoice
            <mat-icon class="invoice__icon">chevron_right</mat-icon>
        </div>
      </a>
        <div class="invoice pay" *ngIf="pay_status === 'Unpaid' && invoice.pay_method === 'CREDIT CARD' ? true: false" routerLink="/payment-information/pay-by-new-card" (click)="PayInvoice()">

              Pay
            <mat-icon class="invoice__icon">chevron_right</mat-icon>
        </div>
      </div>
    </mat-expansion-panel>

