<div class="paypal__information">
  <div class="widget">
    <app-menu
      [back_button]="back_button"
      [title]="phone_number$ | async"
    ></app-menu>
    <div class="widget__contents">
      <div class="widget__contents--title">
        <img
          src="../../../../../../assets/images/logo-payment.svg"
          alt="credit-card"
          class="icon-creditcard"
        />
        <p class="title-creditcard">Submit PayPal Information</p>
      </div>

      <div class="widget__contents--information">
        <p class="info">
          <span class="attention">Attention:</span> You will be responsible for
          the transaction fee if you choose to receive the refund through
          PayPal.
        </p>
        <p class="info">
          The refund can only be made after your contract with Sakura Mobile is
          officially terminated.
        </p>
      </div>

      <div class="widget__contents--paypal-form">
        <form [formGroup]="refundPaypal">
        <p class="label">Paypal Email Address</p>
        <mat-form-field class="paypal">
          <input matInput  class="input" formControlName="refund_paypal_address" [value]="paypal_email" required/>
        </mat-form-field>

        <p class="label">Account Holder's Name</p>
        <mat-form-field class="paypal">
          <input matInput  class="input" formControlName="refund_paypal_holder_name" [value]="paypal_holdername" required/>
        </mat-form-field>
        </form>
      </div>

      <div class="widget__contents--billing-work">
          <mat-icon class="billing--icon">help</mat-icon>
          <p class="billing--title" (click)="openBilling()">How does billing work ?</p>
      </div>

      <div class="widget__contents--conditions">
        <mat-icon class="condition-check" (click)="toggleChecked()">{{mat_content}}</mat-icon>
        <span class="condition-content"
          >I understand the refund condition and confirm the information provided is correct.</span
        >
      </div>

      <button mat-button class="widget__contents--submitted-paypal"
      [ngClass]="{'submitted--button': checked && refundPaypal.valid}"
      (click)="submittedPaypal()"
      [disabled]="!checked && !refundPaypal.valid"
      >
       Submit Paypal Information
      </button>

      <div class="widget__contents--step">
        <button mat-icon-button disabled>
            <mat-icon> radio_button_unchecked</mat-icon>
        </button>
        <button mat-icon-button disabled>
          <mat-icon> radio_button_unchecked</mat-icon>
      </button>
      <button mat-icon-button>
          <mat-icon>
            lens
          </mat-icon>
      </button>
    </div>
    </div>
  </div>
</div>
