<div class="term__conditions__mnp">
  <button class="close--button" mat-icon-button mat-dialog-close matRippleDisabled="disabled">
    <mat-icon class="cancel--icon">cancel</mat-icon>
  </button>

  <mat-dialog-content class="mat-typography">
    <div class="title">
      <mat-icon class="term--icon">help</mat-icon>
      <p class="term--title">Terms and conditions for MNP</p>
    </div>

    <div class="info">
      <ol>
        <li>
          The issuance fee is JPY 1,100.
        </li>
        <li>The MNP number will be terminated by email within 3 days. The issued MNP is valid for 14 days.</li>
        <li>The contract will be terminated automatically on the day your number is successfully transferred to another
          provider by MNP. However, the monthly fee applies for the same month.</li>
        <li>If you do not transfer to another provider after being issued the MNP, the contract will automatically
          continue.</li>
        <li>Once the MNP is issued, you will not be able to request a regular termination until the 14-day period ends.
        </li>
        <li>A new MNP cannot be issued until the previously issued MNP expires.</li>
        <li>You must be contractor himself/herself. You cannot apply for farmily/friend.</li>
      </ol>
    </div>

    <div class="title">
      <mat-icon class="term--icon">help</mat-icon>
      <p class="term--title">How does billing work ?</p>
    </div>
    <p class="billing-info">According to the billing cycle, <span class="info-strong">there will be two more bills
      </span> due after you terminate the contract.</p>
    <p class="payment-method-info">Below is an example of the billing cycle for the Smart Pit Card payment method:</p>
    <table>
      <tr>
        <th>Month</th>
        <th>Invoicing</th>
        <th>Payment</th>
      </tr>
      <tr>
        <td>May</td>
        <td><span style="font-weight: 300;">End of</span> June</td>
        <td class="sakura--text">Jul 1-10</td>
      </tr>
      <tr>
        <td>June</td>
        <td><span style="font-weight: 300;">End of</span> July</td>
        <td class="sakura--text">Aug 1-10</td>
      </tr>
      <tr>
        <td>July</td>
        <td><span style="font-weight: 300;">End of</span> August</td>
        <td class="sakura--text">Sep 1-10</td>
      </tr>
    </table>

    <div class="title">
      <mat-icon class="term--icon">help</mat-icon>
      <p class="term--title">Will the deposit be refunded ?</p>
    </div>
    <p class="billing-info">You will get <span class="info-strong">a partial refund</span> for the deposit. The
      remainder of the deposit will be calculated and refunded to the provided bank account <span
        class="sakura--text">1.5 months after your contract is cancelled.</span></p>
    <p class="billing-info">Depending on the usage fees of the last 2 months, you might not receive a refund from us.
    </p>

    <div class="title">
      <mat-icon class="term--icon">help</mat-icon>
      <p class="term--title">Where will deposit be refunded ?</p>
    </div>
    <p class="billing-info">The deposit can be refunded to your <span class="sakura--text">Japanese bank account</span>
      or your <span class="sakura--text">Paypal account.</span> We will notify you once the refund is processed. </p>
    <p class="note">NOTE:</p>
    <div class="info">
      <ol>
        <li>It needs to be your Japanese bank account. If you have trouble providing your Japanese bank account, please
          contact us.</li>
        <li>You will be responsible for the transaction.</li>
      </ol>
    </div>
  </mat-dialog-content>
</div>