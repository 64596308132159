import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ContractsService } from 'src/app/services/contracts.service';
import { Invoices } from 'src/app/types/invoice';

@Component({
  selector: 'app-complete-contracts',
  templateUrl: './complete-contracts.component.html',
  styleUrls: ['./complete-contracts.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CompleteContractsComponent implements OnInit {
  public contract_id_complete: number;
  public start_day_complete: Date;
  public end_day_complete: Date;
  public contract_code_complete: string;
  public subscription_plan_complete: string;
  public invoiceCom: Invoices;
  public pay_status_complete: boolean;
  constructor(private _router: Router, private _contractService: ContractsService) { }

  @Input() contractComplete;
  @Input() invoicesList;
  ngOnInit() {
    this.renderCompleteContracts();
  }
  public getContractDetailComplete(contract_id: number) {
    const pay_method = this.contractComplete.properties.find(
      item => item.type.key === 'PAY METHOD'
    );
    this._contractService.set_Pay_Method = pay_method === undefined ? null : pay_method.property.value.toLowerCase();
    console.log('CompleteContract Component:: pay_method$', pay_method.property.value.toLowerCase());
    // this._contractService.getListProduct(contract_id);
    this._router.navigate([`portal/contract-details/${contract_id}`]);
  }
  public PaymentHistory() {
    //  set invoice list active to Subject
    this._contractService.set_invoice_list = this._contractService.findArr_Invocies(this.invoicesList, this.contractComplete);
    this._contractService.set_contract = this.contractComplete; // set contract as $
    // END  =======================
    this._router.navigate([`portal/payment-history/${this.contract_id_complete}`]);
  }
  private renderCompleteContracts() {
    this.invoiceCom = this._contractService.findInvoice(this.invoicesList, this.contractComplete);
    console.log(this.invoiceCom);
    // tslint:disable-next-line:max-line-length
    this.pay_status_complete = this.invoiceCom === undefined ? true : this.invoiceCom.every(invoice => invoice.pay_status === 1) ? true : false;
    this.contract_id_complete = this.contractComplete.id;
    this.contract_code_complete = this.contractComplete.contract_code;
    const valueOfSub_Plan = this.contractComplete.properties.find(
              propertie => propertie.type.key === 'SUBSCRIPTION PLAN');

    this.subscription_plan_complete = valueOfSub_Plan === undefined ? '' : valueOfSub_Plan.property.value;
    this.start_day_complete = this.contractComplete.start_at;
    this.end_day_complete = this.contractComplete.end_at;
      }
}
