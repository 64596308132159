<div class="app-portal-cancellation">
  <div class="widget">
    <app-menu
      [back_button]="back_button"
      [title]="
        (is_fiber$ | async) ? 'Terminate Contract' : (line_number$ | async)
      "
    ></app-menu>
    <div class="widget_contents">
      <form [formGroup]="cancelReasons_Case1">
        <app-reasons-cancel
          *ngIf="product_type !== 'fiber'"
          (toggleRating)="toggleRating($event)"
          (valueReason)="valueReason($event)"
          [cancelReasons_Case2]="cancelReasons_Case2"
        ></app-reasons-cancel>
        <app-fiber-reasons-cancellation
          *ngIf="product_type === 'fiber'"
          [fiberCancelReasons]="fiberCancelReasons"
        ></app-fiber-reasons-cancellation>
        <ng-container *ngIf="rateService">
          <app-rate-service #rateStar (ratingUpdated)="onRatingChanged($event)">
          </app-rate-service>
        </ng-container>
        <div class="note">
          <div class="note__header">
            <div class="square-dot"></div>
            <p class="note-title">
              How was your experience with Sakura Mobile? <br />Let us know
              anything!
            </p>
          </div>
          <div class="note__form-field">
            <textarea
              matInput
              class="note_textarea"
              placeholder="Use this column for comments, suggestions ..."
              formControlName="note"
              maxlength="250"
            ></textarea>
          </div>
        </div>
        <div class="btn-submit">
          <button
            class="btn__next"
            [ngClass]="{
              submitted:
                cancelReasons_Case1.valid ||
                cancelReasons_Case2.valid ||
                fiberCancelReasons.valid
            }"
            [disabled]="
              !cancelReasons_Case1.valid &&
              !cancelReasons_Case2.valid &&
              !fiberCancelReasons.valid
            "
            type="button"
            (click)="onNext()"
          >
            Next
          </button>
          <div class="stepper">
            <button mat-icon-button disabled>
              <mat-icon>lens</mat-icon>
            </button>
            <button mat-icon-button disabled>
              <mat-icon> radio_button_unchecked </mat-icon>
            </button>
            <button
              mat-icon-button
              disabled
              *ngIf="(mnp_credit_request$ | async) || (dotSmartpit$ | async)"
            >
              <mat-icon> radio_button_unchecked </mat-icon>
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
