import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { CancellationService } from 'src/app/services/cancellation.service';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { ContractsService } from 'src/app/services/contracts.service';
import { map } from 'rxjs/operators';
import { RouterHistoryService } from 'src/app/services/router-history.service';
import { V1UserAPIService } from 'src/app/services/v1api.service';
import { Subscription } from 'rxjs';
import { LoginResult } from 'src/app/types/auth';
import { Constants } from 'src/app/constants';
import { Utils } from 'src/app/utils';


@Component({
  selector: 'app-mnp-request',
  templateUrl: './mnp-request.component.html',
  styleUrls: ['./mnp-request.component.scss'],
  providers: [],
  encapsulation: ViewEncapsulation.None
})
export class MnpRequestComponent implements OnInit, OnDestroy {
  private subs: Subscription = new Subscription();
  public item: string = '';
  public email: string = '';
  public checkMNP: boolean = true;
  public line_number: string;
  public lineNumber: string;
  public contract_code: string;
  public payment_method: string;
  public device_id: string;
  public contract_product_id: number;
  phone_number$: Observable<string> = this.cancellService.line_number$;
  back_button = true;
  mnpRequest$ = this.contractService.item$.pipe(
    //map( item => item === 'voice' ? true : 'data' || 'wifi' ? false : false)
    map(type_product => type_product === 'voice' ? true : false)
  );
  is_fiber$ = this.contractService.item$.pipe(
    map(item => item === 'fiber' ? true : false)
  )
  constructor(
    private _api: V1UserAPIService,
    private cancellService: CancellationService,
    private contractService: ContractsService,
    private _ActiveRouter: ActivatedRoute,
    private route: Router,
    readonly _routerHistoryService: RouterHistoryService,
    private _contractServices: ContractsService,
  ) {}

  ngOnInit() {
      this.subs.add(this.contractService.item$.subscribe(
        item => {
          this.item = item;
        }
      ));
      this.subs.add(this.contractService.contract$.subscribe(
        contract => {
          this.contract_code = contract.contract_code;
        }
      ));
      this.subs.add(this.contractService.pay_method$.subscribe(
        pay_method => this.payment_method = pay_method
      ));
      this.subs.add(this.contractService.deviceID$.subscribe(
        deviceID => {
          this.device_id = deviceID;
        }
      ));
      // this.cancellService.line_number$.subscribe(lineNumber => {
      //   this.line_number = lineNumber;
      // });
  }

  getLoginUser(): LoginResult {
    const userJson = localStorage.getItem(Constants.LOGIN_USER);
    if (!Utils.isValue(userJson)) {
      return null;
    }
    const user = <LoginResult>JSON.parse(userJson);
    return user;
  }
  private getEmailFromCurrentUser(): string {
    const user = this.getLoginUser();
    console.log('user:', user);
    return user ? user.data.user.email : '';
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

  onRoute(string: string) {
    console.log(string);
    if (string === 'cancellation') {
      this.cancellService.setMnpRequest = false;
      this.route.navigate(['/cancellation']);
    }
    if (string === 'mnp-attention') {
      this.cancellService.setMnpRequest = true;
      this.route.navigate(['/mnp-attention']);
    }
  }

  openSuspend() {
    this.subs.add(this.contractService.ContractProduct_UserPropertie(this.contract_product_id).subscribe(
      res => {
        const [user_properties] = res;
        const user = user_properties.user;
        this.email = user.email;
        this.openSuspend();
      }
    ));
    const email = this.getEmailFromCurrentUser();
    this.email = email;
    
    if (this.checkMNP && this.item.length > 0 && this.email.length > 0) {
      // tslint:disable-next-line:max-line-length
      console.log('openSuspend');
      window.open(`https://my.sakuramobile.jp/requests/renew_plan?item=${this.item}&contract_code=${this.contract_code}&payment=${this.payment_method}&email=${this.email}&device_id=${this.device_id}&line_number=${this.line_number}&suspend=yes`, '_blank');
    } else {
      console.log('条件が満たされていません');
      return;
    }
  }
}
