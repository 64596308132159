import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit, Renderer2, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { LoadChildren, Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { Constants } from 'src/app/constants';
import { ContractsService } from 'src/app/services/contracts.service';
import { LoadingService } from 'src/app/services/loading.service';
import { PaymentInvoicesService } from 'src/app/services/payment-invoices.service';
import { RouterHistoryService } from 'src/app/services/router-history.service';
import { CustomValidators } from 'src/app/custom-validators';
import { TopUpService } from 'src/app/services/top-up.service';
import { TopUpData } from 'src/app/types/top-up-data';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-top-up-checkout',
  templateUrl: './top-up-checkout.component.html',
  styleUrls: ['./top-up-checkout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TopUpCheckoutComponent implements OnInit, OnDestroy {
  private sub = new SubSink();
  checked = false;
  cardNumber = '';
  cardName = '';
  expireMonth = '';
  expireYear = '';
  mat_content = 'radio_button_unchecked';
  cardInformation: FormGroup;
  card_holder_name: FormControl;
  card_number: FormControl;
  expire_month: FormControl;
  expire_year: FormControl;
  card_cvv: FormControl;
  card_number$: Observable<string>;
  topUpData: TopUpData;
  contract_product_id: number;
  spinner$ = this._loadingService.spinner$;
  constructor(
    private _topUpService: TopUpService,
    private fb: FormBuilder,
    private _router: Router,
    private _snackBar: MatSnackBar,
    private _renderer2: Renderer2,
    private _loadingService: LoadingService,
    private _contractService: ContractsService,
    private _paymentInvoice: PaymentInvoicesService,
    readonly _routerHistoryService: RouterHistoryService) { }

  ngOnInit() {
    this.createFormControl();
    this.createCardInformationForm();
    this._paymentInvoice.createScriptTab(this._renderer2);
    this.sub.sink = this._topUpService.topUpData$.subscribe(res => this.topUpData = res);
    this.sub.sink = this._contractService.productId$.subscribe(res => {
      if (typeof res === 'string') {
        this.contract_product_id = Number(res);
      } else {
        this.contract_product_id = res;
      }
    });
  }
  makeCapital(event: any) {
    this.cardName = event.target.value.toUpperCase();
  }
  cardNumberChange(event: any) {
    this.cardNumber = event.target.value.replace(/\s+/g, '');
    this.card_number.patchValue(this.cardNumber);
  }
  onBlurExpireMonth(event: any) {
    if (event.target.value.length === 1) {
      this.expireMonth = `0${event.target.value}`;
      this.expire_month.patchValue(this.expireMonth);
    }
  }
  onBlurExpireYear(event: any) {
    if (event.target.value.length === 1) {
      this.expireYear = `0${event.target.value}`;
      this.expire_year.patchValue(this.expireYear);
    }
  }
  checkOutTopUp() {
    if (this.checked && this.cardInformation.valid) {
      this._loadingService.setSpinnerEvent = true;
      const cardInformation = this.cardInformation.getRawValue();
      const params = {
        holdername: cardInformation.card_holder_name,
        cardno: cardInformation.card_number.trim().replace(/\s+/g, ''),
        expire: cardInformation.expire_year + cardInformation.expire_month,
        securitycode: cardInformation.card_cvv,
        tokennumber: '1',
      };
      (window as any).Multipayment.init(Constants.SHOP_ID);
      (window as any).Multipayment.getToken(params, (response) => {
        if (response.resultCode !== '000') {
          this._loadingService.setSpinnerEvent = false;
          this._snackBar.dismiss();
            this._snackBar.open(
              'Your card information is incorrect',
              'OK',
              Constants.defaultSnackBarConfig('error')
            );
        } else {
          const token: string = response.tokenObject.token[0];
          const dataMB: number = this.topUpData.data * 1000;
          this._topUpService.checkOutTopUpData(token, this.contract_product_id, dataMB)
          .pipe(
            finalize(() => this._loadingService.setSpinnerEvent = false)
          )
          .subscribe(
            res => {
              console.log(res);
              window.location.href = res.data.auth_url;
              // this._router.navigate(['top-up-finish']);
            },
            (error: HttpErrorResponse) => {
              this._snackBar.dismiss();
              this._snackBar.open(
                'Payment Failed',
                'OK',
                Constants.defaultSnackBarConfig('error')
              );
            }
          );
        }
      });
    }
  }
  toggleChecked() {
    this.checked = !this.checked;
    this.checked ? this.mat_content = 'radio_button_checked' :  this.mat_content = 'radio_button_unchecked';
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  private createCardInformationForm() {
    this.cardInformation = this.fb.group({
      card_holder_name: this.card_holder_name,
      card_number: this.card_number,
      expire_month: this.expire_month,
      expire_year: this.expire_year,
      card_cvv: this.card_cvv
    },
    {
      validators: [CustomValidators.checkCardExpired()]
    });
  }
  private createFormControl() {
    this.card_holder_name = this.fb.control(
      '',
      Validators.compose([Validators.required]) // Validators.pattern('^[A-Z ]*')
    );
    this.card_number = this.fb.control(
      '',
      Validators.compose([
        Validators.required,
        Validators.pattern('^[0-9 ]+'),
        Validators.minLength(15),
        Validators.maxLength(16),
      ])
    );
    this.expire_month = this.fb.control(
      '',
      Validators.compose([
        Validators.required,
        Validators.pattern('^[0-9 ]+'),
        Validators.minLength(2),
        Validators.maxLength(2),
      ])
    );
    this.expire_year = this.fb.control(
      '',
      Validators.compose([
        Validators.required,
        Validators.pattern('^[0-9 ]+'),
        Validators.maxLength(2),
      ])
    );
    this.card_cvv = this.fb.control(
      '',
      Validators.compose([
        Validators.required,
        Validators.pattern('^[0-9 ]+'),
        Validators.minLength(3),
        Validators.maxLength(4),
      ])
    );
  }

}
