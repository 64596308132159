import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { DatePipe, CommonModule } from '@angular/common';
import { AppRoutingModule } from './/app-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule, NoopAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/pages/auth/login/login.component';
import { SignUpComponent } from './components/pages/auth/sign-up/sign-up.component';
import { HomeComponent } from './components/pages/home/home.component';
import { PortalComponent } from './components/pages/portal/portal.component';
import { PasswordResetComponent } from './components/pages/auth/password-reset/password-reset.component';
import { PasswordChangeComponent } from './components/pages/auth/password-change/password-change.component';
import { ConfirmationResendComponent } from './components/pages/auth/confirmation-resend/confirmation-resend.component';
import { SiteHeaderComponent } from './components/shared/site-header/site-header.component';
import { PageNotFoundComponent } from './components/pages/other/page-not-found/page-not-found.component';
import { UsersComponent } from './components/pages/admin/users/users.component';
import { UserDetailComponent } from './components/pages/admin/users/user-detail/user-detail.component';
import { UserSummaryComponent } from './components/pages/admin/users/user-detail/user-summary/user-summary.component';
import { UserRoleComponent } from './components/pages/admin/users/user-detail/user-role/user-role.component';
import { TwoButtonDialogComponent } from './components/shared/two-button-dialog/two-button-dialog.component';
import { MaterialUiModule } from './material-ui/material-ui.module';
import { ContractDetailsComponent } from './components/pages/portal/contract-details/contract-details.component';
import { ProductComponent } from './components/shared/product/product.component';
import { MenuComponent } from './components/shared/menu/menu.component';
import { ActiveContractsComponent } from './components/pages/portal/active-contracts/active-contracts.component';
import { EndingContractsComponent } from './components/pages/portal/ending-contracts/ending-contracts.component';
import { CompleteContractsComponent } from './components/pages/portal/complete-contracts/complete-contracts.component';
import { ProductDetailComponent } from './components/pages/portal/product-detail/product-detail.component';
import { BackbuttonDirective } from './directives/backbutton.directive';
import { PaymentHistoryComponent } from './components/pages/portal/payment-history/payment-history.component';
import { InvoiceComponent } from './components/shared/invoice/invoice.component';
import { CancellationComponent } from './components/pages/cancellation/cancellation.component';
import { ReasonsCancelComponent } from './components/pages/cancellation/reasons-cancel/reasons-cancel.component';
import { ChooseReasonDirective } from './directives/choose-reason.directive';
import { PolicyCancellationComponent } from './components/pages/cancellation/policy-cancellation/policy-cancellation.component';
import { PolicyContentsComponent } from './components/pages/cancellation/policy-cancellation/policy-contents/policy-contents.component';
import { RateServiceComponent } from './components/pages/cancellation/rate-service/rate-service.component';
import { ContractCancellationComponent } from './components/pages/cancellation/contract-cancellation/contract-cancellation.component';
import { BillingWorkComponent } from './components/pages/cancellation/contract-cancellation/billing-work/billing-work.component';
import { ReturnSimcardComponent } from './components/pages/cancellation/contract-cancellation/return-simcard/return-simcard.component';
import { MnpRequestComponent } from './components/pages/cancellation/mnp-request/mnp-request.component';
import { SubmitedRequestComponent } from './components/pages/cancellation/submited-request/submited-request.component';
import { MnpAttentionComponent } from './components/pages/cancellation/mnp-attention/mnp-attention.component';
import { MnpSubmitInfomationComponent } from './components/pages/cancellation/mnp-submit-infomation/mnp-submit-infomation.component';
import { PaymentmethodDirective } from './directives/paymentmethod.directive';
// tslint:disable-next-line:max-line-length
import { PaypalInformationComponent } from './components/pages/cancellation/mnp-submit-infomation/paypal-information/paypal-information.component';
// tslint:disable-next-line:max-line-length
import { ConfirmPaypalInformationComponent } from './components/pages/cancellation/mnp-submit-infomation/confirm-paypal-information/confirm-paypal-information.component';
// tslint:disable-next-line:max-line-length
import { TermConditionsMnpComponent } from './components/pages/cancellation/mnp-submit-infomation/confirm-paypal-information/term-conditions-mnp/term-conditions-mnp.component';
// tslint:disable-next-line:max-line-length
import { PaypalSubmittedComponent } from './components/pages/cancellation/mnp-submit-infomation/paypal-submitted/paypal-submitted.component';
// tslint:disable-next-line:max-line-length
import { JpBankInformationComponent } from './components/pages/cancellation/mnp-submit-infomation/jp-bank-information/jp-bank-information.component';
// tslint:disable-next-line:max-line-length
import { ConfirmJpBankInformationComponent } from './components/pages/cancellation/mnp-submit-infomation/confirm-jp-bank-information/confirm-jp-bank-information.component';

import { AccountInformationComponent } from './components/pages/account-information/account-information.component';

// tslint:disable-next-line:max-line-length
import { ConfirmationMnpInformationComponent } from './components/pages/cancellation/mnp-submit-infomation/confirmation-mnp-information/confirmation-mnp-information.component';
// tslint:disable-next-line:max-line-length
import { BranchNumberComponent } from './components/pages/cancellation/mnp-submit-infomation/jp-bank-information/branch-number/branch-number.component';
import { PaymentInformationComponent } from './components/pages/payment-information/payment-information.component';
import { PayByNewCardComponent } from './components/pages/payment-information/pay-by-new-card/pay-by-new-card.component';
import { PaymentReceivedComponent } from './components/pages/payment-information/payment-received/payment-received.component';
import { PersonalDetailComponent } from './components/pages/account-information/personal-detail/personal-detail.component';
import { UpdateCardComponent } from './components/pages/account-information/update-card/update-card.component';
import { CardUpdatedComponent } from './components/pages/account-information/card-updated/card-updated.component';
import { ChangePaymentMethodComponent } from './components/pages/account-information/change-payment-method/change-payment-method.component';
// tslint:disable-next-line:max-line-length
import { DialogDepositRequiredComponent } from './components/pages/account-information/change-payment-method/dialog-deposit-required/dialog-deposit-required.component';
// tslint:disable-next-line:max-line-length
import { ConfirmationChangePaymentComponent } from './components/pages/account-information/confirmation-change-payment/confirmation-change-payment.component';
import { DepositComponent } from './components/shared/deposit/deposit.component';
import { SubmittedRequestComponent } from './components/pages/account-information/submitted-request/submitted-request.component';
import { SpinnerComponent } from './components/shared/spinner/spinner.component';
import { TopUpSelectComponent } from './components/pages/top-up-data/top-up-select/top-up-select.component';
import { TopUpCheckoutComponent } from './components/pages/top-up-data/top-up-checkout/top-up-checkout.component';
import { TopUpFinishComponent } from './components/pages/top-up-data/top-up-finish/top-up-finish.component';
import { InviteFriendsComponent } from './components/pages/invite-friends/invite-friends.component';
import { PreventCloseMenuDirective } from './directives/prevent-close-menu.directive';
import { PointDescriptionComponent } from './components/pages/points/point-description/point-description.component';
import { FiberReasonsCancellationComponent } from './components/pages/cancellation/fiber-reasons-cancellation/fiber-reasons-cancellation.component';
import { ItemOptionComponent } from './components/pages/item-option/item-option.component';
import { Swtich5gComponent } from './components/pages/item-option/swtich5g/swtich5g.component';
import { PaymentMethodDialogComponent } from './components/pages/account-information/payment-method-dialog/payment-method-dialog.component';
import { MatDialogModule } from '@angular/material';



const components = [
  AppComponent,
  LoginComponent,
  SignUpComponent,
  HomeComponent,
  PortalComponent,
  PasswordResetComponent,
  PasswordChangeComponent,
  ConfirmationResendComponent,
  SiteHeaderComponent,
  PageNotFoundComponent,
  UsersComponent,
  UserDetailComponent,
  UserSummaryComponent,
  UserRoleComponent,
  TwoButtonDialogComponent,
  ContractDetailsComponent,
  ProductComponent,
  MenuComponent,
  ActiveContractsComponent,
  EndingContractsComponent,
  CompleteContractsComponent,
  ProductDetailComponent,
  BackbuttonDirective,
  PaymentHistoryComponent,
  InvoiceComponent,
  CancellationComponent,
  ReasonsCancelComponent,
  ChooseReasonDirective,
  PolicyCancellationComponent,
  PolicyContentsComponent,
  RateServiceComponent,
  ContractCancellationComponent,
  BillingWorkComponent,
  ReturnSimcardComponent,
  MnpRequestComponent,
  SubmitedRequestComponent,
  MnpAttentionComponent,
  MnpSubmitInfomationComponent,
  PaymentmethodDirective,
  PaypalInformationComponent,
  ConfirmPaypalInformationComponent,
  TermConditionsMnpComponent,
  PaypalSubmittedComponent,
  JpBankInformationComponent,
  ConfirmJpBankInformationComponent,
  AccountInformationComponent,
  ConfirmationMnpInformationComponent,
  BranchNumberComponent,
  PaymentInformationComponent,
  PayByNewCardComponent,
  PaymentReceivedComponent,
  PersonalDetailComponent,
  UpdateCardComponent,
  CardUpdatedComponent,
  ChangePaymentMethodComponent,
  DialogDepositRequiredComponent,
  ConfirmationChangePaymentComponent,
  DepositComponent,
  SubmittedRequestComponent,
  SpinnerComponent,
  TopUpSelectComponent,
  TopUpCheckoutComponent,
  TopUpFinishComponent,
  InviteFriendsComponent,
  PreventCloseMenuDirective,
  PointDescriptionComponent,
  FiberReasonsCancellationComponent

];
@NgModule({
  declarations: [...components, ItemOptionComponent, Swtich5gComponent, PaymentMethodDialogComponent],
  imports: [
    BrowserAnimationsModule,
    MaterialUiModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    CommonModule,
    NoopAnimationsModule,
    MatDialogModule,
  ],
  providers: [
    { provide: 'windowObject', useValue: window }
  ],
  entryComponents: [
    TwoButtonDialogComponent,
    PolicyCancellationComponent,
    PolicyContentsComponent,
    BillingWorkComponent,
    ReturnSimcardComponent,
    TermConditionsMnpComponent,
    BranchNumberComponent,
    DialogDepositRequiredComponent,
    PaymentMethodDialogComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
