<div class="personal-detail">
  <div class="widget">
    <app-menu [back_button]="true"  [title]="'Personal Detail'"></app-menu>

    <div class="widget__contents">

      <div class="widget__contents--personal-detail-title">
        <img src="../../../../assets/images/logo-payment.svg" alt="logo payments" class="image">
        <p class="details">Personal Details</p>
      </div>

      <div class="widget__contents--detail-card" *ngIf="account_payment_info$ |async as account_info">
        <p class="detail-left">Card Holder's Name</p>
        <p class="detail-right">{{account_info.holder_name | uppercase}}</p>
        <p class="detail-left">Payment Method</p>
        <p class="detail-right">{{account_info.payment_method |titlecase}}</p>
        <p class="detail-left">Number</p>
        <p class="detail-right">{{account_info.card_no}}</p>
        <p class="detail-left">Expiry Date</p>
        <p class="detail-right">{{expire_date$ |async}}</p>
      </div>

      <div class="widget__contents--personal-detail-title">
        <mat-icon class="icon-create">create</mat-icon>
        <p class="details">Update Information</p>
      </div>

      <div class="widget__contents--method">
        <div class="update--card" routerLink="/update-card">
          <p class="title-card">Update Card Information</p>
          <mat-icon class="arrow-icon">keyboard_arrow_right</mat-icon>
        </div>
        <div class="update--card not-border-top" (click)="onClickChangePayMethod()">
          <ng-container *ngIf="is_within_pay_method_change_period === true && can_change_pay_method_user === false; else basic_pay_method_change">
            <p class="title-card">Change Payment Method (via Contact Us)</p>
            <mat-icon class="arrow-icon">open_in_new</mat-icon>
          </ng-container>
          <ng-template #basic_pay_method_change>
            <p class="title-card">Change Payment Method</p>
            <mat-icon class="arrow-icon">keyboard_arrow_right</mat-icon>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
