<div class="app-payment-history">
    <div class="widget">
            <app-menu [title]="title_menu" [back_button]="back_button" [invoices]="invoices"></app-menu>
        <div class="widget-content">
            <div class="widget-content__contract">
              <mat-icon class="icon-contract">{{title_icon}}</mat-icon>
              <div class="content-title">
                  <p class="content-title__name">{{title_contract}}</p>
                  <p class="content-title__info">Phone Number: {{phone_number$ | async}}</p>
                  <p class="content-title__info">Contract code: {{contract_code}} </p>
              </div>

            </div>
            <div class="icon-arrow">
              <mat-icon class="icon-invocies">signal_cellular_4_bar</mat-icon>
            </div>
            <div class="widget-content__invocies">
                <mat-accordion>
                <app-invoice  *ngFor="let invoice of invoices" [invoice]="invoice"></app-invoice>
                </mat-accordion>
            </div>
          </div>
    </div>
</div>
