import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { pluck } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { AccountInformationService } from 'src/app/services/account-information.service';

@Component({
  selector: 'app-point-description',
  templateUrl: './point-description.component.html',
  styleUrls: ['./point-description.component.scss']
})
export class PointDescriptionComponent implements OnInit {
  private sub = new SubSink();
  email: string;
  points: any;
  constructor(private activeRoute: ActivatedRoute,
    private _accountService: AccountInformationService) { }

  ngOnInit() {
    this.activeRoute.paramMap.subscribe(res => {
      const param = res.get('points');
      this.points = parseInt(param, 10);
    });
    this.sub.sink = this._accountService.userInformation$.subscribe(data => {
      this.email = data.data.email;
    });
  }
}
