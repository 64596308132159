
import { MatSnackBarConfig } from '@angular/material';
import { environment } from 'src/environments/environment';

export declare type MySnackBarDefaultType = 'success' | 'warning' | 'error' | 'critical' | 'info';
export class Constants {
    public static srcImageOfBanner = [
      '../../../../assets/images/get3000give3000_banner.jpg',
      '../../../../assets/images/sakura_fiber_banner.jpg'];
    public static hrefLinkOfBanner = [
      'https://support.sakuramobile.jp/hc/en-us/articles/4408255554317',
      'https://www.sakuramobile.jp/lp/?i=sakura-fiber-internet'
    ];
    public static LONGTERM_ORDER = 'https://longterm-order.sakuramobile.jp/step1';
    public static FIBER_ORDER = 'https://fiber-order.sakuramobile.jp/fiber-order';
    public static MSG_DURATION_SHORT = 2000;
    public static MSG_DURATION_MIDDLE = 5000;
    public static MSG_DURATION_LONG = 10000;

    public static LOGIN_USER = 'login_user';
    public static STORE_KEY = 'lastAction';
    public static MINUTES_UNTIL_AUTO_LOGOUT = 60; // minutes
    public static TIME_CHECK_INTERVAL = 1000; // miliseconds
    public static API_ROOT = environment.apiRoot;
    // public static API_ROOT = 'https://api.zerowired-dev.mydns.jp';
    public static GMO_PAYMENT_GATEWAY = 'https://static.mul-pay.jp/ext/js/token.js';

    public static API_VER = '/v1';
    public static API_SIGNUP = Constants.API_ROOT + '/users/sign_up';   // POST
    public static API_CONFIRMATION_RESEND = Constants.API_ROOT + '/users/send_mail_confirm';  // POST
    public static API_LOGIN = Constants.API_ROOT + '/users/login';
    public static API_LOGOUT = Constants.API_ROOT + '/users/logout'; // POST
    public static API_RESET_PASSWORD = Constants.API_ROOT + '/users/request_reset_password';  // POST
    public static API_UPDATE_PASSWORD = Constants.API_ROOT + '/users/update_password'; // PUT
    public static API_IS_LOGGING_IN = Constants.API_ROOT + '/users/is_logging_in';
    public static API_HAS_ROLE = Constants.API_ROOT + Constants.API_VER + '/auth/has_role';

    public static API_LIST_USERS = Constants.API_ROOT + Constants.API_VER + '/users';   // GET
    public static API_SHOW_USER = Constants.API_ROOT + Constants.API_VER + '/users';    // +/:id GET
    public static API_UPDATE_USER = Constants.API_ROOT + Constants.API_VER + '/users';  // +/:id PUT

    public static API_LIST_USER_ROLES = Constants.API_ROOT + Constants.API_VER + '/user_role';   // GET
    public static API_SHOW_USER_ROLES = Constants.API_ROOT + Constants.API_VER + '/user_role';   // +/:id GET
    public static API_UPDATE_USER_ROLES = Constants.API_ROOT + Constants.API_VER + '/user_role';   // +/:id PUT

    public static API_LIST_INVOICE =   Constants.API_ROOT + '/api' + Constants.API_VER + '/invoices';
    public static API_LIST_CONTRACTS =   Constants.API_ROOT + '/api' + Constants.API_VER + '/list_contracts_portal';
    public static API_CONTRACT_DETAIL =  Constants.API_ROOT + '/api' + Constants.API_VER + '/contracts'; // +/:contract_id POST
    public static API_LIST_PRODUCT = Constants.API_ROOT + '/api' + Constants.API_VER + '/contract_products'; // +/:contract_id POST
    public static API_CONTRACT_PRODUCT = Constants.API_ROOT + '/api' + Constants.API_VER + '/contract_product';
    // +/:contract_product_id POST
    public static API_USER_PROPERTIES = Constants.API_ROOT + '/api' + Constants.API_VER + '/user'; // +/: user_id GET
    public static API_GET_PHONE_NUMBER = Constants.API_ROOT + '/api' + Constants.API_VER + '/get_phone_by_contract'; // +/: contract_id GET
    public static API_GET_VALID_COUPONS = Constants.API_ROOT + '/api' + Constants.API_VER + '/iij_get_valid_coupons';
    // +/:contract-product-id
    public static API_GET_PACKET_LOG = Constants.API_ROOT + '/api' + Constants.API_VER + '/iij_get_packet_log'; // +contract-product-id
    public static API_GET_CALL_LOG = Constants.API_ROOT + '/api' + Constants.API_VER + '/iij_get_call_log'; // +contract-product-id

    public static API_CHECK_MNP_REQUEST = Constants.API_ROOT + '/api' + Constants.API_VER + '/mnp_cancellation_status'; //  /+: product_id
    public static API_CONTRACT_CANCELLATION = Constants.API_ROOT + '/api' + Constants.API_VER + '/contracts/cancellation';
    public static API_MNP_REQUEST = Constants.API_ROOT + '/api' + Constants.API_VER + '/contracts/cancellation_by_mnp';

    public static API_ACCOUNT_INFORMATION = Constants.API_ROOT + '/api' + Constants.API_VER + '/user_info'; // +: user_id
    public static API_ACCOUNT_PAYMENTS_INFORMATION = Constants.API_ROOT + '/api' + Constants.API_VER + '/payments/user_info'; // +: user_id
    public static API_UPDATE_CARD_INFORMATION = Constants.API_ROOT + '/api' + Constants.API_VER + '/payments/card_update';
    public static API_PAY_METHOD_CHANGABLE = Constants.API_ROOT + '/api' + Constants.API_VER + '/payments/pay_method_changable'; // user_id
    public static API_USER_DEPOSITS = Constants.API_ROOT + '/api' + Constants.API_VER + '/payments/user_deposits'; // user_id GET
    // tslint:disable-next-line:max-line-length
    public static API_CHANGE_PAYMENT_METHOD_NOTIFY = Constants.API_ROOT + '/api' + Constants.API_VER + '/payments/change_payment_method_notification'; // POST
    public static API_TOP_UP_DATA = Constants.API_ROOT + '/api' + Constants.API_VER + '/contract_products/top_up_data'; // POST
    public static SHOP_ID = '9100176413941';
    public static API_CREDITCARD_INFORMATION = Constants.API_ROOT + '/api' + Constants.API_VER + '/payments/card_info'; // +: user_id
    public static API_PAY_BY_NEW_CARD = Constants.API_ROOT + '/api' + Constants.API_VER + '/invoices/pay_by_card'; // POST : Pay by new card

    public static API_REFERRAL_CODE = Constants.API_ROOT + '/api' + Constants.API_VER + '/referral_codes/new'; // POST : Get referral code

    public static API_GET_POINTS = Constants.API_ROOT + '/api' + Constants.API_VER + '/user_point_data';
    
    public static API_GET_5G_STATUS = Constants.API_ROOT + '/api/v2/contracts/5g_status';
    public static API_SET_5G_STATUS = Constants.API_ROOT + '/api/v2/contracts/5g_status';

    public static defaultSnackBarConfig(type: MySnackBarDefaultType): MatSnackBarConfig {
        const config = new MatSnackBarConfig();
        config.horizontalPosition = 'center';
        config.verticalPosition = 'top';
        switch (type) {
            case 'success':
                config.duration = Constants.MSG_DURATION_SHORT;
                config.panelClass = ['notify_snackbar', 'success'];
                break;
            case 'info':
                config.duration = Constants.MSG_DURATION_MIDDLE;
                config.panelClass = ['notify_snackbar', 'info'];
                break;
            case 'warning':
                config.duration = Constants.MSG_DURATION_MIDDLE;
                config.panelClass = ['notify_snackbar', 'warning'];
                break;
            case 'error':
                config.duration = Constants.MSG_DURATION_MIDDLE;
                config.panelClass = ['notify_snackbar', 'error'];
                break;
            case 'critical':
                config.duration = Constants.MSG_DURATION_LONG;
                config.panelClass = ['notify_snackbar', 'critical'];
                break;
            default:
                return null;
        }
        return config;
    }
}
