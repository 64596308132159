import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-branch-number',
  templateUrl: './branch-number.component.html',
  styleUrls: ['./branch-number.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BranchNumberComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
