<ng-container
  *ngIf="
    type_product === 'voice' ||
      type_product === 'data' ||
      type_product === 'fiber';
    else wifi
  "
>
  <mat-accordion>
    <mat-expansion-panel
      class="product-item"
      [disabled]="active_flat"
      [ngClass]="{ 'disable-product': active_flat }"
    >
      <mat-expansion-panel-header
        class="product-item__header"
        (click)="active_flat ? '' : productDetail()"
      >
        <mat-panel-title>
          <mat-icon class="header-icon">
            {{ type_icon }}
          </mat-icon>
          <div class="title">
            <p>{{ product_title }}</p>
            <p *ngIf="type_product !== 'fiber'">SIM ID: {{ sim_id }}</p>
            <P *ngIf="type_product !== 'fiber'"
              >Phone Number: {{ phone_number$ | async }}</P
            >
            <p>
              {{ start_at }} -
              {{ end_at }}
            </p>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
    </mat-expansion-panel>
  </mat-accordion>
</ng-container>

<ng-template #wifi>
  <mat-accordion>
    <mat-expansion-panel
      class="product-item"
      [disabled]="active_flat"
      [ngClass]="{ 'disable-product': active_flat }"
    >
      <mat-expansion-panel-header
        class="product-item__header"
        (click)="active_flat ? null : userProperties_contractProduct()"
      >
        <mat-panel-title>
          <mat-icon class="header-icon">
            {{ type_icon }}
          </mat-icon>
          <div class="title">
            <p *ngIf="product_title !== null ? product_title : null">
              {{ product_title }}
            </p>
            <p *ngIf="sim_id !== null ? sim_id : null">
              DEVICE ID: {{ sim_id }}
            </p>
            <p *ngIf="plan !== null ? plan : null">{{ plan }}</p>
            <p *ngIf="monthly_data !== null ? monthly_data : null">
              Monthly data: {{ monthly_data }}
            </p>
            <p>
              {{ start_at }} -
              {{ end_at }}
            </p>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
    </mat-expansion-panel>
  </mat-accordion>
</ng-template>
