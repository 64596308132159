import { Component, OnInit, Inject, LOCALE_ID, Input, Output, EventEmitter } from '@angular/core';
import { formatDate } from '@angular/common';
import { MatSnackBar, MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs';
import { EditableProperty } from 'src/app/types/common-types';
import { Constants } from 'src/app/constants';
import { V1UserAPIService } from 'src/app/services/v1api.service';
import { Utils } from 'src/app/utils';
import { User } from 'src/app/types/model-types';
import { ShareDataService } from 'src/app/services/share-data.service';
import { ComponentModifiedEvent } from 'src/app/types/common-types';
import { SelectedsAndCandidates } from '../user-detail.component';

@Component({
  selector: 'app-user-role',
  templateUrl: './user-role.component.html',
  styleUrls: ['./user-role.component.scss']
})
export class UserRoleComponent implements OnInit {

  // input/output
  @Input() tabName: string;
  @Input() commit: EventEmitter<any>;
  @Output() modified = new EventEmitter<ComponentModifiedEvent>();

  // subscribe service
  private _shareDataSubscription: Subscription;

  // subscribing data
  private _user: User;
  private _candidates: Array<any>;
  private _roles: Array<any>;

  // Roles
  public unselects: Array<any>;
  public rolesDataSrc: MatTableDataSource<any>;
  public selectedRoleAdd: string;

  // List property
  public displayColumns: string[] = [
    'name',
    'delete',
  ];

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private _api: V1UserAPIService,
    private _snackBar: MatSnackBar,
    private _shareData: ShareDataService,
  ) {
    this.rolesDataSrc = null;
    this._candidates = new Array<any>();
    this._roles = new Array<any>();
    this.unselects = new Array<any>();
    this.selectedRoleAdd = '';
  }

  ngOnInit() {
    console.log('tabName=? : ' + this.tabName);
    console.log('UserRoleComponent::ngOnInit()');
    // share data service
    this._shareDataSubscription = this._shareData.data$.subscribe(
      data => {
        console.log('UserRoleComponent::ngOnInit() : data arrive');
        this._user = this._shareData.getAt('admin/users/user:selecting_user');
        this._candidates = this._shareData.getAt('role_candidates');
        this._roles = this._shareData.getAt('admin/users/user:roles');
        this.unselects  = Utils.unselectsFromSelecteds(this._candidates, this._roles);
        this.modified.emit(new ComponentModifiedEvent(this.tabName, false, this));
        this.refresh();
      }
    );
  }

  private refresh(): void {
    this.rolesDataSrc = new MatTableDataSource(this._roles);
    console.log(this.rolesDataSrc);
  }

  public onClickRow(event: any): void {
    console.log('UserRoleComponent::onClickRow()' + JSON.stringify(event));
  }

  public onClickRemoveRole(event: any): void {
    const newArray = new Array<any>();
    this._roles.forEach(role => {
      if (role.name !== event.name) {
        newArray.push(role);
      }
    });
    this._roles = newArray;
    this.unselects  = Utils.unselectsFromSelecteds(this._candidates, this._roles);
    this.modified.emit(new ComponentModifiedEvent(this.tabName, true, this));
    this.refresh();
  }

  public get addRoleEnable() {
    return this.selectedRoleAdd.length > 0;
  }

  public onClickAddRole() {
    const addRole = this._candidates.filter(candidate => {
      return candidate.name === this.selectedRoleAdd;
    });
    if (addRole.length > 0) {
      this._roles.push(addRole[0]);
    }
    this.unselects = Utils.unselectsFromSelecteds(this._candidates, this._roles);
    this.selectedRoleAdd = '';
    this.modified.emit(new ComponentModifiedEvent(this.tabName, true, this));
    this.refresh();
  }

  public commitProc(): boolean {
    // create update param
    const roles = [];
    this._roles.forEach(role => {
      roles.push(role.name);
    });

    this._api.put(Constants.API_UPDATE_USER_ROLES + '/' + this._user.id, roles).subscribe(
      response => {
        this._snackBar.dismiss();
        this._snackBar.open(
          Utils.getDisplayMessage(response, 'User role updated.'),
          'OK',
          Constants.defaultSnackBarConfig('success'));
        // set update data as UI data
        this._shareData.setAt('admin/users/user:roles', this._roles);
        this.refresh();
        this.modified.emit(new ComponentModifiedEvent(this.tabName, false, this));
        console.log(response);
      },
      error => {
        this._snackBar.dismiss();
        this._snackBar.open(
          Utils.getDisplayErrorMessage(error, 'Failed to update user role'),
          'OK',
          Constants.defaultSnackBarConfig('error'));
        console.log(error);
      }
    );
    return true;
  }
}
