import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-card-updated',
  templateUrl: './card-updated.component.html',
  styleUrls: ['./card-updated.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class CardUpdatedComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
