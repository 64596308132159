import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material';
import { DialogDepositRequiredComponent } from './dialog-deposit-required/dialog-deposit-required.component';
import { Router } from '@angular/router';
import { AccountInformationService } from 'src/app/services/account-information.service';
import { map, shareReplay, tap, pluck } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { DepositUser } from 'src/app/types/deposit-user';
import { Contracts } from 'src/app/types/contracts';
import { ContractsService } from 'src/app/services/contracts.service';
import { RouterHistoryService } from 'src/app/services/router-history.service';

@Component({
  selector: 'app-change-payment-method',
  templateUrl: './change-payment-method.component.html',
  styleUrls: ['./change-payment-method.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ChangePaymentMethodComponent implements OnInit {
  isChooseCredit = false;
  isChooseCash = false;
  constructor(
    private _matDialog: MatDialog,
    private router: Router,
    private accountService: AccountInformationService,
    private contractService: ContractsService,
    readonly _routerHistoryService: RouterHistoryService
  ) { }
  depositOfUser$: Observable<DepositUser> = this.accountService.InfoDepositOfUser().pipe(
    shareReplay()
  );
  amountPrice$: Observable<number> = this.depositOfUser$.pipe(
    map( res => {
      const productsArray = res.data.contracts[0].products;
      let total = 0;
      productsArray.forEach( product => {
       const typeProduct = this.contractService.check_Product_Type(product.type.title); // voice | data | wifi
       if ( typeProduct === 'voice') {
         total = total + product.deposit.amount;
       } else { // data | wifi
        const price = product.deposit.amount + product.deposit.vat;
        total = total + price;
       }
      });
      return total;
    }),
  );
  ngOnInit() {
  }
  openDialog () {
    this._matDialog.open(DialogDepositRequiredComponent);
  }
  confirmationChange () {
    this.router.navigate(['/confirmation-change-payment']);
  }
  chooseMethod(value: string) {
    this.accountService.setPayMethod = value;
    this.accountService.setTotalPrice = this.amountPrice$;
    this.accountService.setDepositOfUser = this.depositOfUser$;
  }
}
