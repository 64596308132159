import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { V1UserAPIService } from 'src/app/services/v1api.service';
import { Constants } from 'src/app/constants';

@Component({
  selector: 'app-site-header',
  templateUrl: './site-header.component.html',
  styleUrls: ['./site-header.component.scss']
})
export class SiteHeaderComponent implements OnInit {

  private _subscriptionIsLoggingIn: Subscription;
  public isLoggingIn: boolean;

  constructor(
    private _api: V1UserAPIService,
    private _router: Router,
  ) {
    this.isLoggingIn = false;
  }

  ngOnInit() {
    this.isLoggingIn = this._api.isLoggingIn(true);
    this._subscriptionIsLoggingIn = this._api.loggingIn$.subscribe(
      isLoggingIn => {
        // console.log('SiteHeaderComponent::ngOnInit : isLoggingIn = ' + isLoggingIn);
        this.isLoggingIn = isLoggingIn;
      }
    );
  }

  public onClickRemoveLoginData(event: any): void {
    localStorage.removeItem(Constants.LOGIN_USER);
  }

  public onClickSignUp(event: any): void {
    this._router.navigate(['/auth/sign_up']);
  }

  public onClickLogout(event: any): void {
    this._api.logout();
  }

  public onClickLogIn(event_data: any): void {
    this._router.navigate(['/auth/login']);
  }
}
