import { Component, OnInit, OnDestroy, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { LoadingService } from "src/app/services/loading.service";
import { MatSnackBar } from "@angular/material";
import { Constants } from "src/app/constants";
import { PaymentInvoicesService } from "src/app/services/payment-invoices.service";

@Component({
  selector: "app-payment-received",
  templateUrl: "./payment-received.component.html",
  styleUrls: ["./payment-received.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class PaymentReceivedComponent implements OnInit {
  private _paramMap: ParamMap;
  public completed: boolean = false;

  public new_card: boolean = false;
  public invoice: any = null;
  public invoice_month: string = "";

  constructor(
    private _activatedRoute: ActivatedRoute,
    private _loadingService: LoadingService,
    private _snackBar: MatSnackBar,
    private _router: Router,
    private paymentService: PaymentInvoicesService
  ) {}
  ngOnInit() {
    this._activatedRoute.queryParamMap.subscribe((paramMap) => {
      this._paramMap = paramMap;
      this.paymentService
        .payInvoice_Completed(this._paramMap.get("hash"))
        .subscribe(
          (response) => {
            console.log("PaymentReceivedComponent - response=", response);
            this._loadingService.setSpinnerEvent = false;
            this.completed = true;
            if (
              response.error.status == "success" &&
              response.data &&
              response.data.invoice
            ) {
              this.invoice = response.data.invoice;
              console.log(
                "PaymentReceivedComponent - new card registoration completed : invoice=",
                this.invoice
              );
              this.invoice_month = this.invoiceMonthToUsedMonth(
                this.invoice.invoice_month
              );
            }
            return { result: true, data: <any>response };
          },
          (error) => {
            this._loadingService.setSpinnerEvent = false;
            this._snackBar.dismiss();
            this._snackBar.open(
              "Updated Failed",
              "OK",
              Constants.defaultSnackBarConfig("error")
            );
            return { result: false, data: <any>error };
          }
        );
    });
  }

  invoiceMonthToUsedMonth(invoice_month: any): string {
    if (invoice_month) {
      const ivs = invoice_month.split("-");
      const monthes = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      let y = parseInt(ivs[0]);
      let m = parseInt(ivs[1]) - 1; // -1 : month -> index
      m = m - 1; // invoice month -> used month
      if (m < 0) {
        y = y - 1;
        m = 11;
      }
      return monthes[m];
    }
    return "";
  }

  gotoHome() {
    this._router.navigate(["/auth/login"]);
  }
}
