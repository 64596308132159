<div class="dialog__contents">
  <button class="close--button" mat-icon-button mat-dialog-close matRippleDisabled="disabled">
    <mat-icon class="cancel--icon">cancel</mat-icon>
  </button>
 
  <mat-dialog-content class="mat-typography">
    <div class="dialog__contents--title">
      <mat-icon class="icon">help</mat-icon>
      <p class="title" mat-dialog-title>What is the cancellation policy?</p>
    </div>
    <p>Your contract can only be <span style="font-weight: 500;">terminated at the end of the month.</span></p>
    <p>An <span style="font-weight: 500;">official cancellation request </span>must be submitted <span class="sakura--text">by the 25th of the month </span> in
      order to terminated the contract</p>
    <p>Your contract will be terminated at the end</p>
    <table>
      <tr>
        <th>Submission date</th>
        <th>Official termination</th>
      </tr>
      <tr>
        <td>1st - 25th</td>
        <td class="sakura--text">End of this month</td>
      </tr>
      <tr>
        <td>After the 25th</td>
        <td class="sakura--text">End of next month</td>
      </tr>
    </table>
    <!-- </mat-dialog-content> -->
    <div class="dialog__contents--title title--inside">
      <mat-icon class="icon">help</mat-icon>
      <p class="title" mat-dialog-title>Minimum contract period</p>
    </div>
    <!-- <mat-dialog-content class="mat-typography"> -->
    <p>The minimum contract period is <span class="sakura--text">3 months</span>. This means you have to cover at least
      3 months of the usage fee whether you use the service or not.</p>
    <p>The contract <span style="font-weight: 500;">will continue </span>until an official cancellation request is submitted.</p>
    <p> E.g. If you start your contract in January, it is necessary to pay the usage fees for January, February, and
      March at the very least.</p>
  </mat-dialog-content>
</div>