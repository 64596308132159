import { Component, OnInit } from '@angular/core';
import { AccountInformationService } from 'src/app/services/account-information.service';
import { Observable, of } from 'rxjs';
import { map, filter, shareReplay, catchError } from 'rxjs/operators';
import { Utils } from 'src/app/utils';
import { PaymentInvoicesService } from 'src/app/services/payment-invoices.service';
import { HttpErrorResponse } from '@angular/common/http';
import { RouterHistoryService } from 'src/app/services/router-history.service';

@Component({
  selector: 'app-account-information',
  templateUrl: './account-information.component.html',
  styleUrls: ['./account-information.component.scss']
})
export class AccountInformationComponent implements OnInit {
  constructor(
    private accountService: AccountInformationService,
    private paymentService: PaymentInvoicesService,
    readonly _routerHistoryService: RouterHistoryService
  ) {}
  account_information$ = this.accountService.userInformation$.pipe(
    map(res => res.data)
  );
  account_payment_info$ = this.accountService.AccountPaymentsInformation()
                          .pipe(
                            map(res => res.data),
                            shareReplay(),
                            );
  isSmartpit$ = this.account_payment_info$.pipe(
    filter(res => res.payment_method === 'SMARTPIT')
  );
  smartpitNumber$ = this.account_payment_info$.pipe(
    filter(res => res.payment_method === 'SMARTPIT'),
    map(res => {
      const first = res.smartpit_number.slice(0, 3);
      const second = res.smartpit_number.slice(3, 6);
      const third = res.smartpit_number.slice(6, 9);
      const four = res.smartpit_number.slice(9, 13);
      return `${first}\u00A0\u00A0\ ${second}\u00A0\u00A0\ ${third}\u00A0\u00A0\ ${four}`;
    })
  );
  expire_date$ = this.account_payment_info$.pipe(
                map(res => Utils.convertExpireDate(res.expire))
              );
  // card information from GMO
  edit_button$: Observable<boolean> = this.paymentService.creditCardInformation().pipe(
    map(res => {
      if (res.data) {
        return true;
      }
    }),
    catchError(this.handleError)
  );
  ngOnInit() {}
  private handleError (err: HttpErrorResponse) {
    return of(false);
  }
}
