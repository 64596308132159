import { Component, OnInit, Inject, LOCALE_ID, Input, Output, EventEmitter } from '@angular/core';
import { formatDate } from '@angular/common';
import { MatSnackBar, MatTableDataSource } from '@angular/material';
import { Subscription } from 'rxjs';
import { EditableProperty } from 'src/app/types/common-types';
import { Constants } from 'src/app/constants';
import { V1UserAPIService } from 'src/app/services/v1api.service';
import { Utils } from 'src/app/utils';
import { User } from 'src/app/types/model-types';
import { ShareDataService } from 'src/app/services/share-data.service';
import { ComponentModifiedEvent } from 'src/app/types/common-types';

@Component({
  selector: 'app-user-summary',
  templateUrl: './user-summary.component.html',
  styleUrls: ['./user-summary.component.scss']
})
export class UserSummaryComponent implements OnInit {

  // input/output
  @Input() tabName: string;
  @Input() commit: EventEmitter<string>;
  @Output() modified = new EventEmitter<ComponentModifiedEvent>();

  // subscribe service
  private _shareDataSubscription: Subscription;

  // subscribing data
  private _user: User;

  // Edit data
  private _editables: Array<EditableProperty>;
  public editDataSrc: MatTableDataSource<EditableProperty>;

  // List property
  public displayColumns: string[] = [
    'item',
    'value',
    'revert'
  ];

  constructor(
    @Inject(LOCALE_ID) private locale: string,
    private _api: V1UserAPIService,
    private _snackBar: MatSnackBar,
    private _shareData: ShareDataService,
  ) {
    this.editDataSrc = null;
    this._editables = new Array<EditableProperty>();
  }

  ngOnInit() {
    console.log('tabName=? : ' + this.tabName);
    console.log('UserSummaryComponent::ngOnInit()');
    // share data service
    this._shareDataSubscription = this._shareData.data$.subscribe(
      data => {
        console.log('UserSummaryComponent::ngOnInit() : data arrive');
        this._user = this._shareData.getAt('admin/users/user:selecting_user');
        this.modified.emit(new ComponentModifiedEvent(this.tabName, false, this));
        this.refresh();
      }
    );
  }

  private refresh(): void {
    console.log('UserSummaryComponent::refresh()');
    if (Utils.isValue(this._user)) {
      this.buildEditableData();
    }
  }

  private buildEditableData(): void {
    if (Utils.isValue(this._user)) {
      this._editables = new Array<EditableProperty>();
      this._editables.push(new EditableProperty(
        'ID', 'id', 'number', this._user.id, false));
      this._editables.push(new EditableProperty(
        'e-mail', 'email', 'email', this._user.email, false));
      this._editables.push(new EditableProperty(
        'Full name', 'full_name', 'text', this._user.full_name));
      this._editables.push(new EditableProperty(
        'Login count', 'sign_in_count', 'number', this._user.sign_in_count, false));
      this._editables.push(new EditableProperty(
        'Current sign in at', 'current_sign_in_at', 'datetime-local', this._user.current_sign_in_at, false));
      this._editables.push(new EditableProperty(
        'Current Login IP', 'current_sign_in_ip', 'text', this._user.current_sign_in_ip, false));
      this._editables.push(new EditableProperty(
        'Last sign in at', 'last_sign_in_at', 'datetime-local', this._user.last_sign_in_at, false));
      this._editables.push(new EditableProperty(
        'Last login IP', 'last_sign_in_ip', 'text', this._user.last_sign_in_ip, false));
      this._editables.push(new EditableProperty(
        'Confirmed at', 'confirmed_at', 'datetime-local', this._user.confirmed_at, false));
      this._editables.push(new EditableProperty(
        'Confirmation sent at', 'confirmation_sent_at', 'datetime-local', this._user.confirmation_sent_at, false));
      this._editables.push(new EditableProperty(
        'Locked at', 'locked_at', 'datetime-local', this._user.locked_at, false));
      this.editDataSrc = new MatTableDataSource(this._editables);
    }
  }

  public onValueChanged(event: any) {
    console.log(event);
    let someValueChanged = false;
    let newValue = event.event_src.target.value;
    if (newValue.length === 0) {
      newValue = null;
    }
    this._editables.forEach(aData => {
      // TODO : FIX hare if comparision doesn't work properly because of data type.
      if (event.data.key === aData.key) {
        aData.newValue = newValue;
      }
      if (aData.modified === true) {
        console.log(aData.oldValue + ' : ' + aData.newValue);
        someValueChanged = true;
      }
    });
    this.modified.emit(new ComponentModifiedEvent(this.tabName, someValueChanged, this));
  }

  public formatDate(dt: Date): string {
    if (!Utils.isValue(dt)) {
      return '';
    }
    return formatDate(dt, 'yyyy/mm/dd HH:mm:ss', this.locale);
  }

  public commitProc(): boolean {
    // create update param
    const tmpUser = new User(this._user.id, this._user.email);
    this._editables.forEach(editParam => {
      if (editParam.editable === true) {
        tmpUser[editParam.key] = editParam.newValue;
      } else {
        tmpUser[editParam.key] = editParam.oldValue;
      }
    });

    this._api.put(Constants.API_SHOW_USER + '/' + this._user.id, tmpUser).subscribe(
      response => {
        this._snackBar.dismiss();
        this._snackBar.open(
          Utils.getDisplayMessage(response, 'User data updated.'),
          'OK',
          Constants.defaultSnackBarConfig('success'));
        // set update data as UI data
        this._editables.forEach(aData => {
          if (aData.oldValue !== aData.newValue) {
            if (aData.key in this._user) {
              this._user[aData.key] = aData.newValue;
              aData.setCommit();
            }
          }
        });
        this._user = tmpUser;
        this.modified.emit(new ComponentModifiedEvent(this.tabName, false, this));
        console.log(response);
      },
      error => {
        this._snackBar.dismiss();
        this._snackBar.open(
          Utils.getDisplayErrorMessage(error, 'Failed to update user'),
          'OK',
          Constants.defaultSnackBarConfig('error'));
        console.log(error);
      }
    );
    return true;
  }

  public onRevertValue(event: any) {
    console.log(event);
    event.newValue = event.oldValue;

    let someValueChanged = false;
    this._editables.forEach(aData => {
      // TODO : FIX hare if comparision doesn't work properly because of data type.
      if (aData.modified === true) {
        someValueChanged = true;
      }
    });
    this.modified.emit(new ComponentModifiedEvent(this.tabName, someValueChanged, this));
  }
}
