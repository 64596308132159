<div class="submitted__request">
  <div class="widget">
    <app-menu
      [title]="
        (is_fiber$ | async) ? 'Terminate Contract' : (phone_number$ | async)
      "
    ></app-menu>
    <div class="widget__body">
      <div class="widget__body--title">
        <p class="title">Request submitted</p>
      </div>
      <div class="widget__body--info">
        <p class="info">
          After submission, you will receive a confirmation email. If you do not
          receive the confirmation email, the submission may have failed. Please
          re-submit the form or <a href="" class="special">contact us.</a>
        </p>
        <p class="info">
          Please also note that once the cancellation request has been
          successfully submitted, it cannot be withdrawn.
          <span
            class="refund-info"
            *ngIf="
              (item$ | async) === 'Voice + Data SIM' &&
              (payment_method$ | async) === 'SmartPit Card'
            "
          >
            We will notify you once the refund is processed.</span
          >
        </p>
      </div>
      <div class="widget__body__cancel_detail">
        <h3 class="title-head">Cancellation Details</h3>
        <div class="details">
          <p class="info-detail">Contract Type</p>
          <p class="info-detail info">{{ item$ | async }}</p>

          <ng-container
            *ngIf="
              (item$ | async) !== 'Pocket WiFi' &&
              (item$ | async) !== 'Home WiFi' &&
              (item$ | async) !== 'Sakura Fiber Internet'
            "
          >
            <p class="info-detail">Phone Number</p>
            <p class="info-detail info">{{ phone_number$ | async }}</p>
          </ng-container>

          <ng-container *ngIf="(item$ | async) === 'Pocket WiFi' || (item$ | async) === 'Home WiFi'">
            <p class="info-detail">Device ID</p>
            <p class="info-detail info">{{ deviceID$ | async }}</p>
          </ng-container>
          <ng-container *ngIf="(item$ | async) === 'Sakura Fiber Internet'">
            <p class="info-detail">Booking ID</p>
            <p class="info-detail info" *ngIf="contract$ | async as contract">
              {{ contract.contract_code }}
            </p>
          </ng-container>

          <p class="info-detail">Payment Method</p>
          <p class="info-detail info">
            {{ payment_method$ | async | titlecase }}
          </p>
          <p class="info-detail">Cancellation Date</p>
          <p class="info-detail info">
            <span class="sakura-info">{{ terminationDate$ | async }}</span>
          </p>
          <p class="info-detail">Final Payments</p>
          <p class="info-detail info">
            <span class="sakura-info">{{
              (payment_method$ | async) === "SmartPit Card"
                ? "Pay with deposit"
                : "Credit/Debit Card"
                ? (finalPayment$ | async)
                : ""
            }}</span>
          </p>
        </div>
      </div>
      <div
        *ngIf="(item$ | async) === 'Sakura Fiber Internet'"
        class="widget__body__cancel_detail return--method"
      >
        <h3 class="title-head">Rental Devices Return Method</h3>
        <div class="details">
          <p class="info-detail">{{ returnDeviceMethod$ | async }}</p>
        </div>
      </div>
      <div class="widget__body--img">
        <img
          class="imageInside"
          src="../../../../../assets/images/submitSakuraFlower.svg"
        />
      </div>
      <div class="widget__body--submitted">
        <p class="goback">
          Back to
          <span class="special" (click)="goBack()">Contract Detail</span>
        </p>
      </div>
    </div>
  </div>
</div>
