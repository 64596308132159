<div class="confirmation-change-payment">
  <div class="widget">
    <app-menu [back_button]="true" [title]="'Account'"></app-menu>

    <div class="widget__contents">
      <h1 class="title">Change Payment Method</h1>
      <div class="widget__contents--image-change">
        <div class="image">
          <img
            src="../../../../assets/images/creditcard.png"
            alt="credit card payment"
            class="img--payment"
          />
          <p class="title--change">Credit/debit card</p>
        </div>

        <div class="arrow">
          <img
            src="../../../../assets/images/Polygon_6.png"
            alt="transform image"
          />
        </div>
        <div class="image">
          <img
            src="../../../../assets/images/cashpayment.png"
            alt="cash payment"
            class="img--payment"
          />
          <div class="info-cash">
            <p class="title--change cash-payment">Cash payment</p>
            <mat-icon>help</mat-icon>
          </div>
        </div>
      </div>

      <div class="widget__contents--confirm-title">
        <mat-icon class="icon-confirm">check_circle</mat-icon>
        <p class="title-confirm">Confirmation</p>
      </div>

      <div class="widget__contents--confirmation-info">
        <div class="deposit-info">
          <app-deposit *ngFor="let productDeposit of (productsDeposit$ |async)" [productDeposit]="productDeposit"></app-deposit>

          <div class="method">
            <p class="title">Payment Method</p>
            <p class="name">{{paymentMethodChange$ |async}}</p>
          </div>
        </div>
        <div class="deposit-total">
          <p class="title">Total</p>
          <p class="name"  *ngIf="(amountPrice$ |async) !== 0">{{amountPrice$ |async |number}} JPY</p>
        </div>
      </div>

      <div class="widget__contents--term">
        <mat-icon class="term-check" (click)="toggleChecked()">{{
          mat_content
        }}</mat-icon>
        <span class="term-content">I understand and agree with the deposit charge and refund conditions.</span>
      </div>

      <div class="widget__contents--submit-button">
        <button mat-button class="submit-button" [ngClass]="{'submitted--button': checked}" (click)="SubmittedRequest()" [disabled]="!checked">Submit Request</button>
        <div class="stepper">
          <button mat-icon-button disabled>
            <mat-icon> radio_button_unchecked</mat-icon>
          </button>
          <button mat-icon-button disabled>
            <mat-icon>
             lens
            </mat-icon>
          </button>
        </div>
      </div>

    </div>
  </div>
</div>
