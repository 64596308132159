<div class="mnp__attention">
  <div class="widget">
    <app-menu
      [back_button]="back_button"
      [title]="phone_number$ | async"
    ></app-menu>
    <div class="widget__contents">
      <div class="widget__contents--attention">
        <mat-icon class="warning-icon" rounded >warning</mat-icon>
        <p class="attention">Attention</p>
      </div>
      <div class="widget__contents--notes">
        <p class="note-up">
          Before you decide to switch to another provider, we highly recommend
          that you check the following contract conditions of your new provider
          before requesting an MNP number from us.
        </p>
        <p class="note-down">
          We have seen many of our customers who requested an MNP number but did
          not switch providers and kept using Sakura Mobile as they encountered
          some problems with the new provider’s contract conditions. The reasons
          follow.
        </p>
      </div>
      <div class="widget__contents--options">
        <div class="option">
          <p class="title">1. The restricted payment method.</p>
          <p class="content">
            Most of the companies only accept Japanese credit cards.
            Additionally, debit card and cash payment methods are not available.
          </p>
        </div>
        <div class="option">
          <p class="title">2. Long contract period.</p>
          <p class="content">In most cases, the network providers in Japan require a long contract period from 1 to 3 years. It is not possible to sign a contract if you only stay in Japan for a shorter period.</p>
        </div>
        <div class="option">
          <p class="title">3. The contract requires you to purchase a device.</p>
        </div>
        <div class="option">
          <p class="title">4. Once the MNP is issued, you will not be able to request a regular termination until the 14-day period ends.</p>
        </div>
      </div>
      <div class="widget__contents--checkbox">
        <mat-icon class="check" (click)="toggleChecked()">{{mat_content}}</mat-icon>
        <span class="check--content">I understand and would like to issue an MNP number.</span>
      </div>
      <button mat-button class="widget__contents--next--button" [routerLink]="['/cancellation']" [disabled]="!checked"
      [ngClass]="{'submitted--button' : checked}"
      >Next</button>
    </div>
  </div>
</div>
