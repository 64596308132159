<div class="confirmation__paypal">
  <div class="widget">
    <app-menu [back_button]="back_button" [title]="phone_number$ | async"></app-menu>
    <div class="widget__contents">
      <div class="widget__contents--title">
        <mat-icon class="icon">cancel</mat-icon>
        <p class="title">Confirmation</p>
      </div>

      <ng-container *ngIf="mnpRequest$ |async; else notMNP">
        <div class="widget__contents--mnp-request-details">
          <p class="title-details">MNP Request Details</p>
          <div class="details">
            <p class="details-left">Last Name</p>
            <p class="details-right">{{last_name}}</p>
            <p class="details-left">Last Name (kana)</p>
            <p class="details-right">{{last_name_kana}}</p>
            <p class="details-left">First Name</p>
            <p class="details-right">{{first_name}}</p>
            <p class="details-left">First Name (Kana)</p>
            <p class="details-right">{{first_name_kana}}</p>
            <p class="details-left">Date Of Birth</p>
            <p class="details-right">{{birth_day}}</p>
            <p class="details-left">Sex</p>
            <p class="details-right">{{sex |titlecase}}</p>
          </div>
        </div>
      </ng-container>
      <ng-template #notMNP>
        <div class="widget__contents--cancellation-date">
          <div class="info-detail">Cancellation Date:</div>
          <div class="info-detail info">
            <span class="sakura-info">{{ terminationDate }}</span>
          </div>
        </div>
        <div class="widget__contents--mnp-request-details">
          <h3 class="title-details">Cancellation Details</h3>
          <div class="details">
            <p class="details-left">Contract Type</p>
            <p class="details-right">{{contract_type$ |async}}</p>
            <p class="details-left">Phone Number</p>
            <p class="details-right">{{ phone_number$ | async}}</p>
            <p class="details-left">Payment Method</p>
            <p class="details-right">{{payment_method$ |async}}</p>
            <p class="details-left">Cancellation Date</p>
            <p class="details-right"><span class="sakura-info">{{terminationDate}}</span></p>
            <p class="details-left">Final Payments</p>
            <p class="details-right"><span
                class="sakura-info">{{(payment_method$ |async) === 'SmartPit Card' ? 'Pay with deposit' : 'Credit/Debit Card' ? finalPayment : ''}}</span>
            </p>
          </div>
        </div>
      </ng-template>

      <div class="widget__contents--deposit-refund">
        <p class="title-refund">Deposit Refund</p>
        <p class="info-refund">
          The remainder of the deposit will be calculated and refunded to the
          following PayPal account 1.5 months after your contract is cancelled.
        </p>
        <p class="info-refund">
          Depending on the usage fees of the last 2 months, you might not
          receive a refund from us.
        </p>
        <p class="info-refund note">
          <span class="attention">Attention:</span> You will be responsible for
          the transaction fee if you choose to receive the refund through
          PayPal.
        </p>
        <p class="info-refund note" *ngIf="mnpRequest$ |async">
          The refund can only be made after your contract with Sakura Mobile is
          officially terminated.
        </p>
      </div>

      <div class="widget__contents--paypal-info">
        <p class="info-left">Email Address</p>
        <p class="info-right">{{email}}</p>
        <p class="info-left">Name</p>
        <p class="info-right">{{holder_name}}</p>
      </div>

      <div class="widget__contents--edit-submitted">
        <a (click)="editForm()">Edit {{(mnpRequest$ |async) ? 'Submitted' : 'Paypal'}} Information</a>
      </div>

      <div class="widget__contents--mnp-term">
        <mat-icon class="term--icon">help</mat-icon>
        <p class="term--title" (click)="openTermConditions()">MNP Term and Conditions</p>
      </div>

      <div class="widget__contents--conditions">
        <mat-icon class="condition-check" (click)="toggleChecked()">{{
          mat_content
        }}</mat-icon>
        <span class="condition-content">I understand the refund condition and confirm the information
          provided is correct.</span>
      </div>

      <button mat-button class="widget__contents--submitted-request" [ngClass]="{'submitted--button': checked}"
        [disabled]="!checked || isResponding" (click)="submitRequest()">
        Submit Request
      </button>

      <div class="widget__contents--step">
        <button mat-icon-button disabled>
          <mat-icon> radio_button_unchecked</mat-icon>
        </button>
        <button mat-icon-button disabled>
          <mat-icon> radio_button_unchecked</mat-icon>
        </button>
        <button mat-icon-button>
          <mat-icon>
            lens
          </mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
