import { Component, OnInit, ViewEncapsulation, OnDestroy, ChangeDetectionStrategy } from '@angular/core';
import { Observable, pairs, EMPTY, throwError, of } from 'rxjs';
import { Invoices } from 'src/app/types/invoice';
import { ContractsService } from 'src/app/services/contracts.service';
import { map, shareReplay, publish, tap, mapTo, catchError, filter } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { ActivatedRoute, Router } from '@angular/router';
import { V1UserAPIService } from 'src/app/services/v1api.service';
import { RouterHistoryService } from 'src/app/services/router-history.service';

@Component({
  selector: 'app-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class PaymentHistoryComponent implements OnInit, OnDestroy {
  private subs = new SubSink;
  public invoices: Invoices;
  public back_button = true;
  public title_menu = 'Payment history';
  public contract_code: string;
  public title_contract: string;
  public title_icon: string;
  public contract_id: number;
  phone_number$: Observable<string>;
  constructor(
    private _api: V1UserAPIService,
    private _contractServices: ContractsService,
    private _activeRoute: ActivatedRoute,
    private _router: Router,
    readonly _routerHistoryService: RouterHistoryService
  ) { }

  ngOnInit() {
      // START : ===== common implementation for login required components =====================
      if (this._api.isLoggingIn(true) === false) {
        this._router.navigate(['/auth/login']);
      }
      // END : ===== common implementation for login required components =====
    this.subs.sink = this._activeRoute.params.subscribe(
      param => this.contract_id = param.contract_id
    );
    this.phone_number$ = this._contractServices.getPhone_Number(this.contract_id).pipe(
      map(res => res['data']),
      map(data => {
        const firstItem = data.phones[0];
        return firstItem.phone_number;
      }),
      catchError(error => {
        console.log('Can not get phone number');
        return of('');
      })
    );
    this.subs.sink = this._contractServices.invoices$
      .subscribe(
        res => this.invoices = res
      );
    this.subs.sink = this._contractServices.contract$
      .subscribe(
        res => {
          this.contract_code = res.contract_code;
          if (res.properties !== undefined) {
            res.properties.forEach(item => {
              if (item.type.key === 'SUBSCRIPTION PLAN') {
                this.title_contract = item.property.value;
              } else {
                return;
              }
            }
            );
          }
        });
    this.title_icon = this._contractServices.check_Product_Type(this.title_contract) === 'wifi' ? 'wifi' : 'sim_card';
    console.log(this.invoices);
  }
  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
