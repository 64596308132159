<div class="app-login page">
  <div class="widget">
    <div class="widget-image">
      <img src="../../../../../assets/images/sakuramobile.svg" alt="logo sakura mobile" class="logo">
    </div>

    <h3 class="mat-h1 title">Log In</h3>
    <div class="content">
      <form #imageForm=ngForm (ngSubmit)="onClickSubmitWidget($event)" class="mui-form">
        <div class="row">
          <mat-form-field>
            <input matInput type="email" #InputUserId [formControl]="emailFormControl" id="email"
              [errorStateMatcher]="emailErrorMatcher" name="email" (keyup)="onEmailChanged($event)"
              (change)="onEmailChanged($event)" autocomplete="on" required placeholder="Email address">
            <mat-error *ngIf="emailFormControl.hasError('email') &&
              !emailFormControl.hasError('required')">
              Email address is invalid
            </mat-error>
            <mat-error *ngIf="emailFormControl.hasError('required')">
              Required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field>
            <input matInput type="password" #InputPassword [(ngModel)]="password" (keyup)="onPasswordChanged($event)"
              id="password" (change)="onPasswordChanged($event)" name="password" autocomplete="on" required
              placeholder="Password">
          </mat-form-field>
        </div>

        <p class="title-updatepassword"> Click <a class="link-updatepassword" routerLink="/auth/password_reset">here</a>
          if you have forgotten your password or if you are logging in to the site for the first time.
        </p>
        
        <div class="information">
          <div class="title">Information</div>
          <p>
            From April 2024, international calling rates are partially revised. For details, please click the link below and check the 「International Call Rates (Japan→Overseas)」 part on the page.<br/>
            <a href="https://www.sakuramobile.jp/long-term/voice-data-sim/calling-rates-2/" target="_blank">Link : CALLS AND SMS RATES</a>
          </p>
        </div>

        <div class="banner">
          <a #href href="https://support.sakuramobile.jp/hc/en-us/articles/4408255554317" target="_blank" rel="noopener">
            <img #image src="../../../../assets/images/get3000give3000_banner.jpg" alt="Give 3000 Get 3000 Campaign">
          </a>
        </div>
        <button mat-raised-button color="accent" class="btn-login" id="SubmitWidget" type="submit"
          [disabled]="submitDisabled">Login</button>
      </form>
    </div>
  </div>
</div>