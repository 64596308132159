<div class="app-product-detail">
  <div class="widget">
    <!-- <ng-container *ngIf="line_number && back_button && email && user"> -->
    <app-menu
      [title]="line_number || contract_code"
      [back_button]="back_button"
      [Email]="email"
      [User]="user"
    ></app-menu>
    <!-- </ng-container> -->
    <div class="widget-content">
      <div class="monthly-plan">
        <p>Monthly Plan: {{ monthly_plan }}</p>
      </div>
      <div *ngIf="is_sim && !is_cc" class="remaining-data">
        <span class="remaining-data__title">Remaining data:</span>
        <span class="remaining-data__content">{{ remain_data }} MB</span>
      </div>
      <div *ngIf="is_sim && is_cc" class="remaining-data">
        <span class="remaining-data__title"
          >{{ data_usage_title }}<br /><span class="term">{{
            data_usage_term
          }}</span></span
        >
        <span class="remaining-data__content">{{
          dataExpression(data_usage)
        }}</span>
      </div>
      <div
        *ngIf="is_cc"
        class="data-usage-description"
        [innerHTML]="data_usage_description"
      ></div>
      <!-- <div *ngIf="is_sim" class="history"> -->
      <div id="history" class="history" style="display: none">
        <mat-accordion>
          <mat-expansion-panel class="history__daily-usage">
            <mat-expansion-panel-header class="panel--header">
              <mat-panel-title>
                <mat-icon class="icon--daily">date_range</mat-icon>
                <div>
                  <p class="title--daily">Daily Usage</p>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="panel--tables">
              <div class="panel--tables__header">
                <button
                  mat-button
                  (click)="page.nextPage()"
                  class="button-header"
                >
                  <mat-icon class="icon-left">chevron_left</mat-icon>
                </button>
                <p>{{ next_day_title_table }} - {{ day_title_table }}</p>
                <button
                  mat-button
                  (click)="page.previousPage()"
                  class="button-header"
                >
                  <mat-icon class="icon-right">chevron_right</mat-icon>
                </button>
                <mat-paginator
                  #page
                  style="display: none"
                  #TableOnePaginator="matPaginator"
                  hidePageSize
                  [pageSize]="7"
                  (page)="pageEvent_PacketLog($event)"
                >
                </mat-paginator>
              </div>

              <div class="panel--tables__body">
                <table mat-table [dataSource]="dataSourcePacketLog">
                  <ng-container matColumnDef="date">
                    <td mat-cell *matCellDef="let element">
                      {{ element.day }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="month">
                    <td mat-cell *matCellDef="let element">
                      {{ element.date }}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="data">
                    <td mat-cell *matCellDef="let element">
                      {{ element.byte_on / 1048576 | number: "1.0-0" }} MB
                    </td>
                  </ng-container>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumnsPacketLog"
                  ></tr>
                </table>
              </div>
            </div>
          </mat-expansion-panel>

          <mat-expansion-panel class="history__call-history">
            <mat-expansion-panel-header class="call--header">
              <mat-panel-title>
                <mat-icon class="icon--call">history</mat-icon>
                <div>
                  <p class="title--call">Call History</p>
                </div>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="call--body">
              <div class="call--body__header">
                <button mat-button (click)="table.nextPage()">
                  <mat-icon class="icon-left">chevron_left</mat-icon>
                </button>
                <button mat-button (click)="table.previousPage()">
                  <mat-icon class="icon-right">chevron_right</mat-icon>
                </button>
                <mat-paginator
                  #table
                  style="display: none"
                  #TableTwoPaginator="matPaginator"
                  [pageSize]="7"
                  hidePageSize
                >
                </mat-paginator>
              </div>
              <div class="call--body__content">
                <table
                  mat-table
                  [dataSource]="dataSourceCallHistory"
                  class="mat-elevation-z8"
                >
                  <!-- Date Column -->
                  <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef>Date</th>
                    <td mat-cell *matCellDef="let element">
                      <p>{{ element.date }}</p>
                      <p class="text_bottom">{{ element.start_time }}</p>
                    </td>
                  </ng-container>

                  <!-- Destination Column -->
                  <ng-container matColumnDef="callee">
                    <th mat-header-cell *matHeaderCellDef>Destination</th>
                    <td mat-cell *matCellDef="let element">
                      <p>{{ element.phone_number }}</p>
                      <p class="text_bottom">{{ element.destination }}</p>
                    </td>
                  </ng-container>

                  <!-- Duration Column -->
                  <ng-container matColumnDef="duration">
                    <th mat-header-cell *matHeaderCellDef>Duration</th>
                    <td mat-cell *matCellDef="let element">
                      <p>{{ element.duration }}</p>
                    </td>
                  </ng-container>

                  <!-- Type Column -->
                  <ng-container matColumnDef="type1">
                    <th mat-header-cell *matHeaderCellDef>Type</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.type }}
                      <span class="pink-mark" *ngIf="element.discount"></span>
                    </td>
                  </ng-container>

                  <tr
                    mat-header-row
                    *matHeaderRowDef="displayedColumnsCallHistory"
                  ></tr>
                  <tr
                    mat-row
                    *matRowDef="let row; columns: displayedColumnsCallHistory"
                  ></tr>
                </table>
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div *ngIf="is_sim" class="notify">
        <p>
          * Calls make through Sakura Dial have a pink mark in the Type column.
        </p>
        <p>
          * Call may take up to 4 days to be shown in the log. Please refer to
          your invoices for fees.
        </p>
        <p>* Roaming service usages will not be shown.</p>
      </div>
      <mat-divider class="divider"></mat-divider>
      <div *ngIf="item != null" class="manage-plan">
        <p class="manage-plan__title">Manage Plan</p>
        <mat-list class="manage-plan__mat-list">
          <ng-container *ngIf="item !== 'wifi' && item !== 'fiber' && item !== 'home wifi'">
            <mat-list-item
              class="mat--item"
              [ngClass]="{ disabled: false }"
              (click)="topUpData()"
            >
              <p class="link">Top Up Data</p>
              <mat-icon>navigate_next</mat-icon>
            </mat-list-item>
            <mat-divider class="divider--item"></mat-divider>
          </ng-container>

          <ng-container *ngIf="item === 'voice'">
            <mat-list-item
              class="mat--item"
              [ngClass]="{ disabled: false }"
              (click)="routeItemConfiguration()"
            >
              <p class="link">Configurations</p>
              <mat-icon>navigate_next</mat-icon>
            </mat-list-item>
            <mat-divider class="divider--item"></mat-divider>
          </ng-container>

          <ng-container *ngIf="item !== 'fiber' && item !== 'home wifi'">
            <mat-list-item
              class="mat--item"
              [ngClass]="{ disabled: (!checkMNP || !item || !email)}"
              (click)="openChangeMonthlyPlan()"
            >
              <p class="link">Change monthly plan</p>
              <mat-icon>navigate_next</mat-icon>
            </mat-list-item>
            <mat-divider class="divider--item"></mat-divider>
          </ng-container>

          <ng-container *ngIf="item !== 'wifi' && item !== 'fiber' && item !== 'home wifi'">
            <mat-list-item
              class="mat--item"
              [ngClass]="{ disabled: (!checkMNP || !item || !email) }"
              (click)="openReissueSimCard()"
            >
              <p class="link">Re-issue SIM card</p>
              <mat-icon>navigate_next</mat-icon>
            </mat-list-item>

            <mat-divider
              *ngIf="payment_method === 'credit card'"
              class="divider--item"
            ></mat-divider>
            <mat-list-item
              *ngIf="payment_method === 'credit card'"
              class="mat--item"
              [ngClass]="{ disabled: (!checkMNP || !item || !email) }"
              (click)="openSuspend()"
            >
              <p class="link">Suspend</p>
              <mat-icon>navigate_next</mat-icon>
            </mat-list-item>
            <mat-divider class="divider--item"></mat-divider>
          </ng-container>

          <mat-list-item
            class="mat--item"
            [ngClass]="{ disabled: !checkMNP }"
            (click)="routeTerminate()"
          >
            <p class="link">Terminate contract</p>
            <mat-icon>navigate_next</mat-icon>
          </mat-list-item>
        </mat-list>
      </div>
    </div>
  </div>
</div>
