import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ContractsService } from 'src/app/services/contracts.service';
import { Invoices } from 'src/app/types/invoice';
import { Utils } from 'src/app/utils';

@Component({
  selector: 'app-ending-contracts',
  templateUrl: './ending-contracts.component.html',
  styleUrls: ['./ending-contracts.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class EndingContractsComponent implements OnInit {
  public contract_id_ending: number;
  public pay_status_ending: boolean;
  public start_day_ending: Date;
  public end_day_ending: Date;
  public subscription_plan_ending: string;
  public contract_code_ending: string;
  public invoices_Ending: Invoices;

  constructor(private _router: Router, private _contractService: ContractsService) { }
  @Input() endingContract;
  @Input() invoicesList;
  ngOnInit() {
    this.renderEndingContracts();
  }
  public getContractDetailEnding(contract_id: number) {
     // TEST: set active invoice list  as Observables
     this._contractService.set_invoice_list = this._contractService.findArr_Invocies(this.invoicesList, this.endingContract);
     const pay_method = this.endingContract.properties.find(
      item => item.type.key === 'PAY METHOD'
    );
    this._contractService.set_Pay_Method = pay_method === undefined ? null : pay_method.property.value.toLowerCase();
    // set contract as Observables
     this._contractService.set_contract = this.endingContract;
    // navigate contract_detail component
    this._router.navigate([`portal/contract-details/${contract_id}`]);
    // this._contractService.getListProduct(contract_id);
  }
  public PaymentHistory() {
    //  set invoice list active to Subject
    this._contractService.set_invoice_list = this._contractService.findArr_Invocies(this.invoicesList, this.endingContract);
    this._contractService.set_contract = this.endingContract; // set contract as $
    // END  =======================
    this._router.navigate([`portal/payment-history/${this.contract_id_ending}`]);
  }
  private renderEndingContracts() {
    this.invoices_Ending = this._contractService.findArr_Invocies(this.invoicesList, this.endingContract);
    this.pay_status_ending = this.invoices_Ending.every(invoice => invoice.pay_status === 1) ? true : false;
    this.contract_id_ending = this.endingContract.id;
    this.contract_code_ending = this.endingContract.contract_code;
    const valueOfSub_Plan = this.endingContract.properties.find(
            propertie => propertie.type.key === 'SUBSCRIPTION PLAN'
            );
    this.subscription_plan_ending = valueOfSub_Plan === undefined ? '' : valueOfSub_Plan.property.value;
    this.start_day_ending = this.endingContract.start_at;
    this.end_day_ending = this.endingContract.end_at;
  }
}
