import { Component, OnInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { CancellationService } from 'src/app/services/cancellation.service';
import { SubSink } from 'subsink';
import { ContractsService } from 'src/app/services/contracts.service';
import { Contract } from 'src/app/types/contract';
import { map, tap } from 'rxjs/operators';
import { DatePipe } from '@angular/common';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { RouterHistoryService } from 'src/app/services/router-history.service';
import { Constants } from 'src/app/constants';
import { MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-confirm-jp-bank-information',
  templateUrl: './confirm-jp-bank-information.component.html',
  styleUrls: ['./confirm-jp-bank-information.component.scss']
})
export class ConfirmJpBankInformationComponent implements OnInit, OnDestroy {
  private subs = new SubSink();
  back_button = true;
  checked = false;
  isResponding = false;
  mat_content = 'radio_button_unchecked';
  last_name: string;
  first_name: string;
  last_name_kana: string;
  first_name_kana: string;
  birth_day: string;
  sex: string;
  start_at_product: Date;
  bank_name: string;
  branch_name: string;
  account_type: string;
  account_number: string;
  account_holder_name: string;
  resasonsCancellation: FormGroup;
  jpBank: FormGroup;
  mnpInformation: FormGroup;
  mnpRequest$ = this.cancelService.mnpRequest$;
  phone_number$: Observable<string> = this.cancelService.line_number$;
  contractType$: Observable<string> = this._contractService.item$.pipe(
    map(item => item === 'voice' ? 'Voice + Data SIM' : '')
  );
  payment_method$: Observable<string> = this._contractService.pay_method$.pipe(
    map(method => {
      if (method === 'credit card') {
        return 'Credit/Debit Card';
      }
       if (method === 'smartpit') {
        return 'SmartPit Card';
      }
    })
  );
  terminationDate: string;
  finalPayment: string;
  constructor(
    private cancelService: CancellationService,
    private _contractService: ContractsService,
    private datePipe: DatePipe,
    private route: Router,
    private snackbar: MatSnackBar,
    readonly _routerHistoryService: RouterHistoryService
  ) { }

  ngOnInit() {
    this.subs.sink = this._contractService.contract$.pipe().subscribe(
      (res_contract) => {
        if (res_contract !== null) {
          this.start_at_product = new Date(res_contract.start_at);
          this.subs.sink = this._contractService.product$.pipe().subscribe(
            (res) => {
              if (res !== null) {
                console.log('Start_at of Product::', this.start_at_product);
                const start_at_month = this.start_at_product.getMonth() + 1;
                const earliest_termination_date = new Date(
                  this.start_at_product.getFullYear(),
                  start_at_month + 2,
                  0
                );
                console.log('Earliset tertmination date::', earliest_termination_date);
                const day_of_early_termination = earliest_termination_date.getDate();
                const month_of_early_termination = earliest_termination_date.getMonth() + 1;

                const submission_date = new Date();
                const month_of_submission = submission_date.getMonth() + 1;
                const day_in_month_submission = submission_date.getDate();
                console.log(`Submission date:: ${day_in_month_submission} - ${month_of_submission}`);
                // more than 3 month
                if (submission_date > earliest_termination_date) {
                  console.log('Case more than 3 month ===>');
                  if (day_in_month_submission <= 25) {
                    console.log('Case more than 3 month and <= 25 day');
                    this.terminationDate = this.datePipe.transform(
                      new Date(
                        submission_date.getFullYear(),
                        submission_date.getMonth() + 1,
                        0
                      ),
                      'yyyy-MM-dd'
                    );
                    this.finalPayment = this.datePipe.transform(
                      new Date(
                        submission_date.getFullYear(),
                        submission_date.getMonth() + 2,
                        0
                      ),
                      'yyyy-MM-dd'
                    );
                  } else {
                    console.log('Case more than 3 month and > 25 day');
                    this.terminationDate = this.datePipe.transform(
                      new Date(
                        submission_date.getFullYear(),
                        submission_date.getMonth() + 2,
                        0
                      ),
                      'yyyy-MM-dd'
                    );
                    this.finalPayment = this.datePipe.transform(
                      new Date(
                        submission_date.getFullYear(),
                        submission_date.getMonth() + 3,
                        0
                      ),
                      'yyyy-MM-dd'
                    );
                  }
                } else  if (
                  submission_date > this.start_at_product &&
                  submission_date < earliest_termination_date
                ) {
                  console.log('Case in 3 month ===> ');
                  if (month_of_early_termination === month_of_submission) {
                    console.log('Case at 3 month');
                    if (day_in_month_submission <= 25) {
                      console.log('Case at 3 month & <= 25 day');
                      this.terminationDate = this.datePipe.transform(
                        earliest_termination_date,
                        'yyyy-MM-dd'
                      );
                      this.finalPayment = this.datePipe.transform(
                        new Date(
                          earliest_termination_date.getFullYear(),
                          earliest_termination_date.getMonth() + 2,
                          0
                        ),
                        'yyyy-MM-dd'
                      );
                    } else {
                      console.log('Case at 3 month & > 25 day');
                      this.terminationDate = this.datePipe.transform(
                        new Date(
                          earliest_termination_date.getFullYear(),
                          earliest_termination_date.getMonth() + 2,
                          0
                        ),
                        'yyyy-MM-dd'
                      );
                      this.finalPayment = this.datePipe.transform(
                        new Date(
                          earliest_termination_date.getFullYear(),
                          earliest_termination_date.getMonth() + 3,
                          0
                        ),
                        'yyyy-MM-dd'
                      );
                    }
                  } else if (month_of_early_termination > month_of_submission) {
                    console.log('Case less than 3 month');
                    this.terminationDate = this.datePipe.transform(
                     earliest_termination_date,
                      'yyyy-MM-dd'
                    );
                    this.finalPayment = this.datePipe.transform(
                      new Date(
                        earliest_termination_date.getFullYear(),
                        earliest_termination_date.getMonth() + 2,
                        0
                      ),
                      'yyyy-MM-dd'
                    );
                  }
                }
            }
          },
            (error) => console.log(error)
          );
        }
      }
    );

    this.subs.sink = this.cancelService.mnpForm$.subscribe(
      (res) => {
        this.mnpInformation = res;
        if (this.mnpInformation.value !== undefined) {
          this.last_name = this.mnpInformation.value.lastName;
          this.first_name = this.mnpInformation.value.firstName;
          this.last_name_kana = this.mnpInformation.value.lastNameKana;
          this.first_name_kana = this.mnpInformation.value.firstNameKana;
          this.birth_day = this.mnpInformation.value.contract_date_of_birth;
          this.sex = this.mnpInformation.value.contract_sex;
        }
      },
      (error) => {
        console.log(error);
      }
    );
  this.subs.sink = this.cancelService.jpBank$.subscribe(
    data => {
     if (data.value !== undefined) {
      this.jpBank = data;
      this.branch_name = data.value.refund_jp_bank_account_branch_name;
      this.bank_name = data.value.refund_jp_bank_account_bank_name;
      this.account_holder_name = data.value.refund_jp_bank_account_holder_name;
      this.account_number = data.value.refund_jp_bank_account_number;
      this.account_type = data.value.refund_jp_bank_account_type;
     }
    },
    error => console.log(error)
  );
  this.subs.sink = this.cancelService.formCancellation$.subscribe(data => this.resasonsCancellation = data);
  this.subs.sink = this.cancelService.mnpForm$.subscribe(data => this.mnpInformation = data);
  }
  toggleChecked() {
    this.checked = !this.checked;
    if (this.checked === false) {
      this.mat_content = 'radio_button_unchecked';
    } else {
      this.mat_content = 'radio_button_checked';
    }
  }
  editJPBank() {
    this.subs.add(this.mnpRequest$.subscribe(isRequest => {
      if (isRequest) {
        this.route.navigate(['/mnp-information']);
      } else {
        this.route.navigate(['/refund-jp-bank']);
      }
    }));
  }
  submitRequest() {
    this.isResponding = true;
    this.subs.add(this.cancelService.mnpRequest$.subscribe(request => {
      if (request) {
        if (this.checked === true) {
          this.mnpInformation.removeControl('firstName');
          this.mnpInformation.removeControl('lastName');
          this.mnpInformation.removeControl('firstNameKana');
          this.mnpInformation.removeControl('lastNameKana');
          const submitDataMnpRequest = {
            ...this.resasonsCancellation.value,
            ...this.mnpInformation.value,
            ...this.jpBank.value,
          };
          const observer_with_mnp = {
            next: (response) => {
              this.isResponding = false;
              console.log('Successful cancellation', response);
              this.route.navigate(['/submitted-paypal']);
            },
            error: (error) => {
              this.isResponding = false;
              this.snackbar.open('Submit failed', 'OK', Constants.defaultSnackBarConfig('error'));
              console.error(error);
            }
          };
          this.subs.sink = this.cancelService.ContractsCancellation_By_Mnp(submitDataMnpRequest).subscribe(observer_with_mnp);
        }
      } else {
        if (this.checked === true) {
          const data = { ...this.resasonsCancellation.value, ...this.jpBank.value};
          const observer_without_mnp = {
            next: (response) => {
              this.isResponding = false;
              console.log('Successful cancellation');
              this.route.navigate(['/submited-request']);
            },
            error: (error) => {
              this.isResponding = false;
              this.snackbar.open('Submit failed', 'OK', Constants.defaultSnackBarConfig('error'));
              console.error(error);
            }
          };
          this.subs.sink = this.cancelService.ContractsCancellation(data).subscribe(observer_without_mnp);
        }
      }
    }));
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
