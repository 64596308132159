import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { Constants } from 'src/app/constants';
import { Utils } from 'src/app/utils';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PageNotFoundComponent implements OnInit {

  constructor(
    public _router: Router,
    public _snackBar: MatSnackBar,
  ) { }

  ngOnInit() {
    const self = this;
    setTimeout(function() {
      self._snackBar.dismiss();
      self._snackBar.open(
       Utils.getDisplayMessage('', 'Moved to the "Home" because an unknown URL was requested.'),
       'OK',
        Constants.defaultSnackBarConfig('warning'));
    }, 100);
    this._router.navigate(['']);
  }
}
