<div class="contract-cancellation">
  <div class="widget">
    <app-menu
      [back_button]="back_button"
      [title]="
        (is_fiber$ | async) ? 'Terminate Contract' : (phone_number$ | async)
      "
    ></app-menu>
    <div class="widget__content">
      <div class="contract__cancel_title">
        <p class="title">Contract Cancellation</p>
      </div>
      <div class="contract__cancel_content">
        <div class="info-detail">Cancellation Date:</div>
        <div class="info-detail info">
          <span class="sakura-info">{{ terminationDate }}</span>
        </div>
      </div>
      <div class="contract__cancel_popup-policy" (click)="openDialog()">
        <mat-icon class="popup-icon"> help </mat-icon>
        <p class="popup-link">What is the cancellation policy ?</p>
      </div>
      <div class="contract__cancel_detail">
        <h3 class="title-head">Cancellation Details</h3>
        <div class="details">
          <p class="info-detail">Contract Type</p>
          <p class="info-detail info">{{ item$ | async }}</p>

          <ng-container
            *ngIf="
              (item$ | async) !== 'Pocket WiFi' &&
              (item$ | async) !== 'Home WiFi' &&
              (item$ | async) !== 'Sakura Fiber Internet'
            "
          >
            <p class="info-detail">Phone Number</p>
            <p class="info-detail info">{{ phone_number$ | async }}</p>
          </ng-container>

          <ng-container *ngIf="(item$ | async) === 'Pocket WiFi' || (item$ | async) === 'Home WiFi'">
            <p class="info-detail">Device ID</p>
            <p class="info-detail info">{{ deviceID$ | async }}</p>
          </ng-container>
          <ng-container *ngIf="(item$ | async) === 'Sakura Fiber Internet'">
            <p class="info-detail">Booking ID</p>
            <p class="info-detail info" *ngIf="contract$ | async as contract">
              {{ contract.contract_code }}
            </p>
          </ng-container>

          <p class="info-detail">Payment Method</p>
          <p class="info-detail info">
            {{ payment_method$ | async | titlecase }}
          </p>
          <p class="info-detail">Cancellation Date</p>
          <p class="info-detail info">
            <span class="sakura-info">{{ terminationDate }}</span>
          </p>
          <p class="info-detail">Final Payments</p>
          <p class="info-detail info">
            <span class="sakura-info">{{
              (payment_method$ | async) === "SmartPit Card"
                ? "Pay with deposit"
                : "Credit/Debit Card"
                ? finalPayment
                : ""
            }}</span>
          </p>
        </div>
      </div>

      <div class="contract__cancel_checkbox-confirm">
        <div
          *ngIf="(payment_method$ | async) === 'Credit/Debit Card'"
          class="final-payment"
        >
          <p class="title-checkbox">
            <mat-checkbox (change)="onConfirmCancelDateChange($event)"
              >I understand the cancellation date is {{ terminationDate }} and
              confirm final payment period.</mat-checkbox
            >
          </p>
        </div>
        <div class="policy-confirm">
          <p class="title-checkbox">
            <mat-checkbox (change)="onConfirmCancelPolicyChange($event)"
              >I understand the cancellation policy.</mat-checkbox
            >
          </p>
        </div>
      </div>

      <div
        class="contract__cancel_deposit--refund"
        [hidden]="
          !((item$ | async) === 'Voice + Data SIM' && isSmartpitMethod$ | async)
        "
      >
        <p class="deposit-title">Deposit Refund</p>
        <button
          appPaymentmethod
          type="button"
          class="method"
          #paypal
          [value]="method_paypal"
          (click)="selectMethodRefund(paypal)"
        >
          Paypal
        </button>
        <button
          appPaymentmethod
          type="button"
          class="method"
          #jpbank
          [value]="method_jpbank"
          (click)="selectMethodRefund(jpbank)"
        >
          Japanese Bank Account
        </button>
      </div>

      <div
        class="contract__cancel_deposit--refund"
        *ngIf="
          (item$ | async) !== 'Voice + Data SIM' && isSmartpitMethod$ | async
        "
      >
        <p class="deposit-title">Deposit Refund</p>
        <p class="deposit-info-wifi">
          The deposit will be used to pay for the last 2 months of your
          contract. Therefore,<span style="color: #e573aa">
            you will not receive a refund from us.</span
          >
        </p>
      </div>
      <div
        *ngIf="(item$ | async) !== 'Sakura Fiber Internet'"
        class="contract__cancel_popup-policy"
        (click)="openDialogBilling()"
      >
        <mat-icon class="popup-icon"> help </mat-icon>
        <p class="popup-link">How does billing work ?</p>
      </div>
      <div class="contract__cancel_date">
        <h3 class="title-head">Date of departure (if you are leaving Japan)</h3>
        <mat-form-field (click)="picker.open()">
          <input
            matInput
            [matDatepicker]="picker"
            placeholder="Please select your date of departure"
            (dateChange)="dateChange($event)"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
      </div>

      <div
        *ngIf="(item$ | async) === 'Sakura Fiber Internet'"
        class="contract__cancel__returning-kit"
      >
        <div class="contract__cancel__returning-kit__header">
          <div class="square-dot"></div>
          <p class="title_header">
            Questions related to your internet deactivation
          </p>
        </div>
        <div class="contract__cancel__returning-kit__info">
          <p class="title_form">
            Please view the information below and select one of the following
            return options. Detailed instruction of the device return will be
            sent via email after we have received this form. (※)Please note that
            <span style="font-weight: bold"
              >a lost fee will be incurred in case you do not return</span
            >
            the rental devices.
          </p>
        </div>
      </div>

      <div
        *ngIf="(item$ | async) === 'Sakura Fiber Internet'"
        class="contract__cancel__returning-kit__method"
      >
        <button
          type="button"
          class="return-kit"
          (click)="onReturnKitEvent('return kit')"
          #returnkit
        >
          <p class="return-title">Return Kit (Takes 1-1.5 weeks)</p>
          <p class="return-info">
            We will request a return kit from the telecommunications provider
            (NTT) and we will send the return kit to your residence. Please use
            this return kit to return the devices.
          </p>
          <div class="return-details">
            <div class="detail">
              <div class="image">
                <img
                  src="../../../../../assets/images/clock-orange-icon.png"
                  alt="clock icon"
                />
              </div>
              <div>
                <p>
                  It takes about 1 - 1.5 weeks after we send the request to NTT
                  for the return kit to reach your residence.
                </p>
              </div>
            </div>
            <div class="detail">
              <div class="image">
                <img
                  src="../../../../../assets/images/done_FILL0_wght400_GRAD0_opsz24.svg"
                  alt="clock icon"
                />
              </div>
              <div>
                <p>
                  No delivery fee on your end (The return kit will be prepaid)
                </p>
              </div>
            </div>
            <div class="detail">
              <div class="image">
                <img
                  src="../../../../../assets/images/done_FILL0_wght400_GRAD0_opsz24.svg"
                  alt="clock icon"
                />
              </div>
              <div>
                <p>
                  All the return information is written on the delivery slip, so
                  it is convenient for you
                </p>
              </div>
            </div>
          </div>
        </button>
        <button
          type="button"
          class="through-sakura-mobile"
          (click)="onReturnKitEvent('through sakura mobile')"
          #throughsakuramobile
        >
          <p class="return-title">Through Sakura Mobile</p>
          <p class="return-info">
            If you do not have time, we can return the devices to NTT on your
            behalf. Please send the devices to our office in Shinjuku, Tokyo.
            (The return address will be provided later)
          </p>
          <div class="return-details">
            <div class="detail">
              <div class="image">
                <img
                  src="../../../../../assets/images/clock-green-icon.png"
                  alt="clock icon"
                />
              </div>
              <div><p>You can return the devices quickly</p></div>
            </div>
            <div class="detail">
              <div class="image">
                <img
                  src="../../../../../assets/images/error_FILL0_wght400_GRAD0_opsz24.svg"
                  alt="clock icon"
                />
              </div>
              <div>
                <p>You will need to pay the delivery fee to our office.</p>
              </div>
            </div>
            <div class="detail">
              <div class="image">
                <img
                  src="../../../../../assets/images/error_FILL0_wght400_GRAD0_opsz24.svg"
                  alt="clock icon"
                />
              </div>
              <div>
                <p>You will need to arrange the delivery by yourself.</p>
              </div>
            </div>
          </div>
        </button>
      </div>
      <div
        [hidden]="!isReturnKit"
        class="contract__cancel__returning-kit__address-shipping"
      >
        <div class="contract__cancel__returning-kit__address-shipping__header">
          <div class="square-dot"></div>
          <p class="title">Shipping address for the Return Kit</p>
        </div>
        <div
          [formGroup]="addressShipping"
          class="contract__cancel__returning-kit__address-shipping__address-form"
        >
          <div class="postcode--zip">
            <div class="postcode--zip__label">
              <label for="postcodeleft" class="label">Postcode/ZIP</label>
            </div>
            <div class="postcode--zip__form">
              <mat-form-field
                appearance="outline"
                class="mat--form post-code-left"
              >
                <input
                  matInput
                  id="postcodeleft"
                  name="postcode-l"
                  class="mat--input"
                  inputmode="numeric"
                  placeholder="000"
                  #postcodeLeftId
                  maxlength="3"
                  formControlName="post_code_left"
                  required
                />
                <mat-error
                  *ngIf="
                    addressShipping.get('post_code_left').hasError('required')
                  "
                  >Required*</mat-error
                >
                <mat-error
                  *ngIf="
                    addressShipping.get('post_code_left').hasError('minlength')
                  "
                  >Minimum 3 numbers</mat-error
                >
              </mat-form-field>
              <span class="dash">-</span>
              <mat-form-field
                appearance="outline"
                class="mat--form post-code-right"
              >
                <input
                  matInput
                  name="postcode-r"
                  class="mat--input"
                  inputmode="numeric"
                  placeholder="0000"
                  #postcodeRightId
                  maxlength="4"
                  formControlName="post_code_right"
                  required
                />
                <mat-error
                  *ngIf="
                    addressShipping.get('post_code_right').hasError('required')
                  "
                  >Required*</mat-error
                >
                <mat-error
                  *ngIf="
                    addressShipping.get('post_code_right').hasError('minlength')
                  "
                  >Minimum 4 numbers</mat-error
                >
              </mat-form-field>
              <button
                mat-button
                type="button"
                class="auto-fill"
                id="autoFill"
                #autoFillId
                [ngClass]="{
                  'apply-fill':
                    postcodeLeftId.value.length === 3 &&
                    postcodeRightId.value.length === 4
                }"
                [disabled]="
                  !(
                    postcodeLeftId.value.length === 3 &&
                    postcodeRightId.value.length === 4
                  )
                "
              >
                Autofill Address
              </button>
            </div>
            <div>
              <p class="invalid--postcode" *ngIf="!postCodeValid">
                Invalid postcode
              </p>
            </div>
          </div>

          <div class="block-in-japan">
            <div class="block-in-japan__label">
              <label for="blockJapan" class="label">Block in Japanese</label>
            </div>
            <mat-form-field
              appearance="outline"
              class="mat--form address--form"
            >
              <input
                matInput
                id="blockJapan"
                class="mat--input"
                name="address-jp"
                #addressJPId
                formControlName="fiber_location_block_in_jp"
                readonly
                required
              />
              <mat-error
                *ngIf="
                  addressShipping
                    .get('fiber_location_block_in_jp')
                    .hasError('required')
                "
                >Required*</mat-error
              >
            </mat-form-field>
          </div>

          <div class="block-in-english">
            <div class="block-in-english__label">
              <label for="blockEnglish" class="label">Block in English</label>
            </div>
            <mat-form-field
              appearance="outline"
              class="mat--form address--form"
            >
              <input
                matInput
                id="blockEnglish"
                class="mat--input"
                name="address-en"
                formControlName="fiber_location_block_in_en"
                #addressENId
                readonly
                required
              />
              <mat-error
                *ngIf="
                  addressShipping
                    .get('fiber_location_block_in_en')
                    .hasError('required')
                "
                >Required*</mat-error
              >
            </mat-form-field>
          </div>

          <div class="street-number">
            <div class="street-number__label">
              <label for="streetNumber" class="label">Street number</label>
            </div>
            <mat-form-field
              appearance="outline"
              class="mat--form address--form"
            >
              <input
                matInput
                id="streetNumber"
                class="mat--input"
                formControlName="fiber_location_street"
                required
              />
              <mat-error
                *ngIf="
                  addressShipping
                    .get('fiber_location_street')
                    .hasError('required')
                "
                >Required*</mat-error
              >
            </mat-form-field>
          </div>

          <div class="building-name">
            <div class="building-name__label">
              <label for="buildName" class="label"
                >Building name / Room number</label
              >
            </div>
            <mat-form-field
              appearance="outline"
              class="mat--form address--form"
            >
              <input
                matInput
                id="buildName"
                class="mat--input"
                formControlName="fiber_location_building_room"
              />
            </mat-form-field>
          </div>
        </div>
      </div>
      <div
        *ngIf="(item$ | async) !== 'Sakura Fiber Internet'"
        class="contract__cancel_return-simcard"
      >
        <h3 class="title-head">
          Returning
          {{ (item$ | async) === "Pocket WiFi" || (item$ | async) === "Home WiFi" ? "The Items" : "SIM Card" }}
        </h3>
        <div
          class="contract__cancel_popup-policy"
          (click)="openDialogReturnSC()"
        >
          <mat-icon class="popup-icon"> help </mat-icon>
          <p class="popup-link">
            Why do I need to return the
            {{
              (item$ | async) === "Pocket WiFi" || (item$ | async) === "Home WiFi"
                ? "the items"
                : "SIM
            cards"
            }}?
          </p>
        </div>
        <div class="contents-billing">
          <p class="billing-address">Return address:</p>
          <p class="billing-title">In English:</p>
          <p class="billing-info">
            Sakura Mobile SIM Return Desk (Og inc.) <br/>
            〒160-0023 Zenken Plaza II 6th Fl. 1-3-13, Nishishinjuku, Shinjuku, Tokyo, Japan
          </p>
          <p class="billing-title">In Japanese:</p>
          <p class="billing-info">
            〒160-0023 東京都新宿区西新宿１丁目３−１３ Zenken Plaza II 6F
          </p>
          <p class="billing-info-end" *ngIf="(item$ | async) !== 'Pocket WiFi' || (item$ | async) !== 'Home WiFi'">
            There is no due date or extra charge if it is difficult for you to
            return the SIM card. However, we would highly appreciate it if you
            could return it to us.
          </p>
          <p class="product__wifi" *ngIf="(item$ | async) === 'Pocket WiFi' || (item$ | async) === 'Home WiFi'">
            Please note that if the Items are not returned by the 5th of the
            following month without early notice, a loss/damage fee may apply.
          </p>
        </div>
      </div>
      <div
        *ngIf="(item$ | async) === 'Sakura Fiber Internet'"
        class="contract__cancel__note"
        style="display: none"
      >
        <p class="contract__cancel__note__title">Note</p>
        <div class="contract__cancel__note__content">
          <p>
            Please contact us in a separate email if you are a customer whose
            internet was installed by physical installation (NTT staff visited
            your residence) and you need to remove the wiring/equipment when you
            leave your residence. (Restore residence to original conditions)
          </p>
        </div>
      </div>

      <div
        class="contract__cancel-checkbox"
        *ngIf="
          !(isSmartpitMethod$ | async) ||
          (item$ | async) === 'Pocket WiFi' ||
          (item$ | async) === 'Home WiFi' ||
          (item$ | async) === 'Data only SIM' ||
          (item$ | async) === 'Sakura Fiber Internet'
        "
      >
        <p class="title-checkbox">
          <mat-checkbox (change)="onChange($event)"
            >I understand the cancellation policy and confirm the cancel
            information is correct.</mat-checkbox
          >
        </p>
      </div>
      <ng-container
        *ngIf="(item$ | async) === 'Sakura Fiber Internet'; else voicesimcard"
      >
        <div
          class="contract__cancel-submit"
          *ngIf="(payment_method$ | async) === 'Credit/Debit Card'"
        >
          <button
            class="btn-submit"
            type="submit"
            [ngClass]="{
              submited:
                isConfirmCancelDate &&
                isConfirmCancelPolicy &&
                (isReturnKit
                  ? addressShipping.valid
                  : isThroughSakura
                  ? true
                  : false) &&
                isChecked
            }"
            [disabled]="
              !(
                isConfirmCancelDate &&
                isConfirmCancelPolicy &&
                (isReturnKit
                  ? addressShipping.valid
                  : isThroughSakura
                  ? true
                  : false) &&
                isChecked
              ) || isResponding
            "
            (click)="submitCancel()"
          >
            Submit Request
          </button>
          <div class="stepper">
            <button mat-icon-button disabled>
              <mat-icon> radio_button_unchecked</mat-icon>
            </button>
            <button mat-icon-button>
              <mat-icon> lens </mat-icon>
            </button>
          </div>
        </div>
        <div
          class="contract__cancel-submit"
          *ngIf="(payment_method$ | async) === 'SmartPit Card'"
        >
          <button
            class="btn-submit"
            type="submit"
            [ngClass]="{
              submited:
                isConfirmCancelPolicy &&
                (isReturnKit
                  ? addressShipping.valid
                  : isThroughSakura
                  ? true
                  : false) &&
                isChecked
            }"
            [disabled]="
              !(
                isConfirmCancelPolicy &&
                isChecked &&
                (isReturnKit
                  ? addressShipping.valid
                  : isThroughSakura
                  ? true
                  : false)
              ) || isResponding
            "
            (click)="submitCancel()"
          >
            Submit Request
          </button>
          <div class="stepper">
            <button mat-icon-button disabled>
              <mat-icon> radio_button_unchecked</mat-icon>
            </button>
            <button mat-icon-button>
              <mat-icon> lens </mat-icon>
            </button>
          </div>
        </div>
      </ng-container>
      <ng-template #voicesimcard>
        <ng-container
          *ngIf="
            (payment_method$ | async) === 'Credit/Debit Card' ||
              (item$ | async) === 'Pocket WiFi' ||
              (item$ | async) === 'Home WiFi' ||
              (item$ | async) === 'Data only SIM';
            else smartpit
          "
        >
          <div class="contract__cancel-submit">
            <button
              class="btn-submit"
              type="submit"
              [ngClass]="{ submited: isChecked && reasonsCancellForm.valid }"
              [disabled]="
                !(isChecked && reasonsCancellForm.valid) || isResponding
              "
              (click)="submitCancel()"
            >
              Submit Request
            </button>
            <div class="stepper">
              <button mat-icon-button disabled>
                <mat-icon> radio_button_unchecked</mat-icon>
              </button>
              <button mat-icon-button>
                <mat-icon> lens </mat-icon>
              </button>
            </div>
          </div>
        </ng-container>
        <ng-template #smartpit>
          <div class="contract__cancel-submit">
            <button
              class="btn-submit"
              type="submit"
              [ngClass]="{ submited: ispayMethod && isConfirmCancelPolicy }"
              [disabled]="!(ispayMethod && isConfirmCancelPolicy)"
              (click)="submitCancel()"
            >
              Next
            </button>
            <div class="stepper">
              <button mat-icon-button disabled>
                <mat-icon> radio_button_unchecked</mat-icon>
              </button>
              <button mat-icon-button>
                <mat-icon> lens </mat-icon>
              </button>
              <button mat-icon-button disabled>
                <mat-icon> radio_button_unchecked</mat-icon>
              </button>
            </div>
          </div>
        </ng-template>
      </ng-template>
    </div>
  </div>
</div>
