<div class="mnp__information">
  <div class="widget">
    <app-menu [back_button]="back_button" [title]="phone_number$ | async"></app-menu>
    <div class="widget__contents">
      <div class="widget__contents--confirm-title">
        <mat-icon class="icon-confirm">check_circle</mat-icon>
        <p class="title-confirm">Confirmation</p>
      </div>

      <div class="widget__contents--information">
        <p class="info">
          The MNP issuance fee will be charged to your registered credit/debit card.
        </p>
      </div>

      <div class="widget__contents--issuance">
        <span class="issuance-title">Issuance fee</span>
        <span class="issuance-value">1,100 JPY</span>
      </div>

      <div class="widget__contents--description">
        <p class="des">
          The MNP number will be sent to you by email within 3 days. The Issued MNP
          is <span class="des-inside">valid for 14 days.</span>
        </p>
        <p class="des">
          The contract <span class="des-inside">will be terminated automatically on the day your number
          is successfully transferred to another provider by MNP.</span> However, the
          monthly fee applies for the same month.
        </p>
        <p class="des">
          Please be aware that  if you don't transfer to another provider after being issued the MNP, the contract will automatically continue. Furthermore, once the MNP is issued, you will not be able to request a regular termination until the 14-day period ends.
        </p>
        <p class="des">
          A new MNP cannot be issued until the previously issued MNP expires.
        </p>
      </div>

      <div class="widget__contents--arrow">
        <mat-icon class="arrow-icon">fast_forward</mat-icon>
      </div>

      <div class="widget__contents--submit-mnp">
        <mat-icon class="submit-icon">forward</mat-icon>
        <p class="submit-info">Submit MNP Information</p>
      </div>

      <div class="widget__contents--submit-description">
        <p class="submit-des">
          You must be the contractor. You cannot apply for a family/friend. The name has to be less than 30 letters, including spaces.
        </p>
      </div>

      <div class="widget__contents--form-information">
        <form [formGroup]="mnpInformation">
          <div class="form-flex">
              <mat-form-field class="form-field">
                <p class="label">
                  Last Name<br/>
                  <span class="sample">Smith</span>
                </p>
                <input matInput  class="input" [value]="last_name" formControlName="lastName" required/>
              </mat-form-field>

              <mat-form-field class="form-field">
                <p class="label">
                  First Name<br/>
                  <span class="sample">John</span>
                </p>
                <input matInput  class="input" [value]="first_name" formControlName="firstName" required/>
              </mat-form-field>

              <mat-form-field class="form-field">
                <p class="label">
                  Last Name (Kana)<br/>
                  <span class="sample">スミス</span>
                </p>
                <input matInput  class="input" [value]="last_name_kana" formControlName="lastNameKana" required/>
                <mat-error
                *ngIf="mnpInformation.controls['lastNameKana'].hasError('isKana')"
                >Must Kana character</mat-error>
              </mat-form-field>

              <mat-form-field class="form-field">
                <p class="label">
                  First Name (Kana)<br/>
                  <span class="sample">ジョン</span>
                </p>
                <input matInput  class="input" [value]="first_name_kana" formControlName="firstNameKana" required/>
                <mat-error
                *ngIf="mnpInformation.controls['firstNameKana'].hasError('isKana')"
                >Must Kana character</mat-error>
              </mat-form-field>
        </div>
        <div class="birthday">
          <label class="label-birthday">Birthday</label>
          <mat-form-field>
            <input matInput class="input-birthday" [matDatepicker]="picker" placeholder="mm/dd/yyyy"
            [value]="birth_day"
            (dateChange)="dateChange($event)"
            formControlName="contract_date_of_birth" required />
            <mat-datepicker-toggle
              matSuffix
              [for]="picker"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
        </div>
        <div class="sex">
          <label id="sex-radio-group-label" class="label-sex">Sex</label>
         <div class="sex-option">
          <mat-radio-group aria-labelledby="sex-radio-group-label"  formControlName="contract_sex" [value]="sex" ng-required="true">
            <mat-radio-button value="male">Male</mat-radio-button>
            <mat-radio-button value="female">Female</mat-radio-button>
          </mat-radio-group>
         </div>
        </div>
        </form>
      </div>
      <ng-container  *ngIf="pay_method === 'smartpit'">
      <div class="widget__contents--deposit-refund">
        <p class="deposit-title">Deposit Refund</p>
        <button appPaymentmethod type="button" class="method" #choose1   [value]="method_paypal"  (click)="onClick(choose1)"
       >Paypal</button>
        <button appPaymentmethod type="button"  class="method" #choose2 [value]="method_japan" (click)="onClick(choose2)"
        >Japanese Bank Account</button>
      </div>
    </ng-container>
      <div class="widget__contents--term">
        <mat-icon class="term-check" (click)="toggleChecked()">{{mat_content}}</mat-icon>
        <span class="term-content"
          >I agree with the charge and would like to proceed with the MNP
          issuance.</span
        >
      </div>

      <button mat-button class="widget__contents--submitted-button"
      *ngIf="pay_method === 'smartpit'"
      [ngClass]="{'submitted--button': checked && mnpInformation.valid && isPayMethod}"
      [disabled]="!checked && !mnpInformation.valid && !isPayMethod"
      (click)="submittedRequest()"
      >
        Submit Request
      </button>
      <button mat-button class="widget__contents--submitted-button"
      *ngIf="pay_method !== 'smartpit'"
      [ngClass]="{'submitted--button': checked && mnpInformation.valid && !isPayMethod}"
      [disabled]="!checked && !mnpInformation.valid && isPayMethod"
      (click)="submittedRequest()"
      >
        Submit Information
      </button>

      <div class="stepper">
        <button mat-icon-button disabled>
          <mat-icon> radio_button_unchecked</mat-icon>
        </button>
        <button mat-icon-button>
          <mat-icon>
            lens
          </mat-icon>
        </button>
        <button
          mat-icon-button
          disabled
          *ngIf="pay_method === 'smartpit'"
        >
          <mat-icon> radio_button_unchecked</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
