export class User {
    public id: number;
    public email: string;
    public sign_in_count: number;
    public current_sign_in_at: Date;
    public last_sign_in_at: Date;
    public current_sign_in_ip: string;
    public last_sign_in_ip: string;
    public confirmed_at: Date;
    public confirmation_sent_at: Date;
    public locked_at: Date;
    public nickname: string;
    public telephone: string;
    public full_name: string;
    public display_name: string;

    constructor(
        private _id: number,
        private _email: string,
    ) {
        this.id = _id;
        this.email = _email;
    }
}
