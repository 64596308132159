import { Directive, ElementRef, Renderer2, HostListener, HostBinding } from '@angular/core';

@Directive({
  selector: '[appChooseReason]'
})
export class ChooseReasonDirective {

  constructor(
    private el: ElementRef,
    private render: Renderer2
  ) { }
  // @HostBinding('class.choose') private isChoose: boolean;
  @HostListener('click', ['$event.target']) onClick(event: any) {
     this.render.addClass(this.el.nativeElement, 'choose');
     // this.isChoose = !this.getValue(event.value);
  }

  // private getValue(value: string) {
  //   switch (value) {
  //     case 'Go back to home country':
  //       return true;
  //       break;
  //     case 'I no longer use a cellphone/WIFI':
  //       return true;
  //       break;
  //     case  'Moving to another service provider':
  //       return true;
  //       break;
  //     default:
  //       return false;
  //       break;
  //   }
  // }
}
