import { Component, Inject, Input, OnDestroy, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { V1UserAPIService } from 'src/app/services/v1api.service';
import { Invoices } from 'src/app/types/invoice';
import { map, shareReplay, tap } from 'rxjs/operators';
import { AccountInformationService } from 'src/app/services/account-information.service';
import { MatMenuTrigger, MatSnackBar } from '@angular/material';
import { Constants } from 'src/app/constants';
import { Router } from '@angular/router';
import { SubSink } from 'subsink';
import { Observable } from 'rxjs';
import { Point } from 'src/app/types/points';
import { PointsService } from 'src/app/services/points.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MenuComponent implements OnInit, OnDestroy {
  private email: string;
  private user: string;
  private sub = new SubSink();
  public points$: Observable<Point | string>;
  constructor(
    @Inject('windowObject') private window: Window,
    private _api: V1UserAPIService,
    private _pointService: PointsService,
    private _accountService: AccountInformationService) { }
  @Input() invoices: Invoices;
  @Input() User: string;
  @Input() Email: string;
  @Input() title: string;
  @Input() back_button: boolean;
  ngOnInit() {
  }
  public onClickLogout(): void {
    this._api.logout();
  }
  onGetPoints() {
    this.points$ = this._pointService.getPoints().pipe(shareReplay());
  }
  onSupport() {
    this.window.open(`https://sakuramobile.zendesk.com/hc/en-us/categories/360001245792-FOR-LONG-TERM-SIM-WIFI`, '_blank');
  }
  contactUs() {
    this.sub.sink = this._accountService.userInformation$.subscribe(data => {
      this.email = data.data.email;
      this.user = data.data.user_full_name;
    });
    this.window.open(`https://www.sakuramobile.jp/contact-us/?user=${this.user}&email=${this.email}`, '_blank');
  }
  exchangePoints() {
    this.sub.sink = this._accountService.userInformation$.subscribe(data => {
      this.email = data.data.email;
      console.log(this.email, data);
    });
    this.window.open(`https://store.sakuramobile.jp/point-redemption?email=${this.email}`, '_blank');
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
  toLocaleString(value: number): string {
    const separated = String(value).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');
    console.log('toLocaleString value=', value,' : ',separated);
    return separated;
  }
}
