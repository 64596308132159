import { Component, OnInit, Renderer2, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { Invoice } from 'src/app/types/invoice';
import { PaymentInvoicesService } from 'src/app/services/payment-invoices.service';
import { CreditCard } from 'src/app/types/creditcard-information';
import { map, shareReplay, tap } from 'rxjs/operators';
import { Utils } from 'src/app/utils';
import { SubSink } from 'subsink';
import { AccountInformationService } from 'src/app/services/account-information.service';
import { LoadingService } from 'src/app/services/loading.service';
import { RouterHistoryService } from 'src/app/services/router-history.service';


// Region methods which define in JS file
declare function getGMOToken(card_number, expire_date, card_cvv, holder_name): any;

// End JS

@Component({
  selector: 'app-payment-information',
  templateUrl: './payment-information.component.html',
  styleUrls: ['./payment-information.component.scss']
})
export class PaymentInformationComponent implements OnInit, OnDestroy {
  private subs = new SubSink();
  back_button = true;
  holder_name: string;
  card_number: string;
  expire_date: string;
  card_cvv: string;
  card_sequence: string;
  member_id: string;
  invoice_id: number;
  card_pay_type: boolean; // 1: pay by registered card -- 0: pay by new card
  pay_try_count: number;
  invoice$: Observable<Invoice> = this.paymentService.invoice$;
  spinner$ = this._loadingService.spinner$;

  constructor(
    private accountService: AccountInformationService,
    private paymentService: PaymentInvoicesService,
    private _loadingService: LoadingService,
    private _renderer2: Renderer2,
    readonly _routerHistoryService: RouterHistoryService
  ) {}
  creditCardInformation$ = this.paymentService.creditCardInformation().pipe(
    map(res => res.data),
    tap(res => console.log('Credit card information ::Payment-informationcomponent: ------ ', res)),
    shareReplay()
  );
  expireDate$ = this.creditCardInformation$.pipe(
    map(res => Utils.convertExpireDate(res.expire)),
  );

  ngOnInit() {
    // make limitation to try pay count
    this.pay_try_count = 0;
    // Create script tab when component init
    this.paymentService.createScriptTab(this._renderer2);
    // Subcription
    this.subs.sink = this.creditCardInformation$.subscribe(res => this.card_sequence = res.cardSeq);
    this.subs.sink = this.accountService.userInformation$.subscribe(res => this.member_id = res.data.email);
    this.subs.sink = this.invoice$.subscribe(res => this.invoice_id = res.id);
  }
  paymentInvoice() {
    this._loadingService.setSpinnerEvent = true;
    this.card_pay_type = true;
    this.paymentService.setPayType = this.card_pay_type;
    this.paymentService.payinvoces_RegisteredCard(this.invoice_id, this.member_id, this.card_sequence);
    this.pay_try_count++;
  }
  ngOnDestroy() {
    this.subs.unsubscribe();
  }
  isPayDisabled() {
    return this.pay_try_count > 4;
  }
  invoiceMonthToUsedMonth(invoice_month: any): string {
    if (invoice_month) 
    {
      const ivs = invoice_month.split('-');
      const monthes = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ];
      let y = parseInt(ivs[0]);
      let m = parseInt(ivs[1])-1; // -1 : month -> index
      m = m - 1; // invoice month -> used month
      if (m < 0) {
        y = y - 1;
        m = 11;
      }
      return monthes[m];
    }
    return "";
  }
}
