
export class Invoice {
id: number;
invoice_code: string;
invoice_month: string;
contract_id: number;
contract_code: string;
user_id: number;
user_e_mail: string;
user_name_id: number;
user_name: string;
pay_method: string;
mail_send_status: number;
mail_send_config: number;
pay_sub_total: number;
pay_sub_total_tax: number;
pay_date: string;
pay_error: string;
pay_check_date: string;
demand_status: string;
demand_send_user_id: number;
demand_sent_at: string;
pay_status: number;
pay_accept_user_id: number;
sub_total: number;
sub_total_tax: number;
secure_random: string;
created_at: string;
updated_at: string;
}

export type Invoices = Array<Invoice>;
