import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material';
import { PolicyContentsComponent } from './policy-contents/policy-contents.component';
import { CancellationService } from 'src/app/services/cancellation.service';
import { Observable } from 'rxjs';
import { ContractsService } from 'src/app/services/contracts.service';
import { SubSink } from 'subsink';
import { DatePipe } from '@angular/common';
import { RouterHistoryService } from 'src/app/services/router-history.service';

@Component({
  selector: 'app-policy-cancellation',
  templateUrl: './policy-cancellation.component.html',
  styleUrls: ['./policy-cancellation.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PolicyCancellationComponent implements OnInit, OnDestroy {
  private subs = new SubSink();
  back_button = true;
  // line_number$: Observable<string>;
  name_month_product: string;
  year_product: string;
  terminationDate: string;
  finalPayment: string;
  start_at_product: Date;
  constructor(
    public dialog: MatDialog,
    private datePipe: DatePipe,
    private _contractService: ContractsService,
    private cancelService: CancellationService,
    readonly _routerHistoryService: RouterHistoryService
    ) { }

  ngOnInit() {
    this.subs.sink = this._contractService.contract$.pipe().subscribe(
      (res_contract) => {
        if (res_contract !== null) {
          console.log("res_contract : ", res_contract);
          this.start_at_product = new Date(res_contract.start_at);
          // this.line_number$ = this.cancelService.line_number$;
          this.subs.sink = this._contractService.product$.pipe().subscribe(
            (res) => {
              if (res !== null) {
                console.log('Start_at of Product::', this.start_at_product);
                const start_at_month = this.start_at_product.getMonth() + 1;
                const earliest_termination_date = new Date(
                  this.start_at_product.getFullYear(),
                  start_at_month + 2,
                  0
                );
                console.log('Earliset tertmination date::', earliest_termination_date);
                const day_of_early_termination = earliest_termination_date.getDate();
                const month_of_early_termination = earliest_termination_date.getMonth() + 1;

                const submission_date = new Date();
                const month_of_submission = submission_date.getMonth() + 1;
                const day_in_month_submission = submission_date.getDate();
                console.log(`Submission date:: ${day_in_month_submission} - ${month_of_submission}`);
                // more than 3 month
                if (submission_date > earliest_termination_date) {
                  console.log('Case more than 3 month ===> ', this.start_at_product);
                  if (day_in_month_submission <= 25) {
                    console.log('Case more than 3 month and <= 25 day');
                    this.terminationDate = this.datePipe.transform(
                      new Date(
                        submission_date.getFullYear(),
                        submission_date.getMonth() + 1,
                        0
                      ),
                      'yyyy-MM-dd'
                    );
                    this.finalPayment = this.datePipe.transform(
                      new Date(
                        submission_date.getFullYear(),
                        submission_date.getMonth() + 2,
                        0
                      ),
                      'yyyy-MM-dd'
                    );
                    console.log('term/final (Case more than 3 month and <= 25 day)', this.terminationDate, this.finalPayment);
                  } else {
                    console.log('Case more than 3 month and > 25 day');
                    this.terminationDate = this.datePipe.transform(
                      new Date(
                        submission_date.getFullYear(),
                        submission_date.getMonth() + 2,
                        0
                      ),
                      'yyyy-MM-dd'
                    );
                    this.finalPayment = this.datePipe.transform(
                      new Date(
                        submission_date.getFullYear(),
                        submission_date.getMonth() + 3,
                        0
                      ),
                      'yyyy-MM-dd'
                    );
                    console.log('term/final (Case more than 3 month and > 25 day)', this.terminationDate, this.finalPayment);
                  }
                } else  if (
                  submission_date > this.start_at_product &&
                  submission_date < earliest_termination_date
                ) {
                  console.log('Case in 3 month ===> ', this.start_at_product, earliest_termination_date);
                  if (month_of_early_termination === month_of_submission) {
                    console.log('Case at 3 month');
                    if (day_in_month_submission <= 25) {
                      console.log('Case at 3 month & <= 25 day');
                      this.terminationDate = this.datePipe.transform(
                        earliest_termination_date,
                        'yyyy-MM-dd'
                      );
                      this.finalPayment = this.datePipe.transform(
                        new Date(
                          earliest_termination_date.getFullYear(),
                          earliest_termination_date.getMonth() + 2,
                          0
                        ),
                        'yyyy-MM-dd'
                      );
                      console.log('term/final (Case at 3 month & <= 25 day)', this.terminationDate, this.finalPayment);
                    } else {
                      console.log('Case at 3 month & > 25 day');
                      this.terminationDate = this.datePipe.transform(
                        new Date(
                          earliest_termination_date.getFullYear(),
                          earliest_termination_date.getMonth() + 2,
                          0
                        ),
                        'yyyy-MM-dd'
                      );
                      this.finalPayment = this.datePipe.transform(
                        new Date(
                          earliest_termination_date.getFullYear(),
                          earliest_termination_date.getMonth() + 3,
                          0
                        ),
                        'yyyy-MM-dd'
                      );
                      console.log('term/final (Case at 3 month & > 25 day)', this.terminationDate, this.finalPayment);
                    }
                  } else if (earliest_termination_date > submission_date) {
                    console.log('Case less than 3 month');
                    this.terminationDate = this.datePipe.transform(
                     earliest_termination_date,
                      'yyyy-MM-dd'
                    );
                    this.finalPayment = this.datePipe.transform(
                      new Date(
                        earliest_termination_date.getFullYear(),
                        earliest_termination_date.getMonth() + 2,
                        0
                      ),
                      'yyyy-MM-dd'
                    );
                    console.log('term/final (Case less than 3 month)', this.terminationDate, this.finalPayment);
                  }
                }
                this.year_product =  this.terminationDate.slice(0, 4);
                const month_product_end_at = Number( this.terminationDate.slice(5, 7));
                const month_by_name = [  'Jan', 'Feb',  'Mar', 'Apr', 'May',  'Jun',  'Jul',  'Aug',  'Sep',   'Oct',  'Nov',  'Dec'];
                this.name_month_product = month_by_name[month_product_end_at - 1];
            }
          },
            (error) => console.log(error)
          );
        }
      }
    );
  }
  openDialog() {
    this.dialog.open(PolicyContentsComponent);
  }
  ngOnDestroy () {
    this.subs.unsubscribe();
  }
}
