import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SpinnerComponent implements OnInit {

  public spinner$: Observable<boolean>;
  constructor(private loadingServices: LoadingService) {}
  ngOnInit() {
    this.spinner$ = this.loadingServices.spinner$;
  }

}
