import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { RouterHistoryService } from 'src/app/services/router-history.service';
import { V1UserAPIService } from 'src/app/services/v1api.service';

@Component({
  selector: 'app-item-option',
  templateUrl: './item-option.component.html',
  styleUrls: ['./item-option.component.scss']
})
export class ItemOptionComponent implements OnInit {

  constructor(
    private _api: V1UserAPIService,
  ) { }

  ngOnInit() {
    console.log('ItemOptionComponent::ngOnInit', this._api.brideData);
  }
}
