import { Component, OnInit, ViewEncapsulation, OnDestroy, AfterViewInit, NgZone } from '@angular/core';
import {
  Router
} from '@angular/router';
import { V1UserAPIService } from 'src/app/services/v1api.service';
import { ContractsService } from 'src/app/services/contracts.service';
import { Invoices } from 'src/app/types/invoice';
import { SubSink } from 'subsink';
import { AccountInformationService } from 'src/app/services/account-information.service';
import { Constants } from 'src/app/constants';
import { LoginResult } from 'src/app/types/auth';

@Component({
  selector: 'app-portal',
  templateUrl: './portal.component.html',
  styleUrls: ['./portal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PortalComponent implements OnInit, OnDestroy {
  private subs = new SubSink();
  private switchBanner: number;
  public isCampaign: boolean;
  public back_button = true;
  public contract_code: string;
  public start_day: Date;
  public end_day: Date;
  public active_pay_status: boolean;
  public ending_pay_status: boolean;
  public complete_pay_status = false;

  public invoices: Invoices;
  public activeContracts = [];
  public endingContracts = [];
  public completeContracts = [];

  public activeOption: boolean;
  public completeOption: boolean;
  public endingOption: boolean;
  constructor(
    private _api: V1UserAPIService,
    private _contractsService: ContractsService,
    private _router: Router,
    private _accountService: AccountInformationService
  ) {}
  ngOnInit() {
     // Switch banner every 10 seconds.
     this.isCampaign = true;
     let i = 0;
     this.switchBanner = (window as any).setInterval( () => {
       i = i + 1;
       this.isCampaign = false;
       if (i === 2) {
         i = 0;
         this.isCampaign = true;
       }
     }, 10000);
     // End switch banner
    // START : ===== common implementation for login required components =====================
    if (this._api.isLoggingIn(true) === false) {
      this._router.navigate(['/auth/login']);
    }
    // END : ===== common implementation for login required components =====
    this.getListContracts_Invoices();
    this._accountService.AccountInformation().subscribe();
  }

  public getListContracts_Invoices() {
    return this._contractsService.listContractsInvoices().subscribe(
      (result) => {
        const [contracts, invoices] = result;
        const { message: mess, data: dataContracts } = contracts; // dataContracts = []
        const { message: message, data: dataInvoices } = invoices; // dataInvoices = []
        this.invoices = dataInvoices;
        dataContracts.forEach((contract) => {
          // PAY METHOD
          const pay_methods = contract.properties.filter((property) => {
            return property.type.key === 'PAY METHOD';
          });
          if (
            pay_methods === null ||
            pay_methods === undefined ||
            pay_methods.length === 0
          ) {}
          const pay_method = pay_methods[0];

          if ((contract.status >= 600 && contract.status <= 700) || (contract.status >= 1201 && contract.status <= 1201)) {
            // CONDITION : contract.status between 600 - 700 => living contracts

            // contract start date
            let dt_start_at = null;
            if (contract.start_at !== null && contract.start_at !== '') {
              dt_start_at = Date.parse(contract.start_at);
            }
            // contract end date
            let dt_end_at = null;
            if (contract.end_at !== null && contract.end_at !== '') {
              dt_end_at = Date.parse(contract.end_at);
            }
            // current date
            const dt_now = new Date(Date.now());
            // end date of contract can be completed
            let dt_pay_end_at = new Date(dt_end_at);
            if (pay_method.property.value === 'CREDIT CARD') {
              // contract end month + 1 => final invoice pubish month
              dt_pay_end_at = new Date(
                dt_pay_end_at.getFullYear(),
                dt_pay_end_at.getMonth() + 2,
                0
              );
            } else if (pay_method.property.value === 'SMARTPIT') {
              // contract end month + 2 => deposit will be returned
              dt_pay_end_at = new Date(
                dt_pay_end_at.getFullYear(),
                dt_pay_end_at.getMonth() + 3,
                0
              );
            } else {
              console.log('portalComponent:: unknown pay method');
            }

            // skip if no start_at
            if (dt_start_at === null) {
              return; /* exit forEach */
            }

            if (
              dt_start_at <= dt_now &&
              (dt_end_at === null || dt_end_at >= dt_now)
            ) {
              // CONDITION : 'now' in the term of contract => Active | Active has unpaid
              this.activeOption = true;
              this.activeContracts.push(contract);
              const invoices_active = this._contractsService.findArr_Invocies(
                this.invoices,
                this.activeContracts[0]
              );
              this.active_pay_status = invoices_active.some(
                (invoice) => invoice.pay_status === 0
              )
                ? true
                : false;
            } else if (dt_start_at > dt_now) {
              // CONDITION : future start => Don't show
              return; /* exit forEach */
            } else if (dt_end_at < dt_now) {
              // has unpaid invoices?
              if (Object.keys(this.invoices).length === 0) { return; }
              const unpaid_invoices = this.invoices.filter((invoice) => {
                return (
                  invoice.contract_code === contract.contract_code &&
                  invoice.pay_status === 0
                );
              });
              // CONDITION : already end => Ending(has unpaid) / Completed(has no unpaid && no more invoices && no more deposit)
              // console.log('PortalCOmponent::getContracts : unpaid_invoices : ' + JSON.stringify(unpaid_invoices));
              if (unpaid_invoices.length > 0) {
                // has unpaid invoices => anyway 'Ending'
                this.endingOption = true;
                this.endingContracts.push(contract);
                const invoices_ending = this._contractsService.findArr_Invocies(
                  this.invoices,
                  this.endingContracts[0]
                );
                this.ending_pay_status = invoices_ending.some(
                  (invoice) => invoice.pay_status === 0
                )
                  ? true
                  : false;
              } else {
                if (dt_now < dt_pay_end_at) {
                  // Stay in 'Ending' : waiting final invoice / Awaiting payment of deposit
                  this.endingOption = true;
                  this.endingContracts.push(contract);
                  const invoices_ending = this._contractsService.findArr_Invocies(
                    this.invoices,
                    this.endingContracts[0]
                  );
                  this.ending_pay_status = invoices_ending.some(
                    (invoice) => invoice.pay_status === 0
                  )
                    ? true
                    : false;
                } else {
                  this.completeOption = true;
                  this.completeContracts.push(contract);
                }
              }
            } else {
              // unhandled
              // console.log('PortalCOmponent::getContracts : unhandled status/start_at/end_at' + JSON.stringify(contract));
            }
          }
        });
       }
      // ,
      // (err) => {
      //   console.log(err);
      // }
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    clearInterval(this.switchBanner);
  }
}
