<div class="fiber_reasons_cancel">
  <div class="fiber_reasons_cancel__title">
    <p class="title">Contract Cancellation</p>
  </div>
  <div class="fiber_reasons_cancel__require-info">
    Please let us know your opinions so that we can improve our service in the
    future.
  </div>
  <div class="fiber_reasons_cancel__form">
    <div class="fiber_reasons_cancel__form__header">
      <div class="square-dot"></div>
      <p class="title_form">Questions related to your internet deactivation</p>
    </div>
    <div class="fiber_reasons_cancel__form__questions">
      <p>Will you be moving from your current residence?</p>
    </div>
    <div class="fiber_reasons_cancel__form__choose-option">
      <button
        appChooseReason
        class="btn_option"
        type="button"
        #yes
        [value]="'Yes, I will'"
        (click)="reasonCancelFiber(yes)"
      >
        Yes, I will
      </button>
      <button
        appChooseReason
        class="btn_option"
        [value]="'No, I will not'"
        type="button"
        #no
        (click)="reasonCancelFiber(no)"
      >
        No, I will not
      </button>
    </div>
    <div
      *ngIf="show_fiber_yes_case"
      class="fiber_reasons_cancel__form__yes-case"
      [formGroup]="fiberCancelReasons"
    >
      <div class="date-leave">
        <p class="date-leave__title">
          Date you will leave your current residence
        </p>
        <mat-form-field
          appearance="outline"
          class="date-leave__date-picker"
          (click)="picker.open()"
        >
          <input
            matInput
            class="mat--input"
            [matDatepicker]="picker"
            readonly
            required
            (dateChange)="leaveResidenDateChange($event)"
          />
          <mat-datepicker-toggle
            matSuffix
            [for]="picker"
          ></mat-datepicker-toggle>
          <mat-datepicker touchUi #picker></mat-datepicker>
        </mat-form-field>
      </div>
      <div class="area-move">
        <p class="area-move__title">Will you move in Japan or move overseas?</p>
        <button
          appChooseReason
          class="area-move__btn_option"
          type="button"
          #InJapan
          [value]="'In Japan'"
          (click)="chooseWhereToMove(InJapan)"
        >
          In Japan
        </button>
        <button
          appChooseReason
          class="area-move__btn_option"
          [value]="'Overseas'"
          type="button"
          #Overseas
          (click)="chooseWhereToMove(Overseas)"
        >
          Overseas
        </button>
      </div>
    </div>
  </div>
</div>
