import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { CancellationService } from 'src/app/services/cancellation.service';
import { ContractsService } from 'src/app/services/contracts.service';
import { Observable } from 'rxjs';
import { SubSink } from 'subsink';
import { RouterHistoryService } from 'src/app/services/router-history.service';

@Component({
  selector: 'app-paypal-submitted',
  templateUrl: './paypal-submitted.component.html',
  styleUrls: ['./paypal-submitted.component.scss']
})
export class PaypalSubmittedComponent implements OnInit, OnDestroy {
  private subs = new SubSink(); // Subsink: unscrible all subscriptions
  back_button = true;
  constructor(private router: Router,
    private _cancellationService: CancellationService,
    private _contractService: ContractsService,
    readonly _routerHistoryService: RouterHistoryService
    ) { }
  phone_number$: Observable<string> = this._cancellationService.line_number$;
  productId$: Observable<number>;
  ngOnInit() {
    this.productId$ = this._contractService.productId$;
  }
  goBack() {
    this.subs.sink = this.productId$.subscribe(
      productid => this.router.navigate([`portal/contract-product/${productid}`])
    );
  }
  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
