import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-return-simcard',
  templateUrl: './return-simcard.component.html',
  styleUrls: ['./return-simcard.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ReturnSimcardComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data$: Observable<string>) { }

  ngOnInit() {
  }

}
