<div class="submitted__request">
  <div class="widget">
    <app-menu [back_button]="back_button" [title]="phone_number$ | async"></app-menu>
    <div class="widget__body">
      <div class="widget__body--title">
        <p class="title">Request submitted</p>
      </div>
      <div class="widget__body--info">
        <p class="info">
          We have received your MNP issuance request. The MNP information will be sent to you by email within 3 days after we process the submitted information.
          </p>
        <p class="info">
          Please be aware that the MNP has an expiry date and in case you don’t transfer to another provider after issuing MNP, the contract will be automatically continued.
        </p>
      </div>
      <div class="widget__body--img">
        <img class="imageInside" src="../../../../../assets/images/submitSakuraFlower.svg" />
      </div>
      <div class="widget__body--submitted">
        <p class="goback">Back to <span class="special" (click)="goBack()">Contract Detail</span></p>
      </div>

    </div>
  </div>
</div>
