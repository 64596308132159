
<div class="confirmation__mnp__information">
  <div class="widget">
    <app-menu
      [back_button]="back_button"
      [title]="phone_number$ | async"
    ></app-menu>
    <div class="widget__contents">
      <div class="widget__contents--title">
        <mat-icon class="icon">check_circle</mat-icon>
        <p class="title">Confirmation</p>
      </div>
      <div class="widget__contents--information">
          <p class="title-details">MNP Request Details</p>
          <div class="details">
            <p class="details-left">Last Name</p>
            <p class="details-right">{{last_name}}</p>
            <p class="details-left">Last Name (kana)</p>
            <p class="details-right">{{last_name_kana}}</p>
            <p class="details-left">First Name</p>
            <p class="details-right">{{first_name}}</p>
            <p class="details-left">First Name (Kana)</p>
            <p class="details-right">{{first_name_kana}}</p>
            <p class="details-left">Date Of Birth</p>
            <p class="details-right">{{birth_day}}</p>
            <p class="details-left">Sex</p>
            <p class="details-right">{{sex |titlecase}}</p>
          </div>
      </div>

      <div class="widget__contents--title__information">
        <p class="info-refund">
          The MNP issuance fee will be charged to your registered credit/debit card.
        </p>
      </div>

      <div class="widget__contents--issuance__information">
        <span class="issuance-title">Issuance fee</span>
        <span class="issuance-value">1,100 JPY</span>
      </div>
      <div class="widget__contents--branch_name_information" (click)="openTermConditions()">
        <mat-icon class="branch--icon">help</mat-icon>
        <p class="branch--title">MNP Terms and Conditions</p>
      </div>

      <div class="widget__contents--conditions__information">
        <mat-icon class="condition--icon" (click)="toggleChecked()">{{mat_content}}</mat-icon>
        <span class="condition--content"
          >I understand the terms and conditions and would like to proceed with the request.</span>
      </div>

      <button
        mat-button
        class="widget__contents--submitted-request-information"

        [ngClass]="{'submitted--button': checked}"
        [disabled]="!checked || isResponding"
        (click)="submitRequest()"
      >
        Submit Request
      </button>
    </div>
  </div>
</div>

