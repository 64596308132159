import { Directive, HostListener } from '@angular/core';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { RouterHistoryService } from '../services/router-history.service';
@Directive({
  selector: '[appBackbutton]',
})
export class BackbuttonDirective {
  public history: string[];
  private isCancellated: boolean;
  private isPayment: boolean;
  private isUpdateCard: boolean;
  private isTopUpFinish: boolean;
  private flat: number;
  constructor(
    private location: Location,
    private router: Router,
    private routerHistory: RouterHistoryService
  ) {
    this.routerHistory.flas$.subscribe((res) => {
      this.flat = res;
    });
    if (this.router.url === '/portal') {
      this.history.length = 0;
    } else {
      this.history = this.routerHistory.routeHistory;
    }
    this.isCancellated = this.history.some(
      (path) => path === '/submited-request' || path === '/submitted-paypal'
    );
    this.isPayment = this.history.some((path) => path === '/payment-received');
    this.isUpdateCard = this.history.some((path) => path === '/card_updated');
    this.isTopUpFinish = this.history.some((path) => path === '/top-up-finish');
    if (this.isCancellated) {
      this.flat = this.flat + 1;
    }
    if (this.isPayment) {
      this.flat = this.flat + 2;
    }
  }

  @HostListener('click') onclick() {
    if (
      this.isCancellated === false &&
      this.isPayment === false &&
      this.isUpdateCard === false &&
      this.isTopUpFinish === false
    ) {
      this.location.back();
    } else {
      this.history = this.history.slice(0, 2);
      this.routerHistory.flat = this.flat;
      if (this.isCancellated) {
        this.router.navigate([this.history[this.history.length - this.flat]]);
      }
      if (this.isPayment) {
        this.router.navigate([this.history[this.history.length - this.flat]]);
      }
      if (this.isUpdateCard) {
        this.router.navigate([this.history[0]]);
      }
      if (this.isTopUpFinish) {
        this.router.navigate([this.history[0]]);
      }
    }
  }
}
