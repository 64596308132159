import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Constants } from '../constants';
import { LoginResult } from '../types/auth';
import { ReferralCode } from '../types/referral-code';
import { Utils } from '../utils';

@Injectable({
  providedIn: 'root'
})
export class ReferralCodeService {

  constructor(
    private http: HttpClient
  ) { }

  generateReferralCode(): Observable<any> {
    return this.http.post<ReferralCode>(Constants.API_REFERRAL_CODE, null ,
      { headers: this.authHeader})
      .pipe(
        map(res => res.data)
      );
  }
  private get authHeader(): HttpHeaders {
    const current_user = this.getLoginUser();
    return new HttpHeaders({
      'Content-type': 'application/json',
      Authorization:
        'Bearer ' + (current_user ? current_user.data.auth_token : ''),
    });
  }
  private getLoginUser(): LoginResult {
    const userJson = localStorage.getItem(Constants.LOGIN_USER);
    if (!Utils.isValue(userJson)) {
      return null;
    }
    const user = <LoginResult>JSON.parse(userJson);
    return user;
  }
}
