<div class="update-card">
  <div class="widget">
    <app-menu [back_button]="true" [title]="'Account'"></app-menu>

    <div class="widget__contents">
      <div class="widget__contents--update-card-title">
        <img
          src="../../../../assets/images/logo-payment.svg"
          alt="logo payments"
          class="image"
        />
        <p class="details">Update Card Information</p>
      </div>

      <form [formGroup]="UpdateCard">
        <div class="widget__contents--card-holder-name">
          <label class="label-card">Card Holder’s Name</label>
          <p class="label-small">Must match the one registered on the card</p>
          <mat-form-field class="form-field">
            <input
              matInput
              class="input"
              formControlName="card_holder_name"
              required
            />
            <mat-error *ngIf="card_holder_name.hasError('pattern')"
              >Capital letters</mat-error
            >
            <mat-error *ngIf="card_holder_name.hasError('required')"
              >Required</mat-error
            >
            <mat-hint class="card-hint"
              >Please do not insert any special characters such as “-“,
              “.”,...</mat-hint
            >
          </mat-form-field>
        </div>

        <div class="widget__contents--card-number">
          <label class="label-card">Card Number</label>
          <mat-form-field class="form-field">
            <input
              matInput
              formControlName="card_number"
              [value]="card_number$ | async"
              required
            />
            <mat-error
              *ngIf="
                card_number.hasError('pattern') &&
                !card_number.hasError('required')
              "
              >Number only</mat-error
            >
            <mat-error *ngIf="card_number.hasError('required')"
              >Required</mat-error
            >
            <mat-error
              *ngIf="
                card_number.hasError('maxlength') &&
                !card_number.hasError('required') &&
                !card_number.hasError('pattern')
              "
              >Max length 16 number</mat-error
            >
          </mat-form-field>
        </div>

        <div class="widget__contents--expire-date">
          <label class="label-card">Expiry Date</label>
          <mat-form-field
            class="form-field time-input"
            floatLabel="never"
            hideRequiredMarker
          >
            <input
              matInput
              formControlName="expire_month"
              placeholder="MM"
              maxlength="2"
              required
            />
            <mat-error *ngIf="expire_month.hasError('required')"
              >Required</mat-error
            >
            <mat-error
              *ngIf="
                expire_month.hasError('pattern') &&
                !expire_month.hasError('required')
              "
              >Number only</mat-error
            >
            <mat-error
              *ngIf="
                !expire_month.hasError('pattern') &&
                !expire_month.hasError('required') &&
                expire_month.hasError('monthExpired')
              "
              >Invalid date</mat-error
            >
          </mat-form-field>
          <mat-form-field
            class="form-field time-input"
            floatLabel="never"
            hideRequiredMarker
          >
            <input
              matInput
              formControlName="expire_year"
              placeholder="YY"
              maxlength="2"
              required
            />
            <mat-error
              *ngIf="
                expire_year.hasError('pattern') &&
                !expire_year.hasError('required') &&
                !expire_year.hasError('yearExpired')
              "
              >Number only</mat-error
            >
            <mat-error *ngIf="expire_year.hasError('required')"
              >Required</mat-error
            >
            <mat-error
              *ngIf="
                !expire_year.hasError('pattern') &&
                !expire_year.hasError('required') &&
                expire_year.hasError('yearExpired')
              "
              >Invalid date</mat-error
            >
          </mat-form-field>
        </div>

        <div class="widget__contents--cvv">
          <label class="label-card cvv-label">CVV</label>
          <mat-form-field
            class="form-field time-input"
            floatLabel="never"
            hideRequiredMarker
          >
            <input
              matInput
              formControlName="card_cvv"
              placeholder="123"
              required
            />
            <mat-error
              *ngIf="
                card_cvv.hasError('pattern') && !card_cvv.hasError('required')
              "
              >Number only</mat-error
            >
            <mat-error
              *ngIf="
                card_cvv.hasError('required') &&
                !card_cvv.hasError('required') &&
                !card_cvv.hasError('pattern')
              "
              >Required</mat-error
            >
            <mat-error
              *ngIf="
                card_cvv.hasError('maxlength') &&
                !card_cvv.hasError('required') &&
                !card_cvv.hasError('pattern')
              "
              >Max length 4 number</mat-error
            >
          </mat-form-field>
        </div>

        <div class="widget__contents--card-type">
          <label class="label-card type">Card Type</label>
          <mat-radio-group formControlName="payment_method">
            <mat-radio-button value="Debit">Debit</mat-radio-button>
            <mat-radio-button value="Credit">Credit</mat-radio-button>
          </mat-radio-group>
        </div>

        <div class="widget__contents--country">
          <label class="label-card">Card Issuing Country</label>
          <mat-form-field class="form-field">
            <mat-select formControlName="country">
              <mat-option
                *ngFor="let country of countryListData"
                [value]="country.code3"
                >{{ country.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </form>
      <div class="widget__contents--notes">
        <p>
          By submitting this form, 500 JPY will be charged to your new card and
          the transaction will be reflected on your bank account. However, it
          will be refunded immediately.
        </p>
      </div>
      <div class="widget__contents--term">
        <mat-icon class="term-check" (click)="toggleChecked()">{{
          mat_content
        }}</mat-icon>
        <span class="term-content"
          >I confirm the card information provided is correct and would like to
          proceed.</span
        >
      </div>
      <div class="widget__contents--submit-button">
        <button
          mat-button
          class="submit-button"
          [ngClass]="{ 'submitted--button': checked && UpdateCard.valid }"
          [disabled]="UpdateCard.invalid"
          (click)="updateCard()"
          [class.disabled]="spinner$ | async"
        >
          Submit<app-spinner></app-spinner>
        </button>
      </div>
    </div>
  </div>
</div>
