import { Component, OnInit, ViewEncapsulation, AfterViewInit, OnDestroy } from '@angular/core';
import { Observable } from 'rxjs';
import { CancellationService } from 'src/app/services/cancellation.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { SubSink } from 'subsink';
import { Router } from '@angular/router';
import { CustomValidators } from 'src/app/custom-validators';
import { MatDialog } from '@angular/material';
import { BillingWorkComponent } from '../../contract-cancellation/billing-work/billing-work.component';
import { ContractsService } from 'src/app/services/contracts.service';
import { BranchNumberComponent } from './branch-number/branch-number.component';
import { RouterHistoryService } from 'src/app/services/router-history.service';

@Component({
  selector: 'app-jp-bank-information',
  templateUrl: './jp-bank-information.component.html',
  styleUrls: ['./jp-bank-information.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class JpBankInformationComponent implements OnInit, AfterViewInit, OnDestroy {
  private subs = new SubSink();
  back_button = true;
  mat_content = 'radio_button_unchecked';
  checked = false;
  reasonsCancellation: FormGroup;
  jpBankForm: FormGroup;
  account_holder_name = '';
  account_number = '';
  account_type = '';
  account_bank_name = '';
  account_branch_name = '';
  regex: RegExp = /[\u3000-\u303f\u30a0-\u30ff]/;
  constructor(
    private contractService: ContractsService,
    private cancelService: CancellationService,
    private fb: FormBuilder,
    private route: Router,
    private matdialog: MatDialog,
    readonly _routerHistoryService: RouterHistoryService
  ) { }
  pay_method$: Observable<string> = this.contractService.pay_method$;
  phone_number$: Observable<string> = this.cancelService.line_number$;
  ngOnInit() {
    this.jpBankFormData();
    this.subs.sink = this.cancelService.jpBank$.subscribe(data => {
     if (data.value !== undefined) {
      this.account_bank_name = data.value.refund_jp_bank_account_bank_name;
      this.account_branch_name = data.value.refund_jp_bank_account_branch_name;
      this.account_holder_name = data.value.refund_jp_bank_account_holder_name;
      this.account_number = data.value.refund_jp_bank_account_number;
      this.account_type = data.value.refund_jp_bank_account_type;

      this.jpBankForm.patchValue({
        refund_jp_bank_account_bank_name: data.value.refund_jp_bank_account_bank_name,
        refund_jp_bank_account_branch_name: data.value.refund_jp_bank_account_branch_name,
        refund_jp_bank_account_holder_name: data.value.refund_jp_bank_account_holder_name,
        refund_jp_bank_account_number: data.value.refund_jp_bank_account_number,
        refund_jp_bank_account_type: data.value.refund_jp_bank_account_type,
      });
     } else {
      this.account_holder_name = '';
      this.account_number = '';
      this.account_type = '';
      this.account_bank_name = '';
      this.account_branch_name = '';
     }
    });
    this.subs.sink = this.cancelService.formCancellation$.subscribe(data => this.reasonsCancellation = data);
  }
  ngAfterViewInit() {
  }
  toggleChecked() {
    this.checked = !this.checked;
    console.log(this.checked);
    if (this.checked === false) {
      this.mat_content = 'radio_button_unchecked';
    } else {
      this.mat_content = 'radio_button_checked';
    }
  }
  submitBank() {
    if ( this.checked === true &&  this.jpBankForm.valid) {
      this.cancelService.setJPBank = this.jpBankForm;
      this.route.navigate(['/confirmation-jp-bank-information']);
    }

  }
  openBillingWork () {
    this.matdialog.open(BillingWorkComponent, {data: this.pay_method$});
  }
  openBranchNumber () {
      this.matdialog.open(BranchNumberComponent);
  }
  jpBankFormData() {
    this.jpBankForm = this.fb.group({
      // tslint:disable-next-line:max-line-length
      refund_jp_bank_account_holder_name: ['', Validators.compose([Validators.required, CustomValidators.checkKatakana(this.regex, {isKana: true})])],
      // tslint:disable-next-line:max-line-length
      refund_jp_bank_account_number: ['', Validators.compose([ Validators.required, Validators.pattern(/^-?([0-9]\d*)?$/)])],
      refund_jp_bank_account_type: ['', Validators.required],
      refund_jp_bank_account_bank_name: ['', Validators.required],
      refund_jp_bank_account_branch_name: ['', Validators.required],
    });
  }
  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
