import {
  Component,
  OnInit,
  ViewEncapsulation,
  OnDestroy,
  ElementRef,
  ViewChild,
  Renderer2,
  AfterViewInit,
} from '@angular/core';
import {
  MatDialog,
  MatDatepickerInputEvent,
  MatSnackBar,
  MatCheckboxChange,
} from '@angular/material';
import * as $ from 'jquery';
import { PolicyContentsComponent } from '../policy-cancellation/policy-contents/policy-contents.component';
import { BillingWorkComponent } from './billing-work/billing-work.component';
import { ReturnSimcardComponent } from './return-simcard/return-simcard.component';
import { DatePipe } from '@angular/common';
import { CancellationService } from 'src/app/services/cancellation.service';
import { map, tap} from 'rxjs/operators';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SubSink } from 'subsink';
import { ContractsService } from 'src/app/services/contracts.service';
import { Contract } from 'src/app/types/contract';
import { Product } from 'src/app/types/product';
import { Constants } from 'src/app/constants';

import { RouterHistoryService } from 'src/app/services/router-history.service';

declare const postalCodeApiDriver: any;
@Component({
  selector: 'app-contract-cancellation',
  templateUrl: './contract-cancellation.component.html',
  styleUrls: ['./contract-cancellation.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ContractCancellationComponent implements OnInit, AfterViewInit, OnDestroy {
  private subs = new SubSink(); // Subsink: unscrible all subscriptions
  reasonsCancellForm: FormGroup;
  back_button = true;
  isResponding = false;
  isReturnKit = false;
  isThroughSakura = false;
  postCodeValid = true;
  isChecked: boolean;
  isConfirmCancelDate: boolean;
  isConfirmCancelPolicy: boolean;
  terminationDate: string;
  finalPayment: string;
  method_paypal = 'paypal';
  method_jpbank = 'jp_bank';

  start_at_product: Date;
  name_month_product: string;
  year_product: string;
  ispayMethod: boolean;
  addressShipping: FormGroup;


  @ViewChild('paypal', { static: true }) paypal: ElementRef;
  @ViewChild('jpbank', { static: true }) japan: ElementRef;
  @ViewChild('returnkit', { static: false }) return_kit: ElementRef;
  @ViewChild('throughsakuramobile', { static: false }) through_sakura_mobile: ElementRef;

  @ViewChild('autoFillId', {static: false, read: ElementRef}) autofill: ElementRef;
  @ViewChild('postcodeLeftId', {static: false, read: ElementRef}) postcode_left: ElementRef;
  @ViewChild('postcodeRightId', {static: false, read: ElementRef}) postcode_right: ElementRef;
  @ViewChild('addressJPId', {static: false, read: ElementRef}) address_jp: ElementRef;
  @ViewChild('addressENId', {static: false, read: ElementRef}) address_en: ElementRef;
  constructor(
    private render: Renderer2,
    private route: Router,
    private fb: FormBuilder,
    private _contractService: ContractsService,
    public matDialog: MatDialog,
    private datePipe: DatePipe,
    private _snackbar: MatSnackBar,
    private _cancellationService: CancellationService,
    readonly _routerHistoryService: RouterHistoryService
  ) {
    this.isChecked = false;
  }
  product$: Observable<Product>;
  contract$: Observable<Contract> = this._contractService.contract$;
  phone_number$: Observable<string> = this._cancellationService.line_number$;
  is_fiber$ = this._contractService.item$.pipe(
    map(item => item === 'fiber' ? true : false)
  )
  item$: Observable<string> = this._contractService.item$.pipe(
    map((item) => {
      if (item === 'voice') {
        return 'Voice + Data SIM';
      } else if (item === 'data') {
        return 'Data only SIM';
      } else if (item === 'wifi') {
        return 'Pocket WiFi';
      } else if (item === 'fiber') {
        return 'Sakura Fiber Internet';
      } else if (item === 'home wifi') {
        return 'Home WiFi';
      } else {
        return '';
      }
    })
  );
  payment_method$: Observable<string> = this._contractService.pay_method$.pipe(
    map((method) => {
      if (method === 'credit card') {
        return 'Credit/Debit Card';
      }
      if (method === 'smartpit') {
        return 'SmartPit Card';
      }
    })
  );
  deviceID$ = this._contractService.deviceID$;
  isSmartpitMethod$: Observable<boolean> = this.payment_method$.pipe(
    map((res) => {
      if (res === 'SmartPit Card') {
        return true;
      } else {
        return false;
      }
    })
  );
  ngOnInit() {
    this.subs.sink = this._contractService.contract$.pipe().subscribe(
      (res_contract) => {
        if (res_contract !== null) {
          console.log("res_contract= ", res_contract);
          this.start_at_product = new Date(res_contract.start_at);
          this.subs.sink = this._contractService.product$.pipe().subscribe(
            (res) => {
              if (res !== null) {
                console.log('Start_at of Product::', this.start_at_product);
                const start_at_month = this.start_at_product.getMonth() + 1;
                const earliest_termination_date = new Date(
                  this.start_at_product.getFullYear(),
                  start_at_month, // start_at_month + 2,
                  0
                );
                console.log('Earliset tertmination date::', earliest_termination_date);
                const day_of_early_termination = earliest_termination_date.getDate();
                const month_of_early_termination = earliest_termination_date.getMonth() + 1;

                const submission_date = new Date();
                const month_of_submission = submission_date.getMonth() + 1;
                const day_in_month_submission = submission_date.getDate();
                console.log(`Submission date:: ${day_in_month_submission} - ${month_of_submission}`);
                // more than 3 month
                if (submission_date > earliest_termination_date) {
                  console.log('Case more than 3 month ===>');
                  if (day_in_month_submission <= 25) {
                    console.log('Case more than 3 month and <= 25 day');
                    this.terminationDate = this.datePipe.transform(
                      new Date(
                        submission_date.getFullYear(),
                        submission_date.getMonth() + 1,
                        0
                      ),
                      'yyyy-MM-dd'
                    );
                    this._contractService.setTerminationDate = this.terminationDate;
                    this.finalPayment = this.datePipe.transform(
                      new Date(
                        submission_date.getFullYear(),
                        submission_date.getMonth() + 2,
                        0
                      ),
                      'yyyy-MM-dd'
                    );
                    this._contractService.setFinalPayment = this.finalPayment;
                  } else {
                    console.log('Case more than 3 month and > 25 day');
                    this.terminationDate = this.datePipe.transform(
                      new Date(
                        submission_date.getFullYear(),
                        submission_date.getMonth() + 2,
                        0
                      ),
                      'yyyy-MM-dd'
                    );
                    this._contractService.setTerminationDate = this.terminationDate;
                    this.finalPayment = this.datePipe.transform(
                      new Date(
                        submission_date.getFullYear(),
                        submission_date.getMonth() + 3,
                        0
                      ),
                      'yyyy-MM-dd'
                    );
                    this._contractService.setFinalPayment = this.finalPayment;
                  }
                } else  if (
                  submission_date > this.start_at_product &&
                  submission_date < earliest_termination_date
                ) {
                  console.log('Case in 3 month ===> ');
                  if (month_of_early_termination === month_of_submission) {
                    console.log('Case at 3 month');
                    if (day_in_month_submission <= 25) {
                      console.log('Case at 3 month & <= 25 day');
                      this.terminationDate = this.datePipe.transform(
                        earliest_termination_date,
                        'yyyy-MM-dd'
                      );
                      this._contractService.setTerminationDate = this.terminationDate;
                      this.finalPayment = this.datePipe.transform(
                        new Date(
                          earliest_termination_date.getFullYear(),
                          earliest_termination_date.getMonth() + 2,
                          0
                        ),
                        'yyyy-MM-dd'
                      );
                      this._contractService.setFinalPayment = this.finalPayment;
                    } else {
                      console.log('Case at 3 month & > 25 day');
                      this.terminationDate = this.datePipe.transform(
                        new Date(
                          earliest_termination_date.getFullYear(),
                          earliest_termination_date.getMonth() + 2,
                          0
                        ),
                        'yyyy-MM-dd'
                      );
                      this._contractService.setTerminationDate = this.terminationDate;
                      this.finalPayment = this.datePipe.transform(
                        new Date(
                          earliest_termination_date.getFullYear(),
                          earliest_termination_date.getMonth() + 3,
                          0
                        ),
                        'yyyy-MM-dd'
                      );
                      this._contractService.setFinalPayment = this.finalPayment;
                    }
                  } else if (earliest_termination_date > submission_date) {
                    console.log('Case less than 3 month');
                    this.terminationDate = this.datePipe.transform(
                     earliest_termination_date,
                      'yyyy-MM-dd'
                    );
                    this._contractService.setTerminationDate = this.terminationDate;
                    this.finalPayment = this.datePipe.transform(
                      new Date(
                        earliest_termination_date.getFullYear(),
                        earliest_termination_date.getMonth() + 2,
                        0
                      ),
                      'yyyy-MM-dd'
                    );
                    this._contractService.setFinalPayment = this.finalPayment;
                  }
                }
                this.year_product = this.terminationDate.slice(0, 4);
                const month_product_end_at = Number(this.terminationDate.slice(5, 7));
                const month_by_name = [  'Jan', 'Feb',  'Mar', 'Apr', 'May',  'Jun',  'Jul',  'Aug',  'Sep',   'Oct',  'Nov',  'Dec'];
                this.name_month_product = month_by_name[month_product_end_at - 1];
            }
          },
            (error) => console.log(error)
          );
        }
      }
    );

    this.subs.sink = this._cancellationService.formCancellation$.subscribe(
      (res) => {
        console.log(res.value);
        this.reasonsCancellForm = res;
      }
    );
    this.createAddressShippingFG();
  }
  ngAfterViewInit(): void {
    const autoFillEl = this.autofill.nativeElement;
    const postcodeLeft = this.postcode_left.nativeElement.name;
    const postcodeRight = this.postcode_right.nativeElement.name;
    const addressJp = this.address_jp.nativeElement.name;
    const addressEn = this.address_en.nativeElement.name;
    $(autoFillEl).on('click', () => {
      postalCodeApiDriver(
        postcodeLeft,
        postcodeRight,
        addressJp,
        '',
        '', 'ja').then((result) => {
          this.postCodeValid = result;
          if (result) {
            this.addressShipping.patchValue({
              fiber_location_block_in_jp: this.address_jp.nativeElement.value
            });
          }
        });
      postalCodeApiDriver(
        postcodeLeft,
        postcodeRight,
        addressEn,
        '',
        '', 'en').then((result) => {
          this.postCodeValid = result;
          if (result) {
            this.addressShipping.patchValue({
              post_code: `${this.postcode_left.nativeElement.value}-${this.postcode_right.nativeElement.value}`,
              fiber_location_block_in_en: this.address_en.nativeElement.value
            });
          }
        });
    });
  }
  onReturnKitEvent(type_return: string) {
    if(type_return === 'return kit') {
      this.isReturnKit = true;
      this.isThroughSakura = false;
      this.render.addClass(this.return_kit.nativeElement, 'active-return');
      this.render.removeClass(this.through_sakura_mobile.nativeElement, 'active-return');
      this.addValidation_AddressForm();
      this.addressShipping.patchValue({
        returning_method: 'Return Kit'
      })
      this._contractService.setReturnDevice = 'Return Kit';
    }
    if(type_return === 'through sakura mobile') {
      this.isReturnKit = false;
      this.isThroughSakura = true;
      this.render.addClass(this.through_sakura_mobile.nativeElement, 'active-return');
      this.render.removeClass(this.return_kit.nativeElement, 'active-return');
      this.addressShipping.reset();
      this.addressShipping.patchValue({
        returning_method: 'Through SakuraMobile'
      })
      this._contractService.setReturnDevice = 'Through Sakura Mobile';
      this.removeValidation_AddressForm();
    }
  }
  dateChange(event: MatDatepickerInputEvent<Date>) {
    const newday = this.datePipe.transform(event.value, 'yyyy-MM-dd');
    this.reasonsCancellForm.patchValue({
      departure_date: newday,
    });
  }
  // checkbox event
  onChange($event: MatCheckboxChange) {
    this.isChecked = $event.checked;
  }
  onConfirmCancelDateChange($event: MatCheckboxChange) {
    console.log($event.checked);
    this.isConfirmCancelDate = $event.checked;
  }
  onConfirmCancelPolicyChange($event: MatCheckboxChange) {
    console.log($event.checked);
    this.isConfirmCancelPolicy = $event.checked;
  }
  selectMethodRefund(event: any) {
    this.ispayMethod = true;
    this.reasonsCancellForm.patchValue({
      refund_method: event.value,
    });
    // choose pay method type:: paypal or japanese bank
    switch (event.value) {
      case this.method_paypal:
        this.render.removeClass(this.japan.nativeElement, 'choose-method');
        break;

      case this.method_jpbank:
        this.render.removeClass(this.paypal.nativeElement, 'choose-method');
        break;
    }
  }
  openDialog() {
    this.matDialog.open(PolicyContentsComponent);
  }
  openDialogBilling() {
    this.matDialog.open(BillingWorkComponent, {data: {payment_method: this.payment_method$, item: this.item$}});
  }
  openDialogReturnSC() {
    this.matDialog.open(ReturnSimcardComponent, {data: this.item$});
  }
  submitCancel() {
    console.log('Submit cancel');
    this.isResponding = true;
    const observer = {
      next: (response) => {
        this.isResponding = false;
        console.log('Successful cancellation');
        this.route.navigate(['/submited-request']);
      },
      error: (error) => {
        this.isResponding = false;
        this._snackbar.open('Submit failed', 'OK', Constants.defaultSnackBarConfig('error'));
        console.error(error);
      }
    };

    setTimeout(() => {
      this.subs.add(
        this.payment_method$.subscribe((method) => {
          if (method === 'Credit/Debit Card') {
            this.subs.sink = this.item$.subscribe((res) => {
              if (res === 'Sakura Fiber Internet') {;
                const cancel_data = {...this.reasonsCancellForm.value, ...this.addressShipping.value};
                delete cancel_data.post_code_left;
                delete cancel_data.post_code_right;
                console.log('cancel data fiber case ====>', cancel_data);
                this.isResponding = true;
                this._cancellationService.ContractsCancellation(
                  cancel_data
                ).subscribe(observer);
              } else {
                this.isResponding = true;
                this._cancellationService.ContractsCancellation(
                  this.reasonsCancellForm.value
                ).subscribe(observer);
              }
            });
          }
          if (method === 'SmartPit Card') {
            this.subs.sink = this.item$.subscribe((res) => {
              if (res === 'Pocket WiFi' || res === 'Home WiFi' || res === 'Data only SIM') {
                this._cancellationService.ContractsCancellation(
                  this.reasonsCancellForm.value
                ).subscribe(observer);
              }
              if (res === 'Sakura Fiber Internet') {
                const cancel_data = {...this.reasonsCancellForm.value, ...this.addressShipping.value};
                delete cancel_data.post_code_left;
                delete cancel_data.post_code_right;
                console.log('cancel data fiber case ====>', cancel_data);
                this.isResponding = true;
                this._cancellationService.ContractsCancellation(
                  cancel_data
                ).subscribe(observer);
              } else {
                this._cancellationService.setReasonsForm = this.reasonsCancellForm;
                if (this.reasonsCancellForm.value.refund_method === 'paypal' && this.ispayMethod) {
                  this.route.navigate(['/refund-paypal']);
                }
                if (this.reasonsCancellForm.value.refund_method === 'jp_bank' && this.ispayMethod) {
                  this.route.navigate(['/refund-jp-bank']);
                }
              }
            });
          }
        })
      );
    }, 1000);
  }
  private createAddressShippingFG() {
    this.addressShipping = this.fb.group({
      post_code_left: this.fb.control(''),
      post_code_right: this.fb.control(''),
      post_code: this.fb.control(''),
      returning_method: this.fb.control(''),
      fiber_location_block_in_jp: this.fb.control(''),
      fiber_location_block_in_en: this.fb.control(''),
      fiber_location_street: this.fb.control(''),
      fiber_location_building_room: this.fb.control('')
    })
  }
  addValidation_AddressForm(): void {
    this.addressShipping.get('post_code_left').clearValidators();
    this.addressShipping.get('post_code_left').updateValueAndValidity;
    this.addressShipping.get('post_code_right').setValidators(Validators.required);
    this.addressShipping.get('post_code_right').updateValueAndValidity;
    this.addressShipping.get('returning_method').setValidators(Validators.required);
    this.addressShipping.get('returning_method').updateValueAndValidity;
    this.addressShipping.get('fiber_location_block_in_jp').setValidators(Validators.required);
    this.addressShipping.get('fiber_location_block_in_jp').updateValueAndValidity;
    this.addressShipping.get('fiber_location_block_in_en').setValidators(Validators.required);
    this.addressShipping.get('fiber_location_block_in_en').updateValueAndValidity;
    this.addressShipping.get('fiber_location_street').setValidators(Validators.required);
    this.addressShipping.get('fiber_location_street').updateValueAndValidity;
  }
  removeValidation_AddressForm () {
    this.addressShipping.get('post_code_left').clearValidators();
    this.addressShipping.get('post_code_left').updateValueAndValidity;
    this.addressShipping.get('post_code_right').clearValidators();
    this.addressShipping.get('post_code_right').updateValueAndValidity;
    this.addressShipping.get('returning_method').clearValidators();
    this.addressShipping.get('returning_method').updateValueAndValidity;
    this.addressShipping.get('fiber_location_block_in_jp').clearValidators();
    this.addressShipping.get('fiber_location_block_in_jp').updateValueAndValidity;
    this.addressShipping.get('fiber_location_block_in_en').clearValidators();
    this.addressShipping.get('fiber_location_block_in_en').updateValueAndValidity;
    this.addressShipping.get('fiber_location_street').clearValidators();
    this.addressShipping.get('fiber_location_street').updateValueAndValidity;
  }
  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
