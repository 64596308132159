import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { Invoice } from 'src/app/types/invoice';
import { PaymentInvoicesService } from 'src/app/services/payment-invoices.service';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InvoiceComponent implements OnInit {
  public sub_total: number;
  public sub_total_tax: number;
  public contract_code: string;
  public invoice_month: string;
  public secure_random: string;
  public pay_status: string;
  constructor(
    private paymentService: PaymentInvoicesService
  ) { }
  @Input() invoice: Invoice;
  ngOnInit() {
    this.setDataInvoice();
  }
  PayInvoice (): void {
    // set invoice for payment
    this.paymentService.setInvoice = this.invoice;
  }
  private setDataInvoice() {
    this.pay_status = this.invoice.pay_status === 1 ? 'Paid' : 'Unpaid';
    this.sub_total = this.invoice.sub_total;
    this.sub_total_tax = this.invoice.sub_total_tax;
    this.invoice_month = this.invoice.invoice_month;
    this.secure_random = this.invoice.secure_random;
    this.contract_code = this.invoice.contract_code;
  }
}
