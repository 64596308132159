<div class="card-updated">
  <div class="widget">
    <app-menu [back_button]="false" [humberger]="false" [title]="'Account'"></app-menu>

    <div *ngIf="completed === false" class="widget__contents">
      Thank you for completing the card authentication process. <br/>
      Your registered card is currently being updated in our system.<br/>
      <br/>
      Once the update is complete, this screen will automatically change to the confirmation page.<br/>
      If the screen does not update within 1 minute, please contact our customer support team using this <a href="https://www.sakuramobile.jp/contact-us" target="_blank" rel="noopener noreferrer">link</a>.<br/>
    </div>

    <div *ngIf="completed" class="widget__contents">
      <div class="widget__contents__title">
        <h1 class="title">Card updated</h1>
      </div>
      <div class="widget__contents__information">
        <p>Thank you for updating your payment card information.</p>
        <p>It may take a moment for the information to be updated.</p>
      </div>
      <div class="widget__contents__image">
        <img
          src="../../../../../assets/images/submitSakuraFlower.svg"
          alt="sakura submitSakuraFlower"
          class="image"
        />
      </div>
    </div>

    <div class="widget__contents__backlink">
      <p class="back--link">
        Back to
        <span class="sakura--text" routerLink="/portal">Contracts</span>.
      </p>
    </div>
  </div>
</div>
