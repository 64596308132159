<div class="deposit-required">
  <button class="close--button" mat-icon-button mat-dialog-close matRippleDisabled="disabled">
    <mat-icon class="cancel--icon">cancel</mat-icon>
  </button>

  <mat-dialog-content class="dialog__contents">
    <div class="dialog__contents--title">
      <mat-icon>help</mat-icon>
      <h4>Why is a deposit required?</h4>
    </div>
      <p class="info">Due to the billing cycle, there will be undue bills after you terminate the contract.</p>
      <p class="info">Below is an example of the billing cycle of the Smart Pit card payment method:</p>
      <table>
        <tr>
          <th>Month</th>
          <th>Invoicing</th>
          <th>Payment</th>
        </tr>
        <tr>
          <td><span class="bold">May</span></td>
          <td>End of <span class="bold">June</span></td>
          <td class="sakura--text">Jul 1 - 10</td>
        </tr>
        <tr>
          <td><span class="bold">June</span></td>
          <td>End of <span class="bold">July</span></td>
          <td class="sakura--text">Aug 1 - 10</td>
        </tr>
        <tr>
          <td><span class="bold">July</span></td>
          <td>End of <span class="bold">August</span></td>
          <td class="sakura--text">Sep 1 - 10</td>
        </tr>
      </table>
      <p class="info">
        You will notice, due to the structure of the payment, we will have to ask our customer for the deposit to cover the possible undue bills.
      </p>
    <div class="dialog__contents--refund-deposit">
      <mat-icon>play_arrow</mat-icon>
      <h4>Refund of the deposit:</h4>
    </div>
    <div class="dialog__contents--type-sim">
      <h4 class="title-type">Refund of the deposit:</h4>
       <p class="info-type">Voice + Data SIM card: The last 2 months’ fees will be deducted from the deposit and the remaining amount will be refunded to your Japanese bank account 1.5 months after your termination month.</p>
      <h4 class="title-type">Data Only SIM card / Pocket WiFi:</h4>
       <p class="info-type">Since the last 2 months' fees will be covered by the deposit, you will not receive a refund.</p>
    </div>
  </mat-dialog-content>
</div>

