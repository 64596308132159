<div class="mnp__attention">
  <div class="widget">
    <app-menu
      [back_button]="back_button"
      [title]="phone_number$ | async"
    ></app-menu>
    <div class="widget__contents">
      <div class="widget__contents--attention">
        <mat-icon class="warning-icon" rounded >warning</mat-icon>
        <p class="attention">Attention</p>
      </div>
      <div class="widget__contents--notes">
        <p class="note-up">
          Before you decide to switch to another provider, we highly recommend that you check the following contract conditions of your new provider before requesting an MNP number from us.
        </p>
        <p class="note-down">
          We have seen many of our customers who requested an MNP number but did not switch providers and kept using Sakura Mobile as they encountered some problems with the new provider’s contract conditions. The reasons follow.
        </p>
      </div>
      <div class="widget__contents--options">
        <div class="option">
          <p class="title">1. Possible Application Rejections</p>
          <p class="content">Some providers may reject your application if the remaining period of stay on your residence card is less than one year.</p>
        </div>
        <div class="option">
          <p class="title">2. Payment Method Restrictions</p>
          <p class="content">
            There are cases where credit or debit cards issued overseas cannot be used, or may fail the provider’s screening process.<br/>
            Additionally, many providers do not accept payment methods other than credit or debit cards.
          </p>
        </div>
        <div class="option">
          <p class="title">3. Other Considerations</p>
          <p class="content">
            You may be required to purchase a device or commit to a long-term contract to qualify for certain discounts.<br/>
            Please note that advertised prices, including those on websites, may reflect discounted rates.
          </p>
        </div>
      </div>
      <div class="please-note">
        <b>Please Note:</b><br/>
        <p>
          During the whole MNP transfer procedure (submission of MNP number request ～ 2 days after the MNP number expiration), termination and plan change request will be unavailable.
        </p>
      </div>
      <div class="widget__contents--checkbox">
        <mat-icon class="check" (click)="toggleChecked()">{{mat_content}}</mat-icon>
        <span class="check--content">I understand and would like to issue an MNP number.</span>
      </div>
      <button mat-button class="widget__contents--next--button" [routerLink]="['/cancellation']" [disabled]="!checked"
      [ngClass]="{'submitted--button' : checked}"
      >Next</button>
    </div>
  </div>
</div>
