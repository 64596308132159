<div class="jp-bank">
  <div class="widget">
    <app-menu
      [back_button]="back_button"
      [title]="phone_number$ | async"
    ></app-menu>

    <div class="widget__contents">
      <div class="widget__contents--title">
        <img
          src="../../../../../../assets/images/logo-payment.svg"
          alt="credit-card"
          class="icon-creditcard"
        />
        <p class="title-creditcard">Submit Bank Information</p>
      </div>

      <div class="widget__contents--information">
        <p class="info">
          Please provide your Japanese bank account information so that we can
          refund the remainder of the deposit.
        </p>
      </div>
      <form [formGroup]="jpBankForm">
      <div class="widget__contents--jp_bank-form">

          <p class="label">Bank Name</p>
          <mat-form-field class="jp_bank">
            <input
              matInput
              class="input"
              [value]="account_bank_name"
              formControlName="refund_jp_bank_account_bank_name"
              required
            />
          </mat-form-field>

          <p class="label">Branch Name</p>
          <mat-form-field class="jp_bank">
            <input
              matInput
              class="input"
              [value]="account_branch_name"
              formControlName="refund_jp_bank_account_branch_name"
              required
            />
          </mat-form-field>

      </div>

      <div class="widget__contents--information">
        <p class="info">
          For JP Bank (ゆうちょ銀行), please enter the 3 digit branch number
          (店番).
        </p>
      </div>
      <div class="widget__contents--branch-name">
        <mat-icon class="branch--icon">help</mat-icon>
        <p class="branch--title" (click)="openBranchNumber()">Where can I see Branch Number?</p>
      </div>

      <div class="widget__contents--account-type">
        <p class="account-type">Account Type</p>

        <mat-radio-group formControlName="refund_jp_bank_account_type" [value]="account_type">
          <mat-radio-button value="Futuu"
            >Futuu (普通) (Personal account)</mat-radio-button
          >
          <mat-radio-button value="Touza"
            >Touza (当座) (Personal account)</mat-radio-button
          >
        </mat-radio-group>
      </div>

      <div class="widget__contents--jp_bank-form">

          <p class="label">Account Number</p>
          <mat-form-field class="jp_bank">
            <input
              matInput
              class="input"
              [value]="account_number"
              formControlName="refund_jp_bank_account_number"
              required
            />
          </mat-form-field>

          <p class="label">Account Holder's Name (Kana(Phonics))</p>
          <mat-form-field class="jp_bank">
            <input
              matInput
              class="input"
              [value]="account_holder_name"
              formControlName="refund_jp_bank_account_holder_name"
              required
            />
            <mat-error
            *ngIf="jpBankForm.controls['refund_jp_bank_account_holder_name'].hasError('isKana')"
            >Must Kana character</mat-error>
          </mat-form-field>

      </div>
    </form>
      <div class="widget__contents--branch-name">
        <mat-icon class="branch--icon">help</mat-icon>
        <p class="branch--title" (click)="openBillingWork()">How does billing work?</p>
      </div>

      <div class="widget__contents--term">
        <mat-icon class="term-check" (click)="toggleChecked()">{{
          mat_content
        }}</mat-icon>
        <span class="term-content"
          >I understand the refund condition and confirm the information
          provided is correct.</span
        >
      </div>

      <button
        mat-button
        class="widget__contents--submitted-jp-bank"
        [ngClass]="{ 'submitted--button': checked && jpBankForm.valid}"
        [disabled]="!checked"
        (click)="submitBank()"
      >
        Submit Bank Information
      </button>

      <div class="widget__contents--step">
        <button mat-icon-button disabled>
          <mat-icon> radio_button_unchecked</mat-icon>
        </button>
        <button mat-icon-button disabled>
          <mat-icon> radio_button_unchecked</mat-icon>
        </button>
        <button mat-icon-button>
          <mat-icon>
            lens
          </mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
