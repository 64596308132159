import { Component, OnInit } from '@angular/core';
import { flatMap, map, shareReplay, catchError, tap } from 'rxjs/operators';
import { AccountInformationService } from 'src/app/services/account-information.service';
import { Observable, of } from 'rxjs';
import { DepositUser } from 'src/app/types/deposit-user';
import { DepositProduct } from 'src/app/types/deposit';
import { Router } from '@angular/router';
import { ContractsService } from 'src/app/services/contracts.service';
import { RouterHistoryService } from 'src/app/services/router-history.service';

@Component({
  selector: 'app-confirmation-change-payment',
  templateUrl: './confirmation-change-payment.component.html',
  styleUrls: ['./confirmation-change-payment.component.scss'],
})
export class ConfirmationChangePaymentComponent implements OnInit {
  checked = false;
  mat_content = 'radio_button_unchecked';
  constructor(
    private accountService: AccountInformationService,
    readonly _routerHistoryService: RouterHistoryService
  ) {}
  paymentMethodChange$ = this.accountService.paymentMethod$.pipe(
    map((res) => {
      if (res === 'CREDIT CARD') {
        return 'Current credit/debit';
      }
      if ((res = 'SMARTPIT')) {
        return 'Convenience store';
      }
    })
  );
  depositOfUser$: Observable<DepositUser> = this.accountService.depositOfUser$;
  amountPrice$: Observable<number> = this.accountService.totalPrice$;
  productsDeposit$: Observable<DepositProduct[]> = this.depositOfUser$.pipe(
    map((res) => res.data.contracts[0].products)
  );
  ngOnInit() {}
  toggleChecked() {
    this.checked = !this.checked;
    if (this.checked === false) {
      this.mat_content = 'radio_button_unchecked';
    } else {
      this.mat_content = 'radio_button_checked';
    }
  }
  SubmittedRequest() {
    this.accountService.ChangePayMethodNotify();
  }
}
