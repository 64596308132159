import { Component, OnInit } from '@angular/core';
import {
  MatSnackBar, MatSnackBarConfig, MatTableDataSource, MatDialog, MatDialogRef, MAT_DIALOG_DATA
} from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { V1UserAPIService } from 'src/app/services/v1api.service';
import {
  TwoButtonDialogComponent, TwoButtonDialogData, DialogButton
} from 'src/app/components/shared/two-button-dialog/two-button-dialog.component';
import { Constants } from 'src/app/constants';

@Component({
  selector: 'app-swtich5g',
  templateUrl: './swtich5g.component.html',
  styleUrls: ['./swtich5g.component.scss']
})
export class Swtich5gComponent implements OnInit {

  private _show5GDescription: boolean = false;
  public show5GDescription: string = 'description hide';

  public initialized5G: boolean = false;
  public enable5G: boolean = false;

  constructor(
    private _api: V1UserAPIService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private _sanitizer: DomSanitizer,
  ) { }

  ngOnInit() {
    console.log('Swtich5gComponent::ngOnInit', this._api.brideData);
    this.initialized5G = false;
    this._api.get5GStatus({
      item_id: this._api.brideData.item_id
    }).subscribe(
      response => {
        console.log('get 5G status', response);
        this.initialized5G = true;
        this.enable5G = response.data.five_g;
      },
      error => {

      }
    );
  }

  onClickEnable5G(ev: any): void {
    if (!this.initialized5G) {
      console.log('onClickEnable5g 5G status initialized? ', this.initialized5G)
      ev.preventDefault()
    }
  }

  onEnable5GChanged(ev: any):void {
    const dialogData = new TwoButtonDialogData(this._sanitizer);
    let message = '<span style="font-size: 1.5em; font-weight: bold;">WARNING : </span><br/>';
    if (this.enable5G) {
      message = message + 
      'If you turn ON the 5G service on a phone that does not support 5G frequency signal (Band n28, n78, n79, n257), you may not receive the signal at all. In this case, we do not provide any compensation. <br/>' + 
      'Changing this setting can only be done once a day. Please note well that after 5G is turned ON, it can not be turned OFF until the next day. <br/>' + 
      '<br/>' + 
      'Would you like to turn ON the 5G service?<br/><br/>';
    } else {
      message = message + 
      'Changing this setting can only be done once a day. Please note well that after 5G is turned OFF, it can not be turned ON until the next day. <br/>' + 
      '<br/>' + 
      'Would you like to turn OFF the 5G service?<br/><br/>';
    }
    dialogData.message = message;
    dialogData.buttons.push(new DialogButton('Cancel', [], 'cancel'));
    dialogData.buttons.push(new DialogButton('OK', [], 'ok'));
    // dialog
    const dialogRef = this.dialog.open(
      TwoButtonDialogComponent,
      {
        data: dialogData
      }
    );
    dialogRef.afterClosed().subscribe(result => {
      if (!result) {
        this.enable5G = !this.enable5G;
        return;
      }
      
      if (result.id === 'ok') {
        this._api.set5GStatus({
          item_id: this._api.brideData.item_id,
          enable: this.enable5G
        }).subscribe(
          response => {
            this._snackBar.dismiss();
            this._snackBar.open(
              '5G setting changed successfully',
              'OK',
              Constants.defaultSnackBarConfig('success')
            );
          },
          error => {
            let msg = 'Failed to change the 5G setting';
            if (error.error && error.error.data && error.error.data.status_code) {
              if (
                error.error.data.status_code === 510 ||
                error.error.data.status_code === 423
              ) {
                msg = 'Sorry, requests cannot be processed at this time.'
              }
            }
            // if error.
            this.enable5G = !this.enable5G;
            this._snackBar.dismiss();
            this._snackBar.open(
              msg,
              'OK',
              Constants.defaultSnackBarConfig('error')
            );
          }
        );
      }
      else {
        this.enable5G = !this.enable5G;
      }
    });
  }

  onClick5GDescription(ev: any): void {
    // console.log('onClick5GDescription', ev);
    this._show5GDescription = !this._show5GDescription;
    if (this._show5GDescription) {
      this.show5GDescription = 'description';
    } else {
      this.show5GDescription = 'description hide';
    }
  }
}
