<div class="app-moving-residence">
  <div class="widget">
    <app-menu
      [back_button]="back_button"
      [title]="'Moving residences'"
    ></app-menu>
    <div class="widget_contents">
      <p class="title">
        Moving? <br />
        Take your internet line to your new residence!
      </p>
      <ul class="attentions">
        <li>
          Portable Wifi device with huge data to keep you online while you move.
        </li>
        <li>Special discounts on all fees for your new installation.</li>
        <li>
          Fill out the following form, and we will email you the internet plans
          at your new residence and further instructions on how to schedule the
          installation.
        </li>
      </ul>
      <ul class="links">
        <li>LINK: <a href="{{formUrl}}" target="_blank">Moving Residences Information Form FAQ</a></li>
        <li>FAQ LINK: <a href="https://support.sakuramobile.jp/hc/en-us/articles/4416330656281-Moving-to-a-new-residence-Current-Sakura-Fiber-Internet-customers" target="_blank">How to move my Internet to my new home ?</a></li>
      </ul>
    </div>
  </div>
</div>
