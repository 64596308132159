import { Component, OnInit } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { AccountInformationService } from 'src/app/services/account-information.service';
import { ContractsService } from 'src/app/services/contracts.service';
import { Contract } from 'src/app/types/contract';

@Component({
  selector: 'app-moving-residence',
  templateUrl: './moving-residence.component.html',
  styleUrls: ['./moving-residence.component.scss']
})
export class MovingResidenceComponent implements OnInit {

  back_button = true;

  user_email: string = '';
  user_full_name: string = '';

  constructor(
    private accountService: AccountInformationService,
    private contractService: ContractsService,
  ) { }

  ngOnInit() {
    this.accountService.userInformation$.subscribe(user => {
      console.log('MovingResidenceComponent::ngOnInit : user=', user);
      const user_info = <any>user;
      if (user_info && user_info.error && user_info.error.status && user_info.error.status === 'success') {
        this.user_email = user_info.data.email;
        this.user_full_name = user_info.data.user_full_name;
      }
    });
  }

  get formUrl() {
    return encodeURI(`https://docs.google.com/forms/d/e/1FAIpQLSdzL-IyHviBzCbVbULWjOnfzp2N4D015Os0xFdszdHBlxTveg/viewform?usp=pp_url&entry.1908942056=${this.user_full_name}&entry.979244112=${this.user_email}`);
  }
}
