<div class="app-sign-up page">
  <div class="widget">
    <div class="title">Sign up</div>
    <div class="content">
      <form #imageForm=ngForm (ngSubmit)="onClickSubmitWidget($event)" class="mui-form">
        <div class="row">
          <mat-form-field>
            <mat-label>User ID (e-mail)</mat-label>
            <input matInput type="email" #InputUserId
              [formControl]="emailFormControl"
              [errorStateMatcher]="emailErrorMatcher" name="email"
              (keyup)="onEmailChanged($event)" (change)="onEmailChanged($event)"
              autocomplete="on" required>
            <mat-error *ngIf="emailFormControl.hasError('email') &&
              !emailFormControl.hasError('required')">
              Please enter a valid email address
            </mat-error>
            <mat-error *ngIf="emailFormControl.hasError('required')">
              Required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field>
            <mat-label>Password</mat-label>
            <input matInput type="password" #InputPassword
              [formControl]="passwordFormControl" name="password"
              (keyup)="onPasswordChanged($event)"
              (change)="onPasswordChanged($event)"
              autocomplete="new-password"
              required>
            <mat-error *ngIf="passwordFormControl.hasError('required')">
              Required
            </mat-error>
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field>
            <mat-label>Password confirmation</mat-label>
            <input matInput type="password" #InputPassword
              [formControl]="passwordConfirmationFormControl"
              name="password_confirmation"
              (keyup)="onPasswordConfirmationChanged($event)"
              (change)="onPasswordConfirmationChanged($event)"
              autocomplete="new-password">
          </mat-form-field>
          <div class="error_message" *ngIf="passwordNotMatch">
            Confirm password does not match
          </div>
        </div>
        <br/>
        <button mat-flat-button id="SubmitWidget" type="submit"
          [disabled]="submitDisabled">SignUp</button>
      </form>
    </div>
  </div>
</div>
