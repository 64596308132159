import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable, combineLatest } from 'rxjs';
import { CancellationService } from 'src/app/services/cancellation.service';
import { ContractsService } from 'src/app/services/contracts.service';
import { map } from 'rxjs/operators';
import {
  FormControl,
  FormGroup,
  Validators,
  FormArray,
  FormBuilder,
} from '@angular/forms';
import { Router } from '@angular/router';
import { SubSink } from 'subsink';
import { RouterHistoryService } from 'src/app/services/router-history.service';
import { StarRating } from 'src/app/types/rating';

@Component({
  selector: 'app-cancellation',
  templateUrl: './cancellation.component.html',
  styleUrls: ['./cancellation.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CancellationComponent implements OnInit {
  private subs = new SubSink(); // Subsink: unscrible all subscriptions
  reasonSelected = '';
  product_type: string;
  rateService = true;
  back_button = true;
  contractProductId: number;
  line_number$: Observable<string> = this._cancelllationService.line_number$;
  payment_method$: Observable<string> = this.contractService.pay_method$;
  item$: Observable<string> = this.contractService.item$;
  is_fiber$ = this.contractService.item$.pipe(
    map(item => item === 'fiber' ? true : false)
  )
  mnpRequest$: Observable<boolean> = this._cancelllationService.mnpRequest$;
  dotSmartpit$: Observable<boolean> = combineLatest(this.item$, this.payment_method$).pipe(
    map( ([item, paymethod]) => {
      if (paymethod === 'credit card' && item === 'voice') {
        return false;
      } else if (paymethod === 'smartpit' && item === 'voice') {
        return true;
      } else if (item === 'data' || item === 'wifi') {
        return false;
      } else {
        return null;
      }
    })
  );
  mnp_request: boolean;
  mnp_credit_request$: Observable<boolean> = combineLatest(this.payment_method$, this.mnpRequest$).pipe(
    map(([pay_method, mnpRequest]) => {
      if (pay_method === 'credit card' && mnpRequest === true) {
        return false;
      } else if (mnpRequest === true && pay_method !== 'credit card') {
        return true;
      }
    })
  );
  // FormControl
  isDisable: boolean;
  cancelReasons_Case1: FormGroup;
  cancelReasons_Case2: FormGroup;
  fiberCancelReasons: FormGroup;
  contract_product_id: FormControl;
  reason: FormControl;
  reasons_for_changing_provider: FormArray;
  constructor(
    private formBuilder: FormBuilder,
    private contractService: ContractsService,
    private _cancelllationService: CancellationService,
    private route: Router,
    readonly _routerHistoryService: RouterHistoryService
  ) {}

  ngOnInit() {
    this.subs.sink = this.contractService.item$.subscribe(item => this.product_type = item);
    this.subs.sink = this._cancelllationService.mnpRequest$.subscribe(
      (res) => {
        this.mnp_request = res;
      }
    );
    this.subs.sink = this._cancelllationService.contract_product_id$.subscribe(
      (res) => (this.contractProductId = res)
    );
    this.createForm_Case1();
    this.createForm_Case2();
    this.createForm_FiberCancel();
    // this.fiberCancelReasons.valueChanges.subscribe(res => console.log(res))
  }
  createForm_Case1() {
    this.cancelReasons_Case1 = this.formBuilder.group({
      contract_product_id: [this.contractProductId, Validators.required],
      reason: ['', Validators.required],
      rate_price: ['', Validators.required],
      rate_internet_connection: ['', Validators.required],
      rate_customer_support: ['', Validators.required],
      note: [''],
      departure_date: [''],
      refund_method: ['none'],
      refund_paypal_address: [''],
      refund_paypal_holder_name: [''],
    });
  }
  createForm_Case2() {
    this.cancelReasons_Case2 = this.formBuilder.group({
      contract_product_id: [this.contractProductId, Validators.required],
      reason: ['', Validators.required],
      move_to_service_provider: ['', Validators.required],
      move_to_plan: [''],
      reasons_for_changing_provider: this.formBuilder.array([], Validators.required),
      reasons_for_changing_provider_case: ['default'],
      reasons_for_changing_provider_other: [''],
      rate_price: ['', Validators.required],
      rate_internet_connection: ['', Validators.required],
      rate_customer_support: ['', Validators.required],
      note: [''],
      departure_date: [''],
      refund_method: ['none'],
      refund_paypal_address: [''],
      refund_paypal_holder_name: [''],
      refund_jp_bank_account_bank_name: [''],
      refund_jp_bank_account_branch_name: ['']
    });
  }
  createForm_FiberCancel() {
    this.fiberCancelReasons = this.formBuilder.group({
      contract_product_id: [this.contractProductId, Validators.required],
      rate_price: ['', Validators.required],
      rate_internet_connection: ['', Validators.required],
      rate_customer_support: ['', Validators.required],
      note: [''],
      departure_date: [''],
      moving_residense: ['', Validators.required],
      leave_residence_date: [''],
      move_in_japan_or_not: ['']
    })
  }
  toggleRating(event: boolean) {
    this.rateService = !event;
  }
  valueReason(value: string) {
    this.reasonSelected = value;
    switch (value) {
      case 'go_back_to_home_country':
      case 'no_longer_use_cellphone_or_wifi':
        this.cancelReasons_Case1.patchValue({
          reason: value,
        });
        this.reasonsFormArr.clear();
        this.cancelReasons_Case2.patchValue({
            reason: '',
            rate_price: '',
            rate_internet_connection: '',
            rate_customer_support: '',
            move_to_service_provider: '',
            move_to_plan: '',
            reasons_for_changing_provider_case: 'default',
            reasons_for_changing_provider_other: '',
          });
        break;
      case 'moving_to_another_service_provider':
        this.cancelReasons_Case2.patchValue({
          reason: value,
        });
        this.cancelReasons_Case1.patchValue({
          reason: '',
          rate_price: '',
          rate_internet_connection: '',
          rate_customer_support: '',
        });
        break;
    }
  }
  onRatingChanged(ratingObj: StarRating) {
    if (ratingObj.rate_price !== 0) {
      this.cancelReasons_Case1.patchValue({
        rate_price: ratingObj.rate_price.toString()
      });
      this.fiberCancelReasons.patchValue({
        rate_price: ratingObj.rate_price.toString()
      });
    }
    if (ratingObj.rate_internet_connection !== 0) {
      this.cancelReasons_Case1.patchValue({
        rate_internet_connection: ratingObj.rate_internet_connection.toString()
      });
      this.fiberCancelReasons.patchValue({
        rate_internet_connection: ratingObj.rate_internet_connection.toString()
      });
    }
    if (ratingObj.rate_customer_support !== 0) {
      this.cancelReasons_Case1.patchValue({
        rate_customer_support: ratingObj.rate_customer_support.toString()
      });
      this.fiberCancelReasons.patchValue({
        rate_customer_support: ratingObj.rate_customer_support.toString()
      });
    }
  }
  get reasonsFormArr() {
    return this.cancelReasons_Case2.get(
      'reasons_for_changing_provider'
    ) as FormArray;
  }
  onNext() {
    const reason_cancel = this.cancelReasons_Case2.get('reason').value;
    const notes = this.cancelReasons_Case1.get('note').value;
    if (reason_cancel === 'moving_to_another_service_provider') {
      this.cancelReasons_Case2.patchValue({
      note: notes,
      });
      this._cancelllationService.setReasonsForm = this.cancelReasons_Case2;
    } else if(this.product_type === 'fiber') {
      this.fiberCancelReasons.patchValue({
        note: notes,
        });
      this._cancelllationService.setReasonsForm = this.fiberCancelReasons;
    } else {
      this._cancelllationService.setReasonsForm = this.cancelReasons_Case1;
    }
    if (this.mnp_request === true) {
      this.route.navigate(['/mnp-information']);
    } else {
      this.route.navigate(['/contract-cancellation']);
    }
  }
}
