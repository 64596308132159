
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { RouterHistoryService } from 'src/app/services/router-history.service';
import { TopUpService } from 'src/app/services/top-up.service';
import { TopUpData } from 'src/app/types/top-up-data';

@Component({
  selector: 'app-top-up-select',
  templateUrl: './top-up-select.component.html',
  styleUrls: ['./top-up-select.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class TopUpSelectComponent implements OnInit {
  defaultDataMB = 1; // GB unit
  defaultPrice = 1500;
  subTotal: number;
  vat: number;
  total: number;
  checkTop = false;
  checkBottom = false;
  mat_content_top = 'radio_button_unchecked';
  mat_content_bottom = 'radio_button_unchecked';
  private PriceData = [
    {id: 1, value: 1500},
    {id: 2, value: 2800},
    {id: 3, value: 4000},
    {id: 4, value: 5000},
    {id: 5, value: 6000}
  ];
  constructor(
    private _router: Router,
    private _topUpService: TopUpService,
    readonly _routerHistoryService: RouterHistoryService
    ) {}

  ngOnInit() {
    this.calcAll();
  }
  selectTopUp() {
    this._router.navigate(['top-up-checkout']);
  }
  AddData() {
    if (this.defaultDataMB < 5) {
      this.defaultDataMB = this.defaultDataMB + 1;
      this.calcAll();
    } else {
      return;
    }
  }
  RemoveData() {
    if (this.defaultDataMB > 1) {
      this.defaultDataMB = this.defaultDataMB - 1;
      this.calcAll();
    } else {
      return;
    }
  }


  toggleCheckedTop() {
    this.checkTop = !this.checkTop;
    this.checkTop ? this.mat_content_top = 'radio_button_checked' :  this.mat_content_top = 'radio_button_unchecked';
  }
  toggleCheckedBottom() {
    this.checkBottom = !this.checkBottom;
    this.checkBottom ?  this.mat_content_bottom = 'radio_button_checked' : this.mat_content_bottom = 'radio_button_unchecked';
  }
  private calcSubTotal(amountsData: number) {
    const ObjectData = this.PriceData.find(item => item.id === amountsData);
    return ObjectData.value;
  }
  private calcVat(price: number) {
    return price * 0.1;
  }
  private calcAll() {
    this.subTotal = this.calcSubTotal(this.defaultDataMB);
    this.vat = this.calcVat(this.subTotal);
    this.total = this.subTotal + this.vat;
    const data = {
      data: this.defaultDataMB,
      price: this.defaultPrice,
      subtotal: this.subTotal,
      vat: this.vat,
      total: this.total
    };
    this._topUpService.setTopUpData = data;
  }
}
