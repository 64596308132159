import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { Invoice, Invoices } from 'src/app/types/invoice';
import { PaymentInvoicesService } from 'src/app/services/payment-invoices.service';
import { Router } from '@angular/router';
import { ContractsService } from 'src/app/services/contracts.service';
import { SubSink } from 'subsink';
import { map } from 'rxjs/operators';
import { RouterHistoryService } from 'src/app/services/router-history.service';


@Component({
  selector: 'app-payment-received',
  templateUrl: './payment-received.component.html',
  styleUrls: ['./payment-received.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class PaymentReceivedComponent implements OnInit, OnDestroy {
  private subs = new SubSink();
  contract_id: number;
  constructor(
    private contractService: ContractsService,
    private paymentService: PaymentInvoicesService,
    private router: Router,
    readonly _routerHistoryService: RouterHistoryService
  ) { }
  invoice$: Observable<Invoice>  = this.paymentService.invoice$;
  payType$: Observable<boolean> = this.paymentService.payType$;
  unpaidInvoice$: Observable<boolean> = this.contractService.invoices$.pipe(
    map( invoices => invoices.some( invoice => invoice.pay_status === 0))
  );
  ngOnInit() {
  this.subs.sink = this.contractService.contract$.subscribe(res => this.contract_id = res.id);
  }
  paymentHistory() {
    this.contractService.refeshInvoices(this.contract_id);
    this.router.navigate([`/portal/payment-history/${this.contract_id}`]);
  }
  ngOnDestroy() {
    this.subs.unsubscribe();
  }
  invoiceMonthToUsedMonth(invoice_month: any): string {
    if (invoice_month) 
    {
      const ivs = invoice_month.split('-');
      const monthes = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December'
      ];
      let y = parseInt(ivs[0]);
      let m = parseInt(ivs[1])-1; // -1 : month -> index
      m = m - 1; // invoice month -> used month
      if (m < 0) {
        y = y - 1;
        m = 11;
      }
      return monthes[m];
    }
    return "";
  }
}
