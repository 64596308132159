import { NgModule } from '@angular/core';
// import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './components/pages/home/home.component';
import { PortalComponent } from './components/pages/portal/portal.component';
import { LoginComponent } from './components/pages/auth/login/login.component';
import { SignUpComponent } from './components/pages/auth/sign-up/sign-up.component';
import { ConfirmationResendComponent } from './components/pages/auth/confirmation-resend/confirmation-resend.component';
import { PasswordResetComponent } from './components/pages/auth/password-reset/password-reset.component';
import { PasswordChangeComponent } from './components/pages/auth/password-change/password-change.component';
import { PageNotFoundComponent } from './components/pages/other/page-not-found/page-not-found.component';
import { UsersComponent as AdminUsersComponent } from './components/pages/admin/users/users.component';
import { UserDetailComponent as AdminUserComponent } from './components/pages/admin/users/user-detail/user-detail.component';
import { ContractDetailsComponent } from './components/pages/portal/contract-details/contract-details.component';
import { ProductDetailComponent } from './components/pages/portal/product-detail/product-detail.component';
import { PaymentHistoryComponent } from './components/pages/portal/payment-history/payment-history.component';
import { CancellationComponent } from './components/pages/cancellation/cancellation.component';
// import { PolicyCancellationComponent } from './components/pages/cancellation/policy-cancellation/policy-cancellation.component';
import { ContractCancellationComponent } from './components/pages/cancellation/contract-cancellation/contract-cancellation.component';
import { MnpRequestComponent } from './components/pages/cancellation/mnp-request/mnp-request.component';
import { SubmitedRequestComponent } from './components/pages/cancellation/submited-request/submited-request.component';
import { MnpAttentionComponent } from './components/pages/cancellation/mnp-attention/mnp-attention.component';
import { MnpSubmitInfomationComponent } from './components/pages/cancellation/mnp-submit-infomation/mnp-submit-infomation.component';
// tslint:disable-next-line:max-line-length
import { PaypalInformationComponent } from './components/pages/cancellation/mnp-submit-infomation/paypal-information/paypal-information.component';
// tslint:disable-next-line:max-line-length
import { ConfirmPaypalInformationComponent } from './components/pages/cancellation/mnp-submit-infomation/confirm-paypal-information/confirm-paypal-information.component';
// tslint:disable-next-line:max-line-length
import { PaypalSubmittedComponent } from './components/pages/cancellation/mnp-submit-infomation/paypal-submitted/paypal-submitted.component';
// tslint:disable-next-line:max-line-length
import { JpBankInformationComponent } from './components/pages/cancellation/mnp-submit-infomation/jp-bank-information/jp-bank-information.component';
// tslint:disable-next-line:max-line-length
import { ConfirmJpBankInformationComponent } from './components/pages/cancellation/mnp-submit-infomation/confirm-jp-bank-information/confirm-jp-bank-information.component';

import { AccountInformationComponent } from './components/pages/account-information/account-information.component';

// tslint:disable-next-line:max-line-length
import { ConfirmationMnpInformationComponent } from './components/pages/cancellation/mnp-submit-infomation/confirmation-mnp-information/confirmation-mnp-information.component';
import { PaymentInformationComponent } from './components/pages/payment-information/payment-information.component';

import { PayByNewCardComponent } from './components/pages/payment-information/pay-by-new-card/pay-by-new-card.component';

import { PaymentReceivedComponent } from './components/pages/payment-information/payment-received/payment-received.component';
import { PersonalDetailComponent } from './components/pages/account-information/personal-detail/personal-detail.component';
import { UpdateCardComponent } from './components/pages/account-information/update-card/update-card.component';
import { CardUpdatedComponent } from './components/pages/account-information/card-updated/card-updated.component';
import { ChangePaymentMethodComponent } from './components/pages/account-information/change-payment-method/change-payment-method.component';
// tslint:disable-next-line:max-line-length
import { ConfirmationChangePaymentComponent } from './components/pages/account-information/confirmation-change-payment/confirmation-change-payment.component';
import { SubmittedRequestComponent } from './components/pages/account-information/submitted-request/submitted-request.component';
import { TopUpSelectComponent } from './components/pages/top-up-data/top-up-select/top-up-select.component';
import { TopUpCheckoutComponent } from './components/pages/top-up-data/top-up-checkout/top-up-checkout.component';
import { TopUpFinishComponent } from './components/pages/top-up-data/top-up-finish/top-up-finish.component';
import { InviteFriendsComponent } from './components/pages/invite-friends/invite-friends.component';
import { PointDescriptionComponent } from './components/pages/points/point-description/point-description.component';
import { ItemOptionComponent } from './components/pages/item-option/item-option.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home' , component: HomeComponent },
  { path: 'portal', component: PortalComponent },
  // { path: 'policy', component: PolicyCancellationComponent},
  { path: 'cancellation', component: CancellationComponent},
  { path: 'termination-selection', component: MnpRequestComponent},
  { path: 'item-option', component: ItemOptionComponent},
  { path: 'mnp-attention', component: MnpAttentionComponent},
  { path: 'mnp-information', component: MnpSubmitInfomationComponent},
  { path: 'confirmation-mnp-information', component: ConfirmationMnpInformationComponent},
  { path: 'refund-paypal', component: PaypalInformationComponent},
  { path: 'refund-jp-bank', component: JpBankInformationComponent},
  { path: 'confirmation-paypal-information', component: ConfirmPaypalInformationComponent},
  { path: 'confirmation-jp-bank-information', component: ConfirmJpBankInformationComponent},
  { path: 'submitted-paypal', component: PaypalSubmittedComponent},
  { path: 'contract-cancellation', component: ContractCancellationComponent},
  { path: 'submited-request', component: SubmitedRequestComponent},

  {path: 'top-up-select', component: TopUpSelectComponent},
  {path: 'top-up-checkout', component: TopUpCheckoutComponent},
  {path: 'top-up-finish', component: TopUpFinishComponent},

  {path: 'invite-friends', component: InviteFriendsComponent},
  {path: 'point-description/:points', component: PointDescriptionComponent},

  { path: 'account-information', component: AccountInformationComponent},
  { path: 'personal-detail', component: PersonalDetailComponent},
  { path: 'update-card', component: UpdateCardComponent},
  { path: 'card_updated', component: CardUpdatedComponent},

  { path: 'payment-information', component: PaymentInformationComponent},
  { path: 'payment-information/pay-by-new-card', component: PayByNewCardComponent},
  { path: 'payment-received', component: PaymentReceivedComponent},
  { path: 'change-payment', component: ChangePaymentMethodComponent},
  { path: 'confirmation-change-payment', component: ConfirmationChangePaymentComponent},
  { path: 'change-payment-submitted-request', component: SubmittedRequestComponent},

  { path: 'portal/contract-details/:id', component: ContractDetailsComponent},
  { path: 'portal/contract-product/:product_id', component: ProductDetailComponent},
  { path: 'portal/payment-history/:contract_id', component: PaymentHistoryComponent},
  { path: 'auth/login', component: LoginComponent },
  { path: 'auth/sign_up', component: SignUpComponent },
  { path: 'auth/confirmation-resend', component: ConfirmationResendComponent },
  { path: 'auth/password_reset', component: PasswordResetComponent },
  { path: 'auth/password_change', component: PasswordChangeComponent },
  // { path: 'admin/users', component: AdminUsersComponent },
  // { path: 'admin/user', component: AdminUserComponent },
  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  // http://www.fumiononaka.com/Business/html5/FN1805010.html
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
