export class Dictionaries {

    private static _IIJ_DESTINATIONS = [
        { 'original': '＃', 'display': '#', 'is_type': false},
        { 'original': 'ALASKA', 'display': 'Alaska', 'is_type': false},
        { 'original': 'ＡＴ＆Ｔ（ＵＳＡ）', 'display': 'AT&T(USA)', 'is_type': false},
        { 'original': 'Australia', 'display': 'Australia', 'is_type': false},
        { 'original': 'BELGIUM', 'display': 'Belgium', 'is_type': false},
        { 'original': 'Ｂｅｌｌ（Canada）', 'display': 'Bell(Canada)', 'is_type': false},
        { 'original': 'BRAZIL', 'display': 'Brazil', 'is_type': false},
        { 'original': 'BRUNEI', 'display': 'Burunei', 'is_type': false},
        { 'original': 'ＢＹＴＥＬ（ＦＲＡ）', 'display': 'BYTEL(FRA)', 'is_type': false},
        { 'original': 'Canada', 'display': 'Canada', 'is_type': false},
        { 'original': 'Cellphone', 'display': 'Cellphone', 'is_type': true},
        { 'original': 'Cellphone/PHS', 'display': 'Cellphone', 'is_type': true},
        { 'original': 'CHINA', 'display': 'China', 'is_type': false},
        { 'original': 'China', 'display': 'China', 'is_type': false},
        { 'original': 'ＣＭＣＣ（China）', 'display': 'CMCC(China)', 'is_type': false},
        { 'original': 'ＥＥ（UK）', 'display': 'EE(UK)', 'is_type': false},
        { 'original': 'ＦＥＴ（台　湾）', 'display': 'FET(Taiwan)', 'is_type': false},
        { 'original': 'France', 'display': 'France', 'is_type': false},
        { 'original': 'GERMANY', 'display': 'Germany', 'is_type': false},
        { 'original': 'ＧＰＣ（ＶＮＭ）', 'display': 'GPC(VNM)', 'is_type': false},
        { 'original': 'GUAM', 'display': 'Guam', 'is_type': false},
        { 'original': 'Hawaii', 'display': 'Hawaii', 'is_type': false},
        { 'original': 'HONG KONG', 'display': 'Hong Kong', 'is_type': false},
        { 'original': 'Ｉ　Ｐ', 'display': 'IP', 'is_type': false},
        { 'original': 'Ｉ　ＷＩＮ（ＩＴＡ）', 'display': 'I WIN(ITA)', 'is_type': false},
        { 'original': 'India', 'display': 'India', 'is_type': false},
        { 'original': 'Indiaネシア', 'display': 'Indonesia', 'is_type': false},
        { 'original': 'INDONESIA', 'display': 'Indonesia', 'is_type': false},
        { 'original': 'ＩＮＤＯＳ（ＩＤＮ）', 'display': 'INDOS(IDN)', 'is_type': false},
        { 'original': 'Int.SMS', 'display': 'Int.SMS', 'is_type': true },
        { 'original': 'ＩＰＣ', 'display': 'IPC', 'is_type': false},
        { 'original': 'ITALY', 'display': 'ITALY', 'is_type': false},
        { 'original': 'Kanagawa', 'display': 'Kanagawa', 'is_type': false},
        { 'original': 'KOREA', 'display': 'Korea', 'is_type': false},
        { 'original': 'Korea', 'display': 'Korea', 'is_type': false},
        { 'original': 'ＫＴ（Korea）', 'display': 'KT(Korea)', 'is_type': false},
        { 'original': 'Kumamoto', 'display': 'Kumamoto', 'is_type': false},
        { 'original': 'Kyoto', 'display': 'Kyoto', 'is_type': false},
        { 'original': 'MACAU', 'display': 'Macau', 'is_type': false},
        { 'original': 'MALAYSIA', 'display': 'Malaysia', 'is_type': false},
        { 'original': 'Miyagi', 'display': 'Miyagi', 'is_type': false},
        { 'original': 'Ｍｏｂｉｆ（ＶＮＭ）', 'display': 'Mobif(VNM)', 'is_type': false},
        { 'original': 'Ｍｏｂｉｔ（ＬＫＡ）', 'display': 'Mobit(LKA)', 'is_type': false},
        { 'original': 'ＭＹ　ＭＡ（ＭＹＳ）', 'display': 'MY MA(MYS)', 'is_type': false},
        { 'original': 'Nepal', 'display': 'Nepal', 'is_type': false},
        { 'original': 'NETHERLANDS', 'display': 'Netherlands', 'is_type': false},
        { 'original': 'NEW ZEALAND', 'display': 'New Zealand', 'is_type': false},
        { 'original': 'Ｏ２　−　（UK）', 'display': 'O2 − (UK)', 'is_type': false},
        { 'original': 'Ｏｒａｎｇ（ＦＲＡ）', 'display': 'Orang(FRA)', 'is_type': false},
        { 'original': 'Osaka', 'display': 'Osaka', 'is_type': false},
        { 'original': 'Other', 'display': 'Other', 'is_type': false},
        { 'original': 'PHILIPPINES', 'display': 'Philippins', 'is_type': false},
        { 'original': 'ＰＨＳ', 'display': 'PHS', 'is_type': false},
        { 'original': 'Portugal', 'display': 'Portugal', 'is_type': false},
        { 'original': 'ＲＯＧＥＲ（Canada）', 'display': 'ROGER(Canada)', 'is_type': false},
        { 'original': 'RUSSIA', 'display': 'Russia', 'is_type': false},
        { 'original': 'SAIPAN', 'display': 'Saipan', 'is_type': false},
        { 'original': 'Sapporo', 'display': 'Sapporo', 'is_type': false},
        { 'original': 'ＳＦＲ（ＦＲＡ）', 'display': 'SFR(FRA)', 'is_type': false},
        { 'original': 'Shizuoka', 'display': 'Shizuoka', 'is_type': false},
        { 'original': 'SINGAPORE', 'display': 'Singapore', 'is_type': false},
        { 'original': 'ＳＭＡＲＴ（ＰＨＬ）', 'display': 'SMART(PHL)', 'is_type': false},
        { 'original': 'ＳＭＳ', 'display': 'SMS', 'is_type': false},
        { 'original': 'SPAIN', 'display': 'Spain', 'is_type': false},
        { 'original': 'SWITZERLAND', 'display': 'Switzerland', 'is_type': false},
        { 'original': 'Ｔ−Ｍｏｂ（ＵＳＡ）', 'display': 'T−Mob(USA)', 'is_type': false},
        { 'original': 'TAIWAN', 'display': 'Taiwan', 'is_type': false},
        { 'original': 'ＴＣＥＬＬ（トルコ）', 'display': 'TCELL(Turkey)', 'is_type': false},
        { 'original': 'ＴＥＬＥＮ（ＮＯＲ）', 'display': 'TELEN(NOR)', 'is_type': false},
        { 'original': 'Ｔｅｌｓｔ（ＡＵＳ）', 'display': 'Telst(AUS)', 'is_type': false},
        { 'original': 'Thailand', 'display': 'Thailand', 'is_type': false},
        { 'original': 'ＴＭＯ　Ｎ（ＮＬＤ）', 'display': 'TMO N(NLD)', 'is_type': false},
        { 'original': 'Tokyo', 'display': 'Tokyo', 'is_type': false},
        { 'original': 'ＴＲＵＥ（タ　イ）', 'display': 'TRUE(Thai)', 'is_type': false},
        { 'original': 'UAE', 'display': 'UAE', 'is_type': false},
        { 'original': 'UK', 'display': 'UK', 'is_type': false},
        { 'original': 'ＵＮＩＣＯ（China）', 'display': 'UNICO(China)', 'is_type': false},
        { 'original': 'ＵＮＴＬＭ（ＭＮＧ）', 'display': 'UNTLM(MNG)', 'is_type': false},
        { 'original': 'USA', 'display': 'USA', 'is_type': false},
        { 'original': 'VIETNAM', 'display': 'VIETNAM', 'is_type': false},
        { 'original': 'ＶＩＥＴＴ（ＶＮＭ）', 'display': 'VIETT(VNM)', 'is_type': false},
        { 'original': 'ＶＮＭＯＢ（ＶＮＭ）', 'display': 'VNMOB(VNM)', 'is_type': false},
        { 'original': 'ｖｏｄａ　（ＩＴＡ）', 'display': 'voda (ITA)', 'is_type': false},
        { 'original': 'ｖｏｄａ　（ＮＬＤ）', 'display': 'voda (NLD)', 'is_type': false},
        { 'original': 'ｖｏｄａ　（UK）', 'display': 'voda (UK)', 'is_type': false},
        { 'original': 'Voice Msg', 'display': 'Voice Msg', 'is_type': false},
        { 'original': 'Yokohama', 'display': 'Yokohama', 'is_type': false},
        { 'original': 'Yokosuka', 'display': 'Yokosuka', 'is_type': false},
        { 'original': 'アイスランド', 'display': 'Iceland', 'is_type': false},
        { 'original': 'アイルランド', 'display': 'Ireland', 'is_type': false},
        { 'original': 'アゼルバイジャン', 'display': 'Azerbaijan', 'is_type': false},
        { 'original': 'アメリカ', 'display': 'USA', 'is_type': false},
        { 'original': 'アラスカ', 'display': 'Alaska', 'is_type': false},
        { 'original': 'アラブ首長国連邦', 'display': 'UAE', 'is_type': false},
        { 'original': 'アルジェリア', 'display': 'Algeria', 'is_type': false},
        { 'original': 'アルゼンチン', 'display': 'Argentina', 'is_type': false},
        { 'original': 'アンゴラ', 'display': 'Angola', 'is_type': false},
        { 'original': 'アンティグア', 'display': 'Antigua', 'is_type': false},
        { 'original': 'イエメン', 'display': 'Yemen', 'is_type': false},
        { 'original': 'イギリス', 'display': 'UK', 'is_type': false},
        { 'original': 'イスラエル', 'display': 'Israel', 'is_type': false},
        { 'original': 'イタリア・バチカン', 'display': 'Italy/Vatican', 'is_type': false},
        { 'original': 'イラン', 'display': 'Iran', 'is_type': false},
        { 'original': 'いわき', 'display': 'Iwaki', 'is_type': false},
        { 'original': 'インド', 'display': 'India', 'is_type': false},
        { 'original': 'インドネシア', 'display': 'Indonesia', 'is_type': false},
        { 'original': 'インマルサット', 'display': 'Inmarsat', 'is_type': false},
        { 'original': 'ウガンダ', 'display': 'Uganda', 'is_type': false},
        { 'original': 'ウクライナ', 'display': 'Ukraine', 'is_type': false},
        { 'original': 'ウズベキスタン', 'display': 'Uzbekistan', 'is_type': false},
        { 'original': 'エクアドル', 'display': 'Ecuador', 'is_type': false},
        { 'original': 'エジプト', 'display': 'Egypt', 'is_type': false},
        { 'original': 'エチオピア', 'display': 'Ethiopia', 'is_type': false},
        { 'original': 'エルサルバドル', 'display': 'El Salvador', 'is_type': false},
        { 'original': 'オーストラリア', 'display': 'Australia', 'is_type': false},
        { 'original': 'オーストリア', 'display': 'Austria', 'is_type': false},
        { 'original': 'オマーン', 'display': 'Oman', 'is_type': false},
        { 'original': 'オランダ', 'display': 'Netherlands', 'is_type': false},
        { 'original': 'ガーナ', 'display': 'Ghana', 'is_type': false},
        { 'original': 'カタール', 'display': 'Qatar', 'is_type': false},
        { 'original': 'カナダ', 'display': 'Canada', 'is_type': false},
        { 'original': 'ガボン', 'display': 'Gabon', 'is_type': false},
        { 'original': 'カメルーン', 'display': 'Cameroon', 'is_type': false},
        { 'original': 'カンボジア', 'display': 'Cambodia', 'is_type': false},
        { 'original': 'キプロス', 'display': 'Cyprus', 'is_type': false},
        { 'original': 'キューバ', 'display': 'Cuba', 'is_type': false},
        { 'original': 'ギリシャ', 'display': 'Greece', 'is_type': false},
        { 'original': 'キルギス', 'display': 'Kyrgyzstan', 'is_type': false},
        { 'original': 'グアテマラ', 'display': 'Guatemala', 'is_type': false},
        { 'original': 'グアドループ島', 'display': 'Guadeloupe', 'is_type': false},
        { 'original': 'グアム・サイパン', 'display': 'Guam/Saipan', 'is_type': false},
        { 'original': 'ケイマン諸島', 'display': 'Cayman Islands', 'is_type': false},
        { 'original': 'ケニア', 'display': 'Kenya', 'is_type': false},
        { 'original': 'コートジボワール', 'display': 'Ivory Coast', 'is_type': false},
        { 'original': 'コスタリカ', 'display': 'Costa Rica', 'is_type': false},
        { 'original': 'コロンビア', 'display': 'Columbia', 'is_type': false},
        { 'original': 'コンゴ民主共和国', 'display': 'Congo', 'is_type': false},
        { 'original': 'サウジアラビア', 'display': 'Saudi Arabia', 'is_type': false},
        { 'original': 'サモア', 'display': 'Samoa', 'is_type': false},
        { 'original': 'ザンビア', 'display': 'Zambia', 'is_type': false},
        { 'original': 'ジブチ', 'display': 'Djibouti', 'is_type': false},
        { 'original': 'ジャマイカ', 'display': 'Jamaica', 'is_type': false},
        { 'original': 'シンガポール', 'display': 'Singapore', 'is_type': false},
        { 'original': 'シント・マールテン島', 'display': 'Sint Maarten', 'is_type': false},
        { 'original': 'ジンバブエ', 'display': 'Zimbabwe', 'is_type': false},
        { 'original': 'スイス', 'display': 'Switzerland', 'is_type': false},
        { 'original': 'スウェーデン', 'display': 'Sweden', 'is_type': false},
        { 'original': 'スペイン', 'display': 'Spain', 'is_type': false},
        { 'original': 'スラーヤ', 'display': 'Thuraya', 'is_type': false},
        { 'original': 'スリナム', 'display': 'Suriname', 'is_type': false},
        { 'original': 'スリランカ', 'display': 'Sri Lanka', 'is_type': false},
        { 'original': 'スロバキア', 'display': 'Slovakia', 'is_type': false},
        { 'original': 'セネガル', 'display': 'Senegal', 'is_type': false},
        { 'original': 'セルビア', 'display': 'Serbia', 'is_type': false},
        { 'original': 'タイ', 'display': 'Thailand', 'is_type': false},
        { 'original': 'タンザニア', 'display': 'Tanzania', 'is_type': false},
        { 'original': 'チェコ', 'display': 'Czech Republic', 'is_type': false},
        { 'original': 'チュニジア', 'display': 'Tunisia', 'is_type': false},
        { 'original': 'チリ', 'display': 'Chile', 'is_type': false},
        { 'original': 'デンマーク', 'display': 'Denmark', 'is_type': false},
        { 'original': 'ドイツ', 'display': 'Germany', 'is_type': false},
        { 'original': 'ドミニカ共和国', 'display': 'Dominican Republic', 'is_type': false},
        { 'original': 'トリニダード・トバゴ', 'display': 'Trinidad and Tobago', 'is_type': false},
        { 'original': 'トルコ', 'display': 'Turkey', 'is_type': false},
        { 'original': 'トンガ', 'display': 'Tonga', 'is_type': false},
        { 'original': 'ナイジェリア', 'display': 'Nigeria', 'is_type': false},
        { 'original': 'ナウル', 'display': 'Nauru', 'is_type': false},
        { 'original': 'ナミビア', 'display': 'Namibia', 'is_type': false},
        { 'original': 'ニュージーランド', 'display': 'New Zealand', 'is_type': false},
        { 'original': 'ネパール', 'display': 'Nepal', 'is_type': false},
        { 'original': 'ネパール', 'display': 'Nepal', 'is_type': false},
        { 'original': 'ノルウェー', 'display': 'Norway', 'is_type': false},
        { 'original': 'バーレーン', 'display': 'Bahrain', 'is_type': false},
        { 'original': 'パキスタン', 'display': 'Pakistan', 'is_type': false},
        { 'original': 'パナマ', 'display': 'Panama', 'is_type': false},
        { 'original': 'パプアニューギニア', 'display': 'Papua New Guinea', 'is_type': false},
        { 'original': 'パラグアイ', 'display': 'Paraguay', 'is_type': false},
        { 'original': 'バルバドス', 'display': 'Barbados', 'is_type': false},
        { 'original': 'ハワイ', 'display': 'Hawaii', 'is_type': false},
        { 'original': 'ハンガリー', 'display': 'Hungary', 'is_type': false},
        { 'original': 'バングラデシュ', 'display': 'Bangladesh', 'is_type': false},
        { 'original': 'フィジー', 'display': 'Fiji', 'is_type': false},
        { 'original': 'フィリピン', 'display': 'Philippines', 'is_type': false},
        { 'original': 'フィリピン', 'display': 'Philippines', 'is_type': false},
        { 'original': 'フィンランド', 'display': 'Finland', 'is_type': false},
        { 'original': 'プエルトリコ', 'display': 'Puerto rico', 'is_type': false},
        { 'original': 'ブラジル', 'display': 'Brazil', 'is_type': false},
        { 'original': 'フランス', 'display': 'France', 'is_type': false},
        { 'original': 'ブルガリア', 'display': 'Bulgaria', 'is_type': false},
        { 'original': 'ブルネイ', 'display': 'Brunei', 'is_type': false},
        { 'original': 'ベトナム', 'display': 'Vietnam', 'is_type': false},
        { 'original': 'ベナン', 'display': 'Benin', 'is_type': false},
        { 'original': 'ベネズエラ', 'display': 'Venezuela', 'is_type': false},
        { 'original': 'ベラルーシ', 'display': 'Belarus', 'is_type': false},
        { 'original': 'ペルー', 'display': 'Peru', 'is_type': false},
        { 'original': 'ベルギー', 'display': 'Belgium', 'is_type': false},
        { 'original': 'ポーランド', 'display': 'Poland', 'is_type': false},
        { 'original': 'ボツワナ', 'display': 'Botswana', 'is_type': false},
        { 'original': 'ポルトガル', 'display': 'Portugal', 'is_type': false},
        { 'original': 'ポルトガル', 'display': 'Portugal', 'is_type': false},
        { 'original': 'マカオ', 'display': 'Macau', 'is_type': false},
        { 'original': 'マケドニア', 'display': 'Macedonia', 'is_type': false},
        { 'original': 'マダガスカル', 'display': 'Madagascar', 'is_type': false},
        { 'original': 'マレーシア', 'display': 'Malaysia', 'is_type': false},
        { 'original': 'ミクロネシア連邦', 'display': 'Micronesia', 'is_type': false},
        { 'original': 'ミャンマー', 'display': 'Myanmar', 'is_type': false},
        { 'original': 'むつ', 'display': 'Mutsu', 'is_type': false},
        { 'original': 'メキシコ', 'display': 'Mexico', 'is_type': false},
        { 'original': 'モルドバ', 'display': 'Moldova', 'is_type': false},
        { 'original': 'モロッコ', 'display': 'Morocco', 'is_type': false},
        { 'original': 'モンゴル', 'display': 'Mongolia', 'is_type': false},
        { 'original': 'ヨルダン', 'display': 'Jordan', 'is_type': false},
        { 'original': 'ラトビア', 'display': 'Latvia', 'is_type': false},
        { 'original': 'リトアニア', 'display': 'Lithuania', 'is_type': false},
        { 'original': 'リベリア', 'display': 'Liberia', 'is_type': false},
        { 'original': 'ルーマニア', 'display': 'Romania', 'is_type': false},
        { 'original': 'ルクセンブルク', 'display': 'Luxembourg', 'is_type': false},
        { 'original': 'ルワンダ', 'display': 'Rwanda', 'is_type': false},
        { 'original': 'レバノン', 'display': 'Lebanon', 'is_type': false},
        { 'original': 'ロシア・カザフスタン', 'display': 'Russia/Kazakhstan', 'is_type': false},
        { 'original': '一宮', 'display': 'Ichinomiya', 'is_type': false},
        { 'original': '一関', 'display': 'Ichinoseki', 'is_type': false},
        { 'original': '七尾', 'display': 'Nanao', 'is_type': false},
        { 'original': '三　重', 'display': 'Mie', 'is_type': false},
        { 'original': '三春', 'display': 'Miharu', 'is_type': false},
        { 'original': '三木', 'display': 'Miki', 'is_type': false},
        { 'original': '三次', 'display': 'Tertiary', 'is_type': false},
        { 'original': '三田', 'display': 'Mita', 'is_type': false},
        { 'original': '上川', 'display': 'Kamikawa', 'is_type': false},
        { 'original': '上田', 'display': 'Ueda', 'is_type': false},
        { 'original': '上越', 'display': 'Joetsu', 'is_type': false},
        { 'original': '下田', 'display': 'Shimoda', 'is_type': false},
        { 'original': '下関', 'display': 'Shimonoseki', 'is_type': false},
        { 'original': '中国', 'display': 'China', 'is_type': false},
        { 'original': '中津', 'display': 'Nakatsu', 'is_type': false},
        { 'original': '中津川', 'display': 'Nakatsugawa', 'is_type': false},
        { 'original': '中野', 'display': 'Nakano', 'is_type': false},
        { 'original': '串本', 'display': 'Kushimoto', 'is_type': false},
        { 'original': '丸亀', 'display': 'Marugame', 'is_type': false},
        { 'original': '久世', 'display': 'Kuze', 'is_type': false},
        { 'original': '久喜', 'display': 'Kuki', 'is_type': false},
        { 'original': '久慈', 'display': 'Kuji', 'is_type': false},
        { 'original': '久留米', 'display': 'Kurume', 'is_type': false},
        { 'original': '亀岡', 'display': 'Kameoka', 'is_type': false},
        { 'original': '二戸', 'display': 'Ninohe', 'is_type': false},
        { 'original': '京　都', 'display': 'Kyoto', 'is_type': false},
        { 'original': '京都', 'display': 'Kyoto', 'is_type': false},
        { 'original': '今市', 'display': 'Imaichi', 'is_type': false},
        { 'original': '今治', 'display': 'Imabari', 'is_type': false},
        { 'original': '仏領ギアナ', 'display': 'French Guiana', 'is_type': false},
        { 'original': '他　社', 'display': 'Other', 'is_type': false},
        { 'original': '仙台', 'display': 'Sendai', 'is_type': false},
        { 'original': '伊万里', 'display': 'Imari', 'is_type': false},
        { 'original': '伊予三島', 'display': 'Iyo Mishima', 'is_type': false},
        { 'original': '伊勢', 'display': 'Ise', 'is_type': false},
        { 'original': '伊勢崎', 'display': 'Isesaki', 'is_type': false},
        { 'original': '伊東', 'display': 'Ito', 'is_type': false},
        { 'original': '伊豆大島', 'display': 'Izu Oshima', 'is_type': false},
        { 'original': '伊達', 'display': 'Date', 'is_type': false},
        { 'original': '伊那', 'display': 'Ina', 'is_type': false},
        { 'original': '会津若松', 'display': 'Aizuwakamatsu', 'is_type': false},
        { 'original': '伯方', 'display': 'Hakukata', 'is_type': false},
        { 'original': '佐　賀', 'display': 'Sagaga', 'is_type': false},
        { 'original': '佐世保', 'display': 'Sasebo', 'is_type': false},
        { 'original': '佐久', 'display': 'Saku', 'is_type': false},
        { 'original': '佐渡', 'display': 'Sado', 'is_type': false},
        { 'original': '佐賀', 'display': 'Saga', 'is_type': false},
        { 'original': '佐野', 'display': 'Sano', 'is_type': false},
        { 'original': '余市', 'display': 'Yoichi', 'is_type': false},
        { 'original': '修善寺大仁', 'display': 'Shuzenji Ohito', 'is_type': false},
        { 'original': '倉吉', 'display': 'Kurayoshi', 'is_type': false},
        { 'original': '倉敷', 'display': 'Kurashiki', 'is_type': false},
        { 'original': '倶知安', 'display': 'Kutchan', 'is_type': false},
        { 'original': '備前', 'display': 'Bizen', 'is_type': false},
        { 'original': '八代', 'display': 'Yatsushiro', 'is_type': false},
        { 'original': '八尾', 'display': 'Yao', 'is_type': false},
        { 'original': '八戸', 'display': 'Hachinohe', 'is_type': false},
        { 'original': '八日市', 'display': 'Yokaichi', 'is_type': false},
        { 'original': '八日市場', 'display': 'Yoka Market', 'is_type': false},
        { 'original': '八王子', 'display': 'Hachioji', 'is_type': false},
        { 'original': '八重山', 'display': 'Yaeyama', 'is_type': false},
        { 'original': '八鹿', 'display': 'Yoka', 'is_type': false},
        { 'original': '六日町', 'display': 'Muikamachi', 'is_type': false},
        { 'original': '兵　庫', 'display': 'Hyogo', 'is_type': false},
        { 'original': '出水', 'display': 'Flood', 'is_type': false},
        { 'original': '出雲', 'display': 'Izumo', 'is_type': false},
        { 'original': '函　館', 'display': 'Hakodate', 'is_type': false},
        { 'original': '函館', 'display': 'Hakodate', 'is_type': false},
        { 'original': '刈谷', 'display': 'Kariya', 'is_type': false},
        { 'original': '別府', 'display': 'Beppu', 'is_type': false},
        { 'original': '前橋', 'display': 'Maebashi', 'is_type': false},
        { 'original': '加古川', 'display': 'Kakogawa', 'is_type': false},
        { 'original': '加治木', 'display': 'Kajiki', 'is_type': false},
        { 'original': '加賀', 'display': 'Kaga', 'is_type': false},
        { 'original': '北　見', 'display': 'Kitami', 'is_type': false},
        { 'original': '北上', 'display': 'Kitakami', 'is_type': false},
        { 'original': '北九州', 'display': 'Kitakyushu', 'is_type': false},
        { 'original': '北朝鮮', 'display': 'North Korea', 'is_type': false},
        { 'original': '北見', 'display': 'Kitami', 'is_type': false},
        { 'original': '十和田', 'display': 'Towada', 'is_type': false},
        { 'original': '十日町', 'display': 'Tokamachi', 'is_type': false},
        { 'original': '十津川', 'display': 'Totsugawa', 'is_type': false},
        { 'original': '千　葉', 'display': 'Chiba', 'is_type': false},
        { 'original': '千歳', 'display': 'Chitose', 'is_type': false},
        { 'original': '千葉', 'display': 'Chiba', 'is_type': false},
        { 'original': '半田', 'display': 'Handa', 'is_type': false},
        { 'original': '南アフリカ', 'display': 'South Africa', 'is_type': false},
        { 'original': '厚木', 'display': 'Atsugi', 'is_type': false},
        { 'original': '原町', 'display': 'Haramachi', 'is_type': false},
        { 'original': '古河', 'display': 'Furukawa', 'is_type': false},
        { 'original': '台湾', 'display': 'Taiwan', 'is_type': false},
        { 'original': '吉田', 'display': 'Yoshida', 'is_type': false},
        { 'original': '名古屋', 'display': 'Nagoya', 'is_type': false},
        { 'original': '名瀬', 'display': 'Naze', 'is_type': false},
        { 'original': '名護', 'display': 'Nago', 'is_type': false},
        { 'original': '呉', 'display': 'Kure', 'is_type': false},
        { 'original': '和歌山', 'display': 'Wakayama', 'is_type': false},
        { 'original': '和泉', 'display': 'Izumi', 'is_type': false},
        { 'original': '唐津', 'display': 'Karatsu', 'is_type': false},
        { 'original': '四日市', 'display': 'Yokkaichi', 'is_type': false},
        { 'original': '国分寺', 'display': 'Kokubunji', 'is_type': false},
        { 'original': '国際', 'display': 'Int.', 'is_type': true},
        { 'original': '国際ＳＭＳ', 'display': 'Int.SMS', 'is_type': true},
        { 'original': '土佐中村', 'display': 'Tosa Nakamura', 'is_type': false},
        { 'original': '土佐山田', 'display': 'Tosa Yamada', 'is_type': false},
        { 'original': '土庄', 'display': 'Tonosho', 'is_type': false},
        { 'original': '土浦', 'display': 'Tsuchiura', 'is_type': false},
        { 'original': '埼　玉', 'display': 'Saitama', 'is_type': false},
        { 'original': '堺', 'display': 'Sakai', 'is_type': false},
        { 'original': '多治見', 'display': 'Tazimi', 'is_type': false},
        { 'original': '大　分', 'display': 'Oita', 'is_type': false},
        { 'original': '大　阪', 'display': 'Osaka', 'is_type': false},
        { 'original': '大分', 'display': 'Oita', 'is_type': false},
        { 'original': '大和高田', 'display': 'Yamatotakada', 'is_type': false},
        { 'original': '大垣', 'display': 'Ogaki', 'is_type': false},
        { 'original': '大曲', 'display': 'Omagari', 'is_type': false},
        { 'original': '大河原', 'display': 'Ogawara', 'is_type': false},
        { 'original': '大津', 'display': 'Otsu', 'is_type': false},
        { 'original': '大洲', 'display': 'Ozu', 'is_type': false},
        { 'original': '大瀬戸', 'display': 'Oseto', 'is_type': false},
        { 'original': '大町', 'display': 'Omachi', 'is_type': false},
        { 'original': '大船渡', 'display': 'Ofunato', 'is_type': false},
        { 'original': '大野', 'display': 'Ohno', 'is_type': false},
        { 'original': '大阪', 'display': 'Osaka', 'is_type': false},
        { 'original': '大館', 'display': 'Odate', 'is_type': false},
        { 'original': '太田', 'display': 'Ota', 'is_type': false},
        { 'original': '奈　良', 'display': 'Naura', 'is_type': false},
        { 'original': '奈良', 'display': 'Nara', 'is_type': false},
        { 'original': '姫路', 'display': 'Himeji', 'is_type': false},
        { 'original': '宇治', 'display': 'Uji', 'is_type': false},
        { 'original': '宇部', 'display': 'Ube', 'is_type': false},
        { 'original': '宇都宮', 'display': 'Utsunomiya', 'is_type': false},
        { 'original': '安塚', 'display': 'Yasuka', 'is_type': false},
        { 'original': '安芸', 'display': 'Aki', 'is_type': false},
        { 'original': '室戸', 'display': 'Muroto', 'is_type': false},
        { 'original': '室蘭', 'display': 'Muroran', 'is_type': false},
        { 'original': '宮　城', 'display': 'Miyagi', 'is_type': false},
        { 'original': '宮　崎', 'display': 'Miyazaki', 'is_type': false},
        { 'original': '宮崎', 'display': 'Miyazaki', 'is_type': false},
        { 'original': '宮津', 'display': 'Miyazu', 'is_type': false},
        { 'original': '富　山', 'display': 'Toyama', 'is_type': false},
        { 'original': '富士', 'display': 'Fuji', 'is_type': false},
        { 'original': '富士宮', 'display': 'Fujinomiya', 'is_type': false},
        { 'original': '富山', 'display': 'Toyama', 'is_type': false},
        { 'original': '富岡', 'display': 'Tomioka', 'is_type': false},
        { 'original': '富田林', 'display': 'Tondabayashi', 'is_type': false},
        { 'original': '富良野', 'display': 'Furano', 'is_type': false},
        { 'original': '寒河江', 'display': 'Sagae', 'is_type': false},
        { 'original': '寝屋川', 'display': 'Neyagawa', 'is_type': false},
        { 'original': '対馬佐賀', 'display': 'Tsushima Saga', 'is_type': false},
        { 'original': '小山', 'display': 'Koyama', 'is_type': false},
        { 'original': '小松', 'display': 'Komatsu', 'is_type': false},
        { 'original': '小林', 'display': 'Kobayashi', 'is_type': false},
        { 'original': '小樽', 'display': 'Otaru', 'is_type': false},
        { 'original': '小浜', 'display': 'Obama', 'is_type': false},
        { 'original': '小田原', 'display': 'Odawara', 'is_type': false},
        { 'original': '小諸', 'display': 'Komoro', 'is_type': false},
        { 'original': '尾張Yokosuka', 'display': 'Owari Yokosuka', 'is_type': false},
        { 'original': '尾道', 'display': 'Onomichi', 'is_type': false},
        { 'original': '尾鷲', 'display': 'Owase', 'is_type': false},
        { 'original': '屋久島', 'display': 'Yakushima', 'is_type': false},
        { 'original': '山　口', 'display': 'Yamaguchi', 'is_type': false},
        { 'original': '山　形', 'display': 'Yamagata', 'is_type': false},
        { 'original': '山　梨', 'display': 'Yamanashi', 'is_type': false},
        { 'original': '山形', 'display': 'Yamagata', 'is_type': false},
        { 'original': '山梨', 'display': 'Yamanashi', 'is_type': false},
        { 'original': '岐　阜', 'display': 'Gifu', 'is_type': false},
        { 'original': '岐阜', 'display': 'Gifu', 'is_type': false},
        { 'original': '岡　山', 'display': 'Okayama', 'is_type': false},
        { 'original': '岡山', 'display': 'Okayama', 'is_type': false},
        { 'original': '岡崎', 'display': 'Okazaki', 'is_type': false},
        { 'original': '岩　手', 'display': 'Iwate', 'is_type': false},
        { 'original': '岩国', 'display': 'Iwakuni', 'is_type': false},
        { 'original': '岩手', 'display': 'Iwate', 'is_type': false},
        { 'original': '岩沼', 'display': 'Iwanuma', 'is_type': false},
        { 'original': '岸和田貝塚', 'display': 'KishiwadaKaiduka', 'is_type': false},
        { 'original': '島　根', 'display': 'Shimane', 'is_type': false},
        { 'original': '島原', 'display': 'Shimabara', 'is_type': false},
        { 'original': '嶺北', 'display': 'Reihoku', 'is_type': false},
        { 'original': '川内', 'display': 'Kawauchi', 'is_type': false},
        { 'original': '川口', 'display': 'Kawaguchi', 'is_type': false},
        { 'original': '川崎', 'display': 'Kawasaki', 'is_type': false},
        { 'original': '川越', 'display': 'Kawagoe', 'is_type': false},
        { 'original': '市原', 'display': 'Ichihara', 'is_type': false},
        { 'original': '市川', 'display': 'Ichikawa', 'is_type': false},
        { 'original': '帯広', 'display': 'Obihiro', 'is_type': false},
        { 'original': '常陸太田', 'display': 'Hitachi Ota', 'is_type': false},
        { 'original': '平塚', 'display': 'Hiratsuka', 'is_type': false},
        { 'original': '広　島', 'display': 'Hiroshima', 'is_type': false},
        { 'original': '広島', 'display': 'Hiroshima', 'is_type': false},
        { 'original': '延岡', 'display': 'Nobeoka', 'is_type': false},
        { 'original': '廿日市', 'display': 'Hatsukaichi', 'is_type': false},
        { 'original': '弘前', 'display': 'Hirosaki', 'is_type': false},
        { 'original': '弟子屈', 'display': 'Teshikaga', 'is_type': false},
        { 'original': '彦根', 'display': 'Hikone', 'is_type': false},
        { 'original': '御殿場', 'display': 'Gotemba', 'is_type': false},
        { 'original': '御荘', 'display': 'Mishou', 'is_type': false},
        { 'original': '徳　島', 'display': 'Tokushima', 'is_type': false},
        { 'original': '徳山', 'display': 'Tokuyama', 'is_type': false},
        { 'original': '徳島', 'display': 'Tokushima', 'is_type': false},
        { 'original': '愛　媛', 'display': 'Aihime', 'is_type': false},
        { 'original': '愛　知', 'display': 'Aichi', 'is_type': false},
        { 'original': '成田', 'display': 'Narita', 'is_type': false},
        { 'original': '所沢', 'display': 'Tokorozawa', 'is_type': false},
        { 'original': '掛川', 'display': 'Kakegawa', 'is_type': false},
        { 'original': '携　帯', 'display': 'Cellphone', 'is_type': true},
        { 'original': '携帯ＰＨＳ', 'display': 'Cellphone/PHS', 'is_type': true},
        { 'original': '播磨山崎', 'display': 'Harima Yamazaki', 'is_type': false},
        { 'original': '敦賀', 'display': 'Tsuruga', 'is_type': false},
        { 'original': '斜里', 'display': 'Shari', 'is_type': false},
        { 'original': '新　潟', 'display': 'Niigata', 'is_type': false},
        { 'original': '新井', 'display': 'Arai', 'is_type': false},
        { 'original': '新宮', 'display': 'Shingu', 'is_type': false},
        { 'original': '新居浜', 'display': 'Niihama', 'is_type': false},
        { 'original': '新潟', 'display': 'Niigata', 'is_type': false},
        { 'original': '新発田', 'display': 'Shibata', 'is_type': false},
        { 'original': '日南', 'display': 'Nichinan', 'is_type': false},
        { 'original': '日向', 'display': 'Hyuga', 'is_type': false},
        { 'original': '旭　川', 'display': 'Asahikawa', 'is_type': false},
        { 'original': '旭川', 'display': 'Asahikawa', 'is_type': false},
        { 'original': '春日井', 'display': 'Kasugai', 'is_type': false},
        { 'original': '有川', 'display': 'Arikawa', 'is_type': false},
        { 'original': '木更津', 'display': 'Kisarazu', 'is_type': false},
        { 'original': '木曾福島', 'display': 'Kiso Fukushima', 'is_type': false},
        { 'original': '札　幌', 'display': 'Sapporo', 'is_type': false},
        { 'original': '札　幌', 'display': 'Sapporo', 'is_type': false},
        { 'original': '札幌', 'display': 'Sapporo', 'is_type': false},
        { 'original': '村山', 'display': 'Murayama', 'is_type': false},
        { 'original': '東　京', 'display': 'Tokyo', 'is_type': false},
        { 'original': '東ティモール', 'display': 'East Timor', 'is_type': false},
        { 'original': '東京', 'display': 'Tokyo', 'is_type': false},
        { 'original': '東広島', 'display': 'Higashihiroshima', 'is_type': false},
        { 'original': '東松山', 'display': 'Higashimatsuyama', 'is_type': false},
        { 'original': '東金', 'display': 'Togane', 'is_type': false},
        { 'original': '杵築', 'display': 'Kitsuki', 'is_type': false},
        { 'original': '松山', 'display': 'Matsuyama', 'is_type': false},
        { 'original': '松本', 'display': 'Matsumoto', 'is_type': false},
        { 'original': '松橋', 'display': 'Matsubashi', 'is_type': false},
        { 'original': '松江', 'display': 'Matsue', 'is_type': false},
        { 'original': '松阪', 'display': 'Matsusaka', 'is_type': false},
        { 'original': '柏', 'display': 'oak', 'is_type': false},
        { 'original': '栃　木', 'display': 'Tochigi', 'is_type': false},
        { 'original': '栃木', 'display': 'Tochigi', 'is_type': false},
        { 'original': '根室標津', 'display': 'Nemuro Shibetsu', 'is_type': false},
        { 'original': '根雨', 'display': 'Neu', 'is_type': false},
        { 'original': '案　内', 'display': 'Information service', 'is_type': false},
        { 'original': '桐生', 'display': 'Kiryu', 'is_type': false},
        { 'original': '桑名', 'display': 'Kuwana', 'is_type': false},
        { 'original': '榛原', 'display': 'Haibara', 'is_type': false},
        { 'original': '横手', 'display': 'Yokote', 'is_type': false},
        { 'original': '横浜', 'display': 'Yokohama', 'is_type': false},
        { 'original': '横浜', 'display': 'Yokohama', 'is_type': false},
        { 'original': '横須賀', 'display': 'Yokosuka', 'is_type': false},
        { 'original': '横須賀', 'display': 'Yokosuka', 'is_type': false},
        { 'original': '武生', 'display': 'Takefu', 'is_type': false},
        { 'original': '武蔵野三鷹', 'display': 'Musashino Mitaka', 'is_type': false},
        { 'original': '武雄', 'display': 'Takeo', 'is_type': false},
        { 'original': '気仙沼', 'display': 'Kesennuma', 'is_type': false},
        { 'original': '水戸', 'display': 'Mito', 'is_type': false},
        { 'original': '水海道', 'display': 'Mizukaido', 'is_type': false},
        { 'original': '池田', 'display': 'Ikeda', 'is_type': false},
        { 'original': '沖　縄', 'display': 'Okinawa', 'is_type': false},
        { 'original': '沖縄宮古', 'display': 'Okinawa Miyako', 'is_type': false},
        { 'original': '沼津', 'display': 'Numazu', 'is_type': false},
        { 'original': '沼田', 'display': 'Numata', 'is_type': false},
        { 'original': '津', 'display': 'Tsu', 'is_type': false},
        { 'original': '津名', 'display': 'Tsuna', 'is_type': false},
        { 'original': '津山', 'display': 'Tsuyama', 'is_type': false},
        { 'original': '津島', 'display': 'Tsushima', 'is_type': false},
        { 'original': '洲本', 'display': 'Sumoto', 'is_type': false},
        { 'original': '浜松', 'display': 'Hamamatsu', 'is_type': false},
        { 'original': '浦和', 'display': 'Urawa', 'is_type': false},
        { 'original': '海士', 'display': 'Marine', 'is_type': false},
        { 'original': '渋川', 'display': 'Shibukawa', 'is_type': false},
        { 'original': '滋　賀', 'display': 'Shiga', 'is_type': false},
        { 'original': '瀬戸', 'display': 'Seto', 'is_type': false},
        { 'original': '瀬高', 'display': 'Setaka', 'is_type': false},
        { 'original': '熊　本', 'display': 'Kumamoto', 'is_type': false},
        { 'original': '熊　本', 'display': 'Kumamoto', 'is_type': false},
        { 'original': '熊本', 'display': 'Kumamoto', 'is_type': false},
        { 'original': '熊本一の宮', 'display': 'Kumamoto Ichinomiya', 'is_type': false},
        { 'original': '熊谷', 'display': 'Kumagaya', 'is_type': false},
        { 'original': '特　番', 'display': 'Special number', 'is_type': false},
        { 'original': '玖珠', 'display': 'Kusu', 'is_type': false},
        { 'original': '田主丸', 'display': 'Tanushimaru', 'is_type': false},
        { 'original': '田川', 'display': 'Tagawa', 'is_type': false},
        { 'original': '田辺', 'display': 'Tanabe', 'is_type': false},
        { 'original': '甲府', 'display': 'Kofu', 'is_type': false},
        { 'original': '留守番', 'display': 'Answering machine', 'is_type': false},
        { 'original': '留守番', 'display': 'Voice Msg', 'is_type': false},
        { 'original': '留守蓄', 'display': 'Answering service', 'is_type': false},
        { 'original': '留守蓄', 'display': 'Voice Msg', 'is_type': false},
        { 'original': '留萌', 'display': 'Rumoi', 'is_type': false},
        { 'original': '白河', 'display': 'Shirakawa', 'is_type': false},
        { 'original': '白石', 'display': 'Shiroishi', 'is_type': false},
        { 'original': '盛岡', 'display': 'Morioka', 'is_type': false},
        { 'original': '相模原', 'display': 'Sagamihara', 'is_type': false},
        { 'original': '真岡', 'display': 'Mooka', 'is_type': false},
        { 'original': '石　川', 'display': 'Ishikawa', 'is_type': false},
        { 'original': '石岡', 'display': 'Ishioka', 'is_type': false},
        { 'original': '石巻', 'display': 'Ishinomaki', 'is_type': false},
        { 'original': '石狩', 'display': 'Ishikari', 'is_type': false},
        { 'original': '磐城富岡', 'display': 'Iwaki Tomioka', 'is_type': false},
        { 'original': '磐田', 'display': 'Iwata', 'is_type': false},
        { 'original': '神奈川', 'display': 'Kanagawa', 'is_type': false},
        { 'original': '神戸', 'display': 'Kobe', 'is_type': false},
        { 'original': '福　井', 'display': 'Fukui', 'is_type': false},
        { 'original': '福　岡', 'display': 'Fukuoka', 'is_type': false},
        { 'original': '福　島', 'display': 'Fukushima', 'is_type': false},
        { 'original': '福井', 'display': 'Fukui', 'is_type': false},
        { 'original': '福山特例', 'display': 'Fukuyama', 'is_type': false},
        { 'original': '福岡', 'display': 'Fukuoka', 'is_type': false},
        { 'original': '福島', 'display': 'Fukushima', 'is_type': false},
        { 'original': '福野', 'display': 'Fukuno', 'is_type': false},
        { 'original': '秋　田', 'display': 'Akita', 'is_type': false},
        { 'original': '秋田', 'display': 'Akita', 'is_type': false},
        { 'original': '秩父', 'display': 'Chichibu', 'is_type': false},
        { 'original': '稚内', 'display': 'Wakkanai', 'is_type': false},
        { 'original': '種子島', 'display': 'Tanegashima', 'is_type': false},
        { 'original': '立川', 'display': 'Tachikawa', 'is_type': false},
        { 'original': '竹原', 'display': 'Takehara', 'is_type': false},
        { 'original': '竹田', 'display': 'Takeda', 'is_type': false},
        { 'original': '笠岡', 'display': 'Kasaoka', 'is_type': false},
        { 'original': '米子', 'display': 'Yonago', 'is_type': false},
        { 'original': '米沢', 'display': 'Yonezawa', 'is_type': false},
        { 'original': '米領バージン諸島', 'display': 'US Virgin Islands', 'is_type': false},
        { 'original': '網走', 'display': 'Abashiri', 'is_type': false},
        { 'original': '美幌', 'display': 'Bihoro', 'is_type': false},
        { 'original': '美濃加茂', 'display': 'Minokamo', 'is_type': false},
        { 'original': '群　馬', 'display': 'Gunma', 'is_type': false},
        { 'original': '羽咋', 'display': 'Hakui', 'is_type': false},
        { 'original': '能都', 'display': 'Noto', 'is_type': false},
        { 'original': '脇町', 'display': 'Wakimachi', 'is_type': false},
        { 'original': '船橋', 'display': 'Funabashi', 'is_type': false},
        { 'original': '花巻', 'display': 'Hanamaki', 'is_type': false},
        { 'original': '苫小牧', 'display': 'Tomakomai', 'is_type': false},
        { 'original': '茂原', 'display': 'Mobara', 'is_type': false},
        { 'original': '茨　城', 'display': 'Ibaraki', 'is_type': false},
        { 'original': '茨木', 'display': 'Ibaraki', 'is_type': false},
        { 'original': '草加', 'display': 'Soka', 'is_type': false},
        { 'original': '荘川', 'display': 'Shokawa', 'is_type': false},
        { 'original': '藤沢', 'display': 'Fujisawa', 'is_type': false},
        { 'original': '行橋', 'display': 'Gyohashi', 'is_type': false},
        { 'original': '西宮', 'display': 'Nishinomiya', 'is_type': false},
        { 'original': '西尾', 'display': 'Nishio', 'is_type': false},
        { 'original': '角館', 'display': 'Kakunodate', 'is_type': false},
        { 'original': '諏訪', 'display': 'Suwa', 'is_type': false},
        { 'original': '諫早', 'display': 'Isahaya', 'is_type': false},
        { 'original': '豊岡', 'display': 'Toyooka', 'is_type': false},
        { 'original': '豊橋', 'display': 'Toyohashi', 'is_type': false},
        { 'original': '豊田', 'display': 'Toyota', 'is_type': false},
        { 'original': '足利', 'display': 'Ashikaga', 'is_type': false},
        { 'original': '身延', 'display': 'Minobu', 'is_type': false},
        { 'original': '邑久', 'display': 'Oku', 'is_type': false},
        { 'original': '那覇', 'display': 'Naha', 'is_type': false},
        { 'original': '郡上八幡', 'display': 'Gujo Hachiman', 'is_type': false},
        { 'original': '郡家', 'display': 'Gunke', 'is_type': false},
        { 'original': '郡山', 'display': 'Koriyama', 'is_type': false},
        { 'original': '都城', 'display': 'Miyakonojo', 'is_type': false},
        { 'original': '金沢', 'display': 'Kanazawa', 'is_type': false},
        { 'original': '釧　路', 'display': 'Kushiro', 'is_type': false},
        { 'original': '釧路', 'display': 'Kushiro', 'is_type': false},
        { 'original': '銚子', 'display': 'Choshi', 'is_type': false},
        { 'original': '長　崎', 'display': 'Nagasaki', 'is_type': false},
        { 'original': '長　野', 'display': 'Nagano', 'is_type': false},
        { 'original': '長岡', 'display': 'Nagaoka', 'is_type': false},
        { 'original': '長崎', 'display': 'Nagasaki', 'is_type': false},
        { 'original': '長浜', 'display': 'Nagahama', 'is_type': false},
        { 'original': '長野', 'display': 'Nagano', 'is_type': false},
        { 'original': '長野原', 'display': 'Naganohara', 'is_type': false},
        { 'original': '防府', 'display': 'Hofu', 'is_type': false},
        { 'original': '阿児', 'display': 'Ago', 'is_type': false},
        { 'original': '阿波池田', 'display': 'Awa Ikeda', 'is_type': false},
        { 'original': '青　森', 'display': 'Aomori', 'is_type': false},
        { 'original': '青梅', 'display': 'Ome', 'is_type': false},
        { 'original': '青森', 'display': 'Aomori', 'is_type': false},
        { 'original': '静　岡', 'display': 'Shizuoka', 'is_type': false},
        { 'original': '静　岡', 'display': 'Shizuoka', 'is_type': false},
        { 'original': '静岡', 'display': 'Shizuoka', 'is_type': false},
        { 'original': '韮崎', 'display': 'Nirasaki', 'is_type': false},
        { 'original': '飯塚', 'display': 'Iizuka', 'is_type': false},
        { 'original': '飯山', 'display': 'Iiyama', 'is_type': false},
        { 'original': '飯田', 'display': 'Iida', 'is_type': false},
        { 'original': '飯能', 'display': 'Hanno', 'is_type': false},
        { 'original': '館山', 'display': 'Tateyama', 'is_type': false},
        { 'original': '香　川', 'display': 'Kagawa', 'is_type': false},
        { 'original': '香港', 'display': 'Hong Kong', 'is_type': false},
        { 'original': '高　知', 'display': 'Kochi', 'is_type': false},
        { 'original': '高山', 'display': 'Takayama', 'is_type': false},
        { 'original': '高岡', 'display': 'Takaoka', 'is_type': false},
        { 'original': '高崎', 'display': 'Takasaki', 'is_type': false},
        { 'original': '高松', 'display': 'Takamatsu', 'is_type': false},
        { 'original': '高知', 'display': 'Kochi', 'is_type': false},
        { 'original': '魚津', 'display': 'Uozu', 'is_type': false},
        { 'original': '鰍沢青柳', 'display': 'Kajikazawa Aoyagi', 'is_type': false},
        { 'original': '鰺ケ沢', 'display': 'Ajigasawa', 'is_type': false},
        { 'original': '鳥　取', 'display': 'Tottori', 'is_type': false},
        { 'original': '鳥取', 'display': 'Tottori', 'is_type': false},
        { 'original': '鳥羽', 'display': 'Toba', 'is_type': false},
        { 'original': '鴨川', 'display': 'Kamogawa', 'is_type': false},
        { 'original': '鴨方', 'display': 'Kamogata', 'is_type': false},
        { 'original': '鶴岡', 'display': 'Tsuruoka', 'is_type': false},
        { 'original': '鹿児島', 'display': 'Kagoshima', 'is_type': false},
        { 'original': '鹿屋', 'display': 'Kanoya', 'is_type': false},
        { 'original': '鹿沼', 'display': 'Kanuma', 'is_type': false},
        { 'original': '鹿角', 'display': 'Kazuno', 'is_type': false},
        { 'original': '黒磯', 'display': 'Kuroiso', 'is_type': false},
    ];

    public static IIJDisplayInfo(org_dest: string, org_type: string): any {
        const founds = this._IIJ_DESTINATIONS.filter(
            candidate => {
                return candidate.original === org_dest;
            }
        );
        const is_discount = org_type === 'KPD割引通話';
        if (founds !== null && founds.length > 0) {
            const found = founds[0];
            if (found.is_type) {
                return {
                    'destination': '',
                    'type': found.display,
                    'discount': is_discount
                };
            } else {
                return {
                    'destination': found.display,
                    'type': '',
                    'discount': is_discount
                };
            }
        }
        return {
            'destination': '',
            'type': '',
            'discount': is_discount
        };
    }
}
