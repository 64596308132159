<div class="app-password-change page">
  <div class="widget">
    <div class="title">Password Update</div>
    <div class="content">
      <form [formGroup]="passwordUpdateFG" (ngSubmit)="onClickSubmitWidget()" class="mui-form">
        <div class="row">
          <mat-form-field>
            <mat-label>New Password</mat-label>
            <input matInput type="password" 
            formControlName="password" 
              name="password"
              autocomplete="new-password">
              <mat-error *ngIf="passwordUpdateFG.controls['password'].hasError('required')">
                Required
              </mat-error>
              <mat-error *ngIf="passwordUpdateFG.controls['password'].hasError('hasNumber')">
                Must contain at least 1 number!
              </mat-error>
              <mat-error *ngIf="passwordUpdateFG.controls['password'].hasError('minlength')">
                Must be at least 8 characters! 
              </mat-error>
              <mat-error *ngIf="passwordUpdateFG.controls['password'].hasError('hasUpperCase')">
                Must contain at least 1 in capital case!
              </mat-error>
              <mat-error *ngIf="passwordUpdateFG.controls['password'].hasError('hasSmallCase')">
                Must contain at least 1 Letter in small case!
              </mat-error>
          </mat-form-field>
        </div>
        <div class="row">
          <mat-form-field>
            <mat-label>New Password confirmation</mat-label>
            <input matInput type="password"
            formControlName="confirmPassword"
              name="password_confirmation"
              autocomplete="new-password">
              <mat-error *ngIf="passwordUpdateFG.controls['confirmPassword'].hasError('NoPassswordMatch')">
                Confirm password does not match
              </mat-error> 
          </mat-form-field>
       
        </div>
       <div class="descriptions">
        <mat-hint >Requirements for password:</mat-hint>
        <mat-hint >* Have at least 8 characters.</mat-hint>
        <mat-hint >* Include both uppercase and lowercase characters.</mat-hint>
        <mat-hint >* Include a number.</mat-hint>
       </div>
        <div class="image-updatepassword">
          <img src="../../../../../assets/images/pw-update-illust.svg" alt="update password">
        </div>
        <button mat-flat-button class="btn-updatepassword" id="SubmitWidget" type="submit"
          [disabled]="!passwordUpdateFG.valid">Update Password</button>
      </form>
    </div>
  </div>
</div>
