import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appPreventCloseMenu]'
})
export class PreventCloseMenuDirective {

  constructor() { }
  @HostListener('click', ['$event'])
  public  onclick(event: any): void {
    event.stopPropagation();
  }

}
