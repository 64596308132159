import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ContractsService } from 'src/app/services/contracts.service';
import { Invoices } from 'src/app/types/invoice';
import { Utils } from 'src/app/utils';

@Component({
  selector: 'app-active-contracts',
  templateUrl: './active-contracts.component.html',
  styleUrls: ['./active-contracts.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ActiveContractsComponent implements OnInit {
  public contract_id_active: number;
  public contract_code_active: string;
  public subscription_plan_active: string;
  public start_day_active: Date;
  public end_day_active: Date;
  public pay_status_active: boolean;
  public invoices_Active: Invoices;
  public title_icon: string;
  public invoiceList_of_contract$: Invoices;
  constructor(private _router: Router, private _contractService: ContractsService) { }

  @Input() contractAc;
  @Input() invoicesList;

  ngOnInit() {
  this.renderActiveContract();
  }
  public getContractDetailActive(contract_id: number) {
    const pay_method = this.contractAc.properties.find(
      item => item.type.key === 'PAY METHOD'
    );
    this._contractService.set_Pay_Method = pay_method === undefined ? null : pay_method.property.value.toLowerCase();

    // set active invoice list  as Observables
    this._contractService.set_invoice_list = this._contractService.findArr_Invocies(this.invoicesList, this.contractAc);
    this._contractService.set_contract = this.contractAc; // set contract as Observables
    //  =======================
    // this._contractService.getListProduct(contract_id);
    this._router.navigate([`portal/contract-details/${contract_id}`]);
  }
  public PaymentHistory() {
    //  set invoice list active to Subject
    this._contractService.set_invoice_list = this._contractService.findArr_Invocies(this.invoicesList, this.contractAc);
    this._contractService.set_contract = this.contractAc; // set contract as $
    // END  =======================
    this._router.navigate([`portal/payment-history/${this.contract_id_active}`]);
  }
  private renderActiveContract() {
    // Find invoice follow contract
    this.invoices_Active = this._contractService.findArr_Invocies(this.invoicesList, this.contractAc);
    this.pay_status_active = this.invoices_Active.every(invoice => invoice.pay_status === 1) ? true : false;
    this.contract_id_active = this.contractAc.id;
    this.contract_code_active = this.contractAc.contract_code;
    const valueOfSUB_PLAN = this.contractAc.properties.find(
      item => item.type.key === 'SUBSCRIPTION PLAN'
    );
    this.subscription_plan_active = valueOfSUB_PLAN === undefined ? null : valueOfSUB_PLAN.property.value;
    this.title_icon = this._contractService.check_Product_Type(this.subscription_plan_active) === 'wifi' ? 'wifi' : 'sim_card';
    this.start_day_active = this.contractAc.start_at;
    this.end_day_active = this.contractAc.end_at;
  }
}
