import { Injectable, NgZone } from '@angular/core';
import { V1UserAPIService } from './v1api.service';
import { Router } from '@angular/router';
import { Constants } from '../constants';

@Injectable({
  providedIn: 'root'
})

export class AutoLogoutService {

  constructor(private _userService: V1UserAPIService,
    private ngZone: NgZone,
    private router: Router
    ) {
      this.check();
      this.initListerner();
      this.initInterval();
    }
    get getLastAction() {
     return Number(localStorage.getItem(Constants.STORE_KEY));
    }
    set setLastAction(value: number) {
       localStorage.setItem(Constants.STORE_KEY, value.toString());
    }
    initListerner() {
      this.ngZone.runOutsideAngular(
        () => {
          document.body.addEventListener('click', () => this.reset());
        }
      );
    }

    initInterval() {
      this.ngZone.runOutsideAngular(
        () => {
          setInterval( () => {
            this.check();
          }, Constants.TIME_CHECK_INTERVAL);
        }
      );
    }
    check() {
      const now = Date.now();
      const timeleft = this.getLastAction + Constants.MINUTES_UNTIL_AUTO_LOGOUT * 60 * 1000;
      const diff = timeleft - now;
      const isTimeout = diff < 0;
      this.ngZone.run(
        () => {
          if (isTimeout && this._userService.isLoggingIn()) {
          console.log(`You were automatically logged out after ${Constants.MINUTES_UNTIL_AUTO_LOGOUT} minutes of inactivity.`);
          this._userService.logout();
          // this.router.navigate(['auth/login']);  // T.M. is this needed?
          }
        }
      );
    }

    reset() {
      this.setLastAction = Date.now();
    }
}
