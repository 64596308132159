import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { EmailErrorStateMatcher } from 'src/app/error_state_matchers';
import { V1UserAPIService } from 'src/app/services/v1api.service';

@Component({
  selector: 'app-confirmation-resend',
  templateUrl: './confirmation-resend.component.html',
  styleUrls: ['./confirmation-resend.component.scss']
})
export class ConfirmationResendComponent implements OnInit {

  // input data
  public userId: string;

  // form & validation
  public emailFormControl: FormControl;
  public emailErrorMatcher: EmailErrorStateMatcher;

  // control states
  public submitDisabled: boolean;

  constructor(
    private _api: V1UserAPIService,
    private _router: Router,
  ) {
    this.emailFormControl = new FormControl('', [
      Validators.required,
      Validators.email,
    ]);
    this.emailErrorMatcher = new EmailErrorStateMatcher();
    this.submitDisabled = true;
  }

  ngOnInit() {
    // START : ===== common implementation for shoud not login components =====
    if (this._api.isLoggingIn(true) === true) {
      this._router.navigate(['/portal']);
    }
    // END : ===== common implementation for shoud not login components =====
  }

  private enableSubmit() {
    this.submitDisabled = false;
    if (this.emailFormControl.hasError('email')) {
      this.submitDisabled = true;
      return;
    }
    if (this.emailFormControl.hasError('required')) {
      this.submitDisabled = true;
      return;
    }
  }

  public onEmailChanged(event) {
    this.userId = this.emailFormControl.value;
    this.enableSubmit();
  }

  public onClickSubmitWidget(event) {
    console.log('ConfirmationResendComponent:onClickSubmitWidget - ' + this.userId);
    this._api.desiredUrl = '/auth/login';
    this._api.confirmationResend(this.userId);
  }
}
