import {
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
  FormArray,
  FormControl,
  Validators,
  FormGroup,
} from '@angular/forms';

export class CustomValidators {
  static patternValidator(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        // if control is empty return no error
        return null;
      }
      // test the value of the control against the regexp supplied
      const valid = regex.test(control.value);
      // if true, return no error (no error), else return error passed in the second parameter
      return valid ? null : error;
    };
  }

  static passwordMatchValidator(control: AbstractControl) {
    const password: string = control.get('password').value; // get password from our password form control
    const confirmPassword: string = control.get('confirmPassword').value; // get password from our confirmPassword form control
    // compare is the password math
    if (password !== confirmPassword) {
      // if they don't match, set an error in our confirmPassword form control
      control.get('confirmPassword').setErrors({ NoPassswordMatch: true });
    }
  }
  public static minLength(min: number): ValidatorFn | any {
    return (control: AbstractControl[]) => {
      if (!(control instanceof FormArray)) {
        return;
      }
      return control.length < min ? { minLength: true } : null;
    };
  }
  static checkKatakana(regex: RegExp, error: ValidationErrors): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        // if control is empty return no error
        return null;
      }
      const value = control.value;
      // test the value of the control against the regexp supplied
      const valid = regex.test(value);
      // if true, return no error (no error), else return error passed in the second parameter
      return valid ? null : error;
    };
  }
  public static checkCardExpired(): ValidatorFn {
    return (form: FormGroup): ValidationErrors | null => {
      const expire_month = form.get('expire_month').value;
      const expire_year = form.get('expire_year').value;
      if (!expire_month || !expire_year) {
        return null;
      }
      const date = new Date();
      const current_month = date.getMonth();
      const current_year = date.getFullYear();
      const yy_pre_digit = current_year.toString().substring(0, 2);
      const month_year = new Date(current_year, current_month);
      if (expire_year !== null && expire_month !== null) {
        const number_to_string = yy_pre_digit + expire_year;
        const expire_month_year_of_card = new Date(
          Number(number_to_string),
          expire_month - 1
        );
        const isCardExpire =
          expire_month_year_of_card.getTime() - month_year.getTime() > 0;
        if (!isCardExpire) {
          form.get('expire_month').setErrors({ monthExpired: true });
          form.get('expire_year').setErrors({ yearExpired: true });
          return { cardExpired: true };
        } else {
          form.get('expire_month').setErrors(null);
          form.get('expire_year').setErrors(null);
          return null;
        }
      }
    };
  }
}
