import { Component, OnInit, OnDestroy, ElementRef } from '@angular/core';
import { Router, ActivatedRoute, ParamMap, UrlSegment } from '@angular/router';
import {
  MatSnackBar, MatSnackBarConfig, MatTableDataSource, MatDialog, MatDialogRef, MAT_DIALOG_DATA
} from '@angular/material';
import { DomSanitizer } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { Utils } from 'src/app/utils';
import { Constants } from 'src/app/constants';
import { V1UserAPIService } from 'src/app/services/v1api.service';
import { ShareDataService } from 'src/app/services/share-data.service';
import { User } from 'src/app/types/model-types';
import {
  TwoButtonDialogComponent, TwoButtonDialogData, DialogButton
} from 'src/app/components/shared/two-button-dialog/two-button-dialog.component';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, OnDestroy {

  // content control
  private _userDetailModified: boolean;

  // service
  private _shareDataSubscription: Subscription;
  private _local_share_data: Object;

  // list and data
  public users: User[];
  public usersDataSrc: MatTableDataSource<User>;
  public displayColumns: string[] = [
    'account_id',
    'email',
    'full_name',
  ];
  public selectingUserId: number;
  public selectingUser: User;
  public currentUserFilter: string;
  protected _el: HTMLElement;

  // url analyze
  private _urlMap: UrlSegment[];
  private _paramMap: ParamMap;

  // initialize control
  private _content_initialized: boolean;

  constructor(
    private _api: V1UserAPIService,
    private _router: Router,
    private _snackBar: MatSnackBar,
    private _elRef: ElementRef,
    private _activatedRoute: ActivatedRoute,
    private _shareData: ShareDataService,
    public dialog: MatDialog,
    private _sanitizer: DomSanitizer,
  ) {
    this.usersDataSrc = new MatTableDataSource<User>();
    this.currentUserFilter = '';
    this._el = _elRef.nativeElement;
    this._content_initialized = false;
    this._userDetailModified = false;
  }

  ngOnInit() {
    // share data service
    this._shareDataSubscription = this._shareData.data$.subscribe(
      data => {
        console.log('data refresh : ' + this.selectingUserId);
        this._local_share_data = data;
      }
    );
    // START : ===== common implementation for login required components =====
    if (this._api.isLoggingIn(true) === false) {
      this._router.navigate(['/auth/login']);
    }
    // END : ===== common implementation for login required components =====
    // START : ===== common implementation for login required admin role =====
    this._api.has_role('admin').subscribe(
      response => {
        // nothing to do
      },
      error => {
        const self = this;
        setTimeout(function () {
          self._snackBar.dismiss();
          self._snackBar.open(
            'Moved to the "Home" because no access rights.',
            'OK',
            Constants.defaultSnackBarConfig('warning'));
        }, 100);
        this._router.navigate(['']);
      }
    );
    // END : ===== common implementation for login required admin role =====
    this._activatedRoute.url.subscribe(
      urlSegments => {
        console.log('UserComponent::ngOnInit() : urlSegments' + JSON.stringify(urlSegments));
        this._urlMap = urlSegments;
        this.refresh();
      }
    );
    this._activatedRoute.queryParamMap.subscribe(
      paramMap => {
        console.log('UserComponent::ngOnInit() : paramMap' + JSON.stringify(paramMap));
        this._paramMap = paramMap;
        this.selectingUserId = parseInt(this._paramMap.get('user_id'), 10);
        console.log('UserComponent::ngOnInit() : selectingUserId=' + this.selectingUserId);
        if (Utils.isValue(this.selectingUserId)) {
          this.refresh();
        }
      }
    );
    this.refresh();
  }

  ngOnDestroy() {
    // unsubscribe service
    this._shareDataSubscription.unsubscribe();
  }

  private refresh() {
    this._api.get(Constants.API_LIST_USERS, null).subscribe(
      response => {
        this.users = response.data;
        this.usersDataSrc = new MatTableDataSource(this.users);
        this._shareData.setAt('admin/users/user:users', this.users);
        this.selectRowByUser(this.selectingUserId);
      },
      error => {
        this._snackBar.dismiss();
        this._snackBar.open(
          Utils.getDisplayErrorMessage(error, 'Failed to get users list'),
          'OK',
          Constants.defaultSnackBarConfig('warning'));
      }
    );
  }

  private selectRowByUser(user_id: number, navigate: boolean = true): void {
    if (Utils.isValue(user_id) !== true) {
      return;
    }
    const users = this.users.filter(user => {
      return user.id === user_id;
    });
    if (users.length < 1) {
      return;
    }
    this.selectingUser = users[0];
    this._shareData.setAt('admin/users/user:selecting_user', this.selectingUser);
    const elTable = this._el.querySelector('div.app-users div.user-list table tbody');
    if (Utils.isValue(elTable)) {
      const elRows = elTable.querySelectorAll('tr');
      if (Utils.isValue(elRows)) {
        elRows.forEach(elRow => {
          // judge
          const elTd = <HTMLTableCellElement>elRow.querySelector('td.cdk-column-' + 'account_id');
          if (Utils.isValue(elTd)) {
            if (user_id === parseInt(elTd.innerText, 10)) {
              console.log('selected : ' + user_id + ' : ' + elTd.innerText);
              elRow.classList.add('selected');
            } else {
              console.log('removed : ' + user_id + ' : ' + elTd.innerText);
              elRow.classList.remove('selected');
            }
          }
        });
      }
    }
  }

  public onClickRow(row: User): void {
    // TODO : check user-detail modified
    if (this._userDetailModified) {
      // dialog data
      const dialogData = new TwoButtonDialogData(this._sanitizer);
      dialogData.message =
        'If you select another user, <span style="color: red">your changes will be discarded.</span>';
      dialogData.buttons.push(new DialogButton('Cancel', [], 'cancel'));
      dialogData.buttons.push(new DialogButton('OK', [], 'ok'));
      // dialog
      const dialogRef = this.dialog.open(
        TwoButtonDialogComponent,
        {
          data: dialogData
        }
      );
      dialogRef.afterClosed().subscribe(result => {
        if (result.id === 'ok') {
          this.selectRowByUser(row.id);
        }
      });
    } else {
      this.selectRowByUser(row.id);
    }
  }

  public get hasSelectingUser(): boolean {
    return Utils.isValue(this.selectingUserId);
  }

  public get selectingUserName(): string {
    if (this.hasSelectingUser && this.selectingUser.full_name !== null) {
      return this.selectingUser.full_name;
    }
    return '';
  }

  public onFilterKeyUp(event: any): void {
    console.log(this.currentUserFilter);
    this.usersDataSrc.filter = this.currentUserFilter;
  }

  public onModifiedContent(event: any): void {
    this._userDetailModified = event.modified;
    console.log(event);
  }
}
