<div class="policy">
  <div class="policy__widget">
    <app-menu [back_button]="back_button" [title]="'Attention'"></app-menu>
    <div class="policy__widget_contents">
      <div class="content-policy">
        <div class="title">
          <p>Attention</p>
        </div>
        <p class="info">
          This contract can only be cancelled
          <span class="policy-date"
            >from {{ name_month_product }} {{ year_product }}</span
          >
          according to our minimum usage policy.
        </p>
      </div>
      <div class="popup-policy" (click)="openDialog()">
        <mat-icon> help </mat-icon>
        <p class="popup-link">What is the cancellation policy ?</p>
      </div>
      <button
        class="button-process"
        mat-button
        [routerLink]="['/termination-selection']"
      >
        Proceed To Cancellation
      </button>
    </div>
  </div>
</div>
