<div class="topup--checkout">
  <div class="widget">
    <app-menu [title]="'Top Up'" [back_button]="true"></app-menu>
    <div class="widget__contents">
      <div class="widget__contents__checkout">
        <div class="widget__contents__checkout__title">
          <mat-icon class="icon--offer">local_offer</mat-icon>
          <span class="checkout--title">Check-out</span>
        </div>
        <div class="widget__contents__checkout__content">
          <table>
            <tr>
              <td class="tb-title">Data</td>
              <td class="tb-amount">
                <span>{{ topUpData.data }}</span> GB
              </td>
            </tr>
            <tr>
              <td class="tb-title">Sub-total</td>
              <td class="tb-amount">
                <span>{{ topUpData.subtotal | number }}</span> JPY
              </td>
            </tr>
            <tr>
              <td class="tb-vat">VAT 10%:</td>
              <td class="tb-vat">{{ topUpData.vat | number }} JPY</td>
            </tr>
            <tr>
              <td colspan="2">
                <mat-divider class="divider--item"></mat-divider>
              </td>
            </tr>
            <tr>
              <td class="tb-title">Total</td>
              <td class="tb-amount">
                <span>{{ topUpData.total | number }}</span> JPY
              </td>
            </tr>
          </table>
        </div>
      </div>

      <form [formGroup]="cardInformation" class="widget__contents__form">
        <div class="widget__contents__form--card-holder-name">
          <label class="label-card">Name on Card</label>
          <mat-form-field class="form-field">
            <input matInput class="input" formControlName="card_holder_name" [value]="cardName"
              (blur)="makeCapital($event)" required />
            <mat-error *ngIf="card_holder_name.hasError('required')">Required</mat-error>
          </mat-form-field>
        </div>

        <div class="widget__contents__form--card-number">
          <label class="label-card">Card Number</label>
          <mat-form-field class="form-field">
            <input matInput formControlName="card_number" [value]="cardNumber" (keyup)="cardNumberChange($event)"
              required />
            <mat-error *ngIf="
                card_number.hasError('pattern') &&
                !card_number.hasError('required')
              ">Number only</mat-error>
            <mat-error *ngIf="card_number.hasError('required')">Required</mat-error>
            <mat-error *ngIf="
              card_number.hasError('minlength') &&
              !card_number.hasError('required') &&
              !card_number.hasError('pattern')
          ">Min length 15 number</mat-error>
            <mat-error *ngIf="
              card_number.hasError('maxlength') &&
              !card_number.hasError('required') &&
              !card_number.hasError('pattern')
              ">Max length 16 number</mat-error>
          </mat-form-field>
        </div>
        <div class="image--card">
          <img src="../../../../../assets/images/credit-cards.png" class="icon-card" />
        </div>

        <div class="widget__contents__form--expire-date">
          <label class="label-card">Expiry Date</label>
          <mat-form-field class="form-field time-input" floatLabel="never" hideRequiredMarker>
            <input matInput formControlName="expire_month" placeholder="MM" [value]="expireMonth"
              (blur)="onBlurExpireMonth($event)" required />
            <mat-error *ngIf="
                expire_month.hasError('pattern') &&
                !expire_month.hasError('required')
              ">Number only</mat-error>
            <mat-error *ngIf="expire_month.hasError('required')">Required</mat-error>

            <mat-error *ngIf="
                expire_month.hasError('maxlength') &&
                !expire_month.hasError('required') &&
                !expire_month.hasError('pattern')
              ">Max length 2 number</mat-error>
            <mat-error *ngIf="
                !expire_month.hasError('required') &&
                !expire_month.hasError('pattern') &&
                cardInformation.hasError('cardExpired')
              ">Invalid date</mat-error>
          </mat-form-field>
          <mat-form-field class="form-field time-input" floatLabel="never" hideRequiredMarker>
            <input matInput formControlName="expire_year" placeholder="YY" [value]="expireYear"
              (blur)="onBlurExpireYear($event)" required />
            <mat-error *ngIf="
                expire_year.hasError('pattern') &&
                !expire_year.hasError('required')
              ">Number only</mat-error>
            <mat-error *ngIf="expire_year.hasError('required')">Required</mat-error>

            <mat-error *ngIf="
                expire_year.hasError('maxlength') &&
                !expire_year.hasError('required') &&
                !expire_year.hasError('pattern')
              ">Max length 2 number</mat-error>
            <mat-error *ngIf="
                !expire_year.hasError('required') &&
                !expire_year.hasError('pattern') &&
                cardInformation.hasError('cardExpired')
              ">Invalid date</mat-error>
          </mat-form-field>
        </div>

        <div class="widget__contents__form--cvv">
          <label class="label-card cvv-label">CVV</label>
          <mat-form-field class="form-field time-input" floatLabel="never" hideRequiredMarker>
            <input matInput formControlName="card_cvv" placeholder="123" required />
            <mat-error *ngIf="
                card_cvv.hasError('pattern') && !card_cvv.hasError('required')
              ">Number only</mat-error>
            <mat-error *ngIf="
                card_cvv.hasError('required') &&
                !card_cvv.hasError('maxlength') &&
                !card_cvv.hasError('pattern')
              ">Required</mat-error>
            <mat-error *ngIf="
            card_cvv.hasError('minlength') &&
            !card_cvv.hasError('required') &&
            !card_cvv.hasError('pattern')
          ">Min length 3 number</mat-error>
            <mat-error *ngIf="
              card_cvv.hasError('maxlength') &&
              !card_cvv.hasError('required') &&
              !card_cvv.hasError('pattern')
            ">Max length 4 number</mat-error>
          </mat-form-field>
        </div>
      </form>
      <div class="widget__contents__checkbox">
        <div class="term">
          <mat-icon class="term-check" (click)="toggleChecked()">{{
            mat_content
            }}</mat-icon>
          <span class="term-content">I agree with this payment and would like to proceed</span>
        </div>
      </div>
      <button mat-button class="widget__contents__topup--button"
        [ngClass]="{'submitted--button': checked && cardInformation.valid}"
        [disabled]="!(checked && cardInformation.valid)" [class.disabled]="spinner$ |async"
        (click)="checkOutTopUp()">Top Up<app-spinner></app-spinner></button>
    </div>
  </div>
</div>
