import {
  Component,
  OnInit,
  ViewEncapsulation,
  ElementRef,
  Renderer2,
  ViewChild,
  EventEmitter,
  Output,
  Input,
  AfterViewInit,
} from '@angular/core';
import { FormGroup, FormControl, FormArray, Validators } from '@angular/forms';
import { StarRating } from 'src/app/types/rating';

@Component({
  selector: 'app-reasons-cancel',
  templateUrl: './reasons-cancel.component.html',
  styleUrls: ['./reasons-cancel.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ReasonsCancelComponent implements OnInit {
  // tslint:disable-next-line:max-line-length
  public providers = ['docomo', 'Softbank', 'au', 'LINE MOBILE', 'Rakuten Mobile', 'UQ mobile', 'Y! mobile', 'IIJ mio', 'BIGLOBE', 'mobal', 'JP Smart SIM', 'GTN MOBILE', 'Other'];
  public value1 = 'go_back_to_home_country';
  public value2 = 'no_longer_use_cellphone_or_wifi';
  public value3 = 'moving_to_another_service_provider';
  public showAnotherReason = false;
  @Input() cancelReasons_Case2: FormGroup;
  @Output() toggleRating = new EventEmitter<boolean>();
  @Output() valueReason = new EventEmitter<string>();

  @ViewChild('thamso1', { static: true }) choose1: ElementRef;
  @ViewChild('thamso2', { static: true }) choose2: ElementRef;
  @ViewChild('thamso3', { static: true }) choose3: ElementRef;


  active1 = false;
  active2 = false;
  active3 = false;
  active4 = false;
  active5 = false;
  togglebutton = false;
  selectedProvider: string;
  isOther = true;
  constructor(private render: Renderer2) {}

  ngOnInit() {}
  serviceProviderChange (event: any) {
    this.selectedProvider = event.value;
    if (event.value !== 'Other') {
      this.cancelReasons_Case2.get('move_to_service_provider').setValidators(Validators.required);
      this.cancelReasons_Case2.get('move_to_service_provider').updateValueAndValidity();
      this.cancelReasons_Case2.patchValue({
        move_to_service_provider: event.value
      });
    } else {
      this.cancelReasons_Case2.get('move_to_service_provider').clearValidators();
      this.cancelReasons_Case2.get('move_to_service_provider').updateValueAndValidity();
      this.cancelReasons_Case2.patchValue({
        move_to_service_provider: ''
      });
  }
  }
  onRatingChanged(ratingObj: StarRating) {
    this.cancelReasons_Case2.patchValue({
      rate_price: ratingObj.rate_price.toString(),
      rate_internet_connection: ratingObj.rate_internet_connection.toString(),
      rate_customer_support: ratingObj.rate_customer_support.toString(),
    });
  }
  reasonCancellSelect(option: any) {
    switch (option.value) {
      case this.value1:
        this.reset_when_reselect();
        this.valueReason.emit(this.value1);
        this.render.removeClass(this.choose2.nativeElement, 'choose');
        this.render.removeClass(this.choose3.nativeElement, 'choose');
        break;
      case this.value2:
        this.reset_when_reselect();
        this.valueReason.emit(this.value2);
        this.render.removeClass(this.choose1.nativeElement, 'choose');
        this.render.removeClass(this.choose3.nativeElement, 'choose');
        break;
      case this.value3:
        this.showAnotherReason = true;
        this.toggleRating.emit(this.showAnotherReason);
        this.render.removeClass(this.choose1.nativeElement, 'choose');
        this.render.removeClass(this.choose2.nativeElement, 'choose');
        this.valueReason.emit(this.value3);
        break;
    }
  }
  toggleButton() {
    this.togglebutton = !this.togglebutton;
    if (this.togglebutton === true) {
      this.setValidationFormReasons();
      this.cancelReasons_Case2.patchValue({
        reasons_for_changing_provider_case: 'other',
        reasons_for_changing_provider_other: ''
      });
    } else {
      this.setValidationFormArray();
      this.cancelReasons_Case2.patchValue({
        reasons_for_changing_provider_case: 'default',
        reasons_for_changing_provider_other: ''
      });
    }
  }
  reasonChangeProvider(valueItem: any) {
    switch (valueItem.value) {
      case 'Monthly fee is too high':
        this.active1 = !this.active1;
        if (this.active1 === true) {
          this.reasonsFormArr.push(new FormControl(valueItem.value));
        } else {
          this.deleteReasonInArray(valueItem);
        }
        break;
      case 'Service area is poor':
        this.active2 = !this.active2;
        if (this.active2 === true) {
          this.reasonsFormArr.push(new FormControl(valueItem.value));
        } else {
          this.deleteReasonInArray(valueItem);
        }
        break;
      case 'Internet is too slow':
        this.active3 = !this.active3;
        if (this.active3 === true) {
          this.reasonsFormArr.push(new FormControl(valueItem.value));
        } else {
          this.deleteReasonInArray(valueItem);
        }
        break;
      case 'I am using a family plan':
        this.active4 = !this.active4;
        if (this.active4 === true) {
          this.reasonsFormArr.push(new FormControl(valueItem.value));
        } else {
          this.deleteReasonInArray(valueItem);
        }
        break;
      case 'Poor customer service':
        this.active5 = !this.active5;
        if (this.active5 === true) {
          this.reasonsFormArr.push(new FormControl(valueItem.value));
        } else {
          this.deleteReasonInArray(valueItem);
        }
        break;
    }
  }
  get reasonsFormArr() {
    return this.cancelReasons_Case2.get(
      'reasons_for_changing_provider'
    ) as FormArray;
  }
  private deleteReasonInArray (valueItem: any): void {
    const reasonArr = <string[]>this.reasonsFormArr.value;
    this.reasonsFormArr.removeAt(reasonArr.findIndex(value => value === valueItem.value));
  }
  private setValidationFormArray (): void {
    this.reasonsFormArr.setValidators(Validators.required);
    this.reasonsFormArr.updateValueAndValidity();
    this.cancelReasons_Case2.get('reasons_for_changing_provider_other').clearValidators();
    this.cancelReasons_Case2.get('reasons_for_changing_provider_other').updateValueAndValidity();
  }
  private setValidationFormReasons (): void {
    this.reasonsFormArr.clearValidators();
    this.reasonsFormArr.updateValueAndValidity();
    this.cancelReasons_Case2.get('reasons_for_changing_provider_other').setValidators(Validators.required);
    this.cancelReasons_Case2.get('reasons_for_changing_provider_other').updateValueAndValidity();
  }
  private reset_when_reselect(): void {
    this.showAnotherReason = false;
    this.togglebutton = false;
    this.active1 = false;
    this.active2 = false;
    this.active3 = false;
    this.active4 = false;
    this.active5 = false;
    this.setValidationFormArray();
    this.toggleRating.emit(this.showAnotherReason);
  }
}
