import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-term-conditions-mnp',
  templateUrl: './term-conditions-mnp.component.html',
  styleUrls: ['./term-conditions-mnp.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TermConditionsMnpComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
