<div class="modal">
  <h3 class="modal_close" (click)="modalClosing()">×</h3>
  <div class="modal_container">
    <img src="../../../assets/images/Warning-icon.svg" alt="Warning-icon" class="images_warning"/>
      <p class="modal_label_title">
        We are very sorry to inform you that the deadline to change your payment method is the 25th of every month. 
        Therefore, we are unable to process your request at the moment. 
        The application to change your payment method will be available again on the 1st of the following month. 
        Please kindly try to submit your application again after the 1st next month.
      </p>
    <form class="modal_form_conatiner">
    </form>
  </div>
</div>

