import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, MinLengthValidator, FormGroup, FormBuilder } from '@angular/forms';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { V1UserAPIService } from 'src/app/services/v1api.service';
import { CustomValidators } from 'src/app/custom-validators';

@Component({
  selector: 'app-password-change',
  templateUrl: './password-change.component.html',
  styleUrls: ['./password-change.component.scss']
})
export class PasswordChangeComponent implements OnInit {

  // form & validation
  public passwordUpdateFG: FormGroup;
  // other
  private _paramMap: ParamMap;

  constructor(
    private _api: V1UserAPIService,
    private _router: Router,
    private _activatedRoute: ActivatedRoute,
    private fb: FormBuilder
  ) {}

  ngOnInit() {
    this.passwordUpdateFG = this.createPasswordUpdateForm();
    // START : ===== common implementation for shoud not login components =====
    if (this._api.isLoggingIn(true) === true) {
      this._router.navigate(['/portal']);
    }
    // END : ===== common implementation for shoud not login components =====
    this._activatedRoute.queryParamMap.subscribe(
      paramMap => {
        console.log('PasswordChangeComponent::ngOnInit : paramMap => ' + JSON.stringify(paramMap));
        this._paramMap = paramMap;
      }
    );
  }
  public createPasswordUpdateForm(): FormGroup {
    return this.fb.group(
      {
        password: [ null, Validators.compose([
            Validators.required,
           // 2. check whether the entered password has a number
           CustomValidators.patternValidator(/\d/, { hasNumber: true }),
           // 3. check whether the entered password has upper case letter
           CustomValidators.patternValidator(/[A-Z]/, { hasUpperCase: true }),
           // 4. check whether the entered password has a lower-case letter
           CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
           // 5. Has a minimum length of 8 characters
           Validators.minLength(8)])
        ],
        confirmPassword: [null, Validators.compose([Validators.required])]
     },
     {
        // check whether our password and confirm password match
        validator: CustomValidators.passwordMatchValidator
     });
  }
  public onClickSubmitWidget() {
    console.log('PasswordChangeComponent::onClickSubmitWidget : paramMap=' + JSON.stringify(this._paramMap));
    this._api.passwordUpdate(this._paramMap.get('token'),
    this.passwordUpdateFG.value.password, this.passwordUpdateFG.value.confirmPassword);
  }
}
