
<div class="account__information">
  <div class="widget">
    <app-menu [back_button]="true" [title]="'Account'"></app-menu>
    <div class="widget__contents">
      <div class="widget__contents--personal-detail">
        <mat-icon class="icon">person</mat-icon>
        <p class="details">Personal Details</p>
      </div>

      <div class="widget__contents--personal-informations" *ngIf="account_information$ |async as account">
        <p class="information-left">Name</p>
        <p class="information-right">{{account.user_full_name}}</p>
        <p class="information-left">Email Address</p>
        <p class="information-right">{{account.email}}</p>
      </div>
      <div class="widget__contents--personal-detail">
        <img src="../../../../assets/images/logo-payment.svg" alt="logo payments" class="image">
        <p class="details">Payment Details</p>
        <mat-icon class="icon-create" routerLink="/personal-detail">create</mat-icon>
      </div>
      <!-- Credit Card Information -->
      <ng-container *ngIf="!(isSmartpit$ | async)">
      <div class="widget__contents--personal-informations" *ngIf="account_payment_info$ |async as account_info">
        <p class="information-left">Card Holder's Name</p>
        <p class="information-right">{{account_info.holder_name | uppercase}}</p>
        <p class="information-left">Payment Method</p>
        <p class="information-right">{{account_info.payment_method |titlecase}}</p>
        <p class="information-left">Number</p>
        <p class="information-right">{{account_info.card_no}}</p>
        <p class="information-left">Expiry Date</p>
        <p class="information-right">{{expire_date$ |async}}</p>
      </div>
    </ng-container>

      <!-- SmartPit Information -->
      <div class="widget__contents--smartpit-information" *ngIf="isSmartpit$ | async">
        <div class="title--smartpit">
          <img src="../../../../assets/images/smartpit-icon.png" alt="smartpit icon" class="smartpit-icon">
          <div class="title--number">
            <p class="title">スマートピット番号・Smart Pit No.</p>
            <p class="number-card">{{smartpitNumber$ |async}}</p>
          </div>
        </div>

        <div class="information--smartpit">
          <p class="title-information">※注意事項:</p>
          <p class="information">お支払い後の料金はコンビニでは返金いたしません。 本媒体を利用した取扱い等に関しては、当社までお問い合わせください。</p>
          <p class="title-information">Attention:</p>
          <p class="information">Payments are non-refundable. Please contact us if you have any questions using this service.</p>
        </div>

        <div class="user--guide">
          <p class="guide-title">取扱店(コンビニ): </p>
          <p class="guide-info">お支払い可能なコンビニは<span class="guide-title">ローソン・ミニストップ・ファミリーマート</span>です。</p>
          <p class="guide-info">Payable at <span class="guide-title">Lawson, MiniStop</span>, and <span class="guide-title">FamilyMart</span> convenience stores in Japan.</p>
          <p class="guide-title">【お支払い方法・Payment instructions】</p>
        </div>

        <div class="mechine--guide">
          <table>
            <tr>
              <td>
                <p class="guide-title">ローソン ミニストップ</p>
                <p class="guide-title">Lawson MiniStop</p>
              </td>
              <td>
                <p class="guide-info">お客様が店頭の端末機Loppiを操作後、 レジにてお支払いください。</p>
                <p class="guide-info">You can print out the payment receipt using the Loppi machine and make the payment at the cashier.</p>
              </td>
            </tr>
            <tr>
              <td>
                <p class="guide-title">ローソン ミニストップ</p>
                <p class="guide-title">Lawson MiniStop</p>
              </td>
              <td>
                <p class="guide-info">お客様が店頭の端末機Loppiを操作後、 レジにてお支払いください。</p>
                <p class="guide-info">You can print out the payment receipt using the Loppi machine and make the payment at the cashier.</p>
              </td>
            </tr>
          </table>
        </div>

        <div class="small--information">
          <p>いずれの場合も請求内容、金額を確認の上、お支払いください。 Please kindly confirm the payment details before paying.</p>
        </div>

        <div class="account--popup-policy">
          <mat-icon class="popup-icon">
            help
          </mat-icon>
          <p class="popup-link"><a href="https://sakuramobile.zendesk.com/hc/en-us/articles/360020601372-How-to-use-Smart-Pit-at-convenience-store-LAWSON-MINI-STOP-" target="_blank">How to use the Loppi machine?</a></p>
        </div>
        <div class="account--popup-policy">
          <mat-icon class="popup-icon">
            help
          </mat-icon>
          <p class="popup-link"><a href="https://sakuramobile.zendesk.com/hc/en-us/articles/360020854911-How-to-use-Smart-Pit-at-convenience-store-Family-Mart-" target="_blank">How to use the FamiPort machine?</a></p>
        </div>

        <div class="operation">
          <p>Operated by Og Inc. 株式会社Og <span class="hotline">03-5937-5869</span></p>
        </div>

        <div class="smartpit--end">
          <p>Smart PitはNTTインターネット(株)が提供するコンビニ収納代行サービスです。</p>
        </div>
      </div>
    </div>
  </div>
</div>
