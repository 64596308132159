import { Component, OnInit, Output, EventEmitter} from '@angular/core';
import { Router, ActivatedRoute, ParamMap, UrlSegment } from '@angular/router';
import { MatSnackBar } from '@angular/material';
import { Subscription } from 'rxjs';
import { Constants } from 'src/app/constants';
import { V1UserAPIService } from 'src/app/services/v1api.service';
import { Utils } from 'src/app/utils';
import { ShareDataService } from 'src/app/services/share-data.service';
import { ComponentModifiedEvent } from 'src/app/types/common-types';
import { User } from 'src/app/types/model-types';

export declare type UserDetailTabDataType = 'user-summary' | 'user-role' | 'user-property' | 'contract';

export class UserDetailTabInfo {
  public label: string;
  public name: string;
  public dataType: UserDetailTabDataType;
  public data: any;
  public modified: boolean;
  public component: any;

  constructor(label: string, name: string, dataType: UserDetailTabDataType, data: any = null) {
    console.log('UserDetailTabInfo::constructor : ' + dataType);
    this.label = label;
    this.name = name;
    this.dataType = dataType;
    this.data = data;
    this.modified = false;
    this.component = null;
  }
}

export class SelectedsAndCandidates {
  public candidates: string[];
  public selecteds: string[];

  constructor() {
    console.log('SelectedsAndCandidates::constructor : ');
  }

  public setCandidates(candidates: any[]): void {
    this.candidates = [];
    candidates.forEach(candidate => {
      this.candidates.push(candidate.name);
    });
  }

  public setSelects(selects: any[]): void {
    this.selecteds = [];
    selects.forEach(selected => {
      this.selecteds.push(selected.name);
    });
  }

  public get unselects(): string[] {
    const unselects: string[] = [];
    this.candidates.forEach(candidate => {
      const founds = this.selecteds.filter(selected => {
        return selected === candidate;
      });
      if (founds.length < 1) {
        unselects.push(candidate);
      }
    });
    return unselects;
  }
}

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit {

  // input/output
  @Output() modified = new EventEmitter<ComponentModifiedEvent>();

  // service
  private _shareDataSubscription: Subscription;

  // subscribing data
  private _user: User;

  // tabs
  public tabs: Array<UserDetailTabInfo>;

  // url analyze
  private _urlMap: UrlSegment[];
  private _paramMap: ParamMap;

  constructor(
    private _api: V1UserAPIService,
    private _router: Router,
    private _snackBar: MatSnackBar,
    private _activatedRoute: ActivatedRoute,
    private _shareData: ShareDataService,
  ) {
    this._user = null;
    this.tabs = new Array<UserDetailTabInfo>();
    this.tabs.push(new UserDetailTabInfo('Summary', 'user-summary', 'user-summary', null));
    this.tabs.push(new UserDetailTabInfo('Role', 'user-role', 'user-role', null));
    this.tabs.push(new UserDetailTabInfo('Property', 'user-property', 'user-property', null));
    this.tabs.push(new UserDetailTabInfo('Uso', 'contract-1', 'contract', null));
  }

  ngOnInit() {
    // START : ===== common implementation for login required components =====
    if (this._api.isLoggingIn(true) === false) {
      this._router.navigate(['/auth/login']);
    }
    // END : ===== common implementation for login required components =====
    this._activatedRoute.url.subscribe(
      urlSegments => {
        this._urlMap = urlSegments;
        if (Utils.isValue(this._urlMap) && Utils.isValue(this._paramMap)) {
          this.refresh();
        }
      }
    );
    this._activatedRoute.queryParamMap.subscribe(
      paramMap => {
        this._paramMap = paramMap;
        if (Utils.isValue(this._urlMap) && Utils.isValue(this._paramMap)) {
          this.refresh();
        }
      }
    );
    // share data service
    this._shareDataSubscription = this._shareData.data$.subscribe(
      data => {
        const new_data = this._shareData.getAt('admin/users/user:selecting_user');
        if (!Utils.isValue(this._user) ||  this._user !== new_data) {
          this._user = new_data;
          this.refresh();
        }
      }
    );
  }

  private refresh(): void {
    console.log('UserDetailComponent::refresh()');
    if (Utils.isValue(this._user)) {
      this.tabs.forEach(tab => {
        if (tab.name === 'user-summary') {
          this.clearTabInfoDataByName(tab.name);
          this._api.get(Constants.API_SHOW_USER + '/' + this._user.id, null).subscribe(
            response => {
              console.log(response);
              this._shareData.setAt('admin/users/user:user', response.data);
              this.setTabInfoDataByName('user-summary', response.data);
            },
            error => {
              this._snackBar.dismiss();
              this._snackBar.open(
                Utils.getDisplayErrorMessage(error, 'Failed to get user summary user'),
                'OK',
                Constants.defaultSnackBarConfig('error'));
              console.log(error);
            }
          );
        } else if (tab.name === 'user-role') {
          // load all roles as candidates
          this._api.get(Constants.API_LIST_USER_ROLES, null).subscribe(
            response => {
              console.log(response);
              const tabInfo = this.getTabInfoDataByName('user-role');
              let tabData = <SelectedsAndCandidates>tabInfo.data;
              if (!Utils.isValue(tabData)) {
                tabData = new SelectedsAndCandidates();
              }
              tabData.setCandidates(response.data);
              this._shareData.setAt('role_candidates', response.data);
              this.setTabInfoDataByName('user-role', tabData);
            },
            error => {
              this._snackBar.dismiss();
              this._snackBar.open(
                Utils.getDisplayErrorMessage(error, 'Failed to get list of roles'),
                'OK',
                Constants.defaultSnackBarConfig('error'));
              console.log(error);
            }
          );
          // load roles of selecting user
          this._api.get(Constants.API_SHOW_USER_ROLES + '/' + this._user.id, null).subscribe(
            response => {
              console.log(response);
              const tabInfo = this.getTabInfoDataByName('user-role');
              let tabData = <SelectedsAndCandidates>tabInfo.data;
              if (!Utils.isValue(tabData)) {
                tabData = new SelectedsAndCandidates();
              }
              tabData.setSelects(response.data);
              this._shareData.setAt('admin/users/user:roles', response.data);
              this.setTabInfoDataByName('user-role', tabData);
            },
            error => {
              this._snackBar.dismiss();
              this._snackBar.open(
                Utils.getDisplayErrorMessage(error, 'Failed to get roles of selecting user'),
                'OK',
                Constants.defaultSnackBarConfig('error'));
              console.log(error);
            }
          );
        }
      });
    }
  }

  private getTabInfoDataByName(name: string): UserDetailTabInfo {
    let found: UserDetailTabInfo = null;
    this.tabs.forEach(tab => {
      if (tab.name === name) {
        found = tab;
      }
    });
    return found;
  }

  private setTabInfoDataByName(name: string, data: any): void {
    const new_tabs = new Array<UserDetailTabInfo>();
    this.tabs.forEach(tab => {
      if (tab.name === name) {
        tab.data = data;
      }
      new_tabs.push(tab);
    });
    this.tabs = new_tabs;
  }

  private clearTabInfoDataByName(name: string): void {
    const new_tabs = new Array<UserDetailTabInfo>();
    this.tabs.forEach(tab => {
      if (tab.name === name) {
        tab.data = null;
      }
      new_tabs.push(tab);
    });
    this.tabs = new_tabs;
  }

  private clearTabInfoDataByType(type: UserDetailTabDataType): void {
    const new_tabs = new Array<UserDetailTabInfo>();
    this.tabs.forEach(tab => {
      if (tab.dataType === type) {
        tab.data = null;
      }
      new_tabs.push(tab);
    });
    this.tabs = new_tabs;
  }

  public onModifiedContent(event: ComponentModifiedEvent): void {
    let someTabModified = false;
    this.tabs.forEach(tab => {
      if (tab.name === event.name) {
        tab.modified = event.modified;
        tab.component = event.component;
        if (tab.modified === true) {
          someTabModified = true;
        }
      }
    });
    this.modified.emit(new ComponentModifiedEvent('user-detail', someTabModified, this));
  }

  public onClickCommitTabContent(event: UserDetailTabInfo): void {
    event.component.commitProc(event.data);
  }
}
