<div class="widget-menu">
  <mat-icon *ngIf="back_button" class="widget-menu__button" appBackbutton>
    keyboard_arrow_left</mat-icon>
  <p class="widget-menu__title">{{ title || "Contracts" }}</p>
  <button *ngIf="humberger" class="widget-menu__icon" mat-icon-button [matMenuTriggerFor]="toggleMenu" aria-label="icon-menu inside"
    (click)="onGetPoints()">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu class="widget-menu__content" #toggleMenu="matMenu" xPosition="before" [hasBackdrop]="false">
    <div class="content">
      <div class="menu-top">
        <button mat-icon-button aria-label="icon-menu inside" class="close--button">
          <mat-icon class="close--icon">close</mat-icon>
        </button>
        <div class="content__image" appPreventCloseMenu>
          <img class="image--logo" src="../../../../assets/images/logo-1.svg" alt="sakuramobile logo" />
        </div>
      </div>
      <div class="menu-bottom" appPreventCloseMenu>
        <!-- Woo commerce point-->
        <div class="menu-bottom__points">
          <mat-accordion>
            <mat-expansion-panel class="expansion--panel">
              <mat-expansion-panel-header class="pannel--header">
                <mat-panel-title class="mat--panel--title">
                  <h3 class="title">Sakura Points</h3>
                  <div class="points--details">
                    <div class="points--details__point">
                      <div class="special--title">P</div>
                      <span *ngIf="points$ |async as points">
                        {{points === '0' || points === 0 ? points : toLocaleString(points.data)}} Pt
                      </span>
                    </div>
                    <div class="points--details__detail">
                      Details
                    </div>
                  </div>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="panel--contents" *ngIf="points$ |async as points">
                <div class="panel--contents__learn-more"
                  [routerLink]="['/point-description', points === '0' ? points : points.data]">
                  <p>Learn more...</p>
                </div>
                <div class="panel--contents__exchange-points" (click)="exchangePoints()">
                  <p>Exchange points</p>
                </div>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
        </div>
        <!-- -->
        <div class="menu-bottom__menu-list">
          <div class="menu--item" routerLink="/invite-friends">
            <mat-icon class="account-icon">person_add</mat-icon>
            <p>Invite Friend</p>
          </div>

          <div class="menu--item" routerLink="/account-information">
            <mat-icon class="account-icon">person</mat-icon>
            <p>Account</p>
          </div>
          <div class="menu--item" (click)="onSupport()">
            <mat-icon>policy</mat-icon>
            <p>Support</p>
          </div>
          <div class="menu--item" (click)="contactUs()">
            <mat-icon>email</mat-icon>
            <p>Contact us</p>
          </div>
        </div>
        <div class="menu-bottom__logout-button" (click)="onClickLogout()" style="margin-bottom: 5rem">
          <mat-icon>exit_to_app</mat-icon>
          <p>Logout</p>
        </div>
      </div>
    </div>
  </mat-menu>
</div>
