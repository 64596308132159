<div class="topup--select">
  <div class="widget">
    <app-menu [title]="'Top Up'" [back_button]="true"></app-menu>
    <div class="widget__contents">
      <h1>{{defaultDataMB |number}}</h1>
      <h2>GB</h2>
      <div class="widget__contents__select--data">
        <mat-icon class="icon-disabled"
        [ngClass]="{'icon': defaultDataMB > 1}"
        (click)="RemoveData()">remove</mat-icon>
        <span class="number--data">{{defaultDataMB |number}} GB</span>
        <mat-icon class="icon"  (click)="AddData()"
        [ngClass]="{'add-disabled': defaultDataMB === 5}">add</mat-icon>
      </div>
      <div class="widget__contents__checkout">
        <div class="widget__contents__checkout__title">
          <mat-icon class="icon--offer">local_offer</mat-icon>
          <span class="checkout--title">Check-out</span>
        </div>
        <div class="widget__contents__checkout__content">
          <table>
            <tr>
              <td class="tb-title">Data</td>
              <td class="tb-amount"><span>{{defaultDataMB}}</span> GB</td>
            </tr>
            <tr>
              <td class="tb-title">Sub-total</td>
              <td class="tb-amount"><span>{{subTotal |number}}</span> JPY</td>
            </tr>
            <tr>
              <td class="tb-vat">VAT 10%:</td>
              <td class="tb-vat">{{vat |number}} JPY</td>
            </tr>
            <tr>
              <td colspan="2"><mat-divider class="divider--item"></mat-divider></td>
            </tr>
            <tr>
              <td class="tb-title">Total</td>
              <td class="tb-amount"><span>{{total |number}}</span> JPY</td>
            </tr>
          </table>
        </div>
      </div>
      <h3>This payment can only be made with a debit or credit card.</h3>
      <div class="widget__contents__confirm">
        <h3 class="confirm--title">Please confirm:</h3>
        <div class="widget__contents__confirm__radio-button">
          <div class="term">
            <mat-icon class="term-check" (click)="toggleCheckedTop()" >{{
              mat_content_top
            }}</mat-icon>
            <span class="term-content"
              >Top up data is added to your current SIM card.</span
            >
          </div>
          <div class="term">
            <mat-icon class="term-check" (click)="toggleCheckedBottom()">{{
              mat_content_bottom
            }}</mat-icon>
            <span class="term-content"
              >Top up data expires at the end of the month.</span
            >
          </div>
        </div>
      </div>
      <button mat-button class="widget__contents__next--button"
      [ngClass]="{'submitted--button': checkTop && checkBottom}"
      [disabled]="!(checkTop && checkBottom)"
      (click)="selectTopUp()">Next</button>
    </div>
  </div>
</div>
