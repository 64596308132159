<div class="termination-selection">
  <div class="widget">
    <app-menu
      [back_button]="back_button"
      [title]="
        (is_fiber$ | async) ? 'Terminate Contract' : (phone_number$ | async)
      "
    ></app-menu>
    <div class="widget__contents">
      <div class="widget__contents_title">
        <p class="title">Contract Cancellation</p>
      </div>
      <div class="widget__contents__fiber-notes" *ngIf="is_fiber$ | async">
        <div class="widget__contents__fiber-notes__header">
          <div class="square-dot"></div>
          <p class="notice-header">Notice:</p>
        </div>
        <div class="widget__contents__fiber-notes__info">
          <p>
            If you move to a different address in Japan and wish to use our
            internet service there,
            <span style="font-weight: bold"
              >you do not need to submit the termination form here.</span
            >
          </p>
        </div>
        <div class="widget__contents__fiber-notes__info-has-border-left">
          <p>
            In this case,
            <span style="color: #e573aa"
              >please contact our Customer Support team</span
            >
            (contact@sakuramobile.jp) and they will provide guidance for the
            relocation process.
          </p>
        </div>
      </div>
      <div class="widget__contents_selection">
        <div class="selected top" (click)="onRoute('cancellation')">
          <p class="selected--title">Cancel Contract</p>
          <mat-icon class="selected--icon">navigate_next</mat-icon>
        </div>
        <div
          class="selected bottom"
          (click)="onRoute('mnp-attention')"
          *ngIf="mnpRequest$ | async"
        >
          <p class="selected--title">Request MNP</p>
          <mat-icon class="selected--icon">navigate_next</mat-icon>
        </div>
      </div>
      <div class="widget__contents_description" *ngIf="mnpRequest$ | async">
        <mat-icon class="des--icon">help</mat-icon>
        <p class="des--title">What does MNP mean?</p>
      </div>
      <div class="widget__contents_mnp-mean" *ngIf="mnpRequest$ | async">
        <p class="mean--title">
          MNP, or mobile number portability, is a system shared by the MNOs
          (Mobile Network Operators) that allows you
          <span class="bold">to keep your current phone number</span> even
          <span class="bold"
            >when switching to a new contract with a new carrier.</span
          >
        </p>
      </div>
    </div>
    <div class="widget__newplans" *ngIf="mnpRequest$ | async">
      <!-- <img src="../../../../assets/images/newplans.svg" alt="new plan information" class="image"> -->
      <!-- <img
        class="new-plan-img"
        src="../../../../assets/images/termination-new.svg"
        alt="new plan information available now"
      /> -->
      <a (click)="openSuspend()">
        <img
          class="new-plan-img"
          src="../../../../assets/images/Suspend-plan-banner.svg"
          alt="new plan information available now"
        />
      </a>
    </div>
  </div>
</div>
