<div class="app-portal page">
  <div class="widget">
    <ng-container *ngIf="invoices">
      <app-menu [invoices]="invoices"></app-menu>
    </ng-container>

    <div class="widget-content">
      <div class="content-body">
        <mat-accordion>
          <ng-container *ngIf="activeOption">
            <div
              class="widget-content__title"
              [ngClass]="{ 'no-padding-bottom': active_pay_status }"
            >
              <div class="widget-content__active">
                <mat-icon class="check-icon">check_circle</mat-icon>
                <span class="active">Active</span>
              </div>
            </div>
            <app-active-contracts
              *ngFor="let contract of activeContracts"
              [contractAc]="contract"
              [invoicesList]="invoices"
            ></app-active-contracts>
          </ng-container>

          <ng-container *ngIf="endingOption">
            <div
              class="widget-content__title"
              [ngClass]="{ 'no-padding-bottom': ending_pay_status }"
            >
              <div class="widget-content__ending content-title">
                <mat-icon>remove_circle</mat-icon>
                <span>Ending</span>
              </div>
            </div>
            <app-ending-contracts
              *ngFor="let contract of endingContracts"
              [endingContract]="contract"
              [invoicesList]="invoices"
            ></app-ending-contracts>
          </ng-container>

          <ng-container *ngIf="completeOption">
            <div class="widget-content__title">
              <div class="widget-content__completed content-title">
                <mat-icon>cancel</mat-icon>
                <span class="title-complete">Completed</span>
              </div>
            </div>

            <app-complete-contracts
              *ngFor="let contract of completeContracts"
              [contractComplete]="contract"
              [invoicesList]="invoices"
            ></app-complete-contracts>
          </ng-container>
        </mat-accordion>
      </div>
    </div>
    <ng-container *ngIf="isCampaign; else notIsCampain">
      <div class="banner">
        <a routerLink="/invite-friends">
          <img
            src="../../../../assets/images/get3000give3000_banner.jpg"
            alt="get 3000 give 3000 Campaign"
          />
        </a>
      </div>
    </ng-container>
    <ng-template #notIsCampain>
      <div class="banner">
        <a
          href="https://www.sakuramobile.jp/lp/?i=sakura-fiber-internet"
          target="_blank"
        >
          <img
            src="../../../../assets/images/sakura_fiber_banner.jpg"
            alt="Sakura Fiber Internet"
          />
        </a>
      </div>
    </ng-template>
  </div>
</div>
