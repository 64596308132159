import { Component, OnInit, ViewEncapsulation, Output, Input, EventEmitter  } from '@angular/core';
import { StarRating } from 'src/app/types/rating';

@Component({
  selector: 'app-rate-service',
  templateUrl: './rate-service.component.html',
  styleUrls: ['./rate-service.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class RateServiceComponent implements OnInit {
  public ratingArr = [0, 1, 2, 3, 4];
  private priceRate  = 0;
  private internetRate  = 0;
  private customerRate  = 0;
  private ratingObj: StarRating = {
    rate_price: 0,
    rate_customer_support: 0,
    rate_internet_connection: 0
  };
  @Output() private ratingUpdated = new EventEmitter<StarRating>();
  constructor() { }

  ngOnInit() {}
  // render star if input change rating
  showIconPrice(index: number) {
    if (this.priceRate >= index + 1) {
      return 'star';
    } else {
      return 'star_border';
    }
  }
  showIconInternetConnect(index: number) {
    if (this.internetRate >= index + 1) {
      return 'star';
    } else {
      return 'star_border';
    }
  }
  showIconCustomerSupport(index: number) {
    if (this.customerRate >= index + 1) {
      return 'star';
    } else {
      return 'star_border';
    }
  }
  priceRating(star_number: number) {
    this.priceRate = star_number;
    this.ratingObj.rate_price = star_number;
    this.ratingUpdated.emit(this.ratingObj);
  }
  internetConnectionRating(star_number: number) {
    this.internetRate = star_number;
    this.ratingObj.rate_internet_connection = star_number;
    this.ratingUpdated.emit(this.ratingObj);
  }
  customerSupportRating(star_number: number) {
    this.customerRate = star_number;
    this.ratingObj.rate_customer_support = star_number;
    this.ratingUpdated.emit(this.ratingObj);
  }
}
