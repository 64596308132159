import { Component, OnInit, OnDestroy } from '@angular/core';
import { ShareDataService } from 'src/app/services/share-data.service';
import { AutoLogoutService } from './services/auto-logout.service';
import { RouterHistoryService } from './services/router-history.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'app';

  constructor(
    private _shareData: ShareDataService,
    private autoLogout: AutoLogoutService,
    readonly _routerHistory: RouterHistoryService
  ) {

  }

  ngOnInit() {
    this._shareData.run();
  }

  ngOnDestroy() {
    this._shareData.shutdown();
  }
}
