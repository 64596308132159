import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, ParamMap } from "@angular/router";
import { AccountInformationService } from "src/app/services/account-information.service";
import { LoadingService } from "src/app/services/loading.service";
import { MatSnackBar } from "@angular/material";
import { Constants } from 'src/app/constants';

@Component({
  selector: "app-card-updated",
  templateUrl: "./card-updated.component.html",
  styleUrls: ["./card-updated.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class CardUpdatedComponent implements OnInit {
  // other
  private _paramMap: ParamMap;
  public completed: boolean = false;

  constructor(
    private _api: AccountInformationService,
    private _activatedRoute: ActivatedRoute,
    private _loadingService: LoadingService,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this._activatedRoute.queryParamMap.subscribe((paramMap) => {
      console.log(
        "CardUpdatedComponent::ngOnInit : paramMap => " +
          JSON.stringify(paramMap)
      );
      this._paramMap = paramMap;
      
      this._api.UpdateCardCompleted(this._paramMap.get("hash")).subscribe(
        (response) => {
          console.log("Renew card process (finish) successfull::", response);
          this._loadingService.setSpinnerEvent = false;
          this.completed = true;
          return { result: true, data: <any>response };
        },
        (error) => {
          console.log("Renew card process (finish) Error:", error);
          let message = "Update faild";
          if (error.error && error.error.error && error.error.error.message) {
            message = message + ` : ${error.error.error.message}`;
          }
          this._loadingService.setSpinnerEvent = false;
          this._snackBar.dismiss();
          this._snackBar.open(
            message,
            "OK",
            Constants.defaultSnackBarConfig("error")
          );
          return { result: false, data: <any>error };
        }
      );
    });
  }
}
