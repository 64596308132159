import {
  Component,
  OnInit,
  ViewEncapsulation,
  Renderer2,
  ViewChild,
  ElementRef,
  OnDestroy,
  AfterViewInit,
} from '@angular/core';
import { CancellationService } from 'src/app/services/cancellation.service';
import { Observable } from 'rxjs';
import { FormGroup, FormControl, Form, FormBuilder, Validators } from '@angular/forms';
import { SubSink } from 'subsink';
import { FromEventTarget } from 'rxjs/internal/observable/fromEvent';
import { MatDatepickerInputEvent } from '@angular/material';
import { DatePipe, registerLocaleData } from '@angular/common';
import { CustomValidators } from 'src/app/custom-validators';
import { Router } from '@angular/router';
import { ContractsService } from 'src/app/services/contracts.service';
import { RouterHistoryService } from 'src/app/services/router-history.service';

@Component({
  selector: 'app-mnp-submit-infomation',
  templateUrl: './mnp-submit-infomation.component.html',
  styleUrls: ['./mnp-submit-infomation.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MnpSubmitInfomationComponent implements OnInit, AfterViewInit, OnDestroy {
  private subs = new SubSink();
  phone_number$: Observable<string> = this.cancelService.line_number$;
  pay_method: string;
  back_button = true;
  checked = false;
  mat_content = 'radio_button_unchecked';
  method_paypal = 'paypal';
  method_japan = 'jp_bank';
  choosed = false;
  last_name = '';
  first_name = '';
  last_name_kana = '';
  first_name_kana = '';
  birth_day = '';
  sex = '';
  reasonsData: FormGroup;
  mnpInformation: FormGroup;
  firstName: FormControl;
  lastName: FormControl;
  contract_name: FormControl;
  firstNameKana: FormControl;
  lastNameKana: FormControl;
  contract_name_kana: FormControl;
  contract_date_of_birth: FormControl;
  contract_sex: FormControl;
  regex: RegExp = /[\u3000-\u303f\u30a0-\u30ff]/;
  routeLink: string;
  isPayMethod = false;
  @ViewChild('choose1', {static: false}) paypal: ElementRef;
  @ViewChild('choose2', {static: false}) japan: ElementRef;
  constructor(
    private cancelService: CancellationService,
    private contractService: ContractsService,
    private render: Renderer2,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private route: Router,
    readonly _routerHistoryService: RouterHistoryService
  ) {}
  ngOnInit() {
  this.subs.sink = this.cancelService.formCancellation$.subscribe(data => {
    this.reasonsData = data;
  });
  this.subs.sink = this.contractService.pay_method$.subscribe(res => this.pay_method = res);
    console.log('Reasons data MNP submit component::', this.reasonsData.value);
    this.mnpInfomarionForm();
    this.subs.sink = this.cancelService.mnpForm$.subscribe(res => {
      if (res.value !== undefined) {
        this.last_name = res.value.lastName;
        this.first_name = res.value.firstName;
        this.last_name_kana = res.value.lastNameKana;
        this.first_name_kana = res.value.firstNameKana;
        this.birth_day = res.value.contract_date_of_birth;
        this.sex = res.value.contract_sex;
        this.mnpInformation.patchValue({
          firstName: res.value.firstName,
          lastName: res.value.lastName,
          firstNameKana: res.value.firstNameKana,
          lastNameKana: res.value.lastNameKana,
          contract_date_of_birth: res.value.contract_date_of_birth,
          contract_sex: res.value.contract_sex
        });
      }
    });
  }
  ngAfterViewInit () {
  }
  toggleChecked() {
    this.checked = !this.checked;
    if (this.checked === false) {
      this.mat_content = 'radio_button_unchecked';
    } else {
      this.mat_content = 'radio_button_checked';
    }
  }
  dateChange(event: MatDatepickerInputEvent<Date>) {
    const newday = this.datePipe.transform(event.value, 'yyyy-MM-dd');
    this.mnpInformation.patchValue({
      contract_date_of_birth: newday
    });
  }
  onClick(event: any) {
    if (this.pay_method === 'smartpit') {
      this.isPayMethod = true;
      switch (event.value) {
        case this.method_paypal:
          this.render.removeClass(this.japan.nativeElement, 'choose-method');
          this.routeLink = '/refund-paypal';
          break;
        case this.method_japan:
          this.render.removeClass(this.paypal.nativeElement, 'choose-method');
          this.routeLink = '/refund-jp-bank';
          break;
      }
    } else {
      this.isPayMethod = false;
    }
    // choose pay method type:: paypal or japanese bank
    if (this.reasonsData.value !== undefined) {
      this.reasonsData.patchValue({
        refund_method: event.value
      });
    }
    this.cancelService.setReasonsForm = this.reasonsData;
  }
  mnpInfomarionForm () {
  this.mnpInformation = this.fb.group({
  firstName: ['', Validators.required],
  lastName: ['', Validators.required],
  contract_name: [''],
    // tslint:disable-next-line:max-line-length
  lastNameKana: ['', Validators.compose([Validators.required, CustomValidators.checkKatakana(this.regex, {isKana: true})])],
  firstNameKana: ['', Validators.compose([Validators.required, CustomValidators.checkKatakana(this.regex, {isKana: true})])],
  contract_name_kana: [''],
  contract_date_of_birth: ['', Validators.required],
  contract_sex: ['', Validators.required],
    });
  }
  submittedRequest() {
    const full_name = this.mnpInformation.value.lastName + ' ' + this.mnpInformation.value.firstName;
    const full_name_kana = this.mnpInformation.value.lastNameKana + ' ' + this.mnpInformation.value.firstNameKana;
    this.mnpInformation.patchValue({
      contract_name : full_name,
      contract_name_kana: full_name_kana
    });
    console.log('Mnp Form Data Submitted::', this.mnpInformation.value);
    this.cancelService.setMnpForm = this.mnpInformation;
    console.log(this.mnpInformation.valid);
   if (this.checked === true && this.mnpInformation.valid && this.routeLink !== undefined) {
    this.route.navigate([this.routeLink]);
   } else if (this.checked && this.mnpInformation.valid && this.pay_method !== 'smartpit') {
     this.route.navigate(['/confirmation-mnp-information']);
   } else {
     return ;
   }
  }
  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
