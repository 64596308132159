<!-- <div class="empty_div_complete"></div> -->
<mat-expansion-panel class="complete">
    <mat-expansion-panel-header>
        <mat-panel-title>
            <mat-icon class="header-icon icon_complete">sim_card</mat-icon>
            <div class="title">
                <p>{{contract_code_complete}}</p>
                <p>{{subscription_plan_complete}}</p>
                <p *ngIf="start_day_complete === null ? false : true">Start date :
                    {{start_day_complete | date: "yyyy-MM-dd"}}</p>
                <p *ngIf="end_day_complete === null ? false : true">End date :
                    {{end_day_complete | date: "yyyy-MM-dd"}}</p>
            </div>
        </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="complete__contract-content">
        <div class="complete-contract-details" routerLink="portal/contract-details/{{contract_id_complete}}"
        (click)="getContractDetailComplete(contract_id_complete)">
            <p>Contracts details</p>
            <mat-icon>chevron_right</mat-icon>
        </div>

        <div class="complete-history-payment" (click)="PaymentHistory()" routerLink="portal/payment-history/{{contract_id_complete}}">
            <p>Payments</p>
            <mat-icon>chevron_right</mat-icon>
        </div>
    </div>
</mat-expansion-panel>
