import { Component, OnInit, ViewEncapsulation, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { MAT_DIALOG_DATA } from '@angular/material';
import { tap } from 'rxjs/operators';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-billing-work',
  templateUrl: './billing-work.component.html',
  styleUrls: ['./billing-work.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BillingWorkComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {payment_method: Observable<string>, item: Observable<string>},
  ) { }

  ngOnInit() {
  }

}
