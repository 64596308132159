<div class="payment-received">
  <div class="widget">
    <app-menu [back_button]="false" [humberger]="false" [title]="'Pay Your Bill'"></app-menu>

    <div *ngIf="new_card && !completed" class="widget__contents">
      Thank you for completing the card authentication process. <br/>
      Your registered card is currently being updated in our system.<br/>
      <br/>
      Once the update is complete, this screen will automatically change to the confirmation page.<br/>
      If the screen does not update within 1 minute, please contact our customer support team using this <a href="https://www.sakuramobile.jp/contact-us" target="_blank" rel="noopener noreferrer">link</a>.<br/>
    </div>

    <div *ngIf="!new_card && !completed" class="widget__contents">
      This screen will automatically change to the confirmation page.<br/>
      If the screen does not update within 1 minute, please contact our customer support team using this <a href="https://www.sakuramobile.jp/contact-us" target="_blank" rel="noopener noreferrer">link</a>.<br/>
    </div>

    <div *ngIf="completed" class="widget__contents">
      <div class="widget__contents__title">
        <h1 class="title">Payment received!</h1>
      </div>
      <div class="widget__contents__information" *ngIf="invoice">
        <p>Your payment for {{invoice_month}} bill has been successfully made.</p>
        <p> Thank you very much for your cooperation!</p>
        <ng-container *ngIf="new_card">
          <p>The new card information has been registered.</p>
          <p>Please submit the <span class="sakura--text">card update form</span> again if you would like to change back to the old card.</p>
        </ng-container>
      </div>
      <div class="widget__contents__image">
        <img src="../../../../../assets/images/submitSakuraFlower.svg" alt="sakura submitSakuraFlower" class="image">
      </div>
    </div>
    <div class="widget__contents__backlink">
      <p class="back--link">Back to <span class="sakura--text" routerLink="/portal">Contracts</span></p>
    </div>
  </div>
</div>
