import { Component, OnInit, OnDestroy } from '@angular/core';
import { AccountInformationService } from 'src/app/services/account-information.service';
import { tap, map, shareReplay } from 'rxjs/operators';
import { Utils } from 'src/app/utils';
import { Router } from '@angular/router';
import { SubSink } from 'subsink';
import { RouterHistoryService } from 'src/app/services/router-history.service';
import { PaymentMethodDialogComponent } from '../payment-method-dialog/payment-method-dialog.component';
import { MatDialog } from '@angular/material';

@Component({
  selector: 'app-personal-detail',
  templateUrl: './personal-detail.component.html',
  styleUrls: ['./personal-detail.component.scss']
})
export class PersonalDetailComponent implements OnInit, OnDestroy {
  public isDialogOpen = false;
  private subs = new SubSink();
  account_payment_info$ = this.accountService.userPaymentInformation$
  .pipe(
    map(res => res.data),
    shareReplay(),
    );
  expire_date$ = this.account_payment_info$.pipe(
      map(res => Utils.convertExpireDate(res.expire))
    );
  constructor(
    private accountService: AccountInformationService,
    private router: Router,
    private dialog: MatDialog,
    readonly _routerHistoryService: RouterHistoryService
  ) { }

  ngOnInit() {
  }

  fetchPaymentStatus(): void {
    if (this.isDialogOpen) {
      return;
    }
    this.isDialogOpen = true;
    this.accountService.paymentChangeTerm().subscribe(
      (response: boolean) => {
        if(response === false) {
          const dialogRef = this.dialog.open(PaymentMethodDialogComponent, {
            panelClass: 'modal_wrapper',
            data: { key: 'value' } 
          });
          dialogRef.afterClosed().subscribe(() => {
            this.isDialogOpen = false;
          });
        } else {
          this.changePayMethod ();
        }
        console.log('Fetched Response:', response);
      },
      (error) => {
        console.error('Error occurred:', error);
      }
    );
  }

  changePayMethod () {
    this.subs.sink = this.accountService.CheckPayMethodChangable().subscribe(res => {
      if (res) {
        this.router.navigate(['/change-payment']);
      } else {
      window.open('https://www.sakuramobile.jp/contact-us/', '_blank');
      }
    });
  }
  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
