<div class="submitted__request">
  <div class="widget">
    <app-menu [title]="'Top Up'" [back_button]="back_button"></app-menu>
    <div class="widget__body">
      <div class="widget__body--title">
        <p class="title">Request submitted</p>
      </div>
      <div class="widget__body--info">
        <p class="info">
          New data will be added to your account.
        </p>
        <p class="info">
          Note: The top up data will not roll over to the following month.
        </p>
      </div>
      <div class="widget__body--img">
        <img class="imageInside" src="../../../../../assets/images/submitSakuraFlower.svg" />
      </div>
      <div class="widget__body--submitted">
        <p class="goback">Back to <span class="special" (click)="goBack()">Contract Detail</span></p>
      </div>
    </div>
  </div>
</div>
