<div class="payment-received">
  <div class="widget">
    <app-menu [back_button]="true" [title]="'Pay Your Bill'"></app-menu>

    <div class="widget__contents">
      <div class="widget__contents__title">
        <h1 class="title">Payment received!</h1>
      </div>
      <div class="widget__contents__information" *ngIf="invoice$ | async as invoice">
        <p>Your payment for {{invoiceMonthToUsedMonth(invoice.invoice_month)}} bill has been successfully made.</p>
        <p> Thank you very much for your cooperation!</p>
        <ng-container *ngIf="!(payType$ |async)">
        <p>The new card information has been registered.</p>
        <p>Please submit the <a href="" class="sakura--text">card update form</a> again if you would like to change back to the old card.</p>
      </ng-container>
      </div>
      <div class="widget__contents__image">
        <img src="../../../../../assets/images/submitSakuraFlower.svg" alt="sakura submitSakuraFlower" class="image">
      </div>
      <div class="widget__contents__backlink">
        <ng-container *ngIf="!(payType$ |async); else registered">
        <p class="back--link">Back to <span class="sakura--text" (click)="paymentHistory()" >Payment history.</span></p>
       </ng-container>
      </div>
      <ng-template #registered>
        <div class="back--payment">
          <div class="alert--invoices" *ngIf="unpaidInvoice$ |async">
            <mat-icon class="icon">notification_important</mat-icon>
            <p class="sakura--text">It seems like you still have unpaid invoices.</p>
          </div>
          <div class="payment--history">
            <mat-icon class="icon">double_arrow</mat-icon>
            <p>Check <span class="sakura--text" (click)="paymentHistory()">Payment History</span></p>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
