<div class="submitted-request">
  <div class="widget">
    <app-menu [back_button]="false" [title]="'Account'"></app-menu>

    <div class="widget__contents">
      <h1 class="header--title">Request submitted</h1>
      <div class="widget__contents--titles">
      <p>Thank you for requesting the payment method change. We have received your request.</p>
      <p>We will send you a notification email once the process is done</p>
      </div>
      <div class="widget__contents--image">
        <img src="../../../../../assets/images/submitSakuraFlower.svg" alt="">
      </div>
      <div class="back">
        <p>Back to <span class="sakura--text" routerLink="/portal">Contracts</span></p>
      </div>
    </div>
  </div>
</div>
