import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { DepositUser } from 'src/app/types/deposit-user';
import { DepositProduct } from 'src/app/types/deposit';
import { ContractsService } from 'src/app/services/contracts.service';

@Component({
  selector: 'app-deposit',
  templateUrl: './deposit.component.html',
  styleUrls: ['./deposit.component.scss']
})
export class DepositComponent implements OnInit {
  totalPriceProduct = 0;
  constructor(private contractService: ContractsService) { }
  @Input() productDeposit: DepositProduct;
  ngOnInit() {
    const typeProduct =  this.contractService.check_Product_Type(this.productDeposit.type.title);
    if (typeProduct === 'voice') {
      this.totalPriceProduct = +this.productDeposit.deposit.amount;
    } else {
      const priceTotal = this.productDeposit.deposit.amount + this.productDeposit.deposit.vat;
      this.totalPriceProduct = + priceTotal;
    }
  }

}
