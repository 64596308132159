import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { Constants } from '../constants';
import { LoginResult } from '../types/auth';
import { Point } from '../types/points';
import { Utils } from '../utils';

@Injectable({
  providedIn: 'root'
})
export class PointsService {

  constructor(private http: HttpClient) { }

  getPoints(): Observable<Point | string> {
    const user = this.getLoginUser();
    return this.http.get<Point>(Constants.API_GET_POINTS + `/${user.data.user.id}`, { headers: this.authHeader}).pipe(
      catchError(() => of('0')),
    );
  }

  getLoginUser(): LoginResult {
    const userJson = localStorage.getItem(Constants.LOGIN_USER);
    if (!Utils.isValue(userJson)) {
      return null;
    }
    const user = <LoginResult>JSON.parse(userJson);
    return user;
  }
  private get authHeader(): HttpHeaders {
    const current_user = this.getLoginUser();
    return new HttpHeaders({
      'Content-type': 'application/json',
      Authorization: 'Bearer ' + (current_user ? current_user.data.auth_token : '')
    });
  }

}
