import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private loadingSubject = new BehaviorSubject<boolean>(false);
  loading$ = this.loadingSubject.asObservable();
  private spinnerSubject = new BehaviorSubject<boolean>(false);
  public spinner$ = this.spinnerSubject.asObservable();
  constructor() { }

  loadingOn () {
    this.loadingSubject.next(true);
  }
  loadingOff () {
    this.loadingSubject.next(false);
  }
   // Set Loading Subject
  public set setSpinnerEvent(event: boolean) {
    this.spinnerSubject.next(event);
  }


}
