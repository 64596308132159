import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CancellationService } from 'src/app/services/cancellation.service';
import { ContractsService } from 'src/app/services/contracts.service';
import { SubSink } from 'subsink';
import { map, tap } from 'rxjs/operators';
import { Contract } from 'src/app/types/contract';

@Component({
  selector: 'app-submited-request',
  templateUrl: './submited-request.component.html',
  styleUrls: ['./submited-request.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class SubmitedRequestComponent implements OnInit, OnDestroy {
  private subs = new SubSink(); // Subsink: unscrible all subscriptions
  back_button = false;
  constructor(
    private router: Router,
    private _cancellationService: CancellationService,
    private _contractService: ContractsService) { }
  phone_number$: Observable<string> = this._cancellationService.line_number$;
  finalPayment$ = this._contractService.final_payment$;
  productId$: Observable<number> = this._contractService.productId$;
  is_fiber$ = this._contractService.item$.pipe(
    map(item => item === 'fiber' ? true : false)
  )
  item$: Observable<string> = this._contractService.item$.pipe(
    map((item) => {
      if (item === 'voice') {
        return 'Voice + Data SIM';
      } else if (item === 'data') {
        return 'Data only SIM';
      } else if (item === 'wifi') {
        return 'Pocket WiFi'; 
      } else if (item === 'home wifi') {
        return 'Home WiFi';
      } else if (item === 'fiber') {
        return 'Sakura Fiber Internet';
      } else {
        return '';
      }
    })
  );
  payment_method$: Observable<string> = this._contractService.pay_method$.pipe(
    map((method) => {
      if (method === 'credit card') {
        return 'Credit/Debit Card';
      }
      if (method === 'smartpit') {
        return 'SmartPit Card';
      }
    })
  );
  deviceID$ = this._contractService.deviceID$;
  contract$: Observable<Contract> = this._contractService.contract$;
  terminationDate$ = this._contractService.termination_date$;
  returnDeviceMethod$ = this._contractService.return_device$;
  ngOnInit() {}
  goBack() {
    this.subs.sink = this.productId$.subscribe(
      productid => this.router.navigate([`portal/contract-product/${productid}`])
    );
  }
  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
