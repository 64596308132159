<div class="billing">
  <button class="close--button" mat-icon-button mat-dialog-close matRippleDisabled="disabled">
    <mat-icon class="cancel--icon">cancel</mat-icon>
  </button>

  <mat-dialog-content class="mat-typography">
    <div class="dialog__contents--title">
      <mat-icon class="icon">help</mat-icon>
      <p class="title" mat-dialog-title>How does billing work?</p>
    </div>
    <p class="dialog__contents--info">According to the billing cycle, <span class="special">there will be two more bills
        due</span> after you terminate the contract.</p>
    <p class="dialog__contents--info" *ngIf="(data.payment_method |async) !== 'SmartPit Card'">Below is an example of
      the billing
      cycle for the Debit/Credit card payment method:</p>
    <p class="dialog__contents--info" *ngIf="(data.payment_method |async) === 'SmartPit Card'">Below is an example of
      the billing
      cycle for the SmartPit Card payment method:</p>
    <table *ngIf="(data.payment_method |async) !== 'SmartPit Card'">
      <tr>
        <th>Month</th>
        <th>Invoicing</th>
        <th>Payment</th>
      </tr>
      <tr>
        <td>May</td>
        <td>End of June</td>
        <td class="sakura--text">Jun 30</td>
      </tr>
      <tr>
        <td>June</td>
        <td>End of July</td>
        <td class="sakura--text">Jul 31</td>
      </tr>
      <tr>
        <td>July</td>
        <td>End of August</td>
        <td class="sakura--text">Aug 31</td>
      </tr>
    </table>
    <table *ngIf="(data.payment_method |async) === 'SmartPit Card'">
      <tr>
        <th>Month</th>
        <th>Invoicing</th>
        <th>Payment</th>
      </tr>
      <tr>
        <td>May</td>
        <td>End of June</td>
        <td class="sakura--text">Jul 1-10</td>
      </tr>
      <tr>
        <td>June</td>
        <td>End of July</td>
        <td class="sakura--text">Aug 1-10</td>
      </tr>
      <tr>
        <td>July</td>
        <td>End of August</td>
        <td class="sakura--text">Sep 1-10</td>
      </tr>
    </table>
    <!-- Any product + Credit card -->
    <p class="dialog__contents--info info-end" *ngIf="(data.payment_method |async) !== 'SmartPit Card'">The final bill
      will be charged
      on the last day,<span class="sakura--text"> one month after the termination month.</span> Depending on your credit
      card provider, there may be a delay of a few days.</p>
    <!-- Voice + Smartpit-->
    <ng-container *ngIf="(data.item |async) === 'Voice + Data SIM' && (data.payment_method |async) === 'SmartPit Card'">
      <div class="dialog__contents--title">
        <mat-icon class="icon">help</mat-icon>
        <p class="title" mat-dialog-title>Will the deposit be refunded?</p>
      </div>
      <p class="dialog__contents--info">You will get <span class="special">a partial refund</span> for the deposit. The
        remaining of the deposit will be calculated and refunded to the provided bank account <span
          class="sakura--text">1.5 months after your contract is cancelled.</span></p>
      <p class="dialog__contents--info">Depending on the usage fees of the last 2 months, you might not receive a refund
        from us.</p>
      <div class="dialog__contents--title">
        <mat-icon class="icon">help</mat-icon>
        <p class="title" mat-dialog-title>Where will deposit be refunded?</p>
      </div>
      <p class="dialog__contents--info">The deposit can be refunded to your <span class="sakura--text">Japanese bank
          account</span> or your <span class="sakura--text">PayPal account.</span> We will notify you once the refund is
        processed.</p>
      <p class="note"> NOTE:</p>
      <p class="dialog__contents--info">1. It needs to be your Japanese bank account. If you have trouble providing your
        Japanese bank account, please contact us. </p>
      <p class="dialog__contents--info">2. You will be responsible for the transaction fee if you choose to receive the
        refund through PayPal.</p>
    </ng-container>
    <!-- Not voice + Smartpit-->
    <ng-container *ngIf="(data.item |async) !== 'Voice + Data SIM' && (data.payment_method |async) === 'SmartPit Card'">
      <div class="dialog__contents--title">
        <mat-icon class="icon">help</mat-icon>
        <p class="title" mat-dialog-title>Will the deposit be refunded?</p>
      </div>
      <p class="dialog__contents--info">Since the deposit will be used to pay for the last 2 months of your contract,
        you will not receive a refund from us.</p>
    </ng-container>
  </mat-dialog-content>
</div>