<div class="returning__simcard">
    <button class="close--button" mat-icon-button mat-dialog-close matRippleDisabled="disabled">
        <mat-icon class="cancel--icon">cancel</mat-icon>
    </button>

    <mat-dialog-content class="mat-typography">
        <div class="dialog__contents--title">
            <mat-icon class="icon">help</mat-icon>
            <p class="title" mat-dialog-title>Why do I need to return the {{(data$ |async) === 'Pocket WiFi' ? 'the items' : 'SIM cards'}}?</p>
        </div>
        <p class="dialog__contents--info" *ngIf="(data$ |async) !== 'Pocket WiFi'">Please note that all SIM cards are rented out to our customers and they are to
            be returned to us after the contract ends.
            In the case the SIM card is lost or it is difficult to return the SIM card to us, please note that
             <span class="sakura-text"> is no due date or extra charge.</span>

            However, we kindly ask our customers to make an effort and try to return the SIM card to us.</p>
        <p class="dialog__contents--info" *ngIf="(data$ |async) === 'Pocket WiFi'">Please note that all products are rented out to our customers and they are to be returned to us after the contract ends.</p>
        <div class="dialog__contents--title">
            <mat-icon class="icon">help</mat-icon>
            <p class="title" mat-dialog-title>How to return the {{(data$ |async) === 'Pocket WiFi' ? 'the items' : 'SIM cards'}}</p>
        </div>
      <p class="dialog__contents--info" *ngIf="(data$ |async) !== 'Pocket WiFi'">You can purchase a Letter Pack Lite (370 JPY) or go directly to the Post office to return the item.</p>
      <p class="dialog__contents--info" *ngIf="(data$ |async) === 'Pocket WiFi'">Drop the return envelope into a post box or give it to the post office staff to return the items to us.</p>
      <div class="dialog__contents--footer">
          <p class="title--footer">The Letter Pack Lite Envelope is sold at Post Offices and Lawson. It is convenient because it can be deposited in a post box as well as at a postal counter.</p>
          <!-- <img class="img--footer" src="../../../../../../assets/images/Group 367.svg" alt="location-image"> -->
          <img class="img--footer" src="../../../../../../assets/images/letterpack.jpg" alt="location-image">
      </div>
      <div class="dialog__contents--wifi"  *ngIf="(data$ |async) === 'Pocket WiFi'">
        <p class="title--wifi">Lost fee if the item is not returned by the 5th of the following month:</p>
        <table>
          <tr>
            <td>Pocket WiFi</td>
            <td>30,000 JPY</td>
          </tr>
          <tr>
            <td>AC Adapter</td>
            <td>2,000 JPY</td>
          </tr>
          <tr>
            <td>Cable</td>
            <td>1,000 JPY</td>
          </tr>
          <tr>
            <td>Pouch</td>
            <td>1,000 JPY</td>
          </tr>
        </table>
      </div>
    </mat-dialog-content>
</div>
