<div class="app-contract-details">
  <div class="widget">
    <!-- <ng-container *ngIf="back_button && invoices"> -->
      <app-menu [title]="contract_code$ | async" [back_button]="back_button" [invoices]="invoices"></app-menu>
    <!-- </ng-container> -->
    <div class="widget-content">
      <div class="widget-content_product-name">
        <h4>Products:</h4>
      </div>
      <div class="widget-content_product-list">
        <!-- <mat-accordion>  -->
        <app-product *ngFor="let product of (products$ | async)" [product]="product" [contract_code$]="contract_code$" ></app-product>
        <!-- </mat-accordion>  -->
      </div>
    </div>
  </div>
</div>

