import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

export class DialogButton {
  public label: string;
  public classes: Array<string>;
  public id: string;
  constructor(label: string, classes: Array<string>, id: string) {
    this.label = label;
    this.classes = classes;
    this.id = id;
  }
}

export class TwoButtonDialogData {
  public buttons: Array<DialogButton>;
  private _safeMessage: SafeHtml;
  constructor(
    private _sanitizer: DomSanitizer, // given from component
  ) {
    this.buttons = new Array<DialogButton>();
  }

  public set message(message: string) {
    this._safeMessage = this._sanitizer.bypassSecurityTrustHtml(message);
  }
  public get safeMessage(): SafeHtml {
    return this._safeMessage;
  }
}

@Component({
  selector: 'app-two-button-dialog',
  templateUrl: './two-button-dialog.component.html',
  styleUrls: ['./two-button-dialog.component.scss']
})
export class TwoButtonDialogComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<TwoButtonDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: TwoButtonDialogData
  ) {
  }

  ngOnInit() {
    console.log(this.data);
  }

  public onClickDialogButton(event: any): void {
    this.dialogRef.close(event);
  }
}
