import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-policy-contents',
  templateUrl: './policy-contents.component.html',
  styleUrls: ['./policy-contents.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PolicyContentsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
