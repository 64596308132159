<div class="points-description">
  <div class="widget">
    <app-menu [title]="'My Sakura Point'" [back_button]="true"></app-menu>
    <div class="widget__container">
      <div class="widget__container__current-points">
        <h3 class="title">Current Points</h3>
      </div>

      <div class="widget__container__points">
        <div class="points--image">
          <img class="image" src="../../../../../assets/images/point-small.svg" alt="points image" srcset="">
        </div>
        <div class="points--number">
          <p class="number">{{ points.toLocaleString('en-US') }} Pt</p>
        </div>
      </div>

      <div class="widget__container__exchange-points">
        <h3 class="title">Exchange Points</h3>
      </div>
      <div class="widget__container__exchange-points-image">
        <div class="images">
          <img src="../../../../../assets/images/point-big.svg" alt="" srcset="">
        </div>
        <div class="images">
          <img class="images__arrow" src="../../../../../assets/images/right-arrow.svg" alt="" srcset="">
        </div>
        <div class="images"><img src="../../../../../assets/images/coupon.svg" alt="" srcset=""></div>
      </div>
      <div class="widget__container__notes">
        <p class="notes">If you have enough points, you can exchange them for the following items.</p>
      </div>

      <div class="widget__container__exchange-items">
        <div class="title--item">
          <h3 class="title">Exchangeable items</h3>
        </div>

        <div class="items">
          <div class="item">
            <img src="../../../../../assets/images/coupon-small.svg" alt="coupon image" srcset="">
            <p>Gift Card</p>
          </div>
          <div class="item">
            <img src="../../../../../assets/images/sim.svg" alt="sim card image" srcset="">
            <p>Sim Card</p>
          </div>
          <div class="item">
            <img src="../../../../../assets/images/more.svg" alt=" and more image" srcset="">
            <p>and more</p>
          </div>
        </div>
        <p class="items-notes">Please visit the <a href="https://store.sakuramobile.jp/point-redemption?email={{email}}"
            target="_blank">exchange points</a> for more details.</p>
      </div>

      <div class="widget__container__get-points">
        <h3 class="title">Get Points</h3>
      </div>

      <div class="widget__container__get-points-information">
        <h4 class="title--info">There are 2 ways for you to get points.</h4>
        <div class="information">
          <div class="information-up">
            <span class="info-number">1</span><span class="info">You will get points after each month’s usage fee is
              charged. The points will be calculated depending on the amount on the invoice.</span><br>
          </div>
          <div class="information-down">
            <span class="info-number">2</span><span class="info">Introduce your friends to Sakura Mobile. Please visit
              the
              <a href="https://support.sakuramobile.jp/hc/en-us/articles/4408255554317"
                target="_blank">following
                website</a> for more details about the campaign.</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
