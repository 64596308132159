<div class="loadingio-spinner-spinner-ppkc441uvp" *ngIf="spinner$ |async">
  <div class="ldio-c9s6syndqjg">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>
