<div class="pay__by__new__card">
  <div class="widget">
    <app-menu [back_button]="back_button" [title]="'Pay Your Bill'"></app-menu>
    <div class="widget__contents">
      <div class="widget__contents--confirm-title">
        <mat-icon class="icon-confirm">check_circle</mat-icon>
        <p class="title-confirm">Confirmation</p>
      </div>

      <div class="widget__contents--payment-date" *ngIf="invoice$ | async as invoice">
        <span class="date-payment">Payment for {{ invoice.invoice_month }}</span>
        <span class="amount">{{ invoice.sub_total + invoice.sub_total_tax | number }} JPY</span>
      </div>
      <div class="widget__contents--arrow--bottom">
        <mat-icon>signal_cellular_4_bar</mat-icon>
      </div>

      <div class="widget__contents--different-card">
        <img src="../../../../assets/images/logo-payment.svg" alt="logo payments" class="image" />
        <p class="details">Use a different card</p>
      </div>

      <div class="widget__contents--infor-update">
        <p>
          Once the new card information is registered, the old card information
          will be deleted from our system. Please submit the card update form
          again if you would like to change back to the old card.
        </p>
      </div>

      <form [formGroup]="CardInformation">
        <div class="widget__contents--card-holder-name">
          <label class="label-card">Card Holder’s Name</label>
          <p class="label-small">Must match the one registered on the card</p>
          <mat-form-field class="form-field">
            <input matInput class="input" formControlName="card_holder_name" required />
            <mat-error *ngIf="card_holder_name.hasError('pattern')">Capital letters</mat-error>
            <mat-error *ngIf="card_holder_name.hasError('required')">Required</mat-error>
            <mat-hint class="card-hint">Please do not insert any special characters such as “-“,
              “.”,...</mat-hint>
          </mat-form-field>
        </div>

        <div class="widget__contents--card-number">
          <label class="label-card">Card Number</label>
          <mat-form-field class="form-field">
            <input matInput formControlName="card_number" [value]="card_number$ | async" required />
            <mat-error *ngIf="
                card_number.hasError('pattern') &&
                !card_number.hasError('required')
              ">Number only</mat-error>
            <mat-error *ngIf="card_number.hasError('required')">Required</mat-error>
            <mat-error *ngIf="
                card_number.hasError('maxlength') &&
                !card_number.hasError('required') &&
                !card_number.hasError('pattern')
              ">Max length 16 number</mat-error>
          </mat-form-field>
        </div>

        <div class="widget__contents--expire-date">
          <label class="label-card">Expiry Date</label>
          <mat-form-field class="form-field time-input" floatLabel="never" hideRequiredMarker>
            <input matInput formControlName="expire_month" placeholder="MM" maxlength="2" required />
            <mat-error *ngIf="
                !expire_month.hasError('required') &&
                expire_month.hasError('pattern')
              ">Number only</mat-error>
            <mat-error *ngIf="expire_month.hasError('required')">Required</mat-error>
            <mat-error *ngIf="
                !expire_month.hasError('required') &&
                !expire_month.hasError('pattern') &&
                CardInformation.hasError('cardExpired')
              ">Invalid date</mat-error>
          </mat-form-field>
          <mat-form-field class="form-field time-input" floatLabel="never" hideRequiredMarker>
            <input matInput formControlName="expire_year" placeholder="YY" maxlength="2" required />
            <mat-error *ngIf="
                !expire_year.hasError('required') &&
                expire_year.hasError('pattern')
              ">Number only</mat-error>
            <mat-error *ngIf="expire_year.hasError('required')">Required</mat-error>
            <mat-error *ngIf="
                !expire_year.hasError('required') &&
                !expire_year.hasError('pattern') &&
                CardInformation.hasError('cardExpired')
              ">Invalid date</mat-error>
          </mat-form-field>
        </div>

        <div class="widget__contents--cvv">
          <label class="label-card cvv-label">CVV</label>
          <mat-form-field class="form-field time-input" floatLabel="never" hideRequiredMarker>
            <input matInput formControlName="card_cvv" placeholder="123" required />
            <mat-error *ngIf="
                card_cvv.hasError('pattern') && !card_cvv.hasError('required')
              ">Number only</mat-error>
            <mat-error *ngIf="
                card_cvv.hasError('required') &&
                !card_cvv.hasError('required') &&
                !card_cvv.hasError('pattern')
              ">Required</mat-error>
            <mat-error *ngIf="
                card_cvv.hasError('maxlength') &&
                !card_cvv.hasError('required') &&
                !card_cvv.hasError('pattern')
              ">Max length 4 number</mat-error>
          </mat-form-field>
        </div>

        <div class="widget__contents--card-type">
          <label class="label-card type">Card Type</label>
          <mat-radio-group formControlName="payment_method">
            <mat-radio-button value="Debit">Debit</mat-radio-button>
            <mat-radio-button value="Credit">Credit</mat-radio-button>
          </mat-radio-group>
        </div>

        <div class="widget__contents--country">
          <label class="label-card">Card Issuing Country</label>
          <mat-form-field class="form-field">
            <mat-select formControlName="country">
              <mat-option *ngFor="let country of countryListData" [value]="country.code3">{{ country.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </form>

      <div class="widget__contents--term">
        <mat-icon class="term-check" (click)="toggleChecked()">{{
          mat_content
        }}</mat-icon>
        <span class="term-content">I confirm the card information provided is correct and would like to
          proceed.</span>
      </div>

      <button class="submit-button" [ngClass]="{ 'submitted--button': checked && CardInformation.valid }"
        [disabled]="!(checked && CardInformation.valid)" [class.disabled]="spinner$ | async" (click)="payInvoice()">
        Pay<app-spinner></app-spinner>
      </button>
    </div>
  </div>
</div>
