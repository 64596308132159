<div class="widget-content__unpaidinvoice content-invoice" *ngIf="!(pay_status_active)">
    <mat-icon>notification_important</mat-icon>
    <span>Unpaid invoice</span>
</div>
<mat-expansion-panel class="active">
    <mat-expansion-panel-header>
        <mat-panel-title>
            <mat-icon class="header-icon icon_active">{{title_icon}}</mat-icon>
            <div class="title">
                <p>{{contract_code_active}}</p>
                <p>{{subscription_plan_active}}</p>
                <p *ngIf="start_day_active === null ? false : true">Start date:
                    {{start_day_active| date :"yyyy-MM-dd"}}</p>
                <p *ngIf="end_day_active === null ? false : true">End date:
                    {{end_day_active| date :"yyyy-MM-dd"}}</p>
            </div>
        </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="contract-content">
        <div class="contract-details" 
            (click)="getContractDetailActive(contract_id_active)">
            <p>Contracts details</p>
            <mat-icon>chevron_right</mat-icon>
        </div>

        <div class="history-payment" (click)="PaymentHistory()">
            <P>Payments</P>
            <mat-icon>chevron_right</mat-icon>
        </div>
    </div>
</mat-expansion-panel>
