<div class="reasons_cancel">
  <div class="reasons_cancel__title">
    <mat-icon class="icon">cancel</mat-icon>
    <p class="title">Contract Cancellation</p>
  </div>
  <div class="reasons_cancel__form">
    <div class="reasons_cancel__form__header">
      <div class="square-dot"></div>
      <p class="title_form">Reason(s) to cancel</p>
    </div>
    <div class="options">
      <button
        appChooseReason
        class="btn_option"
        type="submit"
        #thamso1
        [value]="value1"
        (click)="reasonCancellSelect(thamso1)"
        required
      >
        Going back to home country
      </button>
      <button
        appChooseReason
        class="btn_option"
        type="submit"
        #thamso2
        [value]="value2"
        (click)="reasonCancellSelect(thamso2)"
        required
      >
        I no longer use a cellphone/WiFi/Fiber Internet
      </button>
      <button
        appChooseReason
        class="btn_option"
        type="submit"
        #thamso3
        [value]="value3"
        (click)="reasonCancellSelect(thamso3)"
        required
      >
        Moving to another cellular/WiFi/Fiber service provider
      </button>
      <p class="rating-title" *ngIf="!showAnotherReason">
        If you don't mind, please let us know your opinions so that we can
        improve our service in feature.
      </p>
      <ng-container *ngIf="showAnotherReason">
        <div class="another__service" [formGroup]="cancelReasons_Case2">
          <div class="another__service_icons">
            <mat-icon class="ff-icon">fast_forward</mat-icon>
          </div>
          <div class="another__service_title">
            <p>
              If you don't mind, please let us know your opinions so that we can
              improve our service in feature.
            </p>
            <app-rate-service (ratingUpdated)="onRatingChanged($event)">
            </app-rate-service>
          </div>
          <div class="another__service_option1">
            <button class="round_number">1</button>
            <span class="text_option1"
              >Which service provider/plan are you moving to?</span
            >
          </div>

          <div class="another__service_form">
            <div class="form">
              <p class="form_title">Service provider:</p>
              <mat-form-field class="form_select">
                <mat-label class="form_select-label"
                  >Select service provider</mat-label
                >
                <mat-select
                  class="form_select-options"
                  (selectionChange)="serviceProviderChange($event)"
                >
                  <mat-option
                    *ngFor="let provider of providers"
                    [value]="provider"
                    >{{ provider }}</mat-option
                  >
                </mat-select>
              </mat-form-field>

              <ng-container *ngIf="selectedProvider === 'Other'">
                <p class="form_title">Other:</p>
                <mat-form-field class="form_input" floatLabel="never">
                  <input
                    value=""
                    matInput
                    placeholder="Please write your new provider here"
                    formControlName="move_to_service_provider"
                  />
                </mat-form-field>
              </ng-container>

              <p class="form_title">Plan:</p>
              <mat-form-field class="form_input" floatLabel="never">
                <input
                  matInput
                  placeholder="Please write your plan here"
                  formControlName="move_to_plan"
                />
              </mat-form-field>
            </div>
          </div>
          <div class="another__service_option1">
            <button class="round_number">2</button>
            <span class="text_option1">Reason(s) for changing providers</span>
          </div>
          <div class="another__service_reasons">
            <div class="form_reasons">
              <mat-selection-list class="form_reasons_selects">
                <mat-list-option
                  class="option"
                  [ngClass]="{ choose: active1 }"
                  [value]="'Monthly fee is too high'"
                  (click)="reasonChangeProvider(listItem1)"
                  #listItem1
                >
                  Monthly fee is too high
                </mat-list-option>
                <mat-list-option
                  class="option"
                  [ngClass]="{ choose: active2 }"
                  [value]="'Service area is poor'"
                  (click)="reasonChangeProvider(listItem2)"
                  #listItem2
                >
                  Service area is poor
                </mat-list-option>
                <mat-list-option
                  class="option"
                  [value]="'Internet is too slow'"
                  [ngClass]="{ choose: active3 }"
                  (click)="reasonChangeProvider(listItem3)"
                  #listItem3
                >
                  Internet is too slow
                </mat-list-option>
                <mat-list-option
                  class="option"
                  [value]="'I am using a family plan'"
                  [ngClass]="{ choose: active4 }"
                  (click)="reasonChangeProvider(listItem4)"
                  #listItem4
                >
                  I am using a family plan
                </mat-list-option>
                <mat-list-option
                  class="option"
                  [value]="'Poor customer service'"
                  [ngClass]="{ choose: active5 }"
                  (click)="reasonChangeProvider(listItem5)"
                  #listItem5
                >
                  Poor Customer Service
                </mat-list-option>
              </mat-selection-list>

              <div class="form_reasons_button">
                <button
                  class="other_button button"
                  (click)="toggleButton()"
                  *ngIf="!togglebutton"
                >
                  Other (Please write down reason)
                </button>
                <mat-form-field
                  class="form_input_other"
                  floatLabel="never"
                  *ngIf="togglebutton"
                >
                  <p class="form_title" (click)="toggleButton()">Other</p>
                  <input
                    matInput
                    class="input"
                    formControlName="reasons_for_changing_provider_other"
                  />
                </mat-form-field>
              </div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
