<div class="change-payment">
  <div class="widget">
    <app-menu [back_button]="true" [title]="'Account'"></app-menu>

    <div class="widget__contents">
      <h1 class="title">Change Payment Method</h1>
      <div class="widget__contents--image-change">
        <div class="image">
          <img
            src="../../../../assets/images/creditcard.png"
            alt="credit card payment"
            class="img--payment"
          />
          <p class="title--change">Credit/debit card</p>
        </div>

        <div class="arrow">
          <img
            src="../../../../assets/images/Polygon_6.png"
            alt="transform image"
          />
        </div>
        <div class="image">
          <img
            src="../../../../assets/images/cashpayment.png"
            alt="cash payment"
            class="img--payment"
          />
          <div class="info-cash">
            <p class="title--change cash-payment">Cash payment</p>
            <mat-icon>help</mat-icon>
          </div>
        </div>
      </div>
      <div class="widget__contents--attention">
        <mat-icon class="icon-warning">warning</mat-icon>
        <h3>Attention:</h3>
      </div>

      <div class="widget__contents--attention-info">
        <ul>
          <li>
            A <span class="sakura--text">{{amountPrice$  |async | number}} JPY deposit</span> is required
            for this plan.
          </li>
          <li>This deposit is to ensure future payment.</li>
          <li>
            The last 2 months of contract will be deducted from this deposit.
            The remaining amount of
            <span class="sakura--text"
              >will be refunded to your Japanese bank account.</span
            >
          </li>
          <li>
            It is not possible to switch back to card payment after switching to
            the cash payment method.
          </li>
          <li>
            It is also not possible to suspend your plan after switching to the
            cash payment method.
          </li>
        </ul>
      </div>

      <div class="widget__contents--deposit-required" (click)="openDialog()">
        <mat-icon class="icon-help">help</mat-icon>
        <p>Why is a deposit required?</p>
      </div>
      <div class="widget__contents--fast-forward-icon">
        <mat-icon class="fast-forward-icon">fast_forward</mat-icon>
      </div>

      <div class="widget__contents--choose-method">
        <h3>Choose the payment method for the deposit</h3>
        <button
          mat-button
          class="method-button"
          (click)="
            chooseMethod('CREDIT CARD');
            isChooseCredit = !isChooseCredit;
            isChooseCash = false
          "
          [ngClass]="{ 'choosed-button': isChooseCredit }"
        >
        Use current credit/debit card
        </button>
        <button
          mat-button
          class="method-button"
          (click)="
            chooseMethod('SMARTPIT');
            isChooseCash = !isChooseCash;
            isChooseCredit = false
          "
          [ngClass]="{ 'choosed-button': isChooseCash }"
        >
        Pay at the convenience store
        </button>
      </div>

      <div class="widget__contents--submit-button">
        <button
          mat-button
          class="next-button"
          (click)="confirmationChange()"
          [ngClass]="{ 'submitted--button': isChooseCredit || isChooseCash }"
          [disabled]="!isChooseCash && !isChooseCredit"
        >
          Next
        </button>
        <div class="stepper">
          <button mat-icon-button disabled>
            <mat-icon>lens</mat-icon>
          </button>
          <button mat-icon-button disabled>
            <mat-icon>
              radio_button_unchecked
            </mat-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
