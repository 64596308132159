import {
  Component,
  OnInit,
  ViewEncapsulation,
  Renderer2,
  INJECTOR,
  Inject,
  OnDestroy,
} from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormControlName,
  FormBuilder,
  Validators,
} from '@angular/forms';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { countryListAllIsoData as countryList } from 'src/app/country';
import { PaymentInvoicesService } from 'src/app/services/payment-invoices.service';
import { Constants } from 'src/app/constants';
import { MatSnackBar } from '@angular/material';
import { SubSink } from 'subsink';
import { AccountInformationService } from 'src/app/services/account-information.service';
import { Invoice } from 'src/app/types/invoice';
import { LoadingService } from 'src/app/services/loading.service';
import { RouterHistoryService } from 'src/app/services/router-history.service';
import { CustomValidators } from 'src/app/custom-validators';

@Component({
  selector: 'app-pay-by-new-card',
  templateUrl: './pay-by-new-card.component.html',
  styleUrls: ['./pay-by-new-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PayByNewCardComponent implements OnInit, OnDestroy {
  private subs = new SubSink();
  back_button = true;
  mat_content = 'radio_button_unchecked';
  checked = false;
  countryListData: {
    code: string;
    code3: string;
    name: string;
    number: string;
  }[];
  invalid_date: boolean;
  CardInformation: FormGroup;
  card_holder_name: FormControl;
  card_number: FormControl;
  expire_month: FormControl;
  expire_year: FormControl;
  card_cvv: FormControl;
  payment_method: FormControl;
  country: FormControl;
  invoice_id: number;
  member_id: string;
  card_number$: Observable<string>;
  invoice$: Observable<Invoice> = this._paymentInvoices.invoice$;
  spinner$ = this._loadingService.spinner$;
  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private _renderer2: Renderer2,
    private _paymentInvoices: PaymentInvoicesService,
    private _accountService: AccountInformationService,
    private _loadingService: LoadingService,
    readonly _routerHistoryService: RouterHistoryService
  ) {}

  ngOnInit() {
    this.countryListData = countryList;
    this.createFormControl();
    this.createCardInformationForm();
    // Reformat card number with blance space
    this.card_number$ = this.CardInformation.get(
      'card_number'
    ).valueChanges.pipe(
      map((string) =>
        string
          .replace(/[^0-9]/g, '')
          .replace(/\W/gi, '')
          .replace(/(.{4})/g, '$1 ')
      )
    );
    // create cript tab
    this._paymentInvoices.createScriptTab(this._renderer2);
    // Subcription
    this.subs.sink = this._paymentInvoices.invoice$.subscribe(
      (invoice) => (this.invoice_id = invoice.id)
    );
    this.subs.sink = this._accountService
      .userInformation$
      .subscribe((res) => {
        this.member_id = res.data.email;
      });
  }

  payInvoice() {
    this._paymentInvoices.setPayType = false;
    if (this.checked && this.CardInformation.valid) {
      this._loadingService.setSpinnerEvent = true;
      const card_params = {
        holdername: this.card_holder_name.value,
        cardno: this.card_number.value.trim().replace(/\s+/g, ''),
        expire: this.expire_year.value + this.expire_month.value,
        securitycode: this.card_cvv.value,
        tokennumber: '3',
      };
      // console.log(Constants.SHOP_ID, card_params);
      (<any>window).Multipayment.init(Constants.SHOP_ID);
      (<any>window).Multipayment.getToken(card_params,
        (repsonse) => {
          console.log('repsonse : ', repsonse);
          if (repsonse.resultCode !== '000') {
            this._snackBar.dismiss();
            this._snackBar.open(
              'Your card information is incorrect',
              'OK',
              Constants.defaultSnackBarConfig('error')
            );
          } else {
            const token = repsonse.tokenObject.token[0];
            const token_update = repsonse.tokenObject.token[1];
            this._paymentInvoices.payInvoice_newCard(
              token,
              token_update,
              this.invoice_id,
              this.member_id,
              this.card_holder_name.value,
            );
          }
        }
      );
    }
  }

  toggleChecked() {
    this.checked = !this.checked;
    if (this.checked === false) {
      this.mat_content = 'radio_button_unchecked';
    } else {
      this.mat_content = 'radio_button_checked';
    }
  }
  createCardInformationForm() {
    this.CardInformation = this.fb.group({
      card_holder_name: this.card_holder_name,
      card_number: this.card_number,
      expire_month: this.expire_month,
      expire_year: this.expire_year,
      card_cvv: this.card_cvv,
      payment_method: this.payment_method,
      country: this.country,
    },
    {
      validators: [CustomValidators.checkCardExpired()]
    });
  }
  createFormControl() {
    this.card_holder_name = this.fb.control(
      '',
      Validators.compose([Validators.required, Validators.pattern('^[A-Z ]*')])
    );
    this.card_number = this.fb.control(
      '',
      Validators.compose([
        Validators.required,
        Validators.pattern('^[0-9 ]+'),
        Validators.minLength(15),
        Validators.maxLength(19),
      ])
    );
    this.expire_month = this.fb.control(
      null,
      Validators.compose([
        Validators.required,
        Validators.pattern('^[0-9 ]+')
      ])
    );
    this.expire_year = this.fb.control(
      null,
      Validators.compose([
        Validators.required,
        Validators.pattern('^[0-9 ]+'),
        Validators.minLength(2)
      ])
    );
    this.card_cvv = this.fb.control(
      '',
      Validators.compose([
        Validators.required,
        Validators.pattern('^[0-9 ]+'),
        Validators.minLength(3),
        Validators.maxLength(4),
      ])
    );
    this.payment_method = this.fb.control('', Validators.required);
    this.country = this.fb.control('', Validators.required);
  }
  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
