import { Directive, Renderer2, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appPaymentmethod]'
})
export class PaymentmethodDirective {

  constructor(
    private render: Renderer2,
    private el: ElementRef
  ) { }
   @HostListener('click') onClick() {
     this.render.addClass(this.el.nativeElement , 'choose-method');
   }
}
