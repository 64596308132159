import { Component, OnInit, Input, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ContractsService } from 'src/app/services/contracts.service';
import { catchError, map } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { Product } from 'src/app/types/product';
@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductComponent implements OnInit, OnDestroy {
  private subs = new SubSink(); // Subsink: unscrible all subscriptions
  public product_title: string;
  public sim_id: string;
  public plan: any;
  public monthly_data: any;
  public type_product: string;
  public type_icon: string;
  public product_id: number;
  public product_type_id: number;
  public start_at: string;
  public end_at: string;
  public item: string;
  public email: string;
  public device_id: string;
  public user: string;
  public payment_method: string;
  public active_flat: boolean;

  phone_number$: Observable<string>;
  constructor(
    private _router: Router,
    private _contractServices: ContractsService
  ) { }

  @Input() product: Product;
  @Input() contract_code$: Observable<string>;

  ngOnInit() {
    const date_start = this.product.product.start_at === null ? null : Date.parse(this.product.product.start_at);
    const date_end = this.product.product.end_at === null ? null : Date.parse(this.product.product.end_at);
    this.active_flat = this.checkProduct(date_start, date_end);
    this.product_id = this.product.product.contract_product_id;
    this.sim_id = this.product.property.value;
    this.start_at = this.product.product.start_at;
    
    this.end_at = this.product.product.end_at;
    this.subs.sink = this._contractServices.pay_method$.subscribe(string => {
      this.payment_method = string !== null ? string : null;
    });
    this.product_type_id = this.product.type.product_type_id;
    this.type_product = this._contractServices.check_Product_Type(this.product.type.title);
    console.log('type product =====>', this.type_product);
    
    this.type_icon = this.type_product === 'wifi' ? 'wifi' : 'sim_card';
    this.product_title = this.product.type.title;
    this.monthly_data = null;

    this.phone_number$ = this._contractServices.ContractProduct(this.product_id).pipe(
      map(res => res.data.properties[0].property.value),
      catchError(error => {
        return of(null);
      })
    );
  }
  // Redirect product detail
  public productDetail() {
    // this._contractServices.contractCode(this.contract_code$);
    this._contractServices.set_MonthlyPlan = this.monthly_data;
    this._contractServices.set_start_at_Product = this.start_at;
    this._contractServices.setProduct = this.product;
    this._router.navigate([`portal/contract-product/${this.product_id}`]);
  }
  // Get user properties and contract product for Wifi_product

  public userProperties_contractProduct() {
    this._contractServices.ContractProduct_UserPropertie(this.product_id)
      .subscribe(
        res => {
          const [user_properties, contract_product] = res;
          this.item = this._contractServices.check_Product_Type(contract_product.properties[0].type.title);
          this.email = user_properties.user.email;

          contract_product.properties.forEach(property => {
            if (property.type.key === 'DEVICE CODE 1') {
              this.device_id = property.property.value;
            }
          });
          user_properties.properties.forEach(
            user => {
              if (user.type.key === 'USER FULL NAME') {
                this.user = user.property.value;
              }
            }
          );
        },
        err => console.log(err)
      );
      this._contractServices.setProduct = this.product;
      this._router.navigate([`portal/contract-product/${this.product_id}`]);
  }
  ngOnDestroy() {
    this.subs.unsubscribe();
  }
  private checkProduct(date_start: number, date_end: number): boolean {
    const date_now = Date.now();
    if (date_start <= date_now) {
      if (date_end === null) {
        return false;
      } else if (date_end >= date_now) {
        return false;
      } else {
        return true;
      }
    } else {
      return true;
    }
  }
}
