import { Injectable } from '@angular/core';
import { Subject, Observable, Subscription } from 'rxjs';
import { Utils } from 'src/app/utils';

@Injectable({
  providedIn: 'root'
})
export class ShareDataService {
  private _data = new Subject<Object>();
  public data$ = this._data.asObservable();
  private _dataSubscription: Subscription;
  private _local_data: Object;
  private _running: boolean;

  constructor(
  ) {
    this._running = false;
    this._local_data = new Object;
  }

  public run(): void {
    if (this._running === false) {
      this._dataSubscription = this.data$.subscribe(
        data => {
          if (this._running === false) {
            console.log('ShareDataService::run : started');
            this._running = true;
          }
          this._local_data = data;
        }
      );
    }
  }

  public shutdown(): void {
    if (this._running === true) {
      this._dataSubscription.unsubscribe();
      this._running = false;
    }
    console.log('ShareDataService::shutdown : stopped');
  }

  private set data(data: Object) {
    this._data.next(data);
  }

  public getAt(key: string): any {
    if (key in this._local_data) {
      return this._local_data[key];
    }
    console.log('ShareDataService::getAt : [' + key + '] does not exist');
    return null;
  }

  public setAt(key: string, data: any): void {
    console.log('ShareDataService::setAt : ' + key + ' : ' + JSON.stringify(data));
    if (key in this._local_data) {
      delete this._local_data[key];
    }
    this._local_data[key] = data;
    this.data = this._local_data;
  }

  public removeAt(key: string): void {
    if (key in this._local_data) {
      delete this._local_data[key];
      this.data = this._local_data;
      return;
    }
  }
}
