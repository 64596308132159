<div class="payment__information">
  <div class="widget">
    <app-menu [back_button]="back_button" [title]="'Pay Your Bill'"></app-menu>

    <div class="widget__contents">
      <div class="widget__contents--confirm-title">
        <mat-icon class="icon-confirm">check_circle</mat-icon>
        <p class="title-confirm">Confirmation</p>
      </div>

      <div class="widget__contents--payment-date" *ngIf="invoice$ | async as invoice">
        <span class="date-payment">Payment for {{invoiceMonthToUsedMonth(invoice.invoice_month)}}</span>
        <span class="amount"> {{invoice.sub_total + invoice.sub_total_tax | number}} JPY</span>
      </div>

      <div class="widget__contents--payment-method">
        <img src="../../../../assets/images/logo-payment.svg" alt="logo payments" class="image">
        <p class="details">Payment Method</p>
      </div>

      <div class="widget__contents--information-card">
        <p class="title-card">Use registered card</p>
        <div class="information" *ngIf="creditCardInformation$ |async as creditCard">
          <p class="name">{{creditCard.holder_name |uppercase}}</p>
          <p class="number-card">{{creditCard.card_no}}</p>
          <p class="number-card">Expire date: {{expireDate$ |async}}</p>
        </div>
      </div>

      <div class="widget__contents--or">
      <div class="line"></div>
        <span class="or">or</span>
      <div class="line"></div>
      </div>

      <div class="widget__contents--different-card" routerLink="/payment-information/pay-by-new-card">
        <mat-icon class="add-icon">add</mat-icon>
        <p class="title-card">Use a different card</p>
      </div>

      <div class="widget__contents--pay-button">
        <button class="pay--button" (click)="paymentInvoice()" [class.disabled]="spinner$ |async" [disabled]="isPayDisabled()">Pay<app-spinner></app-spinner></button>
      </div>
    </div>
  </div>
</div>
