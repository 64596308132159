<div class="option-item">
    <div id="switch_5g_controller" class="controller">
        <div class="title">
            Use 5G
        </div>
        <div class="enabled"><mat-checkbox (click)="onClickEnable5G($event)" (change)="onEnable5GChanged($event)"  [(ngModel)]="enable5G"></mat-checkbox>
        </div>
        <div class="info"><mat-icon (click)="onClick5GDescription($event)">help</mat-icon></div>
    </div>
    <div id="switch_5g_description" [class]="show5GDescription">
        <ul>
            <li>This setting can not be configured between 8PM ~ 9AM.</li>
            <li>Changing this setting can only be done once a day. If you would like to undo the change, then it can only be done the next day.</li>
        </ul>
        <br/>
        <span class="strong">[Caution]</span>
        <ul>
            <li>You must have a 5G compatible phone.</li>
        </ul>
        <p>※This 5G service requires your phone to support 5G frequency signal (Band n28, n78, n79, n257). If your phone does not support these, then you may not be able to receive any service. Please check the compatibility before changing.</p>
        <br/>
        <ul>
            <li>When 5G service is ON, both 4G and 5G service will be available (depending on the coverage).</li>
            <li>When 5G service is OFF, both 3G and 4G service will be available (depending on the coverage).</li>
            <li>Depending on the time of the day, it may take some time for the 5G service to become available.</li>
        </ul>
    </div>
</div>