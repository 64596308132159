import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { Observable } from 'rxjs';
import { ContractsService } from 'src/app/services/contracts.service';
import { TopUpService } from 'src/app/services/top-up.service';
import { LoadingService } from "src/app/services/loading.service";
import { MatSnackBar } from "@angular/material";
import { SubSink } from 'subsink';
import { Constants } from 'src/app/constants';

@Component({
  selector: 'app-top-up-finish',
  templateUrl: './top-up-finish.component.html',
  styleUrls: ['./top-up-finish.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TopUpFinishComponent implements OnInit, OnDestroy {
  private _paramMap: ParamMap;
  private subs = new SubSink(); // Subsink: unscrible all subscriptions
  productId$: Observable<number>;
  back_button = false;
  public completed: boolean = false;
  constructor(
    private router: Router,
    private _activatedRoute: ActivatedRoute,
    private _contractService: ContractsService,
    private _loadingService: LoadingService,
    private _topUpService: TopUpService,
    private _snackBar: MatSnackBar
  ) { }

  ngOnInit() {
    console.log('TopUpFinishComponent::ngOnInit()');
    this._activatedRoute.queryParamMap.subscribe((paramMap) => {
      console.log(
        "TopUpFinishComponent::ngOnInit : paramMap => " +
          JSON.stringify(paramMap)
      );
      this._paramMap = paramMap;

      this._topUpService.completeTopUpPayment(this._paramMap.get("order_id")).subscribe(
        (res) => {
          console.log("TopUpFinishComponent::ngOnInit : res => " + JSON.stringify(res));
          // this.back_button = true;
          this._loadingService.setSpinnerEvent = false;
          this.completed = true;
        },
        (error) => {
          console.log("TopUpFinishComponent::ngOnInit : error => " + JSON.stringify(error));
          let message = "Top up faild";
          if (error.error && error.error.error && error.error.error.message) {
            message = message + ` : ${error.error.error.message}`;
          }
          this._loadingService.setSpinnerEvent = false;
          this._snackBar.dismiss();
          this._snackBar.open(
            message,
            "OK",
            Constants.defaultSnackBarConfig("error")
          );
        }
      );
    });
  }
  goBack() {
    this.subs.sink = this.productId$.subscribe(
      productid => this.router.navigate([`portal/contract-product/${productid}`])
    );
  }
  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
