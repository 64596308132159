<div class="app-password-reset page">
  <div class="widget">
    <div class="widget__title mat-h1">Password Update</div>
    <p class="widget__info">Please enter the email address you used to create the contract and submit.</p>
    <div class="widget-content">
      <form #imageForm=ngForm (ngSubmit)="onClickSubmitWidget($event)" class="mui-form">
        <div class="row">
          <mat-form-field>
            <mat-label>Email address</mat-label>
            <input matInput type="email" #InputUserId
              [formControl]="emailFormControl"
              [errorStateMatcher]="emailErrorMatcher" name="email"
              (keyup)="onEmailChanged($event)" (change)="onEmailChanged($event)"
              autocomplete="on" required >
            <mat-error *ngIf="emailFormControl.hasError('email') &&
              !emailFormControl.hasError('required')">
              Please enter a valid email address
            </mat-error>
            <mat-error *ngIf="emailFormControl.hasError('required')">
              Required
            </mat-error>
          </mat-form-field>
        </div>
        <button mat-raised-button color="accent" class="btn-submit" id="SubmitWidget" type="submit"
          [disabled]="submitDisabled">Submit</button>
      </form>
      <div class="widget-content__footer">
        <p>Already have an account?<a routerLink="/auth/login">Login</a></p>
      </div>
    </div>
  </div>
</div>