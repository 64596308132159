import { Component, OnInit, OnDestroy } from '@angular/core';
import { AccountInformationService } from 'src/app/services/account-information.service';
import { forkJoin, Observable } from 'rxjs';
import { tap, map, shareReplay } from 'rxjs/operators';
import { Constants } from 'src/app/constants';
import { Utils } from 'src/app/utils';
import { Router } from '@angular/router';
import { SubSink } from 'subsink';
import { RouterHistoryService } from 'src/app/services/router-history.service';
import { PaymentMethodDialogComponent } from '../payment-method-dialog/payment-method-dialog.component';
import { MatDialog, MatSnackBar } from '@angular/material';

@Component({
  selector: 'app-personal-detail',
  templateUrl: './personal-detail.component.html',
  styleUrls: ['./personal-detail.component.scss']
})
export class PersonalDetailComponent implements OnInit, OnDestroy {
  public isDialogOpen = false;
  private subs = new SubSink();
  account_payment_info$ = this.accountService.userPaymentInformation$
  .pipe(
    map(res => res.data),
    shareReplay(),
    );
  expire_date$ = this.account_payment_info$.pipe(
      map(res => Utils.convertExpireDate(res.expire))
    );
  is_within_pay_method_change_period: boolean | null = null;
  can_change_pay_method_user: boolean | null = null;
  isReadyClickChangePayMethod: boolean = false;
  constructor(
    private accountService: AccountInformationService,
    private router: Router,
    private dialog: MatDialog,
    private _snackBar: MatSnackBar,
    readonly _routerHistoryService: RouterHistoryService
  ) { }

  ngOnInit() {
    this.fetchPayMethodStatuses();
  }

  ngOnDestroy() {
    this._snackBar.dismiss();
    this.subs.unsubscribe();
  }

  onClickChangePayMethod() {
    if (this.isReadyClickChangePayMethod === false) return this.openErrorSnackbar();
    if (this.is_within_pay_method_change_period === true && this.can_change_pay_method_user === true) {
      this.router.navigate(['/change-payment'])
    }
    else if (this.is_within_pay_method_change_period === true && this.can_change_pay_method_user === false) {
      window.open('https://www.sakuramobile.jp/contact-us/', '_blank');
    }
    else if (this.is_within_pay_method_change_period === false) {
      this.openCannotChangePayMethodDialog();
    }
    else {
      this.openErrorSnackbar();
    }
  }

  private fetchPayMethodStatuses() {
    this.isReadyClickChangePayMethod = false;
    forkJoin([
      this.accountService.paymentChangeTerm(),
      this.accountService.CheckPayMethodChangable()
    ]).subscribe(([isWithin, canChange]) => {
      this.is_within_pay_method_change_period = isWithin;
      this.can_change_pay_method_user = canChange;
      this.isReadyClickChangePayMethod = true;
    });
  }

  private openCannotChangePayMethodDialog() {
    if (this.isDialogOpen) return;
    const dialogRef = this.dialog.open(PaymentMethodDialogComponent, {
      panelClass: 'modal_wrapper',
      data: { key: 'value' }
    });
    dialogRef.afterClosed().subscribe(() => {
      this.isDialogOpen = false;
    });
  }

  private openErrorSnackbar() {
    this._snackBar.dismiss();
    this._snackBar.open('Sorry, try again later.', 'OK', Constants.defaultSnackBarConfig('error'));
  }
}
