<div class="invite-friend">
  <div class="widget">
    <app-menu [title]="'Invite Friends'" [back_button]="true"></app-menu>

    <div class="widget__body">
      <h1 class="widget__body__title">invite your friend, rewards for everyone!</h1>
      <div class="widget__body__img">
        <img src="../../../../assets/images/Asset 1.svg" alt="Inivte friends">
      </div>
      <div class="widget__body__referral-code">
        <p class="referral-code-expire-date">*This code will expire on {{expire_date}}</p>
        <p class="referral-code-title">Your referral code* is</p>
        <div class="referral-code-copy">
          <div class="code">
            <span
            class="code__inside"
            >{{referral_code}}</span>
          </div>
          <div class="copy-icon">
              <mat-icon class="icon"
              (click)="copyClipBoard()"
              >content_copy</mat-icon>
          </div>
        </div>
        <div class="toggle-copied" *ngIf="isCopy">
          <p class="toggle-copied__show">Copied to clipboard</p>
        </div>
      </div>
      <div class="widget__body__submit">
        <button mat-raised-button class="submit-button"
        (click)="inviteFriendSIM()" [disabled]="!isAvaibleCode"
        [ngClass]="{disabled__button: !isAvaibleCode}"
        >Refer to SIM/Pocket WiFi</button>
      </div>
      <br/>
      <div class="widget__body__submit">
        <button mat-raised-button class="submit-button"
        (click)="inviteFriendFiber()" [disabled]="!isAvaibleCode"
        [ngClass]="{disabled__button: !isAvaibleCode}"
        >Refer to Fiber Internet</button>
      </div>
      <div class="widget__body__message">
        <p>For more information about the campaign, please visit <a class="link"
          target="_blank"
          href="https://support.sakuramobile.jp/hc/en-us/articles/4408255554317">this page.</a></p>
      </div>
    </div>
  </div>
</div>
