<div class="submitted__request">
  <div class="widget">
    <app-menu [title]="'Top Up'" [humberger]="false" [back_button]="back_button"></app-menu>
    <div *ngIf="completed == false">
      Thank you for completing the card authentication process. <br/>
      <br/>
      This screen will automatically change to the confirmation page.<br/>
      If the screen does not update within 1 minute, please contact our customer support team using this <a href="https://www.sakuramobile.jp/contact-us" target="_blank" rel="noopener noreferrer">link</a>.<br/>
    </div>
    <div *ngIf="completed">
      <div class="widget__body">
        <div class="widget__body--title">
          <p class="title">Request submitted</p>
        </div>
        <div class="widget__body--info">
          <p class="info">
            New data will be added to your account.
          </p>
          <p class="info">
            Note: The top up data will not roll over to the following month.
          </p>
        </div>
        <div class="widget__body--img">
          <img class="imageInside" src="../../../../../assets/images/submitSakuraFlower.svg" />
        </div>
      </div>
    </div>
    <div class="widget__body--submitted">
      <p class="goback">Back to <span class="special" routerLink="/portal">Contracts</span>.</p>
    </div>
  </div>
</div>
