import { Component, OnInit, ViewEncapsulation, Renderer2, OnDestroy } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  FormBuilder,
} from '@angular/forms';
import { countryListAllIsoData as countryList } from 'src/app/country';
import { map, tap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { AccountInformationService } from 'src/app/services/account-information.service';
import { Constants } from 'src/app/constants';
import { MatSnackBar } from '@angular/material';
import { PaymentInvoicesService } from 'src/app/services/payment-invoices.service';
import { SubSink } from 'subsink';
import { LoadingService } from 'src/app/services/loading.service';
import { RouterHistoryService } from 'src/app/services/router-history.service';
import { CustomValidators } from 'src/app/custom-validators';

@Component({
  selector: 'app-update-card',
  templateUrl: './update-card.component.html',
  styleUrls: ['./update-card.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UpdateCardComponent implements OnInit, OnDestroy {
  private subs = new SubSink();
  checked = false;
  mat_content = 'radio_button_unchecked';
  UpdateCard: FormGroup;
  card_holder_name: FormControl;
  card_number: FormControl;
  expire_month: FormControl;
  expire_year: FormControl;
  card_cvv: FormControl;
  payment_method: FormControl;
  country: FormControl;
  countryListData: {
    code: string;
    code3: string;
    name: string;
    number: string;
  }[];
  private member_id: string;
  private holdername: string;
  card_number$: Observable<string>;
  spinner$ = this._loadingService.spinner$;
  constructor(
    private fb: FormBuilder,
    private _snackBar: MatSnackBar,
    private _paymentInvoices: PaymentInvoicesService,
    private _renderer2: Renderer2,
    private accountService: AccountInformationService,
    private _loadingService: LoadingService,
    readonly _routerHistoryService: RouterHistoryService
    ) {}

  ngOnInit() {
    this.createFormControl();
    this.createUpdateCardForm();
    this.countryListData = countryList;
    this._paymentInvoices.createScriptTab(this._renderer2);
    this.card_number$ = this.UpdateCard.get('card_number').valueChanges.pipe(
      map((string) =>
        string
          .replace(/[^0-9]/g, '')
          .replace(/\W/gi, '')
          .replace(/(.{4})/g, '$1 ')
      )
    );
  this.subs.sink =  this.accountService.userPaymentInformation$.pipe(
    map(res => res.data)).subscribe(res => {
      console.log(res);
      this.member_id = res.gmo_member_id;
    }
    );
  this.subs.sink = this.card_holder_name.valueChanges.subscribe(value => this.holdername = value);
  }

  updateCard() {
    if (this.checked && this.UpdateCard.valid) {
      this._loadingService.setSpinnerEvent = true;
      const card_params = {
        holdername: this.card_holder_name.value,
        cardno: this.card_number.value.trim().replace(/\s+/g, ''),
        expire: this.expire_year.value + this.expire_month.value,
        securitycode: this.card_cvv.value,
        tokennumber: '2',
      };
      // console.log(Constants.SHOP_ID + ":" + this.member_id, card_params);
      (<any>window).Multipayment.init(Constants.SHOP_ID);
      (<any>window).Multipayment.getToken(card_params,
        (repsonse) => {
          console.log('repsonse : ', repsonse);
          if (repsonse.resultCode !== '000') {
            this._snackBar.dismiss();
            this._snackBar.open(
              'Your card information is incorrect',
              'OK',
              Constants.defaultSnackBarConfig('error')
            );
          } else {
            const token = repsonse.tokenObject.token[0];
            const token2 = repsonse.tokenObject.token[1];
            this.accountService.UpdateCard(this.member_id, this.holdername.toUpperCase(), token, token2);
          }
        }
      );
    }
  }

  toggleChecked() {
    this.checked = !this.checked;
    if (this.checked === false) {
      this.mat_content = 'radio_button_unchecked';
    } else {
      this.mat_content = 'radio_button_checked';
    }
  }
  createUpdateCardForm() {
    this.UpdateCard = this.fb.group({
      card_holder_name: this.card_holder_name,
      card_number: this.card_number,
      expire_month: this.expire_month,
      expire_year: this.expire_year,
      card_cvv: this.card_cvv,
      payment_method: this.payment_method,
      country: this.country,
    }, {
      validators: [CustomValidators.checkCardExpired()]
    });
  }
  createFormControl() {
    this.card_holder_name = this.fb.control(
      '',
      Validators.compose([Validators.required, Validators.pattern('^[A-Z ]*')])
    );
    this.card_number = this.fb.control(
      '',
      Validators.compose([
        Validators.required,
        Validators.pattern('^[0-9 ]+'),
        Validators.minLength(15),
        Validators.maxLength(19),
      ])
    );
    this.expire_month = this.fb.control(
      '',
      Validators.compose([
        Validators.required,
        Validators.pattern('^[0-9 ]+')
        // CustomValidators.rangeOfMonthCheck({invalidMonth: true})
      ])
    );
    this.expire_year = this.fb.control(
      '',
      Validators.compose([
        Validators.required,
        Validators.pattern('^[0-9 ]+'),
        Validators.minLength(2)
      ])
    );
    this.card_cvv = this.fb.control(
      '',
      Validators.compose([
        Validators.required,
        Validators.pattern('^[0-9 ]+'),
        Validators.minLength(3),
        Validators.maxLength(4),
      ])
    );
    this.payment_method = this.fb.control('', Validators.required);
    this.country = this.fb.control('', Validators.required);
  }
  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
