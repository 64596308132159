import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { ContractsService } from 'src/app/services/contracts.service';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-top-up-finish',
  templateUrl: './top-up-finish.component.html',
  styleUrls: ['./top-up-finish.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class TopUpFinishComponent implements OnInit, OnDestroy {
  private subs = new SubSink(); // Subsink: unscrible all subscriptions
  productId$: Observable<number>;
  back_button = false;
  constructor(
    private router: Router,
    private _contractService: ContractsService
  ) { }

  ngOnInit() {
    this.productId$ = this._contractService.productId$;
  }
  goBack() {
    this.subs.sink = this.productId$.subscribe(
      productid => this.router.navigate([`portal/contract-product/${productid}`])
    );
  }
  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}
