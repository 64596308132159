import { DatePipe } from "@angular/common";
import { ViewEncapsulation } from "@angular/compiler/src/core";
import {
  Component,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { MatDatepickerInputEvent } from "@angular/material";

@Component({
  selector: "app-fiber-reasons-cancellation",
  templateUrl: "./fiber-reasons-cancellation.component.html",
  styleUrls: ["./fiber-reasons-cancellation.component.scss"],
  encapsulation: ViewEncapsulation.None
})
export class FiberReasonsCancellationComponent implements OnInit {
  public show_fiber_yes_case = false;
  @ViewChild("yes", { static: true }) fiber_answer_yes: ElementRef;
  @ViewChild("no", { static: true }) fiber_answer_no: ElementRef;
  @ViewChild("InJapan", { static: false }) fiber_move_in_japan!: ElementRef;
  @ViewChild("Overseas", { static: false }) fiber_move_overseas!: ElementRef;
  @Input() fiberCancelReasons: FormGroup;
  
  constructor(private render2: Renderer2,  private datePipe: DatePipe,) {}

  ngOnInit() {}
  reasonCancelFiber(option: any) {
    this.fiberCancelReasons.get('moving_residense').patchValue(option.value);
    switch (option.value) {
      case "Yes, I will":
        this.setRequireForFiberForm();
        this.show_fiber_yes_case = true;
        this.render2.removeClass(this.fiber_answer_no.nativeElement, "choose");
        break;
      case "No, I will not":
        this.removeRequireForFiberForm();
        this.show_fiber_yes_case = false;
        this.render2.removeClass(this.fiber_answer_yes.nativeElement, "choose");
        break;
    }
  }
  chooseWhereToMove(option: any) {
    this.fiberCancelReasons.get('move_in_japan_or_not').patchValue(option.value);
    switch (option.value) {
      case "In Japan":
        this.render2.removeClass(this.fiber_move_overseas.nativeElement, "choose");
        break;
      case "Overseas":
        this.render2.removeClass(this.fiber_move_in_japan.nativeElement, "choose");
        break;
    }
  }
  leaveResidenDateChange(event: MatDatepickerInputEvent<Date>) {
    const date_format = this.datePipe.transform(event.value, 'yyyy-MM-dd');
    this.fiberCancelReasons.get('leave_residence_date').patchValue(date_format);
  }
  private setRequireForFiberForm() {
    this.fiberCancelReasons.get('leave_residence_date').setValidators(Validators.required);
    this.fiberCancelReasons.get('leave_residence_date').updateValueAndValidity();
    this.fiberCancelReasons.get('move_in_japan_or_not').setValidators(Validators.required);
    this.fiberCancelReasons.get('move_in_japan_or_not').updateValueAndValidity();
  }
  private removeRequireForFiberForm() {
    this.fiberCancelReasons.get('leave_residence_date').clearValidators();
    this.fiberCancelReasons.get('leave_residence_date').updateValueAndValidity();
    this.fiberCancelReasons.get('leave_residence_date').patchValue('');
    this.fiberCancelReasons.get('move_in_japan_or_not').clearValidators();
    this.fiberCancelReasons.get('move_in_japan_or_not').updateValueAndValidity();
    this.fiberCancelReasons.get('move_in_japan_or_not').patchValue('');
  }
}
