
import { DatePipe } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatSnackBar } from '@angular/material';
import { NgNavigatorShareService } from 'ng-navigator-share';
import { Constants } from 'src/app/constants';
import { ReferralCodeService } from 'src/app/services/referral-code.service';
import { RouterHistoryService } from 'src/app/services/router-history.service';
import { Utils } from 'src/app/utils';
import { SubSink } from 'subsink';

@Component({
  selector: 'app-invite-friends',
  templateUrl: './invite-friends.component.html',
  styleUrls: ['./invite-friends.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InviteFriendsComponent implements OnInit, OnDestroy {
  private sub = new SubSink();
  public referral_code: string;
  public expire_date: string;
  public isAvaibleCode: boolean;
  public isCopy: boolean;
  private ngNavigatorShareService: NgNavigatorShareService;

  constructor(
    private datePipe: DatePipe,
    private _snackBar: MatSnackBar,
    private _referralCodeService: ReferralCodeService,
    ngNavigatorShareService: NgNavigatorShareService,
    readonly _routerHistoryService: RouterHistoryService
  ) {
    this.ngNavigatorShareService = ngNavigatorShareService;
  }

  ngOnInit() {
    this.sub.sink = this._referralCodeService.generateReferralCode()
    .subscribe(res => {
      console.log(res);
      if (res.referral_code) {
        this.isAvaibleCode = true;
        this.referral_code = res.referral_code;
        this.expire_date = this.datePipe.transform(res.end_at, 'yyyy/MM/dd');
        console.log(this.expire_date);
      }
    });
  }
  copyClipBoard () {
    const selBox = document.createElement('textarea');
    selBox.value = this.referral_code;
    document.body.appendChild(selBox);
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.isCopy = true;
  }
  inviteFriendSIM() {
    // If run on android ==> send referral code to android native os
    if (Utils.isAndroid()) {
      (window as any).AndroidShareHandler.share(this.referral_code);
    }
    // End send
    if (!this.ngNavigatorShareService.canShare()) {
      alert(`This service is not supported in your Browser`);
      return;
    }
    this.ngNavigatorShareService.share({
        title: 'Your referral code is ' + `${this.referral_code}`,
        text: 'Sign up with Sakura Mobile and get the reward from the link',
        url: Constants.LONGTERM_ORDER + '?' + `referral_code=${this.referral_code}`
    })
    .then( (response) => {
        this._snackBar.dismiss();
        this._snackBar.open(
          'Successfully sent message',
          'OK',
          Constants.defaultSnackBarConfig('success')
        );
    })
    .catch( (error) => {
        if (error.error.code === 20) {
          console.log('Abort due to cancellation of share.:', error);
          return;
        } else {
          this._snackBar.dismiss();
          this._snackBar.open(
            'Failed to sent message',
            'OK',
            Constants.defaultSnackBarConfig('error')
          );
        }
    });
  }
  inviteFriendFiber() {
    // If run on android ==> send referral code to android native os
    if (Utils.isAndroid()) {
      (window as any).AndroidShareHandler.share(this.referral_code);
    }
    // End send
    if (!this.ngNavigatorShareService.canShare()) {
      alert(`This service is not supported in your Browser`);
      return;
    }
    this.ngNavigatorShareService.share({
        title: 'Your referral code is ' + `${this.referral_code}`,
        text: 'Sign up with Sakura Mobile and get the reward from the link',
        url: Constants.FIBER_ORDER + '?' + `referral_code=${this.referral_code}`
    })
    .then( (response) => {
        this._snackBar.dismiss();
        this._snackBar.open(
          'Successfully sent message',
          'OK',
          Constants.defaultSnackBarConfig('success')
        );
    })
    .catch( (error) => {
        if (error.error.code === 20) {
          console.log('Abort due to cancellation of share.:', error);
          return;
        } else {
          this._snackBar.dismiss();
          this._snackBar.open(
            'Failed to sent message',
            'OK',
            Constants.defaultSnackBarConfig('error')
          );
        }
    });
  }
  ngOnDestroy() {
    this.sub.unsubscribe();
  }
}
