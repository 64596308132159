import { Component, OnDestroy, OnInit } from '@angular/core';
import { CancellationService } from 'src/app/services/cancellation.service';
import { Observable } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { SubSink } from 'subsink';
import { MatDialog, MatSnackBar } from '@angular/material';
import { TermConditionsMnpComponent } from '../confirm-paypal-information/term-conditions-mnp/term-conditions-mnp.component';
import { RouterHistoryService } from 'src/app/services/router-history.service';
import { Router } from '@angular/router';
import { Constants } from 'src/app/constants';

@Component({
  selector: 'app-confirmation-mnp-information',
  templateUrl: './confirmation-mnp-information.component.html',
  styleUrls: ['./confirmation-mnp-information.component.scss']
})
export class ConfirmationMnpInformationComponent implements OnInit, OnDestroy {
  private subs = new SubSink();
  isResponding = false;
  back_button = true;
  last_name = '';
  first_name = '';
  last_name_kana = '';
  first_name_kana = '';
  birth_day = '';
  sex = '';
  checked = false;
  mat_content = 'radio_button_unchecked';
  reasonsFormData: FormGroup;
  mnpFormInformation: FormGroup;
  phone_number$: Observable<string> = this.cancelService.line_number$;
  constructor(
    private route: Router,
    private snackbar: MatSnackBar,
    private cancelService: CancellationService,
    private matdialog: MatDialog,
    readonly _routerHistoryService: RouterHistoryService
  ) { }

  ngOnInit() {
    this.subs.sink = this.cancelService.formCancellation$.subscribe(res => this.reasonsFormData = res);
    this.subs.sink = this.cancelService.mnpForm$.subscribe(res => {
      this.mnpFormInformation = res;
      if (res.value !== undefined) {
        this.last_name = res.value.lastName;
        this.first_name = res.value.firstName;
        this.last_name_kana = res.value.lastNameKana;
        this.first_name_kana = res.value.firstNameKana;
        this.birth_day = res.value.contract_date_of_birth;
        this.sex = res.value.contract_sex;
      }
    });
  }
  openTermConditions () {
    this.matdialog.open(TermConditionsMnpComponent);
  }
  toggleChecked() {
    this.checked = !this.checked;
    if (this.checked === false) {
      this.mat_content = 'radio_button_unchecked';
    } else {
      this.mat_content = 'radio_button_checked';
    }
  }
  submitRequest () {
    this.isResponding = true;
    const full_name = this.mnpFormInformation.value.lastName + ' ' + this.mnpFormInformation.value.firstName;
    const full_name_kana = this.mnpFormInformation.value.lastNameKana + ' ' + this.mnpFormInformation.value.firstNameKana;
    this.mnpFormInformation.patchValue({
      contract_name : full_name,
      contract_name_kana: full_name_kana
    });
    this.mnpFormInformation.removeControl('firstName');
    this.mnpFormInformation.removeControl('lastName');
    this.mnpFormInformation.removeControl('firstNameKana');
    this.mnpFormInformation.removeControl('lastNameKana');
    const dataSubmit = { ...this.reasonsFormData.value, ...this.mnpFormInformation.value};
    // console.log('Data Submitted', dataSubmit);
    for (let key in dataSubmit) {
      // console.log(`Data Submitted : ${key} = ${dataSubmit[key]}`);
      if (dataSubmit[key] === undefined || (dataSubmit[key] !== null && dataSubmit[key].indexOf('undefined') > -1)) {
        this.isResponding = false;
        this.snackbar.open('We apologize for the inconvenience, please reload the page by your browser and try again.', 'OK', Constants.defaultSnackBarConfig('error'));
        return;
      }
    }

    const observer = {
      next: (response) => {
        this.isResponding = false;
        console.log('Successful cancellation', response);
        this.route.navigate(['/submitted-paypal']);
      },
      error: (error) => {
        this.isResponding = false;
        this.snackbar.open('Submit failed', 'OK', Constants.defaultSnackBarConfig('error'));
        console.error(error);
      }
    };
    this.subs.sink = this.cancelService.ContractsCancellation_By_Mnp(dataSubmit).subscribe(observer);
  }
  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
