import { Utils } from 'src/app/utils';


export declare type EditablePropertyDataType =
    'color' |
    'date' |
    'datetime-local' |
    'email' |
    'month' |
    'number' |
    'password' |
    'search' |
    'tel' |
    'text' |
    'time' |
    'url' |
    'week';

export class EditableProperty {
    public type: EditablePropertyDataType;
    public key: string;
    public title: string;
    public oldValue: any;
    public newValue: any;
    private _editable: boolean;

    public constructor(title: string, key: string, type: EditablePropertyDataType, initialValue: any, editable: boolean = true) {
        this.title = title;
        this.key = key;
        this.type = type;
        let tmpValue = initialValue;
        if (!Utils.isValue(initialValue)) {
            switch (type) {
                case 'color':
                    tmpValue = null;
                    break;
                case 'date':
                    tmpValue = null;
                    break;
                case 'datetime-local':
                    tmpValue = null;
                    break;
                case 'email':
                    tmpValue = null;
                    break;
                case 'month':
                    tmpValue = null;
                    break;
                case 'number':
                    tmpValue = null;
                    break;
                case 'password':
                    tmpValue = '';
                    break;
                case 'search':
                    tmpValue = null;
                    break;
                case 'tel':
                    tmpValue = null;
                    break;
                case 'text':
                    tmpValue = null;
                    break;
                case 'time':
                    tmpValue = null;
                    break;
                case 'url':
                    tmpValue = null;
                    break;
                case 'week':
                    tmpValue = null;
                    break;
            }
        }
        this.oldValue = tmpValue;
        this.newValue = tmpValue;
        this._editable = editable;
    }

    public get editable(): boolean {
        return this._editable;
    }

    public get modified(): boolean {
        return this.oldValue !== this.newValue;
    }

    public revert(): void {
        this.newValue = this.oldValue;
    }

    public setCommit(): void {
        this.oldValue = this.newValue;
    }
}

export class ComponentModifiedEvent {
    public name: string;
    public modified: boolean;
    public component: any;
    constructor(name: string, modified: boolean, component: any) {
        this.name = name;
        this.modified = modified;
        this.component = component;
    }
}

