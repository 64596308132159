import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-dialog-deposit-required',
  templateUrl: './dialog-deposit-required.component.html',
  styleUrls: ['./dialog-deposit-required.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DialogDepositRequiredComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
