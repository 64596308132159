import { Component, OnInit, ViewEncapsulation, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute, NavigationStart } from '@angular/router';
import { ContractsService } from 'src/app/services/contracts.service';
import { Subscription, Observable } from 'rxjs';
import { SubSink } from 'subsink';
import { Products } from 'src/app/types/products';
import { V1UserAPIService } from 'src/app/services/v1api.service';
import { Invoices } from 'src/app/types/invoice';
import { map, shareReplay, tap } from 'rxjs/operators';
import { Product } from 'src/app/types/product';
import { RouterHistoryService } from 'src/app/services/router-history.service';
@Component({
  selector: 'app-contract-details',
  templateUrl: './contract-details.component.html',
  styleUrls: ['./contract-details.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ContractDetailsComponent implements OnInit, OnDestroy {
  private subs = new SubSink;
  private contract_id: number;

  ProductList$: Observable<Products>;
  products$: Observable<Product[]>;

  public invoices: Invoices;

  contract_code$: Observable<string>;

  public back_button: boolean;

  constructor(
    private _api: V1UserAPIService,
    private _contractsService: ContractsService,
    private _router: Router,
    private _activeRoute: ActivatedRoute,
    readonly _routerHistoryService: RouterHistoryService) {
    }
  ngOnInit() {
    // START : ===== common implementation for login required components =====================
    if (this._api.isLoggingIn(true) === false) {
      this._router.navigate(['/auth/login']);
    } else {
      // back button control menu side
      this.back_button = true;
      // get param id from url
      this.subs.sink = this._activeRoute.params.subscribe(param => {
        this.contract_id = param.id;
      });
      this.subs.sink = this._contractsService.invoices$.subscribe(res => this.invoices = res);
      // Use rxjs
      this.ProductList$ = this._contractsService.getListProduct(this.contract_id)
        .pipe(shareReplay());
      this.contract_code$ = this.ProductList$.pipe(
        map(res => res.data.contract.contract_code)
      );
      this.products$ = this.ProductList$.pipe(
        map(res => res.data.products)
      );
    }
    // END : ===== common implementation for login required components =====
  }
  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }

}
