export class Contract {
    id: number;
    user_id: number;
    status: string;
    contract_code: string;
    start_at: string;
    end_at: string;
    properties: [
        {
            property: {
                contract_property_type_id: number;
                value: string;
                show_status: number;
                updated_by_user_id: number;
                id: number;
                created_at: string;
                updated_at: string;
            },
            type: {
                cpt_id: number;
                key: string;
                value_type: number;
                title: string;
                description: string;
                show_oder: number;
            }
        }
    ];
}
export type Contracts = Array<Contract>;
