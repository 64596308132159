import { Component, OnInit, ViewEncapsulation, OnDestroy, ViewChild, Inject, AfterViewInit } from '@angular/core';
import { DatePipe } from '@angular/common';
import { ContractsService } from 'src/app/services/contracts.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SubSink } from 'subsink';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Dictionaries } from 'src/app/dictionaries';
import { V1UserAPIService } from 'src/app/services/v1api.service';
import { map} from 'rxjs/operators';
import { CancellationService } from 'src/app/services/cancellation.service';
import { RouterHistoryService } from 'src/app/services/router-history.service';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ProductDetailComponent implements OnInit, OnDestroy {
  private subs = new SubSink(); // Subsink: unscrible all subscriptions
  public item: string | null;
  public is_esim: boolean | null;
  public payment_method: string;
  public contract_code: string;
  public contract_start_at: string;

  public device_id: string;
  public user: string;
  public email: string;
  public title_product_details: string;
  public line_number: string;
  public back_button = true;
  public contract_product_id: number;
  // iij get valid coupons
  public monthly_plan: string;
  public remain_data: number;
  public data_usage: number;
  public data_usage_term: string;
  public data_usage_title: string;
  public data_usage_description: string;
  public is_cc = false;
  public is_sim = false;
  // date string
  private current_month_year: string;
  private next_month_year: string;
  // pink mark
  public discount = true;
  // material tables
  public month_title_table: string;
  public day_title_table: string;
  public next_day_title_table: string;
  private newPacketLog = [];
  // time
  public contract_product_start_at: string;
  public checkMNP: boolean;
  private day_of_week = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  private month_by_name = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  public dataSourcePacketLog: MatTableDataSource<any[]>;
  public displayedColumnsPacketLog = ['date', 'month', 'data'];
  @ViewChild('TableOnePaginator', { static: true }) tableOnePaginator: MatPaginator;
  public dataSourceCallHistory: MatTableDataSource<any[]>;
  public displayedColumnsCallHistory = ['date', 'callee', 'duration', 'type1'];
  @ViewChild('TableTwoPaginator', { static: true }) tableTwoPaginator: MatPaginator;

  constructor(
    private _api: V1UserAPIService,
    private _contractServices: ContractsService,
    private _cancellationService: CancellationService,
    private _ActiveRouter: ActivatedRoute,
    readonly _router: Router,
    readonly _routerHistoryService: RouterHistoryService,
    private datePipe: DatePipe,
    @Inject('windowObject') private window: Window
    // @Inject(Window) private window: Window
  ) {
    this.current_month_year = datePipe.transform(Date.now(), 'yyyyMM');
    this.next_month_year = this.find_next_month(this.current_month_year);
    this.item = null;
    this.is_esim = null;
  }

  ngOnInit() {
    // START : ===== common implementation for login required components =====================
    if (this._api.isLoggingIn(true) === false) {
      this._router.navigate(['/auth/login']);
    } else {
      this.subs.sink = this._ActiveRouter.params.subscribe(
        param => {
          this.contract_product_id = param.product_id;
          this._cancellationService.setcontractProductId = this.contract_product_id;
        });
      this.subs.sink = this._contractServices.pay_method$.subscribe(
        pay_method => this.payment_method = pay_method);
      this.subs.sink = this._contractServices.contract$.subscribe(
        contract => {
          this.contract_code = contract.contract_code;
          this.contract_start_at = contract.start_at;
          console.log('contract_code', this.contract_code);
          console.log('contract_start_at', this.contract_start_at);
        }
      );
      this.subs.sink = this._contractServices.start_at_Product$.subscribe(
        start_at => this.contract_product_start_at = start_at);
      this.contract_Product_user_Properties();
    }
    // END : ===== common implementation for login required components =====
    // Check MNP Request
    this.subs.sink = this._cancellationService.checkMnpRequest(this.contract_product_id).subscribe(res => {
      this.checkMNP = res;
      console.log('Check MNP Requested:::', this.checkMNP);
    });
  }
  pageEvent_PacketLog($event) {
    // page 1 == go back page 0 ==> ok
    if ($event.pageIndex === 1 && $event.previousPageIndex === 0) {
      this.nextPage($event.pageIndex);
    } else if ($event.pageIndex === 0 && $event.previousPageIndex === 1) {
      this.previousPage($event.previousPageIndex);
    } else if ($event.pageIndex === 4 && $event.previousPageIndex === 3) {
      this.day_title_table = this.newPacketLog[$event.pageIndex * 7].day_month; // end page
      this.next_day_title_table = this.newPacketLog[($event.pageIndex * 7) + 1].day_month; // end page
    } else if ($event.pageIndex === 3 && $event.previousPageIndex === 4) {
      this.nextPage($event.pageIndex);
    }
    // page 2 ==== go back page 1 ==> ok
    if ($event.pageIndex === 2 && $event.previousPageIndex === 1) {
      this.nextPage($event.pageIndex);
    } else if ($event.pageIndex === 1 && $event.previousPageIndex === 2) {
      this.previousPage($event.previousPageIndex);
    }
    // page 3 === go back page 2 ==> ok
    if ($event.pageIndex === 3 && $event.previousPageIndex === 2) {
      this.nextPage($event.pageIndex);
    } else if ($event.pageIndex === 2 && $event.previousPageIndex === 3) {
      this.previousPage($event.previousPageIndex);
    }
  }

  public contract_Product_user_Properties() {
    this.subs.add(this._contractServices.ContractProduct_UserPropertie(this.contract_product_id)
      .subscribe(
        res => {
          console.log(res);
          
          const [user_properties, contract_product] = res;
          // Get device code 1 and Kpd line number =======================================================
          this.monthly_plan = contract_product.properties[0].type.title;
          // this.is_cc = this.monthly_plan.includes('25GB', 0);
          this.is_cc = false;
          this.is_sim = this.monthly_plan.includes('SIM', 0);
          if (this.is_sim) {
            this.is_esim = this.monthly_plan.includes('eSIM', 0);
            const history_el = document.querySelector<HTMLElement>('app-product-detail div#history');
            if (history_el) {
              history_el.style.display = this.is_sim ? 'block' : 'none';
            }
          }
          this.item = this._contractServices.check_Product_Type(this.monthly_plan);
          if (!this.item) {
            setTimeout(() => {
              console.log("ProductDetailComponent::contract_Product_user_Properties : Retry...");
              this.contract_Product_user_Properties();
            }, 1000);
            return;
          }
          this._contractServices.set_Item = this.item;
          this.email = user_properties.user.email;
          contract_product.properties.forEach(property => {
            if (property.type.key === 'DEVICE CODE 1') {
              this.device_id = property.property.value;
              this._contractServices.set_deviceID = this.device_id;
            } else if (property.type.key === 'KPD LINE NUMBER') {
              this.line_number = property.property.value;
              this._cancellationService.setLineNumber = this.line_number;
            }
          });
          user_properties.properties.forEach(
            user => {
              if (user.type.key === 'USER FULL NAME') {
                this.user = user.property.value;
              }
            }
          );
          if (this.is_sim) {
            // Get packet logg and coupons =====================================================
            this.subs.add(this._contractServices.PacketLog_CallHistory(this.contract_product_id)
              .subscribe(
                responce => {
                  const {message: mess_coupons, data: Coupons } = responce[0];
                  const {message: mess_packetlog, data: packetLog } = responce[1];
                  const packetlog = packetLog.map(
                    item => {
                      const year_str = item.date.slice(0, 4);
                      const month_str = item.date.slice(4, 6);
                      const day_str = item.date.slice(6, 8);
                      const month_name = this.month_by_name[Number(month_str) - 1];
                      const month_by_name = [month_name, day_str].join(' ');
                      const month_day = [month_str, day_str].join('/');
                      const time = new Date(`${year_str}/${month_str}/${day_str}`);
                      const day_by_name = this.day_of_week[time.getDay()];
                      return ({
                        day: day_by_name,
                        day_month: month_by_name,
                        byte_on: item.byte_on,
                        date: month_day,
                        byte_off: item.byte_off,
                        byte: item.byte
                      });
                    }
                  );
                  this.newPacketLog = packetlog.reverse();
                  console.log('this.newPacketLog', this.newPacketLog);
                  // calcurate data usage
                  const current_month = (new Date().getMonth() + 1).toString();
                  this.data_usage = 0;
                  const packetLogInMonth = this.newPacketLog.filter(el => {
                    return parseInt(el.date.split('/')[0], 10) === parseInt(current_month, 10);
                  });
                  packetLogInMonth.reverse().forEach(el => {
                    this.data_usage += parseInt(el.byte_on, 10);
                  });
                  if (packetLogInMonth.length > 0) {
                    this.data_usage_title = 'Amount used :';
                    this.data_usage_description = null;
                    this.data_usage_term = packetLogInMonth[0].day_month + '-' + packetLogInMonth[packetLogInMonth.length - 1].day_month;
                  } else {
                    this.data_usage_title = 'This month\'s usage* :';
                    this.data_usage_description = '* It might take 1 day to update your data usage.<br/>Please come back tomorrow.';
                    this.data_usage_term = null;
                  }
        
                  // packet log paginator ============================
                  this.dataSourcePacketLog = new MatTableDataSource(this.newPacketLog);
                  this.dataSourcePacketLog.paginator = this.tableOnePaginator;
                  this.day_title_table = this.newPacketLog[0].day_month;
                  this.next_day_title_table = this.newPacketLog[6].day_month;
        
                  // Find coupon in current month ============================
                  const coupon_in_month = Coupons.coupons.find(data => this.convert_date(data.expiration) === this.current_month_year);
                  const coupon_next_month = Coupons.coupons.find(data => this.convert_date(data.expiration) === this.next_month_year);
                  // Avaiables coupon in month:: MB
                  if (coupon_in_month === undefined) {
                    this.remain_data = 0;
                  } else if (coupon_next_month === undefined) {
                    this.remain_data = Number(coupon_in_month.coupon);
                  } else {
                    this.remain_data = Number(coupon_in_month.coupon) + Number(coupon_next_month.coupon);
                  }
                },
                // err => console.error(err)
              ));
            // Get call history logs =====================================================================
            const pre_1month = this.find_previous_month(this.current_month_year);
            const pre_2month = this.find_previous_month(pre_1month);
            const call_history$ = this._contractServices.CallHistory(this.contract_product_id, [this.current_month_year, pre_1month, pre_2month]);
            this.subs.sink = call_history$
              .pipe(
                map(([curr, pre1, pre2]) => {
                  if (curr.logs === undefined && pre1.logs === undefined && pre2.logs === undefined) {
                    return [];
                  } else {
                    const curr_reverse = curr.logs === [] ? [] : curr.logs.reverse();
                    const pre1_reverse = pre1.logs === [] ? [] : pre1.logs.reverse();
                    const pre2_reverse = pre2.logs === [] ? [] : pre2.logs.reverse();
                    return [...curr_reverse, ...pre1_reverse, ...pre2_reverse];
                  }
                })
              )
              .subscribe(
                response => {
                  const newHistoryLog = response.map(
                    call => {
                      const des_type = Dictionaries.IIJDisplayInfo(call.destination, call.type1);
                      const new_year = call.date.slice(0, 4);
                      const new_month = call.date.slice(4, 6);
                      const new_day = call.date.slice(6, 8);
                      const last4digits = call.callee.slice(-4);
                      const mask_Number = last4digits.padStart(call.callee.length, '*');
                      return {
                        ...des_type,
                        start_time: call.start_time.slice(0, 5),
                        duration: call.duration.slice(0, 8),
                        date: [new_year, new_month, new_day].join('/'),
                        phone_number: mask_Number
                      };
                    }
                  );
                  console.log(`Call history logs of 3 month before from ${this.current_month_year}::`, newHistoryLog);
                  this.dataSourceCallHistory = new MatTableDataSource(newHistoryLog);
                  this.dataSourceCallHistory.paginator = this.tableTwoPaginator;
                },
                error => console.error(error)
              );
          }
        },
        err => console.log(err)
      ));
  }
  public routeTerminate() {
    console.log('routeTerminate');
    const date_now = Date.now(); // current date: miliseconds
    const dt = Date.parse(this.contract_start_at); // parse start_at to miliseconds
    const start_day = new Date(this.contract_start_at); // convert to type Date
    // const next_3_month = start_day.setMonth(start_day.getMonth() + 3); // set 3 month after : miliseconds

    if (this.checkMNP === false) { return; }
    if (dt <= date_now) {
    //   this._router.navigate(['/policy']);
    // } else {
      this._router.navigate(['/termination-selection']);
    }
  }
  topUpData() {
    // plan top up data
    this._router.navigate(['top-up-select']);
  }
  routeItemConfiguration() {
    this._api.brideData = {
      contract_code: this.contract_code,
      payment_method: this.payment_method,
      item_id: this.contract_product_id,
      item_title: this.item,
      email: this.email,
      device_id: this.device_id,
      line_number: this.line_number
    };
    this._router.navigate(['item-option']);
  }
  openChangeMonthlyPlan() {
    if (this.checkMNP && this.item.length > 0 && this.email.length > 0) {
      // tslint:disable-next-line:max-line-length
      console.log('openChangeMonthlyPlan');
      this.window.open(`https://my.sakuramobile.jp/requests/renew_plan?item=${this.item}&contract_code=${this.contract_code}&payment=${this.payment_method}&email=${this.email}&device_id=${this.device_id}&line_number=${this.line_number}&is_esim=${this.is_esim}`, '_blank');
    } else {
      return;
    }
  }
  openSuspend() {
    if (this.checkMNP && this.item.length > 0 && this.email.length > 0) {
      // tslint:disable-next-line:max-line-length
      console.log('openSuspend');
      this.window.open(`https://my.sakuramobile.jp/requests/renew_plan?item=${this.item}&contract_code=${this.contract_code}&payment=${this.payment_method}&email=${this.email}&device_id=${this.device_id}&line_number=${this.line_number}&suspend=yes`, '_blank');
    } else {
      return;
    }
  }
  openReissueSimCard() {
    if (this.checkMNP && this.item.length > 0 && this.email.length > 0) {
      // tslint:disable-next-line:max-line-length
      console.log('openReissueSimCard');
      this.window.open(`https://www.sakuramobile.jp/contact-us/?user=${this.user}&email=${this.email}&contract_code=${this.contract_code}&device_id=${this.device_id}&line_number=${this.line_number}&subject=Loss%20and%20Damage`, '_blank');
    } else {
      return;
    }
  }
  ngOnDestroy(): void {
    this.subs.unsubscribe(); // Subsink:: unsubscribe all Subscriptions
  }

  public dataExpression(bytes: number): string {
    console.log(bytes);
    if (!bytes) {
      return '0 MB';
    }
    const max_nums = 4;
    const bytes_exp = ((bytes / 1048576).toString()).split('.');
    const left_len = bytes_exp[0].length;
    if (left_len >= max_nums) {
      return bytes_exp[0] + ' MB';
    } else {
      if (bytes_exp.length < 2) {
        return bytes_exp[0] + ' MB';
      } else {
        return bytes_exp[0] + '.' + bytes_exp[1].substring(0, max_nums - left_len) + ' MB';
      }
    }
  }

  public get monthly_plan_s(): string {
    if (!this.monthly_plan) {
      return '';
    }
    return this.monthly_plan.toLocaleLowerCase();
  }

  private nextPage(index: number, pre?: number) {
    this.day_title_table = this.newPacketLog[index * 7].day_month;
    this.next_day_title_table = this.newPacketLog[(index * 7) + 6].day_month;
  }
  private previousPage(pre: number) {
    this.day_title_table = this.newPacketLog[(pre * 7) - 7].day_month;
    this.next_day_title_table = this.newPacketLog[((pre * 7) + 6) - 7].day_month;
  }
  private convert_date(date: string) {
    const new_year = date.slice(0, 4);
    const new_month = date.slice(4, 6);
    return [new_year, new_month].join('');
  }
  private find_next_month(month_str: string) {
    const year = Number(month_str.slice(0, 4));
    const month = Number(month_str.slice(4, 6));
    if (month === 12) {
      return this.datePipe.transform(new Date(year + 1, 0), 'yyyyMM');
    } else {
      return this.datePipe.transform(new Date(year, month), 'yyyyMM');
    }
  }
  private find_previous_month(current: string) {
    const year = Number(current.slice(0, 4));
    const month = Number(current.slice(4, 6));
    if (month === 1) {
      return this.datePipe.transform(new Date(year - 1, 11), 'yyyyMM');
    } else {
      return this.datePipe.transform(new Date(year, month - 2), 'yyyyMM');
    }
  }
}
