<div class="confirmation__jp-bank">
  <div class="widget">
    <app-menu [back_button]="back_button" [title]="phone_number$ | async"></app-menu>
    <div class="widget__contents">
      <div class="widget__contents--title">
        <mat-icon class="icon">cancel</mat-icon>
        <p class="title">Confirmation</p>
      </div>
      <ng-container *ngIf="mnpRequest$ |async; else notMNP">
        <div class="widget__contents--jp-bank-details">
          <p class="title-details">MNP Request Details</p>
          <div class="details">
            <p class="details-left">Last Name</p>
            <p class="details-right">{{last_name}}</p>
            <p class="details-left">Last Name (kana)</p>
            <p class="details-right">{{last_name_kana}}</p>
            <p class="details-left">First Name</p>
            <p class="details-right">{{first_name}}</p>
            <p class="details-left">First Name (Kana)</p>
            <p class="details-right">{{first_name_kana}}</p>
            <p class="details-left">Date Of Birth</p>
            <p class="details-right">{{birth_day}}</p>
            <p class="details-left">Sex</p>
            <p class="details-right">{{sex |titlecase}}</p>
          </div>
        </div>
      </ng-container>
      <ng-template #notMNP>
        <div class="widget__contents--cancellation-date">
          <div class="info-detail">Cancellation Date:</div>
          <div class="info-detail info">
            <span class="sakura-info">{{ terminationDate }}</span>
          </div>
        </div>
        <div class="widget__contents--jp-bank-details">
          <p class="title-details">Cancellation Details</p>
          <div class="details">
            <p class="details-left">Contract Type</p>
            <p class="details-right">{{contractType$ |async}}</p>
            <p class="details-left">Phone Number</p>
            <p class="details-right">{{phone_number$ |async}}</p>
            <p class="details-left">Payment Method</p>
            <p class="details-right">{{ payment_method$ |async}}</p>
            <p class="details-left">Cancellation Date</p>
            <p class="details-right sakura-text">{{terminationDate}}</p>
            <p class="details-left ">Final Payments</p>
            <p class="details-right sakura-text">
              {{(payment_method$ |async) === 'SmartPit Card' ? 'Pay with deposit' : 'Credit/Debit Card' ? finalPayment : ''}}
            </p>
          </div>
        </div>
      </ng-template>

      <div class="widget__contents--deposit-refund">
        <p class="title-refund">Deposit Refund</p>
        <p class="info-refund">
          The remainder of the deposit will be calculated and refunded to the following bank account 1.5 months after
          your contract is cancelled.
        </p>
        <p class="info-refund">
          Depending on the usage fees of the last 2 months, you might not receive a refund from us.
        </p>
      </div>

      <div class="widget__contents--jp-bank-details">
        <div class="details">
          <p class="details-left">Bank Name</p>
          <p class="details-right">{{bank_name}}</p>
          <p class="details-left">Branch Name</p>
          <p class="details-right">{{branch_name}}</p>
          <p class="details-left">Account Type</p>
          <p class="details-right">{{account_type}}</p>
          <p class="details-left">Account Number</p>
          <p class="details-right">{{account_number}}</p>
          <p class="details-left">Account Holder's Name</p>
          <p class="details-right">{{account_holder_name}}</p>
        </div>
      </div>

      <div class="widget__contents--edit-submitted">
        <a (click)="editJPBank()">Edit {{(mnpRequest$ |async) ? 'Submitted' : 'Bank'}} Information</a>
      </div>

      <div class="widget__contents--conditions">
        <mat-icon class="condition--icon" (click)="toggleChecked()">{{
          mat_content
        }}</mat-icon>
        <span class="condition--content">I understand the cancellation policy and confirm the cancel information is
          correct.</span>
      </div>

      <button mat-button class="widget__contents--submitted-request" [ngClass]="{'submitted--button': checked}"
        [disabled]="!checked || isResponding" (click)="submitRequest()">
        Submit Request
      </button>
    </div>
  </div>
</div>
