<div class="card-updated">
  <div class="widget">
    <app-menu [back_button]="true" [title]="'Account'"></app-menu>

    <div class="widget__contents">

      <div class="widget__contents__title">
        <h1 class="title">Card updated</h1>
      </div>
      <div class="widget__contents__information">
        <p>Thank you for updating your payment card information.</p>
        <p>It may take a moment for the information to be updated.</p>
      </div>
      <div class="widget__contents__image">
        <img src="../../../../../assets/images/submitSakuraFlower.svg" alt="sakura submitSakuraFlower" class="image">
      </div>

      <div class="widget__contents__backlink">
        <p class="back--link">Back to <span class="sakura--text" routerLink="/account-information">Account.</span></p>
      </div>
    </div>
  </div>
</div>
