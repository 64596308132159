import { Component, OnInit, ViewEncapsulation, OnDestroy, AfterViewInit } from '@angular/core';
import { CancellationService } from 'src/app/services/cancellation.service';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material';
import { BillingWorkComponent } from '../../contract-cancellation/billing-work/billing-work.component';
import { FormControl, FormBuilder, Validators, FormGroup } from '@angular/forms';
import { SubSink } from 'subsink';
import { Router } from '@angular/router';
import { RouterHistoryService } from 'src/app/services/router-history.service';

@Component({
  selector: 'app-paypal-information',
  templateUrl: './paypal-information.component.html',
  styleUrls: ['./paypal-information.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PaypalInformationComponent implements OnInit, AfterViewInit, OnDestroy {
  private subs = new SubSink();
  back_button = true;
  checked = false;
  mat_content = 'radio_button_unchecked';
  paypal_email = '';
  paypal_holdername = '';
  phone_number$: Observable<string> = this.cancelService.line_number$;
  reasonCancellForm: FormGroup;
  refundPaypal: FormGroup;
  refund_paypal_address: FormControl;
  refund_paypal_holder_name: FormControl;
  constructor(
    private cancelService: CancellationService,
    private matDialog: MatDialog,
    private fb: FormBuilder,
    private route: Router,
    readonly _routerHistoryService: RouterHistoryService
  ) { }

  ngOnInit() {
    this.createForm();
    this.subs.sink = this.cancelService.formCancellation$.subscribe(data => this.reasonCancellForm = data);
    this.subs.sink = this.cancelService.refundPaypalForm$.subscribe(data => {
      if (data.value !== undefined) {
        this.paypal_email = data.value.refund_paypal_address;
        this.paypal_holdername = data.value.refund_paypal_holder_name;
        this.refundPaypal.patchValue({
          refund_paypal_address: this.paypal_email,
          refund_paypal_holder_name: this.paypal_holdername
        });
      }
    });
  }
  ngAfterViewInit() {
  }
  openBilling() {
    this.matDialog.open(BillingWorkComponent);
  }
  createForm() {
   this.refundPaypal = this.fb.group({
      refund_paypal_address: ['', Validators.compose([Validators.required, Validators.email])],
      refund_paypal_holder_name: ['', Validators.required]
    });
  }
  toggleChecked() {
    this.checked = !this.checked;
    if (this.checked === false) {
      this.mat_content = 'radio_button_unchecked';
    } else {
      this.mat_content = 'radio_button_checked';
    }
  }
  submittedPaypal() {
    console.log(this.refundPaypal.valid);
    if (this.checked === true &&  this.refundPaypal.valid) {
      this.cancelService.setRefundPaypal = this.refundPaypal;
      this.route.navigate(['/confirmation-paypal-information']);
    }
  // mnp request paypal information
  // this.subs.add(this.cancelService.mnpRequest$.subscribe(request => {
  //   if (request) {
  //     if (this.checked === true &&  this.refundPaypal.valid) {
  //       this.cancelService.setRefundPaypal = this.refundPaypal;
  //       this.route.navigate(['/confirmation-paypal-information']);
  //     }
  //   } else {
  //     if (this.checked === true && this.refundPaypal.valid) {
  //       const data = { ...this.reasonCancellForm.value, ...this.refundPaypal.value};
  //       console.log('Cancellation Voice + Smartpit + Paypal:::', data);
  //       this.cancelService.ContractsCancellation(data);
  //     }
  //   }
  // }));
  }
  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
