<div class="rate">
  <div class="rate__title">
    <div class="rate__title__header">
      <div class="square-dot"></div>
      <p class="header">Rate our service</p>
    </div>
    <small>(5 stars being great and 1 star being poor.)</small>
  </div>
  <div>
    <div class="rate__star">
      <div class="rate__star__name"><p>Price:</p></div>
      <div class="rate__star__button">
        <button
          mat-icon-button
          class="star--icon"
          *ngFor="let ratingId of ratingArr; index as i"
          (click)="priceRating(i + 1)"
        >
          <mat-icon>{{ showIconPrice(i) }}</mat-icon>
        </button>
      </div>
    </div>

    <div class="rate__star">
      <div class="rate__star__name"><p>Internet Connection:</p></div>
      <div class="rate__star__button">
        <button
          mat-icon-button
          class="star--icon"
          *ngFor="let ratingId of ratingArr; index as i"
          (click)="internetConnectionRating(i + 1)"
        >
          <mat-icon>{{ showIconInternetConnect(i) }}</mat-icon>
        </button>
      </div>
    </div>

    <div class="rate__star">
      <div class="rate__star__name"><p>Customer Support:</p></div>
      <div class="rate__star__button">
        <button
          mat-icon-button
          class="star--icon"
          *ngFor="let ratingId of ratingArr; index as i"
          (click)="customerSupportRating(i + 1)"
        >
          <mat-icon>{{ showIconCustomerSupport(i) }}</mat-icon>
        </button>
      </div>
    </div>
  </div>
</div>
