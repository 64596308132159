
<div class="widget-content__unpaidinvoice content-invoice" *ngIf="!(pay_status_ending)">
    <mat-icon>notification_important</mat-icon>
    <span>Unpaid invoice</span>
</div>
<mat-expansion-panel class="ending">
    <mat-expansion-panel-header>
        <mat-panel-title>
            <img src="../../../../assets/images/promo_set_icon.svg" alt="icon ending options" class="ending_icon">
            <div class="title">
                <p>{{ contract_code_ending }}</p>
                <p>{{ subscription_plan_ending }}</p>
                <p *ngIf="start_day_ending === null ? false : true">Start date :
                    {{start_day_ending | date :"yyyy-MM-dd"}}</p>
                <p *ngIf="end_day_ending === null ? false : true">End date :
                    {{end_day_ending | date :"yyyy-MM-dd"}}</p>
            </div>
        </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="ending__contract-content">
        <div class="ending-contract-details" routerLink="portal/contract-details/{{contract_id_ending}}"
        (click)="getContractDetailEnding(contract_id_ending)">
            <p>Contracts details</p>
            <mat-icon>chevron_right</mat-icon>
        </div>

        <div class="ending-history-payment" (click)="PaymentHistory()" routerLink="portal/payment-history/{{contract_id_ending}}">
            <p>Payments</p>
            <mat-icon>chevron_right</mat-icon>
        </div>
    </div>
</mat-expansion-panel>
